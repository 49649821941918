import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';

function PhoneCustom({
  control, name, label, rules = null, setCountry,
}: any) {
  // eslint-disable-next-line consistent-return
  function checkValue(value: any) {
    if (value[1] === '0') {
      return '33';
    }
    return value;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Box>
          <MuiTelInput
            {...field}
            value={field.value ? checkValue(field.value) : null}
            onChange={(e: any, country) => {
              field.onChange(e);
              setCountry(country);
            }}
            placeholder={label}
            fullWidth
            size="small"
          />
          {fieldState?.error?.message
            && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
        </Box>
      )}
    />
  );
}

export {
  PhoneCustom,
};
