import { useGetMatchingListByProjectIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetMatchingListByProjectId = (projectId: any | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetMatchingListByProjectIdQuery(headerWithAuth, {
    projectId: projectId || '',
  }, { enabled: !!accessToken && !!projectId });
  return queryInfo;
};

export {
  useGetMatchingListByProjectId,
};
