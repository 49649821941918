import React, { useEffect, useMemo } from 'react';
import {
  Autocomplete, Box, TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { customerSourceOptions } from '../../../../data/data';
import { AllowedCustomerSource } from '../../../../api';
import { useUpdateProject } from '../../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  customer_source: string
  business_provider: string
}

function CustomerSource({ queryInfo, businessProvidersOptions, queryInfoAllBusinessProviders }: any) {
  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    // @ts-ignore
    defaultValues: useMemo(() => {
      return {
        customer_source: customerSourceOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.customer_source),
        business_provider: businessProvidersOptions?.find((f: any) => f.value === queryInfo?.data?.getProjectById?.business_provider),
      };
    }, [queryInfo]),
  });

  useEffect(() => {
    reset({
      // @ts-ignore
      customer_source: customerSourceOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.customer_source),
      // @ts-ignore
      business_provider: businessProvidersOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.business_provider),
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      data: {
        customer_source: form?.customer_source?.value,
        business_provider: form?.business_provider?.value,
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
  };

  return (
    <Box
      component="form"
      onBlur={handleSubmit(onSubmit)}
    >
      <Box marginTop={2}>
        <Controller
          name="customer_source"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              blurOnSelect="mouse"
              disableClearable
              options={customerSourceOptions}
              // @ts-ignore
              value={field.value}
              onChange={(e, value) => {
                field.onChange(value);
              }}
              renderInput={
                (params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Source"
                    placeholder="Sélectionner"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  />
                )
              }
            />
          )}
        />
      </Box>
      <Box marginTop={3}>
        {
          // @ts-ignore
          watch('customer_source')?.value === AllowedCustomerSource.BUSINESS_PROVIDER
          && queryInfoAllBusinessProviders.isSuccess
          && queryInfoAllBusinessProviders?.data?.findAllBusinessProviders?.length > 0
          && (
            <Controller
              name="business_provider"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  blurOnSelect="mouse"
                  disableClearable
                  options={queryInfoAllBusinessProviders.isLoading ? [] : businessProvidersOptions}
                  // @ts-ignore
                  value={field.value}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  renderInput={
                    (params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Apporteur d'affaire"
                        placeholder="Sélectionner"
                        fullWidth
                        variant="outlined"
                        color="primary"
                      />
                    )
                  }
                />
              )}
            />
          )
        }
      </Box>
    </Box>
  );
}

export {
  CustomerSource,
};
