import { Controller } from 'react-hook-form';
import React from 'react';
import {
  Box, TextField, Typography,
} from '@mui/material';
import { NumberFormatSurface } from './format/number-format-surface';

function SurfaceTextField({
  control, name, label, rules = null, read, variant = 'outlined',
}: any) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field, fieldState,
      }) => {
        return (
          <Box>
            <TextField
              label={label}
              value={field.value || ''}
              size="small"
              variant={variant}
              placeholder="0.00 m2"
              onChange={(e) => field.onChange(e.target.value)}
              onBlur={field.onBlur}
              InputProps={{
                inputComponent: NumberFormatSurface as any,
                readOnly: read,
              }}
              fullWidth
            />
            {fieldState?.error?.message
              && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
          </Box>
        );
      }}
    />
  );
}

export {
  SurfaceTextField,
};
