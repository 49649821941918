import React, { useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InsightsIcon from '@mui/icons-material/Insights';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyIcon from '@mui/icons-material/Money';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { useUpdateLoanBudgetRecommended } from '../../../../hooks/mutation/update-loan-budget-recommended-mutation.hook';
import { euro } from '../../../../common/inputs/format/euro-format';

interface IpropsStats {
  icon: any
  info: string | any
  label: string | any
}

interface IFormInput {
  loan_budget_recommended: string
}

function FinancialStatistics({ queryInfo } : any) {
  const {
    control, reset, handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return queryInfo?.data?.getProjectById?.finance;
    }, [queryInfo]),
  });

  useEffect(() => {
    reset(queryInfo?.data?.getProjectById?.finance);
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateLoanBudgetRecommended({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      loanBudgetRecommended: Number(form?.loan_budget_recommended),
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={4}>
        <StatisticsLeft
          icon={null}
          info={(
            <Box
              component="form"
              onBlur={handleSubmit(onSubmit)}
              style={{
                display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center',
              }}
            >
              <CurrencyTextFieldCustom
                variant="standard"
                label="Enveloppe budgétaire"
                name="loan_budget_recommended"
                control={control}
              />
            </Box>
          )}
          label={null}
        />
        <StatisticsLeft
          icon={<DataUsageIcon sx={{ color: 'success.light' }} fontSize="large" />}
          info={queryInfo?.data?.getProjectById?.finance?.monthly_loan_recommended ? euro.format(queryInfo?.data?.getProjectById?.finance?.monthly_loan_recommended) : '0 €'}
          label="Mensualité"
        />
        <Grid item xs={1} justifyContent="space-between" flexDirection="column" display="flex">
          <StatisticsRight
            icon={<AttachMoneyIcon sx={{ color: 'success.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.total_all_yearly_income ? euro.format(queryInfo?.data?.getProjectById?.finance?.total_all_yearly_income) : '0 €'}
            label="Total revenus"
          />
          <StatisticsRight
            icon={<MoneyOffIcon sx={{ color: 'error.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.total_all_yearly_taxes ? euro.format(queryInfo?.data?.getProjectById?.finance?.total_all_yearly_taxes) : '0 €'}
            label="Total Impôts"
          />
        </Grid>
        <Grid item xs={1} justifyContent="space-between" flexDirection="column" display="flex">
          <StatisticsRight
            icon={<AttachMoneyIcon sx={{ color: 'success.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.total_all_yearly_savings ? euro.format(queryInfo?.data?.getProjectById?.finance?.total_all_yearly_savings) : '0 €'}
            label="Total épargne"
          />
          <StatisticsRight
            icon={<MoneyOffIcon sx={{ color: 'error.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.total_all_yearly_cost ? euro.format(queryInfo?.data?.getProjectById?.finance?.total_all_yearly_cost) : '0 €'}
            label="Total charges"
          />
        </Grid>
        <Grid item xs={1} justifyContent="space-between" flexDirection="column" display="flex">
          <StatisticsRight
            icon={<InsightsIcon sx={{ color: 'success.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.total_max_budget ? euro.format(queryInfo?.data?.getProjectById?.finance?.total_max_budget) : '0 €'}
            label="Simulation max"
          />
        </Grid>
        <Grid item xs={1} justifyContent="space-between" flexDirection="column" display="flex">
          <StatisticsRight
            icon={<DataUsageIcon sx={{ color: 'success.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.monthly_loan_max ? euro.format(queryInfo?.data?.getProjectById?.finance?.monthly_loan_max) : '0 €'}
            label="Mensualité max"
          />
        </Grid>
        <Grid item xs={1} justifyContent="space-between" flexDirection="column" display="flex">
          <StatisticsRight
            icon={<MoneyIcon sx={{ color: 'success.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.equity_contribution ? euro.format(queryInfo?.data?.getProjectById?.finance?.equity_contribution) : '0 €'}
            label="Apport recommandé par LSI"
          />
        </Grid>
        {/*        <Grid item container xs={1} justifyContent="space-between" flexDirection="column">
          <StatisticsRight
            icon={queryInfo?.data?.getProjectById?.finance?.viability >= 50
              ? <ThumbUpOffAltIcon sx={{ color: 'success.light', marginRight: 3 }} fontSize="large" />
              : <ThumbDownOffAltIcon sx={{ color: 'error.light', marginRight: 3 }} fontSize="large" />}
            info={queryInfo?.data?.getProjectById?.finance?.viability ? `${queryInfo?.data?.getProjectById?.finance?.viability} %` : '0 %'}
            label="Viabilité"
          />
        </Grid> */}
      </Grid>
    </Box>
  );
}

function StatisticsLeft({ icon, info, label }: IpropsStats) {
  return (
    <Grid item xs={1}>
      <Item sx={{ height: 108 }} elevation={0}>
        {icon}
        <Typography color="text.primary" variant="h6">{info}</Typography>
        <Typography variant="caption">{label}</Typography>
      </Item>
    </Grid>
  );
}

function StatisticsRight({ icon, info, label }: IpropsStats) {
  return (
    <Item
      sx={{
        height: 48,
        flexDirection: 'row',
      }}
      elevation={0}
    >
      {icon}
      <Box>
        <Typography textAlign="center" color="text.primary" variant="h6">{info}</Typography>
        <Typography textAlign="center" variant="caption">{label}</Typography>
      </Box>
    </Item>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: '16px',
  alignItems: 'center',
  display: 'flex',
  textAlign: 'left',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export {
  FinancialStatistics,
};
