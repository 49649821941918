import React from 'react';
import { Button } from '@mui/material';
import { Workbook } from 'exceljs';
// @ts-ignore
import * as fs from 'file-saver';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns';
import { AllowedDocumentLabel } from '../../../api';
import { offersOptions, statusOptions } from '../../../data/data';
import { useGetProjectsByIds } from '../../../hooks/query/get-projects-by-ids-query.hook';

function ExportExcel({ selectionModel }: any) {
  const queryInfo = useGetProjectsByIds(selectionModel);

  const DataExcelRib = () => {
    const formattedData = queryInfo?.data?.getProjectsByIds?.map((e: any) => ({
      name: e?.isCustomerAlready ? `✓ ${e?.name}` : e?.name,
      offer: offersOptions.find((f) => f.value === e?.offer)?.label,
      email: e?.createdBy?.email,
      status: statusOptions.find((f) => f.value === e?.status)?.label,
      total_max_budget: e?.finance?.total_max_budget,
      loan_budget_recommended: e?.finance?.loan_budget_recommended,
      firstEventTaken: e?.createdBy?.first_event_taken ? 'Oui' : 'Non',
      previousAppointment: e?.previousAppointment ? new Date(e?.previousAppointment) : '',
      nextAppointment: e?.nextAppointment ? new Date(e?.nextAppointment) : '',
      nextReminder: e?.nextReminder ? new Date(e?.nextReminder) : '',
      matched: e?.matched ? 'Oui' : 'Non',
      signed_date: e?.documents.find((f: any) => f?.type === AllowedDocumentLabel.CONTRAT)?.createdAt ? format(new Date(e?.documents.find((f: any) => f?.type === AllowedDocumentLabel.CONTRAT)?.createdAt), 'dd/MM/yyyy') : '',
      customers_number: Number(e?.customers?.length),
      creation_date: format(new Date(e?.createdAt), 'dd/MM/yyyy'),
      typeformOffer: offersOptions.find((f) => f.value === e?.createdBy?.typeformInfo?.offer)?.label,
      calendlyName: e?.createdBy?.calendlyEvents?.length > 0 && e?.createdBy?.calendlyEvents[0]?.name ? e?.createdBy?.calendlyEvents[0]?.name : null,
    }));
    return formattedData;
  };

  const downloadExcel = (data: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`lsi-${format(new Date(), 'dd-MM-yyyy')}`);
    const header = [
      'Nom',
      'Offre',
      'Email',
      'Statut',
      'Sim. max',
      'Budget',
      'Rdv',
      'Dernier Rdv',
      'Prochain Rdv',
      'Relance',
      'Match',
      'Signature',
      'Inv.',
      'Création',
      'Typeform',
      'Nom Calendly',
    ];

    worksheet.getRow(3).values = header;
    const fName = `import-projet(s)-lsi-${format(new Date(), 'dd-MM-yyyy')}`;

    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };

    worksheet.getRow(3).alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getRow(3).font = {
      size: 14,
      bold: true,
    };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0052CC' },
    };

    worksheet.columns = [
      { width: 28 }, // Nom
      { width: 12 }, // Offre
      { width: 28 }, // Email
      { width: 24 }, // Statut
      { width: 20 }, // Sim. max
      { width: 20 }, // Budget
      { width: 10 }, // Rdv
      { width: 20 }, // Dernier Rdv
      { width: 20 }, // Prochain Rdv
      { width: 20 }, // Relance
      { width: 10 }, // Match
      { width: 20 }, // Signature
      { width: 10 }, // Inv.
      { width: 20 }, // Création
      { width: 12 }, // Typeform
      { width: 28 }, // Nom Calendly
    ];
    for (const x1 of data) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of x2) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }
    worksheet.getColumn(4).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.getColumn(5).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.mergeCells('A1', 'P1');
    worksheet.getCell('A1').value = `Import projet(s) LSI - ${format(new Date(), 'dd/MM/yyyy')}`;
    // eslint-disable-next-line no-shadow
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fName}-${new Date().valueOf()}.xlsx`);
    });
  };

  return (
    <Button
      disabled={selectionModel.length === 0}
      color="success"
      size="small"
      endIcon={<FileDownloadIcon />}
      variant="outlined"
      onClick={() => downloadExcel(DataExcelRib())}
    >
      Export excel
    </Button>
  );
}

export {
  ExportExcel,
};
