import { useGetAccessToken } from '../auth/get-access-token.hook';
import { useGetProjectsByIdsQuery } from '../../api';

const useGetProjectsByIds = (selectionModel: any) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();

  const queryInfo = useGetProjectsByIdsQuery(headerWithAuth, {
    ids: selectionModel || '',
  }, { enabled: !!accessToken });

  return queryInfo;
};

export {
  useGetProjectsByIds,
};
