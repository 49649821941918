import { useGetUserJourneyBeforeAccountCreationQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetUserJourneyBeforeAccountCreation = (userId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();

  const queryInfo = useGetUserJourneyBeforeAccountCreationQuery(headerWithAuth, {
    userId: userId || '',
  }, { enabled: !!accessToken && !!userId });

  return queryInfo;
};

export {
  useGetUserJourneyBeforeAccountCreation,
};
