import { Controller } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';
import React from 'react';

function TextFieldCustom({
  name,
  control,
  label,
  type = 'text',
  read,
  required = false,
  placeholder = 'Écrire ici ...',
  numberMin,
  numberMax,
  rules = null,
}: any) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field, fieldState,
      }) => (
        <>
          <TextField
            {...field}
            type={type}
            required={required}
            placeholder={placeholder}
            label={label}
            value={type === 'number' ? field.value?.toString() || '' : field.value || ''}
            onChange={(e) => field.onChange(e.target.value)}
            fullWidth
            size="small"
            inputProps={{
              min: numberMin,
              max: numberMax,
            }}
            InputProps={{
              readOnly: read,
            }}
          />
          {fieldState?.error?.message && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
        </>
      )}
    />
  );
}

export {
  TextFieldCustom,
};
