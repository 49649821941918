import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { getTime, isValid } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import {
  employmentContractOptions,
  genderOptions,
  booleanOptions,
  seniorityOptions,
  seniority2Options,
  tabBankOptions,
  tabCountryOptions,
  weddingPlanOptions,
} from '../../../../data/data';
import 'react-phone-input-2/lib/style.css';
import { AutocompleteCustom } from '../../../../common/inputs/autocompletecustom';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { CalendarCustom } from '../../../../common/inputs/calendarcustom';
import { PhoneCustom } from '../../../../common/inputs/phonecustom';
import AdressCustom from '../../../../common/inputs/adresscustom';
import { DisplayPersonalInfos } from './displaypersonalinfos';
import { useUpdatePersonalInfo } from '../../../../hooks/mutation/update-personal-info-mutation.hook';

interface IFormInput {
  gender: string
  last_name: string
  first_name: string
  place_of_birth: string
  nationality: string
  birthdate: string
  is_married: boolean | string
  wedding_plan: string
  number_of_children: number
  email: string
  phone: string
  street_address: string
  job: string
  employer_name: string
  employment_contract: string
  seniority: string
  main_bank: string
  other_banks: []
}

export interface ISelectorOptions {
  key: string
  value: string
}

function PersonalInfos({ data, queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const countryCode = data?.personal_info?.phone && parsePhoneNumber(data?.personal_info?.phone, 'FR');
  const [country, setCountry] = useState<any>({
    countryCode: countryCode?.country ? countryCode?.country : 'FR',
    countryCallingCode: countryCode?.countryCallingCode ? countryCode?.countryCallingCode : '33',
  });

  const [toModify, setToModify] = useState(false);
  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return {
        ...data?.personal_info,
        birthdate: new Date(data?.personal_info?.birthdate),
        is_married: booleanOptions.find((f) => f.value === data?.personal_info?.is_married)?.label,
        other_banks: data?.personal_info?.other_banks
          .map((bank: string) => tabBankOptions.find((elem) => elem.value === bank))
          || [],
      };
    }, [data, searchParams.get('customerId')]),
  });

  useEffect(() => {
    reset({
      ...data?.personal_info,
      birthdate: new Date(data?.personal_info?.birthdate),
      is_married: booleanOptions.find((f) => f.value === data?.personal_info?.is_married)?.label,
      other_banks: data?.personal_info?.other_banks
        .map((bank: string) => tabBankOptions.find((elem) => elem.value === bank))
        || [],
    });
  }, [queryInfo.isSuccess, searchParams.get('customerId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdatePersonalInfo({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  function capitalize(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onSubmit = (form: any): any => {
    const formValid: any = {
      personalInformations: {
        gender: form?.gender?.value,
        last_name: form?.last_name?.toLowerCase().split(' ').map(capitalize).join(' '),
        first_name: form?.first_name?.toLowerCase().split(' ').map(capitalize).join(' '),
        place_of_birth: form?.place_of_birth,
        nationality: form?.nationality?.value,
        birthdate: getTime(form?.birthdate),
        is_married: form?.is_married?.value,
        wedding_plan: form?.wedding_plan?.value,
        number_of_children: Number(form?.number_of_children),
        email: form?.email,
        phone: form?.phone?.replaceAll(' ', ''),
        street_address: {
          address_line_1: form?.street_address?.address_line_1,
          country: form?.street_address?.country,
          locality: form?.street_address?.locality,
          zipcode: form?.street_address?.zipcode,
          region: '',
        },
        job: form?.job,
        employer_name: form?.employer_name,
        employment_contract: form?.employment_contract?.value,
        seniority: form?.seniority?.value,
        main_bank: form?.main_bank?.value,
        other_banks: form?.other_banks?.map((e: any) => e?.value),
      },
      customerId: data?._id,
    };
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <>
      {!toModify
        ? (
          <Box>
            <DisplayPersonalInfos data={data?.personal_info} />
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                onClick={() => {
                  setToModify(true);
                }}
                sx={{ textTransform: 'none' }}
                variant="outlined"
                endIcon={<EditIcon />}
              >
                Modifier
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between">
              <Box display="flex">
                <List sx={{ width: '50%' }}>
                  <Typography color="primary" variant="h5" marginBottom={2}>Informations personnelles</Typography>
                  <ListItem
                    disablePadding
                  >
                    <Grid container item direction="column" display="flex">
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          control={control}
                          name="gender"
                          label="Genre"
                          tab={genderOptions}
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <TextFieldCustom
                          control={control}
                          name="first_name"
                          label="Prénom"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <TextFieldCustom
                          control={control}
                          name="last_name"
                          label="Nom de famille"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          tab={tabCountryOptions}
                          control={control}
                          name="nationality"
                          label="Nationalité"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <CalendarCustom
                          control={control}
                          name="birthdate"
                          minDate={new Date('01/01/1900')}
                          maxDate={new Date()}
                          label="Date de naissance"
                          rules={{
                            validate: (value: string) => isValid(new Date(value)) && getTime(new Date(value)) >= getTime(new Date('01/01/1900')) && getTime(new Date(value)) <= getTime(new Date()),
                          }}
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <TextFieldCustom
                          control={control}
                          name="place_of_birth"
                          label="Lieu de naissance"
                        />
                      </Box>
                    </Grid>
                  </ListItem>
                  <Typography color="primary" variant="h5" marginBottom={2} paddingTop={3}>
                    Situtation professionnelle
                  </Typography>
                  <ListItem
                    disablePadding
                  >
                    <Grid container item direction="column" display="flex">
                      <Box paddingY={1} width={300}>
                        <TextFieldCustom
                          control={control}
                          name="job"
                          label="Profession"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <TextFieldCustom
                          control={control}
                          name="employer_name"
                          label="Employeur"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          tab={employmentContractOptions}
                          control={control}
                          name="employment_contract"
                          label="Contrat de travail"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          // @ts-ignore
                          tab={watch('employment_contract')?.value === 'CDI' || watch('employment_contract') === 'CDI' ? seniorityOptions : seniority2Options}
                          control={control}
                          name="seniority"
                          label="Ancienneté"
                        />
                      </Box>
                    </Grid>
                  </ListItem>
                </List>
                <List sx={{ width: '50%' }}>
                  <Typography color="primary" variant="h5" marginBottom={2}>Situation familiale</Typography>
                  <ListItem disablePadding>
                    <Grid container item direction="column" display="flex">
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          tab={booleanOptions}
                          control={control}
                          name="is_married"
                          label="Marié ou pacsé"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          tab={weddingPlanOptions}
                          control={control}
                          name="wedding_plan"
                          label="Sous quel régime"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <TextFieldCustom
                          type="number"
                          control={control}
                          name="number_of_children"
                          label="Nombre d'enfants"
                        />
                      </Box>
                    </Grid>
                  </ListItem>
                  <Typography color="primary" variant="h5" marginBottom={2} paddingTop={3}>
                    Informations bancaire
                  </Typography>
                  <ListItem disablePadding>
                    <Grid container item direction="column" display="flex">
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          tab={tabBankOptions}
                          control={control}
                          name="main_bank"
                          label="Banque principale"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <AutocompleteCustom
                          tab={tabBankOptions}
                          control={control}
                          name="other_banks"
                          label="Autre banque"
                          multiple
                        />
                      </Box>
                    </Grid>
                  </ListItem>
                  <Typography color="primary" variant="h5" marginBottom={2} paddingTop={3}>Contact</Typography>
                  <ListItem disablePadding>
                    <Grid container item direction="column" display="flex">
                      <Box paddingY={1} width={300}>
                        <AdressCustom control={control} name="street_address" label="Adresse" />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <TextFieldCustom
                          type="email"
                          control={control}
                          name="email"
                          label="E-mail"
                        />
                      </Box>
                      <Box paddingY={1} width={300}>
                        <PhoneCustom
                          control={control}
                          name="phone"
                          label="+33 X XX XX XX XX"
                          setCountry={setCountry}
                          rules={{
                            validate: (value: string) => {
                              return isValidPhoneNumber(value, country?.countryCode?.toUpperCase()) || `Merci de renseigner un format valide (+${country?.countryCallingCode} XX XX XX XX).`;
                            },
                            required: 'Veuillez renseigner votre numéro de téléphone',
                            minLength: 1,
                          }}
                        />
                      </Box>
                    </Grid>
                  </ListItem>
                </List>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
}

export {
  PersonalInfos,
};
