import {
  Box, Button, Divider, Paper, Tooltip, Typography,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { CalendlyInfo } from './calendly-info';
import { TypeformInfo } from './typeforminfo';
import { AlreadyCustomer } from './already-customer';

function RightColumn({ queryInfo }: any) {
  const navigate = useNavigate();

  return (
    <Item elevation={0}>
      <Box>
        <Box>
          <Tooltip title={queryInfo?.data?.findClientById?.email ? queryInfo?.data?.findClientById?.email : ''} placement="bottom-start">
            <Typography align="left" variant="h6" fontWeight="bold" color="text.primary" marginBottom={2}>
              {queryInfo?.data?.findClientById?.email}
            </Typography>
          </Tooltip>
          <Typography marginTop={2} align="left" variant="body1" color="text.secondary" marginBottom={2}>
            {`Crée le ${queryInfo?.data?.findClientById?.createdAt && format(new Date(queryInfo?.data?.findClientById?.createdAt), 'dd/MM/yyyy')}`}
          </Typography>
          {queryInfo?.data?.findClientById?.calendlyEvents && queryInfo?.data?.findClientById?.calendlyEvents?.length > 0
            && (
              <>
                <Divider />
                <CalendlyInfo queryInfo={queryInfo} />
              </>
            )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="flex-end">
          <Divider sx={{ marginY: 1 }} />
          <TypeformInfo queryInfo={queryInfo} />
          <Box width="100%" display="flex" justifyContent="space-between" marginTop={2} marginBottom={1}>
            <Typography align="left" variant="body2" color="primary">PROJET :</Typography>
            <Typography
              variant="body2"
              color="primary"
            >
              {`Nb : ${queryInfo?.data?.findClientById?.projects?.length}`}
            </Typography>
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            <Button
              onClick={() => navigate(`/project-list/projectcard?projectId=${queryInfo?.data?.findClientById?.projects[0]}`)}
              disabled={queryInfo?.data?.findClientById?.projects?.length === 0}
              sx={{ fontWeight: 'bold' }}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Aller vers projet
            </Button>
          </Box>
        </Box>
        <AlreadyCustomer queryInfo={queryInfo} />
      </Box>
    </Item>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto',
}));

export {
  RightColumn,
};
