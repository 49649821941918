import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Button, ClickAwayListener, InputBase, Modal, Typography,
} from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useInstantSearch, useSearchBox } from 'react-instantsearch-hooks-web';
import { flatTypeOptions, propertyTypeOptions } from '../../../../data/data';
import { useCreateMatching } from '../../../../hooks/mutation/create-matching-mutation.hook';

function SearchBarMatched({ propertyInfo, matchingList, ...props }: any) {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [projectId, setProjectId] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<any>(null);
  const [values, setValues] = useState('');
  const [search, setSearch] = useState({ projects: [], properties: [] });

  const { refine } = useSearchBox(props);
  const { results } = useInstantSearch();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const formatHitFromSearch = (hit: any) => ({ ...hit, _id: hit.objectID });

  useEffect(() => {
    if (values) {
      setOpen(true);
    }
  }, [values]);

  const mutate = useCreateMatching({
    onSuccess: async (resp: any) => {
      setProjectId(null);
      setModalMessage(null);
      setOpenModal(false);
      matchingList.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
      setOpenModal(false);
    },
  });

  useEffect(() => {
    const reduce = results?.hits
      .map(formatHitFromSearch)
      .reduce((prev, next) => {
        if ('ad_link' in next) {
          prev.properties.push(next);
        } else {
          prev.projects.push(next);
        }
        return prev;
      }, { projects: [], properties: [] });
    setSearch(reduce);
  }, [results]);

  const toggleSearch = () => {
    setOpen(true);
  };

  const handleClickAway = (setState: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; }) => {
    setState(false);
  };

  function handleSearch(event: any) {
    refine(event.target.value);
    setValues(event.target.value);
  }

  return (
    <Search>
      <Modal open={openModal}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Box
            width="500px"
            bgcolor="background.paper"
            borderRadius="16px"
            marginBottom={2}
            display="flex"
            padding={3}
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            flexDirection="column"
          >
            <CloseIcon
              sx={{
                position: 'absolute', top: 5, right: 5, cursor: 'pointer',
              }}
              onClick={() => {
                setProjectId(null);
                setModalMessage(null);
                setOpenModal(false);
              }}
            />
            <Typography gutterBottom fontWeight={600}>Voulez-vous vraiment associer le bien</Typography>
            <Typography
              gutterBottom
              color="primary"
              fontWeight={600}
            >
              {propertyInfo?.data?.getPropertiesById?.type ? `${propertyTypeOptions?.find((f) => f.value === propertyInfo?.data?.getPropertiesById?.type)?.label} ${propertyInfo?.data?.getPropertiesById?.description?.flat_type ? `type ${flatTypeOptions?.find((f) => f.value === propertyInfo?.data?.getPropertiesById?.description?.flat_type)?.label}` : ''} ${propertyInfo?.data?.getPropertiesById?.description?.surface ? `de ${propertyInfo?.data?.getPropertiesById?.description?.surface}m2` : ''}` : ''}
            </Typography>
            <Typography
              gutterBottom
              color="primary"
              fontWeight={600}
            >
              {propertyInfo?.data?.getPropertiesById?.localisation?.street_address?.address_line_1}
            </Typography>
            <Typography gutterBottom fontWeight={600}>avec le projet</Typography>
            <Typography color="primary" fontWeight={600} marginBottom={5}>{modalMessage}</Typography>
            <QuestionMarkIcon fontSize="large" />
            <Box display="flex" marginTop={5} justifyContent="space-around" alignItems="center" width="100%">
              <Button
                color="error"
                variant="contained"
                sx={{
                  textTransform: 'inherit',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  setProjectId(null);
                  setModalMessage(null);
                  setOpenModal(false);
                }}
              >
                Annuler
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  textTransform: 'inherit',
                  fontWeight: 'bold',
                }}
                onClick={() => mutate({
                  data: {
                    projectId,
                    // @ts-ignore
                    propertyId: searchParams.get('propertyId'),
                  },
                })}
              >
                Confirmer
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <ClickAwayListener onClickAway={() => handleClickAway(setOpen)}>
        <StyledInputBase
          placeholder="Rechercher…"
          onFocus={toggleSearch}
          value={values}
          onChange={(e) => handleSearch(e)}
          inputProps={{ 'aria-label': 'search' }}
        />
      </ClickAwayListener>
      {
        open
        // @ts-ignore
        && search.projects?.length > 0
        && (
          <Box sx={{
            position: 'absolute',
            backgroundColor: 'background.paper',
            border: '1px solid',
            paddingY: 2,
            borderColor: 'divider',
            width: '100%',
            maxHeight: 300,
            overflow: 'auto',
            marginTop: 1,
            borderRadius: '16px',
          }}
          >
            {
              // @ts-ignore
              search?.projects?.length > 0 && search?.projects?.map((e: any) => {
                return (
                  <Box
                    key={e?._id}
                    sx={{
                      width: '100%',
                      height: 30,
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      ':hover': {
                        backgroundColor: 'background.default',
                      },
                    }}
                    onClick={() => {
                      setProjectId(e?._id);
                      setModalMessage(`${e?.name || ''} ${e?.customers[0]?.personal_info?.first_name ? '|' : ''} ${e?.customers[0]?.personal_info?.first_name || ''} ${e?.customers[0]?.personal_info?.last_name || ''}`);
                      setOpenModal(true);
                    }}
                    display="flex"
                    alignItems="center"
                    paddingX={2}
                  >
                    <Typography
                      variant="body2"
                      noWrap
                      color="text.secondary"
                    >
                      {`${e?.name || ''} ${e?.customers[0]?.personal_info?.first_name ? '|' : ''} ${e?.customers[0]?.personal_info?.first_name || ''} ${e?.customers[0]?.personal_info?.last_name || ''}`}
                    </Typography>
                  </Box>
                );
              })
            }
          </Box>
        )
      }
    </Search>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.default,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(5),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: 15,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '32ch',
      },
    },
  },
}));

export {
  SearchBarMatched,
};
