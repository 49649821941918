import React from 'react';
import {
  Box,
} from '@mui/material';

function Infos() {
  return (
    <Box />
  );
}

export {
  Infos,
};
