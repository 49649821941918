import {
  Box, Divider, Paper,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import {
  AllowedStatus,
} from '../../../../api';
import { Offers } from './offers';
import { Status } from './status';
import { CustomerSource } from './customersource';
import { InvestmentMethod } from './investment_method';
import { ClosedStatus } from './closed-status';
import { CalendlyInfo } from './calendly-info';
import { Typology } from './typology';
import { SectionTop } from './section-top';
import { Project } from './project';
import { useGetProjectById } from '../../../../hooks/query/get-project-by-id-query.hook';
import { useFindAllBusinessProviders } from '../../../../hooks/query/find-all-business-providers-query.hook';
import { TypeformInfo } from './typeform-infos';
import { Referral } from './referral';
import { DiscoverySheet } from './discovery-sheet/discovery-sheet';

function RightColumn() {
  const [searchParams] = useSearchParams();
  const queryInfo = useGetProjectById(searchParams.get('projectId'));
  const queryInfoAllBusinessProviders = useFindAllBusinessProviders();

  const businessProvidersOptions = queryInfoAllBusinessProviders?.data?.findAllBusinessProviders?.map((e) => ({
    value: e?.name.replaceAll(' ', '_').toUpperCase(),
    label: e?.name,
  }));

  return (
    <Item elevation={0}>
      <Box>
        <Box>
          <SectionTop queryInfo={queryInfo} />
          <Divider sx={{ marginY: 2 }} />
          {/* <ProgressBar queryInfo={queryInfo} /> */}
          <Offers queryInfo={queryInfo} />
          <Box marginTop={2}>
            {queryInfo?.isLoading ? null : <Status queryInfo={queryInfo} />}
          </Box>
          {queryInfo.data?.getProjectById?.status === AllowedStatus.PROJET_FERME
          && (
          <Box marginTop={2}>
            {queryInfo?.isLoading ? null : <ClosedStatus queryInfo={queryInfo} />}
          </Box>
          )}
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <Project queryInfo={queryInfo} />
        <Divider sx={{ marginY: 2 }} />
        {queryInfo.isSuccess && queryInfoAllBusinessProviders.isSuccess
          && (
            <CustomerSource
              queryInfo={queryInfo}
              businessProvidersOptions={businessProvidersOptions}
              queryInfoAllBusinessProviders={queryInfoAllBusinessProviders}
            />
          )}
        {queryInfo.isSuccess
          && (
            <>
              <InvestmentMethod
                queryInfo={queryInfo}
              />
              <Divider sx={{ marginY: 2 }} />
              <CalendlyInfo queryInfo={queryInfo} />
              <Divider sx={{ marginY: 2 }} />
              <Typology queryInfo={queryInfo} />
              <Divider sx={{ marginY: 2 }} />
              <Referral queryInfo={queryInfo} />
              <Divider sx={{ marginY: 2 }} />
              <DiscoverySheet queryInfo={queryInfo} />
            </>
          )}
        <TypeformInfo queryInfo={queryInfo} />
      </Box>
    </Item>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  borderRadius: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto',
}));

export {
  RightColumn,
};
