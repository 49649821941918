import {
  Autocomplete, Box, TextField, TextFieldProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { SectionTitle } from '../../../../common/right-column/section-title';
import { useGetProjectsByUser } from '../../../../hooks/query/get-projects-by-user-query.hook';

function Project({ queryInfo }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [optionsInvestor, setOptionsInvestor] = useState<{ label: string | null, value: string | null }[] | any>([{
    label: null,
    value: null,
  }]);
  const [optionsProject, setOptionsProject] = useState<{ label: string | null, value: string | null, customer: string | null }[] | any>([{
    label: null,
    value: null,
    customer: null,
  }]);
  const [selectedInvestor, setSelectedInvestor] = useState<{ label: string | null, value: string | null } | any>({
    label: null,
    value: null,
  });
  const [selectedProject, setSelectedProject] = useState<{ label: string | null, value: string | null, customer: string | null } | any>({
    label: null,
    value: null,
    customer: null,
  });

  const queryUser = useGetProjectsByUser(queryInfo?.data?.getProjectById?.createdBy?._id);

  useEffect(() => {
    const projectId = searchParams.get('projectId');
    if (queryUser?.data?.getProjectsByUser && queryUser?.data?.getProjectsByUser?.length > 0) {
      const formatted = queryUser?.data?.getProjectsByUser?.map((e) => ({
        value: e._id,
        label: e.name,
        // @ts-ignore
        customer: e?.customers[0]?._id,
      }));
      setOptionsProject(formatted);
      // @ts-ignore
      if (formatted.length !== 0) {
        if (projectId) {
          const f = formatted.find((e) => e.value === projectId);
          if (f) {
            setSelectedProject(f);
          } else {
            setSelectedProject(formatted[0]);
            // @ts-ignore
          }
        }
      }
    }
  }, [queryUser?.data?.getProjectsByUser]);

  useEffect(() => {
    const projectId = searchParams.get('projectId');
    const customerId = searchParams.get('customerId');
    if (queryInfo?.data?.getProjectById?.customers && queryInfo?.data?.getProjectById?.customers?.length > 0) {
      const formatted = queryInfo?.data?.getProjectById?.customers?.map((e: any) => ({
        value: e._id,
        label: `${e.personal_info.first_name} ${e.personal_info.last_name}`,
      }));
      setOptionsInvestor(formatted);
      // @ts-ignore
      if (formatted.length !== 0) {
        if (customerId) {
          const f = formatted.find((e: any) => e.value === customerId);
          if (f) {
            setSelectedInvestor(f);
            // @ts-ignore
            setSearchParams({ projectId, customerId: f.value });
          }
        } else {
          setSelectedInvestor(formatted[0]);
          // @ts-ignore
          setSearchParams({ projectId, customerId: formatted[0]?.value });
        }
      }
    }
  }, [queryInfo?.data?.getProjectById?.customers]);

  return (
    <Box>
      <Box marginBottom={1}>
        <SectionTitle title="projet" length={queryUser?.data?.getProjectsByUser?.length} />
        {queryUser && queryUser?.data?.getProjectsByUser?.length !== 0 && (
        <Autocomplete
          // @ts-ignore
          options={optionsProject}
          disableClearable
          blurOnSelect="mouse"
          getOptionLabel={(options) => options.label || ''}
          isOptionEqualToValue={(options, value) => options.value === value.value}
          value={selectedProject}
          disablePortal
          onChange={(e, value) => {
            setSelectedProject(value);
            setSearchParams({ projectId: value.value, customerId: value.customer });
          }}
          renderInput={
            (params) => (
              <CssAutocomplete
                {...params}
                size="small"
                fullWidth
                placeholder="Sélectionner un projet"
                variant="outlined"
              />
            )
          }
        />
        )}
      </Box>
      {queryInfo && queryInfo?.data?.getProjectById?.customers?.length !== 0 && optionsInvestor && (
        <>
          <SectionTitle title="investisseur" length={queryInfo?.data?.getProjectById?.customers?.length} />
          <Autocomplete
            disableClearable
            fullWidth
            blurOnSelect="mouse"
            getOptionLabel={(options) => options.label || ''}
            isOptionEqualToValue={(options, value) => options.value === value.value}
            ListboxProps={{
            // @ts-ignore
              sx: { fontSize: '12px', fontWeight: 'bold' },
            }}
            options={optionsInvestor}
            value={selectedInvestor}
            onChange={(e, value) => {
              setSelectedInvestor(value);
              // @ts-ignore
              setSearchParams({ projectId: searchParams.get('projectId'), customerId: value?.value });
            }}
            renderInput={
            (params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <CssAutocomplete
                {...params}
                fullWidth
                placeholder="Sélectionner un investisseur"
                size="small"
                variant="outlined"
              />
            )
          }
          />
        </>
      )}
    </Box>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
  borderRadius: '4px',
  input: {
    fontWeight: 'bold',
  },
}));

export {
  Project,
};
