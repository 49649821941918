import { useCreateCommentMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useCreateComment = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useCreateCommentMutation(headerWithAuth, options);

  return mutate;
};

export {
  useCreateComment,
};
