import React, { useState } from 'react';
import {
  Alert,
  Box, Button, Grid, IconButton, Paper, Snackbar, Typography,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import { useSearchParams } from 'react-router-dom';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { useCreateProjectFromCrm } from '../../../../hooks/mutation/create-project-from-crm-mutation.hook';

function CreateProjectFromCrm({ queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const [openSnack, setOpenSnack] = useState(false);
  const [uploadMessage, setUploadMessage] = useState({ text: '', type: '' });
  const {
    control, handleSubmit,
  } = useForm<any>({
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'customers',
  });

  const defaultValue = {
    personal_info: {
      first_name: null,
      last_name: null,
    },
  };

  const mutate = useCreateProjectFromCrm({
    onSuccess: (resp: any) => {
      // @ts-ignore
      remove(fields?.map((e, i) => remove(i)));
      setUploadMessage({ text: 'Votre projet a été crée avec succès !', type: 'success' });
      setOpenSnack(true);
    },
    onError: (err: any) => {
      setOpenSnack(true);
      setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
    },
  });

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setUploadMessage({ text: '', type: '' });
  };

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        ...form,
      },
      userId: searchParams.get('userId'),
    };
    mutate({ ...formValid });
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: '16px' }}>
      <Snackbar open={openSnack} onClose={handleCloseSnack} autoHideDuration={5000}>
        <Alert severity={uploadMessage?.type === 'success' ? 'success' : 'error'} onClose={handleCloseSnack} sx={{ width: '100%' }}>
          {uploadMessage?.text}
        </Alert>
      </Snackbar>
      <Grid paddingY={3} paddingX={2}>
        <Box display="flex" flexDirection="column" paddingBottom={5}>
          <Box display="flex" alignItems="center">
            <Typography marginRight={1} textAlign="left" variant="h5" color="primary">Ajouter un projet supplémentaire associé au prospect</Typography>
            <Typography textAlign="left" variant="h5" fontWeight="600">{queryInfo?.data?.findClientById?.email}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {fields?.map((item, indexField) => (
              <Box key={item?.id}>
                <Typography variant="h6" marginBottom={1}>{`Investisseur n° ${indexField + 1}`}</Typography>
                <Grid container columns={10} spacing={2} width="60%">
                  <Grid item paddingY={3} xs={4}>
                    <TextFieldCustom
                      required
                      control={control}
                      name={`customers[${indexField}].personal_info.first_name`}
                      label="Prénom"
                    />
                  </Grid>
                  <Grid item paddingY={3} xs={4}>
                    <TextFieldCustom
                      required
                      control={control}
                      name={`customers[${indexField}].personal_info.last_name`}
                      label="Nom"
                    />
                  </Grid>
                  <Grid item paddingY={3} xs={2}>
                    <IconButton
                      sx={{
                        height: 40,
                        width: 40,
                      }}
                      onClick={() => remove(indexField)}
                      color="error"
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Box display="flex" flexDirection="column">
              <Button
                type="button"
                sx={{
                  cursor: 'pointer',
                  textTransform: 'none',
                  textDecoration: 'underline',
                  transition: 'opacity 0.3s',
                  fontSize: '14px',
                  width: 'fit-content',
                  ':hover': {
                    opacity: 0.8,
                  },
                }}
                startIcon={<AddCircleIcon />}
                color="secondary"
                onClick={() => append(defaultValue)}
              >
                Ajouter un investisseur supplémentaire
              </Button>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer le nouveau projet
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Paper>
  );
}

export {
  CreateProjectFromCrm,
};
