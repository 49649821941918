import React, { useState } from 'react';
import {
  Alert,
  Box, Button, Chip, Grid, IconButton, Menu, MenuItem,
  Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { fileLabelOptions } from '../../../../data/data';
import { useGetAccessToken } from '../../../../hooks/auth/get-access-token.hook';
import { LightTooltip } from '../../../../common/components/light-tooltip';
import { useDeleteDocumentsFromProject } from '../../../../hooks/mutation/delete-documents-from-project-mutation.hook';

interface IFormInput {
  file: string
}

function Documents({ queryInfo }: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [searchParams] = useSearchParams();
  const [openSnack, setOpenSnack] = useState(false);
  const [uploadMessage, setUploadMessage] = useState({ text: '', type: '' });
  const { accessToken } = useGetAccessToken();
  const [loading, setLoading] = useState(false);
  const {
    control, handleSubmit, watch, setValue,
  } = useForm<IFormInput>({});

  const open = Boolean(anchorEl);
  const projectId = searchParams.get('projectId');

  const numberDocuments = queryInfo?.data?.getProjectById?.documents?.length;

  const handleClick = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (form: any): any => {
    setLoading(true);
    const formData = new FormData();
    for (const file of form.file) {
      formData.append('files', file);
    }
    formData.append('projectId', projectId ?? '');
    formData.append('label', documentType);

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/project/upload`, requestOptions).then((response) => response.json())
      .then((result) => {
        if (result.success) {
          queryInfo.refetch();
          setUploadMessage({ text: 'Votre ou vos document(s) ont été importé avec succès !', type: 'success' });
          setOpenSnack(true);
          setValue('file', '');
          setLoading(false);
        }
      }).catch((err) => {
        setOpenSnack(true);
        setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
        setLoading(false);
      });
  };

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setUploadMessage({ text: '', type: '' });
  };

  const mutate = useDeleteDocumentsFromProject({
    onSuccess: () => {
      queryInfo.refetch();
      setOpenSnack(true);
      setValue('file', '');
    },
    onError: (err: any) => {
      setOpenSnack(true);
      setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
    },
  });

  const checkFileName = (files: any) => {
    if (files) {
      let filesName = '';
      for (const file of files) {
        // eslint-disable-next-line no-multi-assign
        filesName = filesName += `${file?.name}\n`;
      }
      return filesName;
    }
    return 'Le fichier';
  };

  return (
    <Box>
      <Snackbar open={openSnack} onClose={handleCloseSnack} autoHideDuration={5000}>
        <Alert
          severity={uploadMessage?.type === 'success' ? 'success' : 'error'}
          onClose={handleCloseSnack}
          sx={{ width: '100%' }}
        >
          {uploadMessage?.text}
        </Alert>
      </Snackbar>
      <Paper elevation={0} sx={{ borderRadius: '16px' }}>
        <Grid paddingY={3}>
          <Box display="flex" justifyContent="space-between" paddingX={2} paddingBottom={5}>
            <Box display="flex" alignItems="center">
              <Typography marginRight={3} textAlign="left" variant="h5" color="primary">📁 Liste des documents</Typography>
              <Chip label={`nb: ${numberDocuments}`} color="primary" variant="outlined" size="small" />
            </Box>
            <Box display="flex" flexDirection="column" width={300}>
              <Button
                sx={{
                  marginRight: 3, textTransform: 'none', fontWeight: 'bold',
                }}
                fullWidth
                color="secondary"
                variant="contained"
                component="span"
                onClick={(e: any) => handleClick(e)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Importer vos documents
              </Button>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem disabled>Sélectionner le type de document à importer</MenuItem>
                  {fileLabelOptions.map((e, i) => (
                    <label key={e + i.toString()}>
                      <MenuItem>
                        {e.label}
                      </MenuItem>
                      <Controller
                        name="file"
                        control={control}
                        render={({ field }) => (
                          <Input
                            multiple
                            id="contained-button-file"
                            // eslint-disable-next-line no-shadow
                            onChange={(elem) => {
                              // @ts-ignore
                              const file = elem.target.files ?? null;
                              if (!file) return;
                              field.onChange(file);
                              setDocumentType(e.value);
                              setAnchorEl(null);
                            }}
                            type="file"
                          />
                        )}
                      />
                    </label>
                  ))}
                </Menu>
                {watch('file')
                  && (
                    <Box display="flex" flexDirection="column">
                      <Box
                        bgcolor="background.default"
                        marginTop={1}
                        padding={1}
                        display="flex"
                        borderRadius="4px"
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            width: '95%',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {
                            // @ts-ignore
                            checkFileName(watch('file'))
                          }
                        </Typography>
                        <Box
                          width="5%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setValue('file', '')}
                        >
                          <ClearIcon fontSize="small" color="error" />
                        </Box>
                      </Box>
                      <LoadingButton
                        fullWidth
                        loading={loading}
                        sx={{
                          marginRight: 3, marginTop: 1, textTransform: 'none', fontWeight: 'bold',
                        }}
                        color="primary"
                        variant="outlined"
                        type="submit"
                      >
                        Télécharger
                      </LoadingButton>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
          <Box padding={3}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date de téléversement</TableCell>
                    <TableCell width={3} align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {queryInfo?.data?.getProjectById?.documents?.map((row: any, i: any) => (
                    <TableRow
                      key={row + i.toString()}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell width="50%">
                        <a
                          target="_blank"
                          href={row?.url}
                          rel="noreferrer"
                          style={{ textDecoration: 'none', width: '50%' }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              width: '50%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              textDecoration: 'underline',
                            }}
                            color="primary"
                          >
                            {row?.path.split('-').map((e: any) => e).slice(1).join('-')}
                          </Typography>
                        </a>
                      </TableCell>
                      <TableCell width="20%">{fileLabelOptions?.find((f) => f?.value === row?.type)?.label}</TableCell>
                      <TableCell width="20%">{format(new Date(row?.createdAt), 'dd/MM/yyyy')}</TableCell>
                      <TableCell width="10%" align="right">
                        <LightTooltip
                          placement="right"
                          title={(
                            <Box>
                              <IconButton
                                onClick={() => {
                                  setUploadMessage({
                                    text: `Le document ${row?.path.split('-').map((e: any) => e).slice(1).join('-')} a bien été supprimé !`,
                                    type: 'deleted',
                                  });
                                  mutate({ documentId: row?._id });
                                }}
                                color="error"
                                size="small"
                              >
                                <DeleteIcon
                                  fontSize="small"
                                />
                              </IconButton>
                            </Box>
                          )}
                        >
                          <IconButton size="small">
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </LightTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
}

const Input = styled('input')({
  display: 'none',
});

export {
  Documents,
};
