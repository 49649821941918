import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, Grid, IconButton, ToggleButton, Tooltip,
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useSearchParams } from 'react-router-dom';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import HomeIcon from '@mui/icons-material/Home';
import { getTime } from 'date-fns';
import { miniOffersOptions, propertyStatusOptions, stateOptions } from '../../../data/data';
import { AutocompleteFilter } from '../../../common/inputs/filters/autocomplete-filter';
import { CalendarFilter } from '../../../common/inputs/filters/calendar-filter';

function Filter({ queryParamsValue, setQueryParamsValue, setPageIndex }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<any>({
    hotDeal: searchParams.get('hotDeal') ? true : null,
    matched: searchParams.get('matched') ? true : null,
    // @ts-ignore
    status: searchParams.get('status') ? searchParams.get('status').split(',').map((e) => propertyStatusOptions.find((f) => f.value === e)) : [],
    state: searchParams.get('state') ? stateOptions.find((f) => f?.value.toString() === searchParams.get('state')) : null,
    propertyOffer: searchParams.get('propertyOffer') ? miniOffersOptions.find((f) => f?.value === searchParams.get('propertyOffer')) : null,
    startDate: searchParams.get('startDate') ? Number(searchParams.get('startDate')) : null,
    endDate: searchParams.get('endDate') ? Number(searchParams.get('endDate')) : null,
  });

  const [hotDeal, setHotDeal] = useState(filter.hotDeal);
  const [matched, setMatched] = useState(filter.matched);

  const resetFilters = () => {
    setQueryParamsValue({});
    setPageIndex(0);
    setHotDeal(false);
    setMatched(false);
    setFilter({
      hotDeal: null,
      matched: null,
      status: [],
      state: null,
      propertyOffer: null,
      startDate: null,
      endDate: null,
    });
  };

  useEffect(() => {
    const searchParamsTmp = new URLSearchParams();
    if (filter.hotDeal) {
      searchParamsTmp.set('hotDeal', filter.hotDeal);
      setQueryParamsValue((old: any) => ({ ...old, hotDeal: filter?.hotDeal ?? null }));
    }
    if (filter.matched) {
      searchParamsTmp.set('matched', filter.matched);
      setQueryParamsValue((old: any) => ({ ...old, matched: filter?.matched ?? null }));
    }

    if (filter.status.length !== 0) {
      searchParamsTmp.set('status', filter.status);
      setQueryParamsValue((old: any) => ({ ...old, status: filter?.status.map((e: any) => e?.value) ?? [] }));
    }
    if (filter.state) {
      searchParamsTmp.set('state', filter.state);
      setQueryParamsValue((old: any) => ({ ...old, state: filter?.state?.value ?? null }));
    }
    if (filter.propertyOffer) {
      searchParamsTmp.set('propertyOffer', filter.propertyOffer);
      setQueryParamsValue((old: any) => ({ ...old, propertyOffer: filter?.propertyOffer?.value ?? null }));
    }
    if (filter.startDate) {
      searchParamsTmp.set('startDate', filter.startDate);
      setQueryParamsValue((old: any) => ({ ...old, startDate: filter?.startDate ?? null }));
    }
    if (filter.endDate) {
      searchParamsTmp.set('endDate', filter.endDate);
      setQueryParamsValue((old: any) => ({ ...old, endDate: filter?.endDate ?? null }));
    }
  }, [filter, setQueryParamsValue]);

  useEffect(() => {
    const defaultUrl = new URLSearchParams();
    Object.keys(filter).forEach((key) => {
      if (filter[key] && filter[key]?.length !== 0) {
        defaultUrl.set(key, queryParamsValue[key]);
      }
    });
    setSearchParams(defaultUrl.toString());
  }, [filter, queryParamsValue, setSearchParams]);

  return (
    <Grid container item gap={1} paddingTop={3}>
      <ButtonGroup variant="outlined" color="inherit" sx={{ maxHeight: '38.35px' }}>
        <Tooltip title="Hot deal">
          <ToggleButton
            component={Button}
            selected={hotDeal}
            value="hotDeal"
            color="warning"
            size="small"
            onChange={() => {
              setPageIndex(0);
              setHotDeal(!hotDeal);
              setQueryParamsValue((old: any) => ({ ...old, hotDeal: !hotDeal ? true : null }));
              setFilter((oldFilter: any) => ({ ...oldFilter, hotDeal: !hotDeal ? true : null }));
            }}
          >
            <LocalFireDepartmentIcon fontSize="small" />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Biens  matchés">
          <ToggleButton
            component={Button}
            selected={matched}
            value="matched"
            color="warning"
            size="small"
            sx={{ textDecoration: 'none' }}
            onChange={() => {
              setPageIndex(0);
              setMatched(!matched);
              setQueryParamsValue((old: any) => ({ ...old, matched: !matched ? true : null }));
              setFilter((oldFilter: any) => ({ ...oldFilter, matched: !matched ? true : null }));
            }}
          >
            <HomeIcon fontSize="small" />
          </ToggleButton>
        </Tooltip>
      </ButtonGroup>
      <AutocompleteFilter
        multiple
        value={filter?.status ? filter.status : []}
        onChange={(_: any, value: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, status: value.map((e: any) => e?.value) ?? [] }));
          setFilter((oldFilter: any) => ({ ...oldFilter, status: value }));
        }}
        options={propertyStatusOptions}
        label="Statut"
        width={230}
      />
      <AutocompleteFilter
        value={filter?.state}
        onChange={(_: any, value: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, state: value?.value ?? null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, state: value }));
        }}
        options={stateOptions}
        label="Activité"
        width={150}
      />
      <AutocompleteFilter
        value={filter?.propertyOffer}
        onChange={(_: any, value: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, propertyOffer: value?.value ?? null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, propertyOffer: value }));
        }}
        options={miniOffersOptions}
        label="Offre"
        width={150}
      />
      <CalendarFilter
        value={filter?.startDate}
        onChange={(e: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, startDate: e ? getTime(new Date(e)) : null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, startDate: e ? getTime(new Date(e)) : null }));
        }}
        label="Date début"
        width={150}
      />
      <CalendarFilter
        value={filter?.endDate}
        onChange={(e: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, endDate: e ? getTime(new Date(e)) : null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, endDate: e ? getTime(new Date(e)) : null }));
        }}
        label="Date fin"
        width={150}
      />
      <Tooltip title="Réinitialiser les filtres">
        <IconButton
          color="warning"
          sx={{ maxHeight: '38.34px' }}
          onClick={() => resetFilters()}
          disabled={filter?.status?.length === 0 && !filter?.state && !filter.hotDeal && !filter.matched && !filter?.propertyOffer && !filter?.startDate && !filter?.endDate}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export {
  Filter,
};
