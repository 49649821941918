import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FeedIcon from '@mui/icons-material/Feed';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Divider } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useFindUserLoggedIn } from '../../hooks/query/find-user-logged-in-query.hook';

type iProps = {
  pageSelected?: string;
  setPageSelected?: string | any
};

const menuTop = [{
  key: 'project',
  to: 'project-list',
  title: 'Projets',
  icon: <FeedIcon />,
}, {
  key: 'property',
  to: 'property-list',
  title: 'Biens',
  icon: <MapsHomeWorkIcon />,
}, /* {
  key: 'project-management',
  to: 'project-management-list',
  title: 'Gestion de projet',
  icon: <WidgetsIcon />,
}, */ {
  key: 'sales',
  to: 'sales',
  title: 'Kanban',
  icon: <ViewWeekIcon />,
}];

function TitlePage({ pageSelected }: iProps) {
  const location = useLocation();

  const checkTitle = () => {
    const expr = location.pathname || pageSelected;
    switch (expr) {
      case '/project-list':
        return 'Liste projets';
      case '/project-list/projectcard':
        return 'Fiche projet';
      case '/property-list':
        return 'Liste biens';
      case '/property-list/propertycard':
        return 'Fiche du bien';
      case '/clientcard':
        return 'Fiche client';
      case '/sales':
        return 'Kanban';
      default:
        return '';
    }
  };
  return (
    <Typography variant="h6" noWrap component="div">
      {checkTitle()}
    </Typography>
  );
}

function MenuTop({ setPageSelected }: iProps) {
  const location = useLocation();
  const firstArgsInUrl = location.pathname?.split('/')[1];

  return (
    <>
      {menuTop.map((e) => {
        return (
          <Link key={e.key} to={e.to} style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItem
              key={e.key}
              onClick={() => setPageSelected(e.to)}
            >
              <ListItemIcon sx={{
                color: firstArgsInUrl === e.to ? 'primary.main' : 'primary.light',
              }}
              >
                {e.icon}
              </ListItemIcon>
              <ListItemText primary={e.title} />
            </ListItem>
          </Link>
        );
      })}
    </>
  );
}

function MenuBottom({ open }: any) {
  const queryInfo = useFindUserLoggedIn();
  return (
    <>
      {open && (
        <Box marginLeft={2} marginBottom={3}>
          <Box
            component="a"
            target="_blank"
            marginBottom={1}
            display="flex"
            alignItems="center"
            href="https://docs.google.com/spreadsheets/d/1KExmSAPC1dgKp3L_x0bnd9XA3uT-v9eyYcMdcu_TW2Q/edit?usp=sharing"
            sx={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Box width={30}>
              <LinkIcon />
            </Box>
            <Typography variant="body2">liste parrainages</Typography>
          </Box>
          <Box
            display="flex"
            marginBottom={1}
          >
            <Box width={30}>
              <MailOutlineIcon fontSize="small" />
            </Box>
            <Typography variant="body2">{queryInfo?.data?.findUserLoggedIn?.email || '...'}</Typography>
          </Box>
        </Box>
      )}
      <ListItem
        key="disconnect"
        sx={{ cursor: 'pointer' }}
      >
        <Disconnect />
      </ListItem>
    </>
  );
}

function Disconnect() {
  const {
    logout,
  } = useAuth0<{
    sub: any;
    name: string
  }>();
  const auth0Logout = () => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI });
  return (
    <>
      <ListItemIcon onClick={auth0Logout}>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Déconnexion" onClick={auth0Logout} />
    </>
  );
}

export {
  MenuTop,
  TitlePage,
  MenuBottom,
};
