import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, Typography } from '@mui/material';
import React from 'react';

function AutocompleteCustom({
  control, name, label, tab, multiple = false, rules = null,
}: any) {
  function checkValue(data: any) {
    if (tab.some((f: any) => f.value === data)) {
      return tab.find((f: any) => f.value === data);
    }
    if (!tab.some((f: any) => f.value === data)) {
      return data;
    }
    if (multiple) {
      return data || [''];
    }
    return '';
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...field}
            disablePortal
            options={tab}
            onBlur={field.onBlur}
            value={checkValue(field.value)}
            multiple={multiple}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            renderInput={
            (params) => (
              <>
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  label={label}
                />
                {fieldState?.error?.message && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
              </>
            )
          }
          />
        );
      }}
    />
  );
}

export {
  AutocompleteCustom,
};
