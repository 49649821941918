import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Grid, Paper, Box, Typography, Button, Chip, Autocomplete, TextField, Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import CancelIcon from '@mui/icons-material/Cancel';
import FlareIcon from '@mui/icons-material/Flare';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import StarRateIcon from '@mui/icons-material/StarRate';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatInTimeZone } from 'date-fns-tz';
import { propertyStatusOptions } from '../../../data/data';
import { Filter } from '../components/filter';
import { useInfiniteGetAllProperties } from '../../../hooks/query/Infinite-get-all-properties-query.hook';
import { euro } from '../../../common/inputs/format/euro-format';
import { Loader } from '../../../common/components/loader';
import { AllowedOffer } from '../../../api';

function PropertyList() {
  const [pageIndex, setPageIndex] = useState(0);
  const [rowPerPageOptions, setRowPerPageOptions] = useState<any>({ value: 100, label: '100' });
  const [queryParamsValue, setQueryParamsValue] = useState({});

  const freshFilter = Object?.fromEntries(Object?.entries(queryParamsValue)?.filter(([_, v]) => {
    // @ts-ignore
    return v !== null && v?.length !== 0;
  }));

  useEffect(() => {
    setPageIndex(0);
  }, [rowPerPageOptions]);

  const {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetAllProperties({
    args: { first: rowPerPageOptions?.value },
    filters: {
      ...freshFilter,
    },
  }, rowPerPageOptions);

  // eslint-disable-next-line no-shadow
  function createRow(data: any) {
    return data && data?.map((e: any) => ({
      id: e?.node?._id,
      street_address: e?.node?.localisation?.street_address,
      hotDeal: e?.node?.hotDeal,
      status: propertyStatusOptions.find((f) => f.value === e?.node?.status)?.label,
      surface: Number(e?.node?.description?.surface),
      total_budget: Number(e?.node?.finance?.total_budget),
      expected_price: Number(e?.node?.finance?.expected_price),
      matched: e?.node?.matched,
      propertyOffer: e?.node?.conclusion?.property_offer,
      projectMatchedName: e?.node?.project?.name,
      projectMatchedId: e?.node?.project?._id,
      createdAt: new Date(e?.node?.createdAt),
    }
    ));
  }

  function chooseIconForOffer(value: string) {
    switch (value) {
      case AllowedOffer.INELIGIBLE: {
        return <Tooltip title="Inéligible"><CancelIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ETINCELLE: {
        return <Tooltip title="Etincelle"><FlareIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ENVOL: {
        return <Tooltip title="Envol"><AirplanemodeActiveIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ETOILE: {
        return <Tooltip title="Étoile"><StarRateIcon fontSize="small" /></Tooltip>;
      }
      default: {
        return <Tooltip title="Non-défini"><RemoveIcon fontSize="small" /></Tooltip>;
      }
    }
  }

  const numberProperties = data?.pages[0]?.getAllProperties?.pageData?.count;

  const columns: GridColDef[] = [{
    field: 'street_address',
    headerName: 'Adresse',
    width: 400,
    renderCell: (params) => {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/property-list/propertycard?propertyId=${params?.id}`}
        >
          <Tooltip title={params?.value?.address_line_1}>
            <Typography
              fontWeight="bold"
              variant="body2"
              color="primary"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {params?.row?.hotDeal && <LocalFireDepartmentIcon color="warning" sx={{ marginRight: 0.3, fontSize: 15 }} />}
              {params?.value?.address_line_1}
            </Typography>
          </Tooltip>
        </Link>
      );
    },
  }, {
    field: 'status',
    headerName: 'Statut',
    width: 180,
  }, {
    field: 'propertyOffer',
    headerName: 'Offre',
    width: 65,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {chooseIconForOffer(params.value)}
        </Box>
      );
    },
  }, {
    field: 'total_budget',
    headerName: 'Budget Total',
    width: 140,
    type: 'number',
    // @ts-ignore
    valueFormatter: (params) => (params?.value ? euro.format(params?.value) : ''),
  }, {
    field: 'expected_price',
    headerName: 'Prix à négocier',
    width: 140,
    type: 'number',
    // @ts-ignore
    valueFormatter: (params) => (params?.value ? euro.format(params?.value) : ''),
  }, {
    field: 'surface',
    headerName: 'Surface',
    width: 100,
    type: 'number',
    // @ts-ignore
    valueFormatter: (params) => (params?.value ? `${params?.value} m2` : ''),
  }, {
    field: 'matched',
    headerName: 'Matching',
    width: 90,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {params.value === true ? <CheckIcon color="success" fontSize="small" />
            : <CloseIcon color="error" fontSize="small" />}
        </Box>
      );
    },
  }, {
    field: 'projectMatchedName',
    headerName: 'Projet matché',
    width: 150,
    type: 'string',
    renderCell: (params) => {
      return (
        <Tooltip title={params?.value}>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/project-list/projectcard?projectId=${params?.row?.projectMatchedId}`}
          >
            <Typography
              sx={{
                width: '145px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="body2"
              color="text.primary"
            >
              {params?.value}
            </Typography>
          </Link>
        </Tooltip>
      );
    },
  }, {
    field: 'createdAt',
    headerName: 'Création',
    width: 75,
    type: 'date',
    // @ts-ignore
    renderCell: (params) => {
      return (
        <Box display="flex">
          <Typography variant="caption">
            {params?.value
              ? formatInTimeZone(
                // @ts-ignore
                new Date(params?.value),
                'Europe/Paris',
                'dd/MM/yyyy',
              ) : ''}
          </Typography>
        </Box>
      );
    },
  }];

  // eslint-disable-next-line no-unsafe-optional-chaining
  const totalPages = data ? Math.trunc(data?.pages[0]?.getAllProperties.pageData.count / rowPerPageOptions?.value) : 0;

  return (
    <Grid container item columns={1} spacing={2} paddingX={2}>
      <Grid item xs={1}>
        <Paper
          elevation={0}
          sx={{
            borderRadius: '16px', padding: 3, width: '100%',
          }}
        >
          <Box style={{
            width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between',
          }}
          >
            <Box display="flex" alignItems="center">
              <Typography marginRight={3} color="primary" variant="h5">Liste des biens</Typography>
              {!isSuccess
                ? <CircularProgress size={20} />
                : (
                  <Chip
                    label={`Nb total de biens: ${numberProperties || 'pas de résultats'}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}
            </Box>
            <Box display="flex">
              {/* <ExportExcel selectionModel={selectionModel} /> */}
              <Autocomplete
                value={rowPerPageOptions || undefined}
                onChange={(_, value) => {
                  setRowPerPageOptions(value);
                }}
                disablePortal
                disableClearable
                options={[
                  { value: 20, label: '20' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' },
                ]}
                renderInput={
                  (params) => (
                    <TextField
                      {...params}
                      label="Nb. d'éléments"
                      fullWidth
                      size="small"
                      sx={{ width: 150, marginLeft: 3 }}
                    />
                  )
                }
              />
            </Box>
          </Box>
          <Filter
            queryParamsValue={queryParamsValue}
            setQueryParamsValue={setQueryParamsValue}
            rowPerPageOptions={rowPerPageOptions}
            setRowPerPageOptions={setRowPerPageOptions}
            setPageIndex={setPageIndex}
          />
        </Paper>
      </Grid>
      <Grid item xs={1}>
        <Paper elevation={0} sx={{ borderRadius: '16px', padding: 2, width: '100%' }}>
          <Box sx={{ height: 'calc(100vh - 340px)', width: '100%' }}>
            {
              !isFetching && isSuccess
                ? (
                  <DataGrid
                    rows={createRow(data?.pages[pageIndex]?.getAllProperties?.page?.edges) || []}
                    columns={columns}
                    pageSize={rowPerPageOptions?.value}
                    density="compact"
                    hideFooter
                    loading={isFetching && isSuccess}
                    rowsPerPageOptions={[rowPerPageOptions?.value]}
                    sx={{
                      border: 'none',
                    }}
                  />
                ) : <Loader />
            }
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop={2}>
            <Button
              type="button"
              disabled={pageIndex === 0}
              size="small"
              startIcon={<ArrowBackIosIcon />}
              sx={{
                marginRight: 3,
                width: 150,
                textTransform: 'none',
              }}
              onClick={() => {
                setPageIndex(() => pageIndex - 1);
              }}
            >
              Précédent
            </Button>
            <Button
              size="small"
              disabled={data && pageIndex === totalPages}
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                marginRight: 3,
                width: 150,
                textTransform: 'none',
              }}
              onClick={() => {
                if (hasNextPage) {
                  fetchNextPage();
                }
                setPageIndex(() => pageIndex + 1);
              }}
            >
              Suivant
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export {
  PropertyList,
};
