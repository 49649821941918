import {
  AllowedAppointmentType,
  AllowedClosedStatus,
  AllowedCollectiveOwnershipWorks,
  AllowedCustomerSource,
  AllowedDocumentLabel,
  AllowedDpeGes,
  AllowedEmploymentContract,
  AllowedExploitation,
  AllowedFlatType,
  AllowedGender,
  AllowedInternetFiber,
  AllowedInvestmentMethod,
  AllowedKanbanType,
  AllowedLandlordFees,
  AllowedLevelOfUrgency,
  AllowedMatchingState,
  AllowedOffer,
  AllowedOrientation,
  AllowedPropertyClosedStatus,
  AllowedPropertyDocumentsLabels,
  AllowedPropertyState,
  AllowedPropertyStatus,
  AllowedPropertyType,
  AllowedSellingReason,
  AllowedSeniority,
  AllowedStatus,
  AllowedTransportation,
  AllowedWeddingPlan,
} from '../api';

const statusOptions = [{
  value: AllowedStatus.NOUVEAU_LEAD,
  label: 'Nouveau lead',
  index: 0,
}, {
  value: AllowedStatus.PROSPECT,
  label: 'Prospect',
  index: 1,
}, {
  value: AllowedStatus.EN_ATTENTE_DE_BIEN,
  label: 'En attente de bien',
  index: 2,
}, {
  value: AllowedStatus.MATCHING_EN_COURS,
  label: 'Matching en cours',
  index: 3,
}, {
  value: AllowedStatus.MATCHING_ACCEPTE,
  label: 'Matching accepté',
  index: 4,
}, {
  value: AllowedStatus.OFFRE_DACHAT_SIGNEE,
  label: "Offre d'achat signée",
  index: 5,
}, {
  value: AllowedStatus.COMPROMIS_EN_REDACTION,
  label: 'Compromis en rédaction',
  index: 6,
}, {
  value: AllowedStatus.COMPROMIS_A_VERIFIER,
  label: 'Compromis à vérifier',
  index: 7,
}, {
  value: AllowedStatus.COMPROMIS_VERIFIE,
  label: 'Compromis vérifié',
  index: 8,
}, {
  value: AllowedStatus.COMPROMIS_SIGNE,
  label: 'Compromis signé',
  index: 9,
}, {
  value: AllowedStatus.MONTAGE_FINANCEMENT,
  label: 'Montage financement',
  index: 10,
}, {
  value: AllowedStatus.DOSSIERS_BANCAIRES_ENVOYES,
  label: 'Dossiers bancaires envoyés',
  index: 11,
}, {
  value: AllowedStatus.ANALYSE_SIMULATIONS,
  label: 'Analyse simulations',
  index: 12,
}, {
  value: AllowedStatus.OFFRE_DE_PRET_VERIFIEE,
  label: 'Offre de prêt vérifiée',
  index: 13,
}, {
  value: AllowedStatus.OFFRE_DE_PRETSIGNEE,
  label: 'Offre de prêt signée',
  index: 14,
}, {
  value: AllowedStatus.ACTE_AUTHENTIQUE_SIGNE,
  label: 'Acte authentique signé',
  index: 15,
}, {
  value: AllowedStatus.TRAVAUX_EN_COURS,
  label: 'Travaux en cours',
  index: 16,
}, {
  value: AllowedStatus.PROJET_LIVRE,
  label: 'Projet livré',
  index: 17,
}, {
  value: AllowedStatus.PROJET_FERME,
  label: 'Projet fermé',
  index: 18,
}];

const closedStatusOptions = [{
  value: AllowedClosedStatus.UNDESIRABLE,
  label: 'Indésirable',
  index: 0,
}, {
  value: AllowedClosedStatus.NOT_ELIGIBLE,
  label: 'Non éligible',
  index: 1,
}, {
  value: AllowedClosedStatus.GOOD_PROFILE,
  label: 'Bon Profil',
  index: 2,
}, {
  value: AllowedClosedStatus.OUTSIDE_IDF,
  label: 'Hors IDF',
  index: 3,
}, {
  value: AllowedClosedStatus.DISCONTINUED,
  label: 'Abandon',
  index: 4,
}];

const stateOptions = [{
  value: AllowedPropertyState.IN_PROGRESS,
  label: 'En cours',
}, {
  value: AllowedPropertyState.MATCHED,
  label: 'Matchés',
}, {
  value: AllowedPropertyState.CLOSED,
  label: 'Fermés',
}];

const fileLabelOptionsInputProperty = [{
  value: AllowedPropertyDocumentsLabels?.CO_OWNERSHIP_REGULATIONS,
  label: 'Règlement de copropriété',
}, {
  value: AllowedPropertyDocumentsLabels?.BUILDING_STATE_DATE,
  label: 'Prêt état daté ',
}, {
  value: AllowedPropertyDocumentsLabels?.DIAGNOSIS,
  label: 'Diagnostic',
}, {
  value: AllowedPropertyDocumentsLabels?.GENERAL_ASSEMBLY_NOTICE,
  label: 'PV AG',
}, {
  value: AllowedPropertyDocumentsLabels?.BUILDING_BLUEPRINT,
  label: 'Plan',
}, {
  value: AllowedPropertyDocumentsLabels?.MAINTENANCE_BOOKLET,
  label: 'Carnet d\'entretien',
}, {
  value: AllowedPropertyDocumentsLabels?.CONDOMINIUM_FEES,
  label: 'Charges de copropriété',
}, {
  value: AllowedPropertyDocumentsLabels?.HOUSING_TAX,
  label: 'Taxe foncière',
}, {
  value: AllowedPropertyDocumentsLabels?.PROPERTY_PICTURE,
  label: 'Image ou video',
}, {
  value: AllowedPropertyDocumentsLabels?.OTHERS,
  label: 'Autre',
}];

const propertyStatusOptions = [{
  value: AllowedPropertyStatus.NEW_PROPERTY,
  label: 'Nouveau bien',
  index: 0,
}, {
  value: AllowedPropertyStatus.QUALIFIED_PROPERTY,
  label: 'Bien qualifié',
  index: 1,
}, {
  value: AllowedPropertyStatus.PROPERTY_UNDER_NEGOTIATION,
  label: 'Bien en négociation',
  index: 2,
}, {
  value: AllowedPropertyStatus.ACCEPTED_OFFER,
  label: 'Offre Acceptée',
  index: 3,
}, {
  value: AllowedPropertyStatus.PENDING_MATCHING,
  label: 'Matching en cours',
  index: 4,
}, {
  value: AllowedPropertyStatus.PROPERTY_MATCHED,
  label: 'Bien Matché',
  index: 5,
}, {
  value: AllowedPropertyStatus.WAITING_TO_BE_MATCHED,
  label: 'En attente de matching',
  index: 6,
}, {
  value: AllowedPropertyStatus.CLOSED,
  label: 'Fermé',
  index: 7,
}];

const propertyClosedStatusOptions = [{
  value: AllowedPropertyClosedStatus.OUT_OF_SCOPE,
  label: 'Hors scope',
  index: 0,
}, {
  value: AllowedPropertyClosedStatus.REJECTED_OFFER,
  label: 'Offre refusée',
  index: 1,
}, {
  value: AllowedPropertyClosedStatus.RETRACTION,
  label: 'Rétractation',
  index: 2,
}];

const transportationOptions = [{
  value: AllowedTransportation.SUBWAY,
  label: '(M) Métro de Paris',
}, {
  value: AllowedTransportation.RER,
  label: '(RER) RER d’Île-de-France',
}, {
  value: AllowedTransportation.TRANSILIEN,
  label: 'Transilien',
}, {
  value: AllowedTransportation.TRAMWAY,
  label: '(T) Tramway d’Île-de-France',
}, {
  value: AllowedTransportation.AUTOBUS,
  label: 'Autobus d’Île-de-France',
}];

const orientationOptions = [{
  value: AllowedOrientation.NORTH,
  label: 'Nord',
}, {
  value: AllowedOrientation.EAST,
  label: 'Est',
}, {
  value: AllowedOrientation.SOUTH,
  label: 'Sud',
}, {
  value: AllowedOrientation.WEST,
  label: 'Ouest',
}, {
  value: AllowedOrientation.NORTH_EAST,
  label: 'Nord-Est',
}, {
  value: AllowedOrientation.SOUTH_EAST,
  label: 'Sud-Est',
}, {
  value: AllowedOrientation.SOUTH_WEST,
  label: 'Sud-Ouest',
}, {
  value: AllowedOrientation.NORTH_WEST,
  label: 'Nord-Ouest',
}];

const customerSourceOptions = [{
  value: AllowedCustomerSource.WORD_OF_MOUTH,
  label: 'Bouche à oreille',
}, {
  value: AllowedCustomerSource.FACEBOOK,
  label: 'Facebook',
}, {
  value: AllowedCustomerSource.GOOGLE,
  label: 'Google Ads',
}, {
  value: AllowedCustomerSource.INSTAGRAM,
  label: 'Instagram',
}, {
  value: AllowedCustomerSource.LINKEDIN,
  label: 'LinkedIn',
}, {
  value: AllowedCustomerSource.SEO,
  label: 'SEO',
}, {
  value: AllowedCustomerSource.BUSINESS_PROVIDER,
  label: 'Apporteur d\'affaire',
}, {
  value: AllowedCustomerSource.OTHER,
  label: 'Direct',
}];

const landlordFeesOptions = [{
  value: AllowedLandlordFees.COLD_WATER,
  label: 'Eau froide',
}, {
  value: AllowedLandlordFees.HOT_WATER,
  label: 'Eau chaude',
}, {
  value: AllowedLandlordFees.HEATING,
  label: 'Chauffage',
}, {
  value: AllowedLandlordFees.GAS,
  label: 'Gaz',
}];

const collectiveOwnershipWorksOptions = [{
  value: AllowedCollectiveOwnershipWorks.VOTED,
  label: 'Oui, votés',
}, {
  value: AllowedCollectiveOwnershipWorks.PAID,
  label: 'Oui, payés',
}, {
  value: AllowedCollectiveOwnershipWorks.DISCUSSED,
  label: 'Oui, discutés',
}];

const offersOptions = [{
  value: AllowedOffer.INELIGIBLE,
  label: 'Inéligible',
}, {
  value: AllowedOffer.ETINCELLE,
  label: 'Étincelle',
}, {
  value: AllowedOffer.ENVOL,
  label: 'Envol',
}, {
  value: AllowedOffer.ETOILE,
  label: 'Étoile',
}];

const investmentMethodOptions = [{
  value: AllowedInvestmentMethod.LMNP,
  label: 'LMNP',
}, {
  value: AllowedInvestmentMethod.SARL_DE_FAMILLE,
  label: 'SARL de famille',
}, {
  value: AllowedInvestmentMethod.SCI_IR,
  label: 'SCI IR',
}, {
  value: AllowedInvestmentMethod.SCI_IS,
  label: 'SCI IS',
}, {
  value: AllowedInvestmentMethod.SAS,
  label: 'SAS',
}, {
  value: AllowedInvestmentMethod.NOM_PROPRE,
  label: 'Nom Propre',
}, {
  value: AllowedInvestmentMethod.DEFICIT_FONCIER,
  label: 'Déficit foncier',
}];

const miniOffersOptions = [{
  value: AllowedOffer.ETINCELLE,
  label: 'Étincelle',
}, {
  value: AllowedOffer.ENVOL,
  label: 'Envol',
}, {
  value: AllowedOffer.ETOILE,
  label: 'Étoile',
}];

const kanbanTypeOptions = [{
  value: AllowedKanbanType?.PROJECTS,
  label: 'Projets',
}, {
  value: AllowedKanbanType?.PROPERTIES,
  label: 'Biens',
}, {
  value: AllowedKanbanType?.SALES,
  label: 'Commercial',
}];

const fileLabelOptions = [{
  value: AllowedDocumentLabel.ID_DOC,
  label: 'Document d\'identité',
}, {
  value: AllowedDocumentLabel.WEDDING_CONTRAT_DOC,
  label: 'Contrat d\'union',
}, {
  value: AllowedDocumentLabel.OTHER_DOC,
  label: 'Autre',
}, {
  value: AllowedDocumentLabel.CONTRAT,
  label: 'Contrat',
}];

const weddingPlanOptions = [{
  label: 'De la communauté réduite aux acquêts',
  value: AllowedWeddingPlan.COMMUNITY_REDUCED_TO_ACQUISITIONS,
}, {
  label: 'De la séparation de biens',
  value: AllowedWeddingPlan.SEPARATION_OF_PROPERTY,
}, {
  label: 'De la participation aux acquêts',
  value: AllowedWeddingPlan.PARTICIPATION_IN_ACQUISITIONS,
}, {
  label: 'De la communauté universelle',
  value: AllowedWeddingPlan.UNIVERSAL_COMMUNITY,
}, {
  label: 'PACS - indivision',
  value: AllowedWeddingPlan.PACS_INDIVISION,
}, {
  label: 'PACS - séparation de biens',
  value: AllowedWeddingPlan.PACS_SEPARATION,
}];

const booleanOptions = [{
  label: 'Oui',
  value: true,
}, {
  label: 'Non',
  value: false,
}];

const genderOptions = [{
  label: 'Homme',
  value: AllowedGender.MALE,
}, {
  label: 'Femme',
  value: AllowedGender.FEMALE,
}];

const employmentContractOptions = [{
  value: AllowedEmploymentContract.CDI,
  label: 'CDI',
}, {
  value: AllowedEmploymentContract.CDD,
  label: 'CDD',
}, {
  value: AllowedEmploymentContract.ENTREPRENEUR,
  label: 'Chef d’entreprise',
}, {
  value: AllowedEmploymentContract.INDEPENDENT,
  label: 'Indépendant',
}, {
  value: AllowedEmploymentContract.LIBERAL_PROFESSION,
  label: 'Profession libérale',
}, {
  value: AllowedEmploymentContract.OTHER,
  label: 'Autre',
}];

const exploitationOptions = [{
  label: 'Colocation',
  value: AllowedExploitation.FLAT_SHARE,
}, {
  label: 'Location nue',
  value: AllowedExploitation.EMPTY_RENTAL,
}, {
  label: 'Location meublée',
  value: AllowedExploitation.FURNISHED_RENTAL,
}, {
  label: 'Location saisonnière',
  value: AllowedExploitation.SEASONAL_RENTAL,
}, {
  label: 'Location pro',
  value: AllowedExploitation.PRO_RENTAL,
}];

const seniorityOptions = [{
  label: 'Période d\'essai',
  value: AllowedSeniority.TRIAL_PERIOD,
}, {
  label: 'Hors période d\'essai',
  value: AllowedSeniority.OUT_OF_TRIAL_PERIOD,
}];

const seniority2Options = [{
  label: 'Moins de 3 ans',
  value: AllowedSeniority.UNDER_THREE_YEARS,
},
{
  label: 'Plus de trois ans',
  value: AllowedSeniority.MORE_THREE_YEARS,
}];

const seniorityMergedOptions = [{
  label: 'Période d\'essai',
  value: AllowedSeniority.TRIAL_PERIOD,
},
{
  label: 'Hors période d\'essai',
  value: AllowedSeniority.OUT_OF_TRIAL_PERIOD,
},
{
  label: 'Moins de 3 ans',
  value: AllowedSeniority.UNDER_THREE_YEARS,
},
{
  label: 'Plus de trois ans',
  value: AllowedSeniority.MORE_THREE_YEARS,
}];

const tabCountryOptions = [{
  value: 'AF',
  label: 'Afghane',
}, {
  value: 'AX',
  label: 'Ålandaise',
}, {
  value: 'AL',
  label: 'Albanaise',
}, {
  value: 'DZ',
  label: 'Algérienne',
}, {
  value: 'AD',
  label: 'Andorrane',
}, {
  value: 'AO',
  label: 'Angolaise',
}, {
  value: 'AI',
  label: 'Anguillane',
}, {
  value: 'AQ',
  label: 'Antarctique',
}, {
  value: 'AG',
  label: 'Antigua et Barbuda',
}, {
  value: 'AR',
  label: 'Argentine',
}, {
  value: 'AM',
  label: 'Arménienne',
}, {
  value: 'AW',
  label: 'Arubainne',
}, {
  value: 'AU',
  label: 'Australienne',
}, {
  value: 'AT',
  label: 'Autrichienne',
}, {
  value: 'AZ',
  label: 'Azerbaïdjanaise',
}, {
  value: 'BS',
  label: 'Bahamienne',
}, {
  value: 'BH',
  label: 'Bahreïnienne',
}, {
  value: 'BD',
  label: 'Bangladaise',
}, {
  value: 'BB',
  label: 'Barbadienne',
}, {
  value: 'BY',
  label: 'Biélorusse',
}, {
  value: 'BE',
  label: 'Belge',
}, {
  value: 'BZ',
  label: 'Bélizienne',
}, {
  value: 'BJ',
  label: 'Béninoise',
}, {
  value: 'BM',
  label: 'Bermudienne',
}, {
  value: 'BT',
  label: 'Bhoutanaise',
}, {
  value: 'BO',
  label: 'Bolivienne',
}, {
  value: 'BA',
  label: 'Bosniaque',
}, {
  value: 'BW',
  label: 'Botswanaise',
}, {
  value: 'BR',
  label: 'Brésilienne',
}, {
  value: 'BN',
  label: 'Brunéienne',
}, {
  value: 'BG',
  label: 'Bulgare',
}, {
  value: 'BF',
  label: 'Burkinabée',
}, {
  value: 'BI',
  label: 'Burundaise',
}, {
  value: 'KH',
  label: 'Cambodgienne',
}, {
  value: 'CM',
  label: 'Camerounaise',
}, {
  value: 'CA',
  label: 'Canadienne',
}, {
  value: 'CV',
  label: 'Cap-Verdienne',
}, {
  value: 'CF',
  label: 'Centrafricaine',
}, {
  value: 'TD',
  label: 'Tchadienne',
}, {
  value: 'CL',
  label: 'Chilienne',
}, {
  value: 'CN',
  label: 'Chinoise',
}, {
  value: 'HK',
  label: 'Hongkongais',
}, {
  value: 'CO',
  label: 'Colombienne',
}, {
  value: 'KM',
  label: 'Comorienne',
}, {
  value: 'CG',
  label: 'Congolaise',
}, {
  value: 'CR',
  label: 'Costaricaine',
}, {
  value: 'CI',
  label: 'Ivoirienne',
}, {
  value: 'HR',
  label: 'Croate',
}, {
  value: 'CU',
  label: 'Cubaine',
}, {
  value: 'CY',
  label: 'Chypriote',
}, {
  value: 'CZ',
  label: 'Tchèque',
}, {
  value: 'DK',
  label: 'Danoise',
}, {
  value: 'DJ',
  label: 'Djiboutienne',
}, {
  value: 'DM',
  label: 'Dominiquaise',
}, {
  value: 'DO',
  label: 'Dominicaine',
}, {
  value: 'EC',
  label: 'Équatorienne',
}, {
  value: 'EG',
  label: 'Égyptienne',
}, {
  value: 'SV',
  label: 'Salvadorienne',
}, {
  value: 'GQ',
  label: 'Équato-Guinéenne',
}, {
  value: 'ER',
  label: 'Érythréenne',
}, {
  value: 'EE',
  label: 'Estonienne',
}, {
  value: 'ET',
  label: 'Éthiopienne',
}, {
  value: 'FJ',
  label: 'Fidjienne',
}, {
  value: 'FI',
  label: 'Finlandaise',
}, {
  value: 'FR',
  label: 'Française',
}, {
  value: 'GA',
  label: 'Gabonaise',
}, {
  value: 'GM',
  label: 'Gambienne',
}, {
  value: 'GE',
  label: 'Géorgienne',
}, {
  value: 'DE',
  label: 'Allemande',
}, {
  value: 'GH',
  label: 'Ghanéenne',
}, {
  value: 'GI',
  label: 'Gibraltarienne',
}, {
  value: 'GR',
  label: 'Grecque',
}, {
  value: 'GD',
  label: 'Grenadienne',
}, {
  value: 'GT',
  label: 'Guatémaltèque',
}, {
  value: 'GG',
  label: 'Guernesey',
}, {
  value: 'GN',
  label: 'Guinéenne',
}, {
  value: 'GW',
  label: 'Bissau-Guinéenne',
}, {
  value: 'GY',
  label: 'Guyanien',
}, {
  value: 'HT',
  label: 'Haïtienne',
}, {
  value: 'VA',
  label: 'Vatican',
}, {
  value: 'HN',
  label: 'Hondurienne',
}, {
  value: 'HU',
  label: 'Hongroise',
}, {
  value: 'IS',
  label: 'Islandaise',
}, {
  value: 'IN',
  label: 'Indienne',
}, {
  value: 'ID',
  label: 'Indonésienne',
}, {
  value: 'IR',
  label: 'Iranienne',
}, {
  value: 'IQ',
  label: 'Irakienne',
}, {
  value: 'IE',
  label: 'Irlandaise',
}, {
  value: 'IL',
  label: 'Israélienne',
}, {
  value: 'IT',
  label: 'Italienne',
}, {
  value: 'JM',
  label: 'Jamaïcaine',
}, {
  value: 'JP',
  label: 'Japonaise',
}, {
  value: 'JO',
  label: 'Jordanienne',
}, {
  value: 'KZ',
  label: 'Kazakh',
}, {
  value: 'KE',
  label: 'Kenyane',
}, {
  value: 'KI',
  label: 'Kiribatienne',
}, {
  value: 'KR',
  label: 'Sud-Coréenne',
}, {
  value: 'KW',
  label: 'Koweïtienne',
}, {
  value: 'KG',
  label: 'Kirghiz',
}, {
  value: 'LA',
  label: 'Laotienne',
}, {
  value: 'LV',
  label: 'Lettonne',
}, {
  value: 'LB',
  label: 'Libanaise',
}, {
  value: 'LS',
  label: 'Lesothane',
}, {
  value: 'LR',
  label: 'Libérienne',
}, {
  value: 'LY',
  label: 'Libyenne',
}, {
  value: 'LI',
  label: 'Liechtensteinoise',
}, {
  value: 'LT',
  label: 'Lituanienne',
}, {
  value: 'LU',
  label: 'Luxembourgeoise',
}, {
  value: 'MK',
  label: 'Macédonienne',
}, {
  value: 'MG',
  label: 'Malgache',
}, {
  value: 'MW',
  label: 'Malawite',
}, {
  value: 'MY',
  label: 'Malaisienne',
}, {
  value: 'MV',
  label: 'Maldivienne',
}, {
  value: 'ML',
  label: 'Malienne',
}, {
  value: 'MT',
  label: 'Maltaise',
}, {
  value: 'MR',
  label: 'Mauritanienne',
}, {
  value: 'MU',
  label: 'Mauricienne',
}, {
  value: 'MX',
  label: 'Mexicaine',
}, {
  value: 'FM',
  label: 'Micronésien',
}, {
  value: 'MD',
  label: 'Moldaves',
}, {
  value: 'MC',
  label: 'Monégasque',
}, {
  value: 'MN',
  label: 'Mongole',
}, {
  value: 'ME',
  label: 'Monténégrine',
}, {
  value: 'MA',
  label: 'Marocaine',
}, {
  value: 'MZ',
  label: 'Mozambicaine',
}, {
  value: 'MM',
  label: 'Birmans',
}, {
  value: 'NA',
  label: 'Namibienne',
}, {
  value: 'NR',
  label: 'Nauruans',
}, {
  value: 'NP',
  label: 'Népalaise',
}, {
  value: 'NL',
  label: 'Néerlandaise',
}, {
  value: 'NZ',
  label: 'Néo-Zélandaise',
}, {
  value: 'NI',
  label: 'Nicaraguayenne',
}, {
  value: 'NE',
  label: 'Nigérienne',
}, {
  value: 'NG',
  label: 'Nigériane',
}, {
  value: 'NU',
  label: 'Niuéen',
}, {
  value: 'NO',
  label: 'Norvégienne',
}, {
  value: 'OM',
  label: 'Omanaise',
}, {
  value: 'PK',
  label: 'Pakistanaise',
}, {
  value: 'PW',
  label: 'Palaois',
}, {
  value: 'PS',
  label: 'Palestinienne',
}, {
  value: 'PA',
  label: 'Panaméenne',
}, {
  value: 'PG',
  label: 'Papouasienne',
}, {
  value: 'PY',
  label: 'Paraguayenne',
}, {
  value: 'PE',
  label: 'Péruvienne',
}, {
  value: 'PH',
  label: 'Philippine',
}, {
  value: 'PL',
  label: 'Polonaise',
}, {
  value: 'PT',
  label: 'Portugaise',
}, {
  value: 'QA',
  label: 'Qatarie',
}, {
  value: 'RO',
  label: 'Roumaine',
}, {
  value: 'RU',
  label: 'Russe',
}, {
  value: 'RW',
  label: 'Rwandaise',
}, {
  value: 'KN',
  label: 'Christophien',
}, {
  value: 'VC',
  label: 'Vincentaise',
}, {
  value: 'ST',
  label: 'Santoméen',
}, {
  value: 'SA',
  label: 'Saoudienne',
}, {
  value: 'SN',
  label: 'Sénégalaise',
}, {
  value: 'RS',
  label: 'Serbe',
}, {
  value: 'SC',
  label: 'Seychelloise',
}, {
  value: 'SL',
  label: 'Sierraléonaise',
}, {
  value: 'SG',
  label: 'Singapourienne',
}, {
  value: 'SK',
  label: 'Slovaque',
}, {
  value: 'SI',
  label: 'Slovène',
}, {
  value: 'SB',
  label: 'Salomonienne',
}, {
  value: 'SO',
  label: 'Somalienne',
}, {
  value: 'ZA',
  label: 'Sud-Africaine',
}, {
  value: 'SS',
  label: 'Sud-soudanaise',
}, {
  value: 'ES',
  label: 'Espagnole',
}, {
  value: 'LK',
  label: 'Sri-Lankaise',
}, {
  value: 'SD',
  label: 'Soudanaise',
}, {
  value: 'SR',
  label: 'Surinamienne',
}, {
  value: 'SZ',
  label: 'Eswatinienne',
}, {
  value: 'SE',
  label: 'Suédoise',
}, {
  value: 'CH',
  label: 'Suisse',
}, {
  value: 'SY',
  label: 'Syrienne',
}, {
  value: 'TW',
  label: 'Taïwanaise',
}, {
  value: 'TJ',
  label: 'Tadjik',
}, {
  value: 'TZ',
  label: 'Tanzanienne',
}, {
  value: 'TH',
  label: 'Thaïlandaise',
}, {
  value: 'TL',
  label: 'Timoraise',
}, {
  value: 'TG',
  label: 'Togolaise',
}, {
  value: 'TO',
  label: 'Tongienne',
}, {
  value: 'TT',
  label: 'Trinidadienne',
}, {
  value: 'TN',
  label: 'Tunisienne',
}, {
  value: 'TR',
  label: 'Turque',
}, {
  value: 'TM',
  label: 'Turkmène',
}, {
  value: 'TV',
  label: 'Tuvalaise',
}, {
  value: 'UG',
  label: 'Ougandaise',
}, {
  value: 'UA',
  label: 'Ukrainienne',
}, {
  value: 'AE',
  label: 'Émirienne',
}, {
  value: 'GB',
  label: 'Britannique',
}, {
  value: 'US',
  label: 'Américaine',
}, {
  value: 'UY',
  label: 'Uruguayenne',
}, {
  value: 'UZ',
  label: 'Ouzbek',
}, {
  value: 'VU',
  label: 'Vanuataise',
}, {
  value: 'VE',
  label: 'Vénézuélienne',
}, {
  value: 'VN',
  label: 'Vietnamienne',
}, {
  value: 'YE',
  label: 'Yéménite',
}, {
  value: 'ZM',
  label: 'Zambienne',
}, {
  value: 'ZW',
  label: 'Zimbabwéenne',
},
];

const tabBankOptions = [{
  value: 'allianz_banque',
  label: 'Allianz Banque',
}, {
  value: 'axa_banque',
  label: 'AXA Banque',
}, {
  value: 'banque_accord',
  label: 'Banque Accord',
}, {
  value: 'banque_de_savoie',
  label: 'Banque de Savoie',
}, {
  value: 'banque_federale_mutualiste_bfm',
  label: 'Banque Fédérale Mutualiste (BFM)',
}, {
  value: 'barclays',
  label: 'Barclays',
}, {
  value: 'bnp_paribas',
  label: 'BNP Paribas',
}, {
  value: 'bpe',
  label: 'BPE',
}, {
  value: 'bred_banque_populaire',
  label: 'BRED (Banque populaire)',
}, {
  value: 'caisse_depargne',
  label: 'Caisse d\'Epargne',
}, {
  value: 'cetelem',
  label: 'Cetelem',
}, {
  value: 'cic',
  label: 'CIC',
}, {
  value: 'credit_agricole',
  label: 'Crédit agricole',
}, {
  value: 'credit_coopératif',
  label: 'Crédit coopératif',
}, {
  value: 'credit_du_nord',
  label: 'Crédit du nord',
}, {
  value: 'credit_foncier',
  label: 'Crédit Foncier',
}, {
  value: 'credit_mutuel',
  label: 'Crédit mutuel',
}, {
  value: 'floa_bank_ex_banque_casino',
  label: 'FLOA Bank (ex. Banque Casino)',
}, {
  value: 'fortis_banque',
  label: 'Fortis Banque',
}, {
  value: 'groupama_banque',
  label: 'Groupama Banque',
}, {
  value: 'hsbc',
  label: 'HSBC',
}, {
  value: 'institut_pour_le_financement_du_cinema_et_des_industries_culturelles',
  label: 'Institut pour le Financement du Cinéma et des Industries Culturelles',
}, {
  value: 'la_banque_postale',
  label: 'La Banque Postale',
}, {
  value: 'lcl',
  label: 'LCL',
}, {
  value: 'rci_banque',
  label: 'RCI Banque',
}, {
  value: 'lombard_odier',
  label: 'Lombard Odier',
}, {
  value: 'societe_generale',
  label: 'Société Générale',
}, {
  value: 'vtb_bank',
  label: 'VTB Bank',
}, {
  value: 'banque_caledonienne_investissement_bci',
  label: 'Banque Calédonienne d’Investissement (BCI)',
}, {
  value: 'banque_de_nouvelle_caledonie_bnc',
  label: 'Banque de Nouvelle Calédonie (BNC)',
}, {
  value: 'banque_des_antilles_francaises_bdaf',
  label: 'Banque des Antilles françaises (BDAF)',
}, {
  value: 'b_for_bank',
  label: 'B For Bank',
}, {
  value: 'boursorama_banque',
  label: 'Boursorama Banque',
}, {
  value: 'coopabanque',
  label: 'Coopabanque',
}, {
  value: 'cortal_consors',
  label: 'Cortal Consors',
}, {
  value: 'e.lcl',
  label: 'e.LCL',
}, {
  value: 'fortuneo',
  label: 'Fortuneo',
}, {
  value: 'hello_bank!',
  label: 'Hello bank!',
}, {
  value: 'ing_direct',
  label: 'ING Direct',
}, {
  value: 'l_agence_directe',
  label: 'L\'agence directe',
}, {
  value: 'l_agence_en_ligne_de_bnp_paribas',
  label: 'L\'Agence en ligne de BNP Paribas',
}, {
  value: 'manager.one',
  label: 'Manager.one',
}, {
  value: 'monabanq',
  label: 'Monabanq',
}, {
  value: 'psa_banque',
  label: 'PSA Banque',
}, {
  value: 'anytime',
  label: 'Anytime',
}, {
  value: 'aumax_pour_moi',
  label: 'Aumax pour moi',
}, {
  value: 'blank',
  label: 'Blank',
}, {
  value: 'c-zam_carrefour_banque',
  label: 'C-Zam (Carrefour Banque)',
}, {
  value: 'compte_nickel',
  label: 'Compte Nickel',
}, {
  value: 'ditto_bank',
  label: 'Ditto Bank',
}, {
  value: 'eko',
  label: 'eko',
}, {
  value: 'finom',
  label: 'Finom',
}, {
  value: 'globe_trotter',
  label: 'Globe Trotter',
}, {
  value: 'lydia',
  label: 'Lydia',
}, {
  value: 'ma_french_bank',
  label: 'Ma French Bank',
}, {
  value: 'memo_bank',
  label: 'Memo bank',
}, {
  value: 'morning',
  label: 'Morning',
}, {
  value: 'n26',
  label: 'N26',
}, {
  value: 'orange_bank',
  label: 'Orange Bank',
}, {
  value: 'prismea',
  label: 'Prismea',
}, {
  value: 'qonto',
  label: 'Qonto',
}, {
  value: 'revolut',
  label: 'Revolut',
}, {
  value: 'shine',
  label: 'Shine',
}, {
  value: 'smartpay',
  label: 'SmartPay',
}, {
  value: 'vivid_money',
  label: 'Vivid Money',
}, {
  value: 'banque_hottinguer',
  label: 'Banque Hottinguer',
}, {
  value: 'banque_palatine',
  label: 'Banque Palatine',
}, {
  value: 'banque_privee_europeenne',
  label: 'Banque Privée Européenne',
}, {
  value: 'legal_and_general',
  label: 'Legal & General',
}, {
  value: 'mirabaud',
  label: 'Mirabaud',
}, {
  value: 'natixis_wealth_management',
  label: 'Natixis Wealth Management',
}, {
  value: 'rothschild',
  label: 'Rothschild',
}, {
  value: 'societe_generale_private_banking',
  label: 'Société générale Private Banking',
}, {
  value: 'wormser_freres',
  label: 'Wormser Frères',
}, {
  value: 'other_bank',
  label: 'Autre banque',
}];

const propertyTypeOptions = [{
  label: 'Appartement',
  value: AllowedPropertyType.FLAT,
}, {
  label: 'Maison',
  value: AllowedPropertyType.HOUSE,
}, {
  label: 'Immeuble',
  value: AllowedPropertyType.BUILDING,
}, {
  label: 'Terrain',
  value: AllowedPropertyType.LAND,
}];

const flatTypeOptions = [{
  value: AllowedFlatType.T1,
  label: 'T1',
}, {
  value: AllowedFlatType.T2,
  label: 'T2',
}, {
  value: AllowedFlatType.T3,
  label: 'T3',
}, {
  value: AllowedFlatType.T4,
  label: 'T4',
}, {
  value: AllowedFlatType.T5,
  label: 'T5',
}, {
  value: AllowedFlatType.T6,
  label: 'T6',
}, {
  value: AllowedFlatType.T7,
  label: 'T7',
}, {
  value: AllowedFlatType.T8,
  label: 'T8',
}, {
  value: AllowedFlatType.T9,
  label: 'T9',
}, {
  value: AllowedFlatType.T10,
  label: 'T10',
}];

const dpeGesOptions = [{
  value: AllowedDpeGes.A,
  label: 'A',
}, {
  value: AllowedDpeGes.B,
  label: 'B',
}, {
  value: AllowedDpeGes.C,
  label: 'C',
}, {
  value: AllowedDpeGes.D,
  label: 'D',
}, {
  value: AllowedDpeGes.E,
  label: 'E',
}, {
  value: AllowedDpeGes.F,
  label: 'F',
}, {
  value: AllowedDpeGes.G,
  label: 'G',
},
];

const sellingReasonOptions = [{
  value: AllowedSellingReason.DEATHS,
  label: 'Décès',
}, {
  value: AllowedSellingReason.SEPARATION,
  label: 'Séparation',
}, {
  value: AllowedSellingReason.MOVING_OUT,
  label: 'Déménagement',
}, {
  value: AllowedSellingReason.DIVORCE,
  label: 'Divorce',
}, {
  value: AllowedSellingReason.FINANCIAL,
  label: 'Financière',
}, {
  value: AllowedSellingReason.OTHER,
  label: 'Autre',
}];

const levelOfUrgencyOptions = [{
  value: AllowedLevelOfUrgency.UNDER_48H,
  label: 'Moins de 48h',
  color: (theme: any) => theme?.palette?.error.main,
}, {
  value: AllowedLevelOfUrgency.BETWEEN_48_AND_A_WEEK,
  label: 'Entre 2j et une sem.',
  color: (theme: any) => theme?.palette?.warning.main,
}, {
  value: AllowedLevelOfUrgency.MORE_THAN_A_WEEK,
  label: "Plus d'une sem.",
  color: (theme: any) => theme?.palette?.success.main,
}];

const matchingStateOptions = [{
  value: AllowedMatchingState.ACCEPTED,
  label: 'Accepté',
}, {
  value: AllowedMatchingState.PENDING,
  label: 'En attente',
}, {
  value: AllowedMatchingState.REFUSED,
  label: 'Décliné',
}];

const internetFiberOptions = [{
  value: AllowedInternetFiber?.YES,
  label: 'Oui',
}, {
  value: AllowedInternetFiber?.NO,
  label: 'Non',
}, {
  value: AllowedInternetFiber?.YES_IN_PROPERTY,
  label: 'Oui dans l‘immeuble',
}];

const appointmentTypeOptions = [{
  value: AllowedAppointmentType.INFORMATION,
  label: 'Information',
}, {
  value: AllowedAppointmentType.PROJETS,
  label: 'Projets',
}, {
  value: AllowedAppointmentType.FINANCEMENT,
  label: 'Financement',
}, {
  value: AllowedAppointmentType.CLOSING,
  label: 'Closing',
}];

const appointmentTypeVariantOptions = [{
  value: AllowedAppointmentType.INFORMATION,
  label: 'Information',
}, {
  value: AllowedAppointmentType.PROJETS,
  label: 'Projets',
}, {
  value: AllowedAppointmentType.FINANCEMENT,
  label: 'Financement',
}, {
  value: AllowedAppointmentType.CLOSING,
  label: 'Closing',
}, {
  value: 'DEFAULT',
  label: 'Aucun rendez-vous',
}];

const statusForProjectsLogs = [...propertyStatusOptions, ...statusOptions];

export {
  weddingPlanOptions,
  genderOptions,
  tabCountryOptions,
  tabBankOptions,
  booleanOptions,
  employmentContractOptions,
  seniorityOptions,
  seniority2Options,
  seniorityMergedOptions,
  statusOptions,
  propertyClosedStatusOptions,
  offersOptions,
  fileLabelOptions,
  levelOfUrgencyOptions,
  sellingReasonOptions,
  dpeGesOptions,
  flatTypeOptions,
  propertyTypeOptions,
  kanbanTypeOptions,
  orientationOptions,
  collectiveOwnershipWorksOptions,
  landlordFeesOptions,
  exploitationOptions,
  customerSourceOptions,
  transportationOptions,
  miniOffersOptions,
  investmentMethodOptions,
  propertyStatusOptions,
  fileLabelOptionsInputProperty,
  matchingStateOptions,
  statusForProjectsLogs,
  closedStatusOptions,
  stateOptions,
  internetFiberOptions,
  appointmentTypeOptions,
  appointmentTypeVariantOptions,
};
