import {
  Box, Grid, Tab, Tabs, Typography, Button,
} from '@mui/material';
import React, { useState } from 'react';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useSearchParams, useNavigate } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { RightColumn } from '../components/right-column';
import { PersonalInfos } from '../components/personal-infos';
import { Documents } from '../components/document';
import { Logs } from '../components/logs';
import { Finance } from '../components/finance';
import { PropertyMatched } from '../components/property-matched';
import { useGetProjectById } from '../../../hooks/query/get-project-by-id-query.hook';
import { AnalyticalData } from '../components/analytical-data';

function ProjectCard() {
  const [value, setValue] = useState(0);

  const tabs = [
    <InfoIcon />,
    <AttachMoneyIcon />,
    <MapsHomeWorkIcon />,
    <FolderOpenIcon />,
    <FormatListBulletedIcon />,
    <QueryStatsIcon />,
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      paddingX={1}
      display="flex"
      width="100%"
    >
      <Box
        width="75%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <TabsHandler value={value} onChange={handleChange} tabs={tabs} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="25%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <RightColumn />
      </Box>
    </Box>
  );
}

function TabsHandler({ value = 0, onChange, tabs }: { value: number, onChange: any, tabs: any }) {
  const [searchParams] = useSearchParams();
  const queryInfo = useGetProjectById(searchParams.get('projectId'));

  const chooseInvestor = () => {
    return queryInfo?.data?.getProjectById?.customers?.find((f) => f._id === searchParams.get('customerId'));
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={onChange}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '16px',
        }}
      >
        {tabs.map((e: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined, i: { toString: () => any; }) => (
          <Tab
            icon={e}
            key={e + i.toString()}
          />
        ))}
      </Tabs>
      <Grid
        item
        padding={value === 0 && !queryInfo?.data?.getProjectById?.config?.isDefaultProject ? 3 : 0}
        marginTop={3}
        sx={{
          bgcolor: value === 0 && !queryInfo?.data?.getProjectById?.config?.isDefaultProject ? 'background.paper' : 'inherit',
          height: 'calc(100vh - 182px)',
          borderRadius: '16px',
          overflow: 'auto',
        }}
      >
        {value === 0 ? queryInfo?.data?.getProjectById?.config?.isDefaultProject
          ? <DisplayTextWhenIsDefaultProject queryInfo={queryInfo} />
          : <PersonalInfos data={chooseInvestor()} queryInfo={queryInfo} /> : null}
        {value === 1 ? queryInfo?.data?.getProjectById?.config?.isDefaultProject
          ? <DisplayTextWhenIsDefaultProject queryInfo={queryInfo} />
          : <Finance data={chooseInvestor()} queryInfo={queryInfo} /> : null}
        {value === 2 ? queryInfo?.data?.getProjectById?.config?.isDefaultProject
          ? <DisplayTextWhenIsDefaultProject queryInfo={queryInfo} /> : <PropertyMatched /> : null}
        {value === 3 ? <Documents queryInfo={queryInfo} /> : null}
        {value === 4 ? <Logs /> : null}
        {value === 5 ? <AnalyticalData queryInfo={queryInfo} /> : null}
      </Grid>
    </Box>
  );
}

function DisplayTextWhenIsDefaultProject({ queryInfo }: any) {
  const navigate = useNavigate();
  const string = "Veuillez créer un projet avant d'accéder à cette page";
  return (
    <Box display="flex" alignItems="center" justifyContent="center" paddingY={8} flexDirection="column">
      <Typography variant="h6" marginBottom={2}>{string}</Typography>
      <Button
        variant="contained"
        sx={{ textTransform: 'none' }}
        endIcon={(
          <ArrowForwardIosIcon
            fontSize="small"
          />
        )}
        onClick={() => navigate(`/clientcard?userId=${queryInfo?.data?.getProjectById?.createdBy?._id}`)}
      >
        Fiche prospect
      </Button>
    </Box>
  );
}

export {
  ProjectCard,
};
