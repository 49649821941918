import algoliasearch from 'algoliasearch/lite';

const useAlgolia = () => {
  const searchClient = algoliasearch(`${process.env.REACT_APP_ALGOLIA_APP_ID}`, `${process.env.REACT_APP_ALGOLIA_API_KEY}`);
  const indexName = `${process.env.REACT_APP_ALGOLIA_INDEX_NAME}`;
  return {
    searchClient,
    indexName,
  };
};

export {
  useAlgolia,
};
