import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { CalendarCustom } from '../../../../common/inputs/calendarcustom';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';

function HousingLoan() {
  const {
    control,
  } = useFormContext<any>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'housing_loan',
  });

  const defaultValue = {
    monthly_amount: 0,
    loan_amount: 0,
    remaining_loan: 0,
    end_date: null,
  };

  return (
    <Box>
      {fields?.map((item, indexField) => (
        <Box key={item?.id} display="flex" justifyContent="space-between" paddingY={1} alignItems="center">
          <Box>
            <CurrencyTextFieldCustom
              size="small"
              variant="outlined"
              control={control}
              name={`housing_loan[${indexField}].monthly_amount`}
              label="Montant de la mensualité"
            />
          </Box>
          <Box paddingX={1}>
            <CurrencyTextFieldCustom
              control={control}
              size="small"
              variant="outlined"
              name={`housing_loan[${indexField}].loan_amount`}
              label="Capital emprunté"
            />
          </Box>
          <Box paddingX={1}>
            <CurrencyTextFieldCustom
              control={control}
              size="small"
              variant="outlined"
              name={`housing_loan[${indexField}].remaining_loan`}
              label="Capital restant dû"
            />
          </Box>
          <Box paddingX={1}>
            <CalendarCustom
              control={control}
              size="small"
              variant="outlined"
              name={`housing_loan[${indexField}].end_date`}
              label="Date de fin"
            />
          </Box>
          <Box>
            <Button fullWidth size="small" sx={{ height: '38.34px' }} color="error" variant="outlined" onClick={() => remove(indexField)}>
              <ClearIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
      ))}
      <Button
        size="small"
        sx={{ marginTop: 2, width: '100%', fontWeight: 'bold' }}
        variant="outlined"
        onClick={() => append(defaultValue)}
        color="secondary"
        endIcon={<AddIcon />}
      >
        Ajouter une ligne
      </Button>
    </Box>
  );
}

export {
  HousingLoan,
};
