import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  AllowedOffer,
} from '../../../../api';
import { DisplayValue } from '../../pages';
import { levelOfUrgencyOptions, miniOffersOptions, sellingReasonOptions } from '../../../../data/data';
import { AutocompleteCustom } from '../../../../common/inputs/autocompletecustom';
import { RichTextCustom } from '../../../../common/inputs/richtextcustom';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { UpdateAgentInfos } from './agency/update-agent-infos';
import { AddAgencyContainer } from './agency/add-agency';
import { AddAgentContainer } from './agency/add-agent';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { useGetAllRealEstateAgencies } from '../../../../hooks/query/get-all-real-estate-agencies-query.hook';
import { useGetAgencyById } from '../../../../hooks/query/get-agency-by-id-query.hook';
import { euro } from '../../../../common/inputs/format/euro-format';

interface IFormInput {
  selling_reason: string
  level_of_urgency: string
  comment: string
  property_offer: string
  expected_property_selling_price: string,
  realEstateAgency: string | null,
  realEstateAgent: string | null,
}

function Conclusion({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();
  const [openAddAgency, setOpenAddAgency] = useState(false);
  const [openAddAgent, setOpenAddAgent] = useState(false);
  const [agencyId, setAgencyId] = useState(queryInfo?.data?.getPropertiesById?.realEstateAgency?._id);
  const { enqueueSnackbar } = useSnackbar();

  const queryAgency = useGetAllRealEstateAgencies();
  const queryAgent = useGetAgencyById(agencyId);

  const format = {
    ...data?.conclusion,
    selling_reason: sellingReasonOptions.find((f) => f.value === data?.conclusion?.selling_reason),
    level_of_urgency: levelOfUrgencyOptions.find((f) => f.value === data?.conclusion?.level_of_urgency),
    property_offer: miniOffersOptions.find((f) => f.value === data?.conclusion?.property_offer),
    realEstateAgency: queryInfo?.data?.getPropertiesById?.realEstateAgency?._id ? {
      value: queryInfo?.data?.getPropertiesById?.realEstateAgency?._id,
      label: `${queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyName} - ${queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyAddress}`,
    } : null,
    realEstateAgent: {
      value: queryInfo?.data?.getPropertiesById?.realEstateAgent?._id,
      label: queryInfo?.data?.getPropertiesById?.realEstateAgent?.name,
    },
  };

  const {
    control, reset, handleSubmit, watch, setValue,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return { ...format };
    }, [format, searchParams.get('propertyId')]),
  });

  useEffect(() => {
    // @ts-ignore
    setAgencyId(watch('realEstateAgency')?.value);
  }, [watch('realEstateAgency')]);

  useEffect(() => {
    reset({ ...format });
    // @ts-ignore
  }, [queryInfo.isSuccess]);

  const tabAgency = queryAgency?.data?.getAllRealEstateAgencies?.map((e: any) => ({
    value: e?._id,
    label: `${e?.realEstateAgencyName} - ${e?.realEstateAgencyAddress}`,
  }));

  const tabAgent = queryAgent?.data?.getAgencyById?.realEstateAgents?.map((e: any) => ({
    value: e?._id,
    label: e?.name,
  }));

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        realEstateAgency: form.realEstateAgency?.value,
        realEstateAgent: form?.realEstateAgent?.value,
        conclusion: {
          level_of_urgency: form?.level_of_urgency?.value,
          comment: form?.comment,
          selling_reason: form?.selling_reason?.value,
          property_offer: form?.property_offer?.value,
          expected_property_selling_price: Number(form?.expected_property_selling_price),
        },
      },
    };
    Object.keys(formValid.data?.conclusion).forEach((key) => {
      if (formValid.data?.conclusion[key] === undefined || formValid.data?.conclusion[key] === null || Number.isNaN(formValid.data?.conclusion[key])) {
        delete formValid.data?.conclusion[key];
      }
    });
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
          <Typography color="primary" variant="h5" marginBottom={2}>Contexte de la vente</Typography>
          <Grid container item columns={2} spacing={3}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      tab={sellingReasonOptions}
                      control={control}
                      name="selling_reason"
                      label="Raison de la vente"
                    />
                  ) : (
                    <DisplayValue
                      title="Raison de la vente"
                      // @ts-ignore
                      value={data?.conclusion?.selling_reason ? sellingReasonOptions.find((f: any) => f.value === data?.conclusion?.selling_reason).label : ''}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={levelOfUrgencyOptions}
                      name="level_of_urgency"
                      label="Niveau d’urgence"
                    />
                  ) : (
                    <DisplayValue
                      title="Niveau d'urgence"
                      // @ts-ignore
                      value={data?.conclusion?.level_of_urgency ? levelOfUrgencyOptions.find((f: any) => f.value === data?.conclusion?.level_of_urgency).label : ''}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={miniOffersOptions}
                      name="property_offer"
                      label="Offre"
                    />
                  ) : (
                    <DisplayValue
                      title="Offre"
                      // @ts-ignore
                      value={data?.conclusion?.property_offer ? miniOffersOptions.find((f: any) => f.value === data?.conclusion?.property_offer).label : ''}
                    />
                  )}
              </Box>
              {
                // @ts-ignore
                watch('property_offer')?.value === AllowedOffer.Etoile
                && (
                  <Box paddingY={1}>
                    {toModify
                      ? (
                        <CurrencyTextFieldCustom
                          control={control}
                          name="expected_property_selling_price"
                          label="Prix de revente estimé"
                        />
                      ) : (
                        <DisplayValue
                          title="Prix de revente estimé"
                          value={data?.conclusion?.expected_property_selling_price ? euro.format(data?.conclusion?.expected_property_selling_price) : 0}
                        />
                      )}
                  </Box>
                )
              }
            </Grid>
          </Grid>
          <Agency
            toModify={toModify}
            tabAgency={tabAgency}
            control={control}
            tabAgent={tabAgent}
            openAddAgency={openAddAgency}
            setOpenAddAgency={setOpenAddAgency}
            setOpenAddAgent={setOpenAddAgent}
            queryAgency={queryAgency}
            setValue={setValue}
            openAddAgent={openAddAgent}
            watch={watch}
            queryInfo={queryInfo}
            queryAgent={queryAgent}
          />
          <Grid container item columns={1}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <RichTextCustom
                      control={control}
                      name="comment"
                      label="Commentaire"
                    />
                  ) : (
                    <DisplayValue comment title="Commentaire" value={data?.conclusion?.comment} />
                  )}
              </Box>
            </Grid>
          </Grid>
          {toModify
            ? (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setToModify(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

function Agency({
  toModify,
  tabAgency,
  tabAgent,
  control,
  openAddAgency,
  setOpenAddAgency,
  setOpenAddAgent,
  queryAgency,
  setValue,
  watch,
  openAddAgent,
  queryInfo,
  queryAgent,
}: any) {
  useEffect(() => {
    // @ts-ignore
    if (watch('realEstateAgency')?.value !== queryInfo?.data?.getPropertiesById?.realEstateAgency?._id) {
      setValue('realEstateAgent', null);
    }
  }, [watch('realEstateAgency')]);
  return (
    <Grid container item columns={2} spacing={3}>
      <Grid item xs={1}>
        <Box paddingY={1}>
          {toModify
            ? (
              <>
                {!openAddAgency && tabAgency
                  && (
                    <>
                      <AutocompleteCustom
                        size="small"
                        tab={tabAgency && tabAgency}
                        control={control}
                        name="realEstateAgency"
                        label="Veuillez sélectionner une agence immobilière"
                      />
                      <Button
                        type="button"
                        sx={{
                          cursor: 'pointer',
                          textTransform: 'none',
                          textDecoration: 'underline',
                          transition: 'opacity 0.3s',
                          width: 'fit-content',
                          fontSize: '14px',
                          ':hover': {
                            opacity: 0.8,
                          },
                        }}
                        startIcon={<AddCircleIcon fontSize="small" />}
                        color="primary"
                        onClick={() => {
                          setOpenAddAgency(!openAddAgency);
                          setOpenAddAgent(false);
                        }}
                      >
                        Ajouter une agence immobilière
                      </Button>
                    </>
                  )}
                {openAddAgency && (
                  <AddAgencyContainer
                    queryAgency={queryAgency}
                    openAddAgency={openAddAgency}
                    setValue={setValue}
                    setOpenAddAgency={setOpenAddAgency}
                  />
                )}
              </>
            ) : (
              <DisplayValue
                title="Agence immobilière"
                value={queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyName && queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyAddress ? `${queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyName} - ${queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyAddress}` : '...'}
              />
            )}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box paddingY={1}>
          {toModify
            ? (
              <>
                {watch('realEstateAgency') && !openAddAgent
                  && (
                    <>
                      <AutocompleteCustom
                        size="small"
                        tab={tabAgent || []}
                        control={control}
                        name="realEstateAgent"
                        label="Veuillez sélectionner un agent immobilier"
                      />
                      <Button
                        type="button"
                        sx={{
                          cursor: 'pointer',
                          textTransform: 'none',
                          textDecoration: 'underline',
                          transition: 'opacity 0.3s',
                          fontSize: '14px',
                          width: 'fit-content',
                          ':hover': {
                            opacity: 0.8,
                          },
                        }}
                        startIcon={<AddCircleIcon fontSize="small" />}
                        color="primary"
                        onClick={() => {
                          setOpenAddAgent(!openAddAgent);
                          setOpenAddAgency(false);
                        }}
                      >
                        Ajouter un agent immobilier
                      </Button>
                      <UpdateAgentInfos
                        queryInfo={queryInfo}
                        agentId={watch('realEstateAgent')?.value}
                        queryAgent={queryAgent}
                        queryAgency={queryAgency}
                        setValueInput={setValue}
                      />
                    </>
                  )}
                {openAddAgent && (
                  <AddAgentContainer
                    setOpen={setOpenAddAgent}
                    agencyId={watch('realEstateAgency')?.value}
                    queryAgency={queryAgency}
                    setValue={setValue}
                  />
                )}
              </>
            ) : (
              <>
                <DisplayValue
                  title="Agent immobilier"
                  value={queryInfo?.data?.getPropertiesById?.realEstateAgent?.name}
                />
                <Box
                  component="a"
                  sx={{ color: 'inherit', textDecoration: 'none' }}
                  href={`mailto:+${queryInfo?.data?.getPropertiesById?.realEstateAgent?.email}`}
                >
                  <Typography
                    color="text.primary"
                  >
                    {queryInfo?.data?.getPropertiesById?.realEstateAgent?.email || '...'}
                  </Typography>
                </Box>
                <Box
                  component="a"
                  sx={{ color: 'inherit', textDecoration: 'none' }}
                  href={`tel:+${queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone}`}
                >
                  <Typography
                    color="text.primary"
                  >
                    {queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone ? queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone : '...'}
                  </Typography>
                </Box>
              </>
            )}
        </Box>
      </Grid>
    </Grid>
  );
}

export {
  Conclusion,
};
