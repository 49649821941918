import Box from '@mui/material/Box';
import React from 'react';
import { Typography } from '@mui/material';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';

function Taxes({ control, modify }: any) {
  return (
    <Box>
      <Typography paddingLeft={1} variant="h5" color="primary">Impôts</Typography>
      <Box paddingY={1} display="flex" width="100%" flexDirection="column">
        <Box paddingY={1}>
          <CurrencyTextFieldCustom
            control={control}
            name="amount_taxe"
            read={!modify}
            label="Impôt sur le revenu"
          />
        </Box>
        <Box paddingY={1}>
          <CurrencyTextFieldCustom
            control={control}
            name="amount_housing_taxe"
            read={!modify}
            label="Taxe foncière"
          />
        </Box>
        <Box paddingY={1}>
          <CurrencyTextFieldCustom
            control={control}
            name="amount_renting_taxe"
            read={!modify}
            label="Taxe d’habitation"
          />
        </Box>
      </Box>
    </Box>
  );
}

export {
  Taxes,
};
