import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';

function Saving({ control, modify }: any) {
  return (
    <Box>
      <Typography paddingLeft={1} variant="h5" color="primary">Épargne</Typography>
      <Box paddingY={1} display="flex" width="100%" flexDirection="column">
        <Box paddingY={1}>
          <CurrencyTextFieldCustom
            control={control}
            read={!modify}
            label="Épargne disponible"
            name="amount_available"
          />
        </Box>
        <Box paddingY={1}>
          <CurrencyTextFieldCustom
            control={control}
            read={!modify}
            label="Épargne placée"
            name="amount_invested"
          />
        </Box>
      </Box>
    </Box>
  );
}

export {
  Saving,
};
