import React from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Box, Button, Container, Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Reset } from 'styled-reset';
import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { StyleProvider, ToggleColorMode } from './style/style';
import { AuthenticatedApp } from './authentication/components/authenticatedapp';

function Authentication() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0<{
    sub: any;
    name: string
  }>();

  if (isLoading) {
    return (
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '70vh',
        }}
      >
        <CircularProgress size={80} />
        <Typography variant="h6" paddingTop={15}>Chargement ...</Typography>
      </Container>
    );
  }
  if (error) {
    return (
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h1" paddingTop={10} paddingBottom={10}>Oops...</Typography>
        <Typography variant="h6" paddingBottom={15}>{error?.message}</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}
        >
          Déconnexion
        </Button>
      </Container>
    );
  }

  if (isAuthenticated && user) {
    return (
      <AuthenticatedApp />
    );
  }

  return (
    <>
      {loginWithRedirect()}
    </>
  );
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <StyleProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
        redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
        scope="email profile openid"
      >
        <Reset />
        <QueryClientProvider client={queryClient}>
          <GlobalContainer>
            <ToggleColorMode />
            <SnackbarProvider variant="error" autoHideDuration={5000}>
              <Authentication />
            </SnackbarProvider>
          </GlobalContainer>
        </QueryClientProvider>
      </Auth0Provider>
    </StyleProvider>
  );
}

const GlobalContainer = styled(Box)(() => ({
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  scrollBehavior: 'smooth',
  webkitFontSmoothing: 'antialiased',
}));

export default App;
