import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box, ClickAwayListener, InputBase, Typography,
} from '@mui/material';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import TimerIcon from '@mui/icons-material/Timer';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { useInstantSearch, useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web';
import { AllowedPropertyType } from '../../api';
import { flatTypeOptions, levelOfUrgencyOptions, propertyStatusOptions } from '../../data/data';

function SearchBar(props: UseSearchBoxProps) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState('');
  const [search, setSearch] = useState({ projects: [], properties: [] });

  const { refine } = useSearchBox(props);
  const { results } = useInstantSearch();

  const toggleSearch = () => {
    setOpen(true);
  };

  const handleClickAway = (setState: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; }) => {
    setState(false);
  };

  function handleSearch(event: any) {
    refine(event.target.value);
    setValues(event.target.value);
  }

  useEffect(() => {
    if (values) {
      setOpen(true);
    }
  }, [values]);

  const formatHitFromSearch = (hit: any) => ({ ...hit, _id: hit.objectID });

  useEffect(() => {
    const reduce = results?.hits.filter((elem) => !elem?._doc)
      .map(formatHitFromSearch).reduce((prev, next) => {
        if ('ad_link' in next) {
          prev.properties.push(next);
        } else {
          prev.projects.push(next);
        }
        return prev;
      }, { projects: [], properties: [] });
    setSearch(reduce);
  }, [results]);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <ClickAwayListener
        onClickAway={() => handleClickAway(setOpen)}
      >
        <StyledInputBase
          placeholder="Rechercher…"
          onFocus={toggleSearch}
          value={values}
          onChange={(e) => handleSearch(e)}
          inputProps={{ 'aria-label': 'search' }}
        />
      </ClickAwayListener>
      <DiplayAll projects={search?.projects} properties={search?.properties} open={open} />
    </Search>
  );
}

function chooseIcon(param: string) {
  switch (param) {
    case AllowedPropertyType.FLAT:
      return '🔑 ';
    case AllowedPropertyType.HOUSE:
      return '🏡 ';
    case AllowedPropertyType.BUILDING:
      return '🏢 ';
    case AllowedPropertyType.LAND:
      return '⛰ ';
    default:
      return '🔑 ';
  }
}

function chooseProjectTitle(length: number) {
  switch (length) {
    case 0:
      return 'Aucun projet trouvé ...';
    case 1:
      return '1 Projet trouvé';
    default:
      return `${length} Projets`;
  }
}

function choosePropertyTitle(length: number) {
  switch (length) {
    case 0:
      return 'Aucun bien trouvé ...';
    case 1:
      return '1 Bien trouvé';
    default:
      return `${length} Biens trouvés`;
  }
}

function DiplayAll({
  open, projects, properties,
}: any) {
  const isDarkTheme = useTheme().palette.mode === 'dark';

  return (
    <>
      {
        open && (projects?.length > 0 || properties?.length > 0)
        // @ts-ignore
        && (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'background.paper',
              paddingY: 3,
              paddingX: 3,
              width: '200%',
              marginTop: 1,
              borderRadius: 4,
              boxShadow: `0px 4px 7px ${isDarkTheme ? '#202020' : '#EBEAEA'}`,
            }}
            display="flex"
          >
            <ProjectSide projects={projects} />
            <PropertySide properties={properties} />
          </Box>
        )
      }
    </>
  );
}

function ProjectSide({ projects }: any) {
  return (
    <Box width="50%" flexDirection="column" marginRight={1}>
      <Box paddingX={2} paddingBottom={1}>
        <Typography variant="h6" fontWeight="bold">
          {chooseProjectTitle(projects?.length)}
        </Typography>
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          maxHeight: 300,
        }}
      >
        {
          // @ts-ignore
          projects?.length > 0 && projects?.map((e, i) => (
            <a
              key={e?._id + i.toString()}
              href={`/project-list/projectcard?projectId=${e?._id}&customerId=${e?.customers[0]?._id}`}
              style={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  transition: 'all 0.2s',
                  ':hover': {
                    backgroundColor: 'background.default',
                  },
                }}
                display="flex"
                paddingX={2}
                paddingY={0.7}
                flexDirection="column"
              >
                <Typography
                  variant="body2"
                  noWrap
                  fontWeight="600"
                  color="text.primary"
                  display="flex"
                  alignItems="center"
                >
                  {e?.hotDeal
                    && <LocalFireDepartmentIcon color="warning" sx={{ marginRight: 0.5, fontSize: '16px' }} />}
                  {e?.createdBy?.isCustomerAlready
                    && <LocalPoliceIcon color="warning" sx={{ marginRight: 0.5, fontSize: '16px' }} />}
                  {`${e?.name || ''} ${e?.customers[0]?.personal_info?.first_name ? '|' : ''} ${e?.customers[0]?.personal_info?.first_name || ''} ${e?.customers[0]?.personal_info?.last_name || ''}`}
                </Typography>
                <Typography
                  variant="caption"
                  noWrap
                  fontWeight="bold"
                  color="text.secondary"
                >
                  {e?.createdBy?.email}
                </Typography>
              </Box>
            </a>
          ))
        }
      </Box>
    </Box>
  );
}

function PropertySide({ properties }: any) {
  return (
    <Box width="50%" flexDirection="column" marginLeft={1}>
      <Box paddingX={2} paddingBottom={1}>
        <Typography variant="h6" fontWeight="bold">
          {choosePropertyTitle(properties?.length)}
        </Typography>
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          maxHeight: 300,
        }}
      >
        {
          // @ts-ignore
          properties?.length > 0 && properties?.map((e, i) => (
            <a
              key={e?._id + i.toString()}
              href={`/property-list/propertycard?propertyId=${e?._id}`}
              style={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  transition: 'all 0.2s',
                  ':hover': {
                    backgroundColor: 'background.default',
                  },
                }}
                display="flex"
                paddingX={2}
                paddingY={0.7}
                flexDirection="column"
              >
                <Typography
                  variant="body2"
                  noWrap
                  fontWeight="600"
                  color="text.primary"
                  alignItems="center"
                  display="flex"
                >
                  {chooseIcon(e?.type)}
                  {e?.description?.flat_type ? `${flatTypeOptions?.find((f) => f.value === e?.description?.flat_type)?.label} ${e?.description?.surface ? `de ${e?.description?.surface}m2` : ''} ${e?.localisation?.street_address?.locality ? `à ${e?.localisation?.street_address?.locality}` : '' || ''}` : e?.localisation?.street_address?.address_line_1}
                </Typography>
                <Typography
                  variant="caption"
                  noWrap
                  fontWeight="bold"
                  color="primary"
                >
                  {e?.localisation?.street_address?.address_line_1}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="caption"
                    noWrap
                    fontWeight="bold"
                    color="text.secondary"
                  >
                    {propertyStatusOptions?.find((f) => f.value === e?.status)?.label || ''}
                  </Typography>
                  <Typography
                    variant="caption"
                    noWrap
                    fontWeight="bold"
                    color="text.secondary"
                    marginX={0.5}
                  >
                    {levelOfUrgencyOptions?.find((f) => f.value === e?.conclusion?.level_of_urgency) && '|'}
                  </Typography>
                  <Typography
                    variant="caption"
                    noWrap
                    fontWeight="bold"
                    display="flex"
                    alignItems="center"
                    color={levelOfUrgencyOptions?.find((f) => f.value === e?.conclusion?.level_of_urgency)?.color}
                  >
                    {levelOfUrgencyOptions?.find((f) => f.value === e?.conclusion?.level_of_urgency)
                      && <TimerIcon sx={{ marginRight: 0.5, fontSize: '16px' }} />}
                    {levelOfUrgencyOptions?.find((f) => f.value === e?.conclusion?.level_of_urgency)?.label || ''}
                  </Typography>
                </Box>
              </Box>
            </a>
          ))
        }
      </Box>
    </Box>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '16px',
  backgroundColor: theme.palette.background.default,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(5),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: 15,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '32ch',
      },
      '&:hover': {
        width: '32ch',
      },
    },
  },
}));

export {
  SearchBar,
};
