import { useGetEmailsSentFromCustomerIoQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetEmailsSentFromCustomerIo = (email: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();

  const queryInfo = useGetEmailsSentFromCustomerIoQuery(headerWithAuth, {
    email: email || '',
  }, { enabled: !!accessToken });

  return queryInfo;
};

export {
  useGetEmailsSentFromCustomerIo,
};
