import React from 'react';
import { Box } from '@mui/material';
import { CustomerIoEmails } from './customer-io-emails';
import { UserJourneyBeforeAccountCreation } from './user-journey-before-account-creation';

function AnalyticalData({ queryInfo }: any) {
  return (
    <>
      <CustomerIoEmails queryInfo={queryInfo} />
      <Box marginY={3} />
      <UserJourneyBeforeAccountCreation queryInfo={queryInfo} />
    </>
  );
}

export {
  AnalyticalData,
};
