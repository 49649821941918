import { useInfiniteGetAllPropertiesQuery } from '../../api/infinite_scroll_index';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useInfiniteGetAllProperties = (variables: any, rowPerPageOptions: any) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetAllPropertiesQuery<any>(
    headerWithAuth,
    'args',
    variables,
    {
      // @ts-ignore
      enabled: !!accessToken,
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.getAllProperties?.page?.pageInfo.hasNextPage) {
          return {
            first: rowPerPageOptions?.value,
            after: lastPage?.getAllProperties?.page?.pageInfo?.endCursor,
          };
        }
        return null;
      },
    },
  );

  return {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  };
};

export {
  useInfiniteGetAllProperties,
};
