import {
  Autocomplete,
  Box,
  styled,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { closedStatusOptions } from '../../../../data/data';
import { SectionTitle } from '../../../../common/right-column/section-title';
import { useUpdateProject } from '../../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  closedStatus: any
}

function ClosedStatus({ queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const {
    control, handleSubmit, reset,
  } = useForm<IFormInput>({
    mode: 'onBlur',
    // @ts-ignore
    defaultValues: useMemo(() => {
      return {
        closedStatus: closedStatusOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.closedStatus),
      };
    }, [queryInfo]),
  });

  useEffect(() => {
    reset({
      // @ts-ignore
      closedStatus: closedStatusOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.closedStatus),
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      data: {
        closedStatus: form?.closedStatus?.value,
      },
      projectId: searchParams.get('projectId'),
    };
    if (form?.closedStatus?.value !== queryInfo?.data?.getProjectById?.closedStatus) {
      mutate({ ...formValid });
    }
  };

  return (
    <Box
      component="form"
      onBlur={handleSubmit(onSubmit)}
    >
      <SectionTitle title="SOUS-STATUT" />
      <Controller
        name="closedStatus"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            blurOnSelect="mouse"
            disableClearable
            options={closedStatusOptions}
            value={field.value || ''}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            renderInput={
              (params) => (
                <CssAutocomplete
                  {...params}
                  size="small"
                  placeholder="Sous-statut"
                  fullWidth
                  variant="outlined"
                  color="error"
                />
              )
            }
          />
        )}
      />
    </Box>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.main : theme.palette.error.main,
  borderRadius: '4px',
  input: {
    fontWeight: 'bold',
    color: theme.palette.mode === 'dark' ? 'white' : 'white',
  },
  button: {
    color: theme.palette.mode === 'dark' ? 'inherit' : 'white !important',
  },
}));

export {
  ClosedStatus,
};
