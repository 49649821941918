import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { DisplayOffer } from './components/offer';
import { Appointment } from './components/appointment';
import { statusForProjectsLogs } from '../../../../data/data';
import { DisplayStatus } from '../../../../common/logs/status';
import { DisplayComment } from '../../../../common/logs/comment';
import { useCreateComment } from '../../../../hooks/mutation/create-comment-mutation.hook';
import { useGetLogsByProject } from '../../../../hooks/query/get-logs-by-project-query.hook';
import { RichTextCustomLogs } from '../../../../common/logs/rich-text-custom-logs';

interface IFormInput {
  log_message: string
}

function Logs() {
  const [searchParams] = useSearchParams();
  const {
    control, handleSubmit, setValue, watch,
  } = useForm<IFormInput>({
    mode: 'onChange',
  });

  const queryInfoLogs = useGetLogsByProject(searchParams.get('projectId'));

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useCreateComment({
    onSuccess: (resp: any) => {
      setValue('log_message', '');
      queryInfoLogs.refetch().then((r) => r);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        comment: form?.log_message,
        projectId: searchParams.get('projectId'),
      },
    };
    mutate({ ...formValid });
  };

  return (
    <Box>
      <Appointment queryInfoLogs={queryInfoLogs} />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <RichTextCustomLogs
          name="log_message"
          control={control}
          watch={watch}
        />
      </Box>
      {queryInfoLogs?.data?.getLogsByProject?.map((e, i) => {
        if ('status' in e.action) {
          return (
            <Box key={e._id}>
              <DisplayStatus
                // @ts-ignore
                label={statusForProjectsLogs.find((f) => f.value === e?.action?.status)?.label}
                mail={e?.user?.email}
                createdAt={e?.createdAt && format(new Date(Number(e.createdAt)), 'dd MMMM yyyy', { locale: fr })}
                display={i + 1 === queryInfoLogs?.data?.getLogsByProject?.length ? 'none' : 'inherit'}
              />
            </Box>
          );
        }
        if ('offer' in e.action) {
          return (
            <Box key={e._id}>
              <DisplayOffer data={e} index={i} queryInfoLogs={queryInfoLogs} />
            </Box>
          );
        }
        if ('comment' in e.action) {
          return (
            <Box key={e._id}>
              <DisplayComment
                picto={e?.user?.email && e?.user?.email.length > 1 ? `${e.user.email[0]}${e.user.email[1]}` : '...'}
                mail={e?.user?.email}
                createdAt={e.createdAt && format(new Date(Number(e.createdAt)), 'dd MMMM yyyy', { locale: fr })}
                comment={e?.action?.comment}
                display={i + 1 === queryInfoLogs?.data?.getLogsByProject?.length ? 'none' : 'inherit'}
              />
            </Box>
          );
        }
        return <Box key={e._id} />;
      })}
    </Box>
  );
}

export {
  Logs,
};
