import React from 'react';
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Loader } from '../../../../common/components/loader';
import { useGetEmailsSentFromCustomerIo } from '../../../../hooks/query/get-emails-sent-from-customer-io.hook';

function CustomerIoEmails({ queryInfo }: any) {
  const queryInfoCustomerIoEmails = useGetEmailsSentFromCustomerIo(queryInfo?.data?.getProjectById?.createdBy?.email);

  return (
    <Box>
      <Paper elevation={0} sx={{ borderRadius: '16px' }}>
        <Grid paddingY={3}>
          <Box display="flex" justifyContent="space-between" paddingX={2} paddingBottom={5}>
            <Box display="flex" alignItems="center">
              <Typography marginRight={3} textAlign="left" variant="h5" color="primary">
                ✉️ Liste des emails d‘onboarding
              </Typography>
              <Chip
                label={`nb: ${queryInfoCustomerIoEmails?.data?.getEmailsSentFromCustomerIO?.length || '0'}`}
                color="primary"
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
          {queryInfoCustomerIoEmails?.isLoading ? (<Loader size={40} />) : (
            <Box padding={3}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Objet d‘Email</TableCell>
                      <TableCell>Envoyé</TableCell>
                      <TableCell>Délivré</TableCell>
                      <TableCell>Ouvert</TableCell>
                      <TableCell>Désinscrit</TableCell>
                      <TableCell>Rejeté</TableCell>
                      <TableCell>Indésirable</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {queryInfoCustomerIoEmails?.data?.getEmailsSentFromCustomerIO?.map((row: any) => (
                      <TableRow
                        key={row?.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell width="40%">
                          {row?.type === 'webhook' ? 'Webhook' : row?.subject}
                        </TableCell>
                        <TableCell width="10%">{row?.metrics?.sent ? format(new Date(Number(row?.metrics?.sent) * 1000), 'dd/MM/yyyy') : ''}</TableCell>
                        <TableCell width="10%">{row?.metrics?.delivered ? format(new Date(Number(row?.metrics?.delivered) * 1000), 'dd/MM/yyyy') : ''}</TableCell>
                        <TableCell width="10%">{row?.metrics?.opened ? format(new Date(Number(row?.metrics?.opened) * 1000), 'dd/MM/yyyy') : ''}</TableCell>
                        <TableCell width="10%">{row?.metrics?.unsubscribed ? format(new Date(Number(row?.metrics?.unsubscribed) * 1000), 'dd/MM/yyyy') : ''}</TableCell>
                        <TableCell width="10%">{row?.metrics?.bounced ? format(new Date(Number(row?.metrics?.bounced) * 1000), 'dd/MM/yyyy') : ''}</TableCell>
                        <TableCell width="10%">{row?.metrics?.spammed ? format(new Date(Number(row?.metrics?.spammed) * 1000), 'dd/MM/yyyy') : ''}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Grid>
      </Paper>
    </Box>
  );
}

export {
  CustomerIoEmails,
};
