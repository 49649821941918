import { useCreateCommentForClientOnlyMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useCreateCommentForClientOnly = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useCreateCommentForClientOnlyMutation(headerWithAuth, options);

  return mutate;
};

export {
  useCreateCommentForClientOnly,
};
