import React, { useState } from 'react';
import {
  Box, Divider, IconButton, List, ListItemText, Switch, Tooltip, Typography,
} from '@mui/material';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useUpdateUser } from '../../../../hooks/mutation/update-user-mutation.hook';

function AlreadyCustomer({ queryInfo }: any) {
  const [editAlreadyCustomer, setEditAlreadyCustomer] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const mutate = useUpdateUser({
    onSuccess: () => {
      setEditAlreadyCustomer(false);
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end" marginY={2}>
      <Divider sx={{ marginY: 1 }} />
      <List>
        <Box width="100%" display="flex" justifyContent="space-between" marginTop={2} marginBottom={1}>
          <Typography align="left" variant="body2" color="primary">INFOS :</Typography>
        </Box>
        <ListItemText
          secondaryTypographyProps={{ component: 'div' }}
          primary={(
            <Typography variant="body2" fontWeight="500" height="24px" display="flex" alignItems="center">
              Client existant
            </Typography>
          )}
          secondary={(
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                {queryInfo?.data?.findClientById?.isCustomerAlready
                  ? <LocalPoliceIcon color="warning" fontSize="small" />
                  : <CloseIcon color="error" fontSize="small" />}
              </Box>
              <Tooltip title={!editAlreadyCustomer ? 'Modifier' : 'Annuler'}>
                <IconButton
                  onClick={() => {
                    setEditAlreadyCustomer(!editAlreadyCustomer);
                  }}
                  size="small"
                  sx={{ marginLeft: 1 }}
                >
                  {!editAlreadyCustomer ? <EditIcon fontSize="inherit" /> : <EditOffIcon fontSize="inherit" />}
                </IconButton>
              </Tooltip>
              { editAlreadyCustomer
                && (
                  <Box>
                    <Switch
                      size="small"
                      checked={queryInfo?.data?.findClientById?.isCustomerAlready}
                      onChange={(e, value) => {
                        const formValid: any = {
                          data: {
                            isCustomerAlready: value,
                          },
                          userId: searchParams.get('userId'),
                        };
                        mutate({ ...formValid });
                      }}
                    />
                  </Box>
                )}
            </Box>
          )}
        />
      </List>
    </Box>
  );
}

export {
  AlreadyCustomer,
};
