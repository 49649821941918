import { Box, Tooltip, Typography } from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import { format } from 'date-fns';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { flatTypeOptions, levelOfUrgencyOptions, propertyTypeOptions } from '../../../../data/data';
import { AllowedPropertyType } from '../../../../api';
import flat_white from '../../../../assets/property-white/appartement_white.png';
import flat from '../../../../assets/property/appartement.png';
import house_white from '../../../../assets/property-white/maison_white.png';
import house from '../../../../assets/property/maison.png';
import building_white from '../../../../assets/property-white/immeuble_white.png';
import building from '../../../../assets/property/immeuble.png';
import land_white from '../../../../assets/property-white/terrain_white.png';
import land from '../../../../assets/property/terrain.png';

function SectionTop({ queryInfo }: any) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const propertyName = queryInfo?.data?.getPropertiesById?.description?.flat_type ? `${flatTypeOptions?.find((f) => f.value === queryInfo?.data?.getPropertiesById?.description?.flat_type)?.label} ${queryInfo?.data?.getPropertiesById?.description?.surface ? `de ${queryInfo?.data?.getPropertiesById?.description?.surface}m2` : ''} ${queryInfo?.data?.getPropertiesById?.localisation?.street_address?.locality ? `à ${queryInfo?.data?.getPropertiesById?.localisation?.street_address?.locality}` : '' || ''}` : '';

  const chooseTypeOfProperty = (type: any) => {
    switch (type) {
      case AllowedPropertyType.FLAT: {
        return isDarkTheme ? flat_white : flat;
      }
      case AllowedPropertyType.HOUSE: {
        return isDarkTheme ? house_white : house;
      }
      case AllowedPropertyType.BUILDING: {
        return isDarkTheme ? building_white : building;
      }
      case AllowedPropertyType.LAND: {
        return isDarkTheme ? land_white : land;
      }
      default: {
        return flat;
      }
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box width="40px" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
          <Box
            component="img"
            sx={{
              display: 'block', objectFit: 'cover', width: '100%', paddingRight: '8px',
            }}
            src={chooseTypeOfProperty(queryInfo?.data?.getPropertiesById?.type)}
          />
        </Box>
        <Box display="flex" justifyContent="center" flexDirection="column" height="100%" width="calc(100% - 40px)">
          <Box display="flex" alignItems="center">
            {queryInfo?.data?.getPropertiesById?.hotDeal && <LocalFireDepartmentIcon fontSize="small" color="warning" sx={{ marginRight: 0.5 }} />}
            <Tooltip title={propertyName} placement="bottom-start">
              <Typography
                align="left"
                fontWeight="bold"
                variant="h5"
                color="text.primary"
                textOverflow="ellipsis"
                sx={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {propertyName}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {queryInfo?.data?.getPropertiesById?.conclusion?.level_of_urgency
  && (
    <Typography
      marginTop={2}
      marginBottom={1}
      display="flex"
      alignItems="center"
      fontWeight="bold"
      variant="body1"
      sx={{
        color: levelOfUrgencyOptions.find((f) => f.value === queryInfo?.data?.getPropertiesById?.conclusion?.level_of_urgency)?.color,
      }}
    >
      <TimerIcon
        sx={{
          marginRight: 1,
          color: levelOfUrgencyOptions.find((f) => f.value === queryInfo?.data?.getPropertiesById?.conclusion?.level_of_urgency)?.color,
        }}
      />
      {levelOfUrgencyOptions.find((f) => f.value === queryInfo?.data?.getPropertiesById?.conclusion?.level_of_urgency)?.label}
    </Typography>
  )}
      <Typography marginY={1} align="left" variant="body1" color="text.secondary">
        {`Type de bien: ${propertyTypeOptions?.find((f) => f.value === queryInfo?.data?.getPropertiesById?.type)?.label}`}
      </Typography>
      <Typography marginY={1} align="left" variant="body1" color="text.secondary">
        {`Crée le ${queryInfo?.data?.getPropertiesById?.createdAt && format(new Date(queryInfo?.data?.getPropertiesById?.createdAt), 'dd/MM/yyyy')}`}
      </Typography>
      <Typography
        marginY={1}
        display="flex"
        alignItems="center"
        variant="body1"
        color="text.secondary"
      >
        <LocationOnIcon color="primary" sx={{ marginRight: 1 }} />
        {queryInfo?.data?.getPropertiesById?.localisation?.street_address?.address_line_1}
      </Typography>
      <Box marginY={1}>
        {queryInfo?.data?.getPropertiesById?.ad_link?.map((e: string, i: number) => (
          <Typography
            key={e + i.toString()}
            display="flex"
            alignItems="center"
            variant="body1"
            color="text.secondary"
          >
            <LinkIcon color="primary" sx={{ marginRight: 1 }} />
            <a
              href={e}
              style={{ color: 'inherit' }}
              rel="noreferrer"
              target="_blank"
            >
              {`Lien vers l'annonce n° ${i + 1}`}
            </a>
          </Typography>
        ))}
      </Box>
    </>
  );
}

export {
  SectionTop,
};
