import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Box, TextField, TextFieldProps, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import frLocale from 'date-fns/locale/fr';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';

function CalendarWithHoursCustom({
  control, name, label, rules = null, maxDate, minDate,
}: any) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Box>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={frLocale}
          >
            <DateTimePicker
              {...field}
              minutesStep={5}
              maxDate={maxDate}
              minDate={minDate}
              value={field.value || null}
              onChange={(e: any) => {
                field.onChange(e);
              }}
              label={label}
              renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                />
              )}
            />
            {fieldState?.error?.message && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
          </LocalizationProvider>
        </Box>
      )}
    />
  );
}

export {
  CalendarWithHoursCustom,
};
