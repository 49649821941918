import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { getTime, format } from 'date-fns';
import { DisplayValue } from '../../pages';
import {
  dpeGesOptions, exploitationOptions, flatTypeOptions, orientationOptions,
} from '../../../../data/data';
import { AutocompleteCustom } from '../../../../common/inputs/autocompletecustom';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { SwitchCustom } from '../../../../common/inputs/switchcustom';
import { SurfaceTextField } from '../../../../common/inputs/surfacetextfield';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { CalendarCustom } from '../../../../common/inputs/calendarcustom';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { euro } from '../../../../common/inputs/format/euro-format';

interface IFormInput {
  street_address: string
  address_details: string
  flat_type: string
  upfront_price: string
  already_visited: string
  surface: string
  property_story: string
  already_rent: string
  rentingEndDate: string
  dpe_audit: string
  ges_audit: string
  orientation: string
  current_rent: string
  expected_rent: string
  tax: string
  flat_number: string
  visited_date: string
  exploitation: string
}

function Description({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();

  const formatData = {
    ...data?.description,
    visited_date: data?.description?.visited_date ? new Date(data?.description?.visited_date) : null,
    rentingEndDate: data?.description?.rentingEndDate ? new Date(data?.description?.rentingEndDate) : null,
    dpe_audit: dpeGesOptions.find((f) => f.value === data?.description?.dpe_audit),
    ges_audit: dpeGesOptions.find((f) => f.value === data?.description?.ges_audit),
    flat_type: flatTypeOptions.find((f) => f.value === data?.description?.flat_type),
    orientation: orientationOptions.find((f) => f.value === data?.description?.orientation),
    exploitation: exploitationOptions.find((f) => f.value === data?.description?.exploitation),
  };

  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return { ...formatData };
    }, [data, searchParams.get('propertyId')]),
  });

  useEffect(() => {
    reset({ ...formatData });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        description: {
          flat_type: form?.flat_type?.value,
          upfront_price: Number(form?.upfront_price),
          already_visited: form?.already_visited,
          surface: Number(form?.surface),
          orientation: form?.orientation?.value,
          already_rent: form?.already_rent,
          visited_date: form?.visited_date ? getTime(form?.visited_date) : null,
          rentingEndDate: form?.rentingEndDate ? getTime(form?.rentingEndDate) : null,
          current_rent: Number(form?.current_rent),
          flat_number: form?.flat_number,
          property_story: Number(form?.property_story),
          expected_rent: Number(form?.expected_rent),
          tax: Number(form?.tax),
          dpe_audit: form?.dpe_audit?.value,
          ges_audit: form?.ges_audit?.value,
          exploitation: form?.exploitation?.value,
        },
      },
    };
    Object.keys(formValid.data?.description).forEach((key) => {
      if (formValid.data?.description[key] === undefined || formValid.data?.description[key] === null || Number.isNaN(formValid.data?.description[key])) {
        delete formValid.data?.description[key];
      }
    });
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
          <Typography color="primary" variant="h5" marginBottom={2}>Description du bien</Typography>
          <Grid container item columns={3} spacing={3}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={flatTypeOptions}
                      name="flat_type"
                      label="Type de bien"
                    />
                  ) : (
                    <DisplayValue
                      title="Type de bien"
                      // @ts-ignore
                      value={data?.description?.flat_type ? flatTypeOptions?.find((f: any) => f.value === data?.description?.flat_type).label : ''}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="upfront_price"
                      label="Prix affiché"
                    />
                  ) : (
                    <DisplayValue
                      title="Prix affiché"
                      value={data?.description?.upfront_price ? euro.format(data?.description?.upfront_price) : 0}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <SwitchCustom
                      control={control}
                      name="already_visited"
                      label="Déjà visité"
                    />
                  ) : (
                    <DisplayValue
                      title="Déjà visité"
                      value={data?.description?.already_visited ? data?.description?.already_visited ? 'Oui' : 'Non' : ''}
                    />
                  )}
              </Box>
              {
                // @ts-ignore
                watch('already_visited') === true
                && (
                  <Box paddingY={1}>
                    {toModify
                      ? (
                        <CalendarCustom
                          name="visited_date"
                          control={control}
                          minDate={new Date('01/01/1900')}
                          label="Date de visite"
                        />
                      ) : (
                        // @ts-ignore
                        <DisplayValue
                          title="Date de visite"
                          value={data?.description?.visited_date ? format(new Date(data?.description?.visited_date), 'dd/MM/yyyy') : ''}
                        />
                      )}
                  </Box>
                )
              }
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={orientationOptions}
                      name="orientation"
                      label="Orientation du bien"
                    />
                  ) : (
                    <DisplayValue
                      title="Orientation du bien"
                      // @ts-ignore
                      value={data?.description?.orientation ? orientationOptions?.find((f: any) => f.value === data?.description?.orientation).label : ''}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <SurfaceTextField
                      control={control}
                      name="surface"
                      label="Surface habitable"
                      rules={{
                        validate: (value: string) => {
                          return (Number(value) >= 5 && Number(value) <= 1500) || 'Merci de renseigner une surface entre 5 et 1500 m2';
                        },
                      }}
                    />
                  ) : (
                    <DisplayValue
                      title="Surface habitable"
                      value={data?.description?.surface ? `${data?.description?.surface}m2` : 0}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <SwitchCustom
                      control={control}
                      name="already_rent"
                      label="Déjà loué"
                    />
                  ) : (
                    <DisplayValue
                      title="Déjà loué"
                      value={data?.description?.already_rent ? data?.description?.already_rent ? 'Oui' : 'Non' : ''}
                    />
                  )}
              </Box>
              {
                // @ts-ignore
                watch('already_rent') === true
                && (
                  <Box paddingY={1}>
                    {toModify
                      ? (
                        <CalendarCustom
                          name="rentingEndDate"
                          control={control}
                          minDate={new Date()}
                          label="Date de fin de bail"
                        />
                      ) : (
                        // @ts-ignore
                        <DisplayValue
                          title="Date de fin de bail"
                          value={data?.description?.rentingEndDate ? format(new Date(data?.description?.rentingEndDate), 'dd/MM/yyyy') : ''}
                        />
                      )}
                  </Box>
                )
              }
              {
                // @ts-ignore
                watch('already_rent') === true
                && (
                <Box paddingY={1}>
                  {toModify
                    ? (
                      <CurrencyTextFieldCustom
                        control={control}
                        name="current_rent"
                        label="Loyer actuel charges comprises"
                      />
                    ) : (
                      <DisplayValue
                        title="Loyer actuel charges comprises"
                        value={data?.description?.current_rent ? euro.format(data?.description?.current_rent) : 0}
                      />
                    )}
                </Box>
                )
              }
              <Box paddingY={1}>
                {toModify
                  ? (
                    <TextFieldCustom
                      control={control}
                      name="property_story"
                      label="Étage du bien"
                      type="number"
                      numberMax={100}
                    />
                  ) : (
                    <DisplayValue
                      title="Étage du bien"
                      value={data?.description?.property_story ? data?.description?.property_story : '0'}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <TextFieldCustom
                      control={control}
                      name="flat_number"
                      label="Numéro d'appartement"
                    />
                  ) : (
                    // @ts-ignore
                    <DisplayValue
                      title="Numéro d'appartement"
                      value={data?.description?.flat_number ? data?.description?.flat_number : ''}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="expected_rent"
                      label="Loyer prévu charges comprises"
                    />
                  ) : (
                    <DisplayValue
                      title="Loyer prévu charges comprises"
                      value={data?.description?.expected_rent ? euro.format(data?.description?.expected_rent) : 0}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="tax"
                      label="Taxes foncières"
                    />
                  ) : (
                    <DisplayValue
                      title="Taxes foncières"
                      value={data?.description?.tax ? euro.format(data?.description?.tax) : 0}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={dpeGesOptions}
                      name="dpe_audit"
                      label="DPE"
                    />
                  ) : (
                    <DisplayValue
                      title="DPE"
                      // @ts-ignore
                      value={data?.description?.dpe_audit ? dpeGesOptions.find((f: any) => f.value === data?.description?.dpe_audit).label : ''}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={dpeGesOptions}
                      name="ges_audit"
                      label="GES"
                    />
                  ) : (
                    <DisplayValue
                      title="GES"
                      // @ts-ignore
                      value={data?.description?.ges_audit ? dpeGesOptions.find((f: any) => f.value === data?.description?.ges_audit).label : ''}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={exploitationOptions}
                      name="exploitation"
                      label="Exploitation"
                    />
                  ) : (
                    <DisplayValue
                      title="Exploitation"
                      // @ts-ignore
                      value={data?.description?.exploitation ? exploitationOptions.find((f: any) => f.value === data?.description?.exploitation).label : ''}
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
          {toModify
            ? (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setToModify(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  Description,
};
