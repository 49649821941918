import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { getTime } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { CalendarWithHoursCustom } from '../../../../../common/inputs/calendar-with-hours-custom';
import { useUpdateProject } from '../../../../../hooks/mutation/update-project-mutation.hook';
import { AutocompleteCustom } from '../../../../../common/inputs/autocompletecustom';
import { appointmentTypeOptions } from '../../../../../data/data';

interface IFormInput {
  appointmentDate: string | null
  appointmentDateReminder: string | null
  appointmentType: { value: string, label: string } | null
  withEmail: boolean | null
}

function Appointment({ queryInfoLogs }: any) {
  const [reminderDateOpen, setReminderDateOpen] = useState(false);
  const [reminderDateDisabled, setReminderDateOpenDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const mutate = useUpdateProject({
    onSuccess: () => {
      setReminderDateOpenDisabled(true);
      queryInfoLogs.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = () => {
    const formValid: any = {
      data: {
        appointmentDateReminder: getTime(new Date()),
      },
      projectId: searchParams.get('projectId'),
    };
    mutate({ ...formValid });
  };

  return (
    <Box marginBottom={2}>
      <Accordion
        elevation={0}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px !important',
          ':before': {
            content: 'none',
          },
        }}
      >
        <AccordionSummary
          sx={{
            paddingX: 3,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            color="text.secondary"
            variant="h6"
          >
            Ajouter une date
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingX: 3,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FormAppointment
              queryInfoLogs={queryInfoLogs}
              label="Date de rendez-vous"
            />
            {!reminderDateOpen
              && (
                <Box style={{ width: '45%' }}>
                  <Box width="100%" display="flex" justifyContent="center">
                    <Button
                      color="secondary"
                      sx={{ textTransform: 'none' }}
                      variant="outlined"
                      disabled={reminderDateDisabled}
                      onClick={() => onSubmit()}
                    >
                      Relance effectuée
                    </Button>
                    <Button
                      sx={{ textTransform: 'none', marginLeft: 3 }}
                      variant="outlined"
                      onClick={() => setReminderDateOpen(true)}
                    >
                      Relance à programmer
                    </Button>
                  </Box>
                </Box>
              )}
            {reminderDateOpen
              && (
                <FormReminder
                  queryInfoLogs={queryInfoLogs}
                  label="Date de relance"
                  closableValue={setReminderDateOpen}
                  setAppointmentDateOpen={setReminderDateOpen}
                />
              )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function FormAppointment({
  queryInfoLogs,
  label,
}: any) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const {
    control, handleSubmit, watch, reset,
  } = useForm<IFormInput>({
    defaultValues: {
      appointmentDate: null,
      appointmentType: null,
      withEmail: true,
    },
    mode: 'onChange',
  });

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfoLogs.refetch();
      reset({
        appointmentDate: null,
        appointmentType: null,
        withEmail: true,
      });
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        appointmentDate: {
          date: getTime(new Date(form.appointmentDate)),
          type: form?.appointmentType?.value,
          withEmail: form?.withEmail,
        },
      },
      projectId: searchParams.get('projectId'),
    };
    mutate({ ...formValid });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '45%' }}
    >
      <Box width="100%" display="flex" justifyContent="center" flexDirection="column">
        <Box paddingY={0.5} width="100%">
          <CalendarWithHoursCustom
            control={control}
            name="appointmentDate"
            label={label}
          />
        </Box>
        <Box paddingY={0.5} width="100%">
          <AutocompleteCustom
            control={control}
            name="appointmentType"
            label="Type"
            tab={appointmentTypeOptions}
          />
        </Box>
        <Box>
          <Controller
            name="withEmail"
            control={control}
            render={({ field }) => (
              <Box paddingY={0.5} width="100%">
                <FormControlLabel
                  {...field}
                  control={(
                    <Checkbox
                      {...field}
                      defaultChecked
                      value={field.value}
                      onClick={(value) => field.onChange(value)}
                    />
                  )}
                  label="Avec envoi d'email"
                />
              </Box>
            )}
          />
        </Box>
        <Box paddingY={0.5} width="100%">
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            fullWidth
            size="medium"
            type="submit"
            disabled={(watch('appointmentDate') === undefined || watch('appointmentDate') === null) || !watch('appointmentType')}
          >
            Enregistrer date
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function FormReminder({
  queryInfoLogs, label, closableValue, setAppointmentDateOpen,
}: any) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const {
    control, handleSubmit, reset, watch,
  } = useForm<IFormInput>({
    defaultValues: {
      appointmentDateReminder: null,
    },
    mode: 'onChange',
  });

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfoLogs.refetch();
      setAppointmentDateOpen(false);
      reset({
        appointmentDateReminder: null,
      });
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        appointmentDateReminder: getTime(new Date(form?.appointmentDateReminder)),
      },
      projectId: searchParams.get('projectId'),
    };

    mutate({ ...formValid });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '45%' }}
    >
      <Box width="100%" display="flex" justifyContent="center" flexDirection="column">
        <Box paddingY={0.5} width="100%">
          <Button
            sx={{ textTransform: 'none', height: '38.35px' }}
            variant="outlined"
            fullWidth
            color="error"
            onClick={() => closableValue(false)}
            startIcon={<CloseIcon />}
          >
            Annuler
          </Button>
        </Box>
        <Box paddingY={0.5} width="100%">
          <CalendarWithHoursCustom
            control={control}
            name="appointmentDateReminder"
            label={label}
          />
        </Box>
        <Box paddingY={0.5} width="100%">
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            fullWidth
            size="medium"
            type="submit"
            disabled={watch('appointmentDateReminder') === undefined || watch('appointmentDateReminder') === null}
          >
            Enregistrer date
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export {
  Appointment,
};
