import {
  Box, Button, Grid, IconButton, Typography,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AutocompleteCustom } from '../../../../common/inputs/autocompletecustom';
import { transportationOptions } from '../../../../data/data';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { DisplayValue } from '../../pages';

function Transportation({ data, toModify }: any) {
  const {
    control,
  } = useFormContext<any>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'localisation.transportations',
  });

  const defaultValue = {
    transportation: null,
    transportation_detail: null,
    walking_time: null,
  };

  return (
    <Box
      marginTop={3}
    >
      {toModify
        ? (
          <Box>
            {fields.map((item, indexField) => (
              <Box paddingY={1} key={item + indexField.toString()} display="flex" width="100%">
                <Grid container columns={3} spacing={3} marginRight={2}>
                  <Grid item xs={1}>
                    <AutocompleteCustom
                      size="small"
                      control={control}
                      tab={transportationOptions}
                      name={`localisation.transportations[${indexField}].transportation`}
                      label="Type de transport proche"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextFieldCustom
                      size="small"
                      control={control}
                      name={`localisation.transportations[${indexField}].transportation_detail`}
                      label="Ligne de Transport"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextFieldCustom
                      size="small"
                      control={control}
                      type="number"
                      name={`localisation.transportations[${indexField}].walking_time`}
                      label="Temps à pied jusqu’à l’arrêt (min)"
                    />
                  </Grid>
                </Grid>
                <IconButton
                  sx={{
                    height: 40,
                    width: 40,
                  }}
                  onClick={() => remove(indexField)}
                  color="error"
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              type="button"
              sx={{
                cursor: 'pointer',
                textTransform: 'none',
                textDecoration: 'underline',
                transition: 'opacity 0.3s',
                fontSize: '14px',
                width: 'fit-content',
                ':hover': {
                  opacity: 0.8,
                },
              }}
              startIcon={<AddCircleIcon />}
              color="primary"
              onClick={() => append(defaultValue)}
            >
              Ajouter transport
            </Button>
          </Box>
        ) : (
          <Box>
            <DisplayValue
              title="Transport"
              value={' '}
            />
            <Box>
              {
                data?.localisation?.transportations?.map((e: any, i: any) => (
                  <Typography key={e + i.toString()}>
                    {`${transportationOptions.find((f) => f.value === e?.transportation)?.label}
                    ligne
                    ${e?.transportation_detail}
                    à ${e?.walking_time} minutes`}
                  </Typography>
                ))
              }
            </Box>
          </Box>
        )}
    </Box>
  );
}

export {
  Transportation,
};
