import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import { TextFieldCustom } from '../../../../../common/inputs/textfieldcustom';
import { PhoneCustom } from '../../../../../common/inputs/phonecustom';
import { useUpdateRealEstateAgent } from '../../../../../hooks/mutation/update-real-estate-agent-mutation.hook';

interface IFormInput {
  name: string,
  email: string,
  phone: string
}
function UpdateAgentInfos({
  queryInfo, agentId, queryAgent, queryAgency, setValueInput,
}: any) {
  const { enqueueSnackbar } = useSnackbar();
  const countryCode = queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone && parsePhoneNumber(queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone, 'FR');

  const [country, setCountry] = useState<any>({
    countryCode: countryCode?.country ? countryCode?.country : 'FR',
    dialCode: countryCode?.countryCallingCode ? countryCode?.countryCallingCode : '33',
  });
  const agent = queryAgent?.data?.getAgencyById?.realEstateAgents?.find((f: any) => f?._id === agentId);
  const [open, setOpen] = useState(false);

  const format = {
    name: agent?.name,
    email: agent?.email,
    phone: agent?.phone,
  };

  const {
    control, reset, setValue, handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return { ...format };
    }, [format]),
  });

  useEffect(() => {
    reset({ ...format });
    // @ts-ignore
  }, [queryInfo.isSuccess, agentId]);

  useEffect(() => {
    // @ts-ignore
    if (!agentId) {
      setValue('name', '');
      setValue('email', '');
      setValue('phone', '');
    }
  }, [agentId]);

  const mutate = useUpdateRealEstateAgent({
    onSuccess: (resp: any) => {
      queryAgent.refetch();
      queryInfo.refetch();
      queryAgency.refetch();
      setValueInput('realEstateAgent', { value: resp?.updateRealEstateAgent?._id, label: resp?.updateRealEstateAgent?.name });

      setOpen(false);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      updateRealEstateAgentId: agentId,
      updateRealEstateAgent: {
        email: form?.email,
        name: form?.name,
        phone: form?.phone?.replaceAll(' ', ''),
      },
    };
    mutate({ ...formValid });
  };

  return (
    <Box>
      {
        agentId
        && (
        <Box display="flex" paddingY={2}>
          <Button
            color={open ? 'error' : 'primary'}
            onClick={() => setOpen(!open)}
            fullWidth
            variant="contained"
            endIcon={open ? null : <EditIcon />}
            sx={{
              textTransform: 'none',
              color: (theme) => theme.palette.background.paper,
            }}
          >
            {open ? 'Annuler' : 'Modifier infos agent immobilier'}
          </Button>
        </Box>
        )
      }
      {open && agentId
      && (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box paddingBottom={1} paddingTop={1}>
            <TextFieldCustom
              name="name"
              required
              control={control}
              rules={{
                required: 'Veuillez renseigner un nom',
              }}
              label="Modifier le nom de l'agent immobilier"
            />
          </Box>
          <Box paddingY={1}>
            <TextFieldCustom
              rules={{
                required: 'Veuillez renseigner un email',
              }}
              type="email"
              required
              name="email"
              control={control}
              label="Modifier email de l'agent immobilier"
            />
          </Box>
          <Box paddingY={1}>
            <PhoneCustom
              fullWidth
              control={control}
              name="phone"
              setCountry={setCountry}
              rules={{
                validate: (value: string) => {
                  return isValidPhoneNumber(value, country?.countryCode?.toUpperCase()) || `Merci de renseigner un format valide (+${country?.countryCallingCode} XX XX XX XX).`;
                },
              }}
            />
          </Box>
          <Box paddingY={1}>
            <Button
              color="primary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              endIcon={<SaveIcon />}
              sx={{
                textTransform: 'none',
                color: (theme) => theme.palette.background.paper,
              }}
            >
              Enregistrer les modifications
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export {
  UpdateAgentInfos,
};
