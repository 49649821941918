import {
  Box, Grid, ListItemText, Tab, Tabs, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useSearchParams } from 'react-router-dom';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { RightColumn } from '../components/right-column';
import { PropertyInfos } from '../components/property-infos';
import { Simulation } from '../components/simulation';
import { Renovation } from '../components/renovation';
import { Documents } from '../components/document';
import { Logs } from '../components/logs';
import { ProjectMatched } from '../components/project-matched';
import { useGetPropertiesById } from '../../../hooks/query/get-properties-by-id-query.hook';
import { useGlobalConfig } from '../../../hooks/query/global-config.hook';

function PropertyCard() {
  const [searchParams] = useSearchParams();
  const queryInfo = useGetPropertiesById(searchParams.get('propertyId'));
  const [value, setValue] = useState(0);

  const tabs = [
    <InfoIcon />,
    <EngineeringIcon />,
    <AttachMoneyIcon />,
    <MapsHomeWorkIcon />,
    <FolderOpenIcon />,
    <FormatListBulletedIcon />,
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      paddingX={1}
      display="flex"
      width="100%"
    >
      <Box
        width="75%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <TabsHandler queryInfo={queryInfo} value={value} onChange={handleChange} tabs={tabs} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="25%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <RightColumn queryInfo={queryInfo} />
      </Box>
    </Box>
  );
}

function TabsHandler({
  queryInfo, value = 0, onChange, tabs,
}: { queryInfo: any, value: number, onChange: any, tabs: any }) {
  const { getGlobalConfig } = useGlobalConfig();
  return (
    <Box>
      <Tabs
        value={value}
        onChange={onChange}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '16px',
        }}
      >
        {tabs.map((e: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined, i: { toString: () => any; }) => (
          <Tab
            key={e + i.toString()}
            icon={e}
          />
        ))}
      </Tabs>
      <Grid
        item
        marginTop={3}
        sx={{
          height: 'calc(100vh - 182px)',
          borderRadius: '16px',
          overflow: 'auto',
        }}
      >
        {value === 0 ? queryInfo.isLoading ? null : <PropertyInfos queryInfo={queryInfo} /> : null}
        {value === 1 ? queryInfo.isLoading ? null : <Renovation queryInfo={queryInfo} /> : null}
        {value === 2 ? queryInfo.isLoading ? null : <Simulation queryInfo={queryInfo} getGlobalConfig={getGlobalConfig} /> : null}
        {value === 3 ? queryInfo.isLoading ? null : <ProjectMatched propertyInfo={queryInfo} /> : null}
        {value === 4 ? queryInfo.isLoading ? null : <Documents queryInfo={queryInfo} /> : null}
        {value === 5 ? <Logs /> : null}
      </Grid>
    </Box>
  );
}

function DisplayValue({
  title, value, comment = false, children,
}: any) {
  return (
    <ListItemText
      primary={<Typography sx={{ opacity: 0.4 }}>{title}</Typography>}
      secondary={(
        <Box display="flex" alignItems="center">
          {children}
          <Typography whiteSpace={comment ? 'pre-line' : 'inherit'} color="text.primary">{value || '...'}</Typography>
        </Box>
      )}
    />
  );
}

export {
  PropertyCard,
  DisplayValue,
};
