import React from 'react';
import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import FlareIcon from '@mui/icons-material/Flare';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { useSnackbar } from 'notistack';
import StarRateIcon from '@mui/icons-material/StarRate';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { matchingStateOptions, statusOptions } from '../../../../data/data';
import { AllowedMatchingState, AllowedOffer } from '../../../../api';
import { SearchBarMatched } from './searchbar-matched';
import { useGetMatchingListByPropertyId } from '../../../../hooks/query/get-matching-list-by-property-id-query.hook';
import { useUpdateMatchingState } from '../../../../hooks/mutation/update-matching-state-mutation.hook';
import { useAlgolia } from '../../../../hooks/search/algolia';

function ProjectMatched({ propertyInfo }: any) {
  const { searchClient, indexName } = useAlgolia();
  const [searchParams] = useSearchParams();
  const queryInfo = useGetMatchingListByPropertyId(searchParams.get('propertyId'));
  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateMatchingState({
    onSuccess: async () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      // @ts-ignore
      enqueueSnackbar(err.message);
    },
  });

  function chooseIconForOffer(value: any) {
    switch (value) {
      case AllowedOffer.INELIGIBLE: {
        return <Tooltip title="Inéligible"><CancelIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ETINCELLE: {
        return <Tooltip title="Etincelle"><FlareIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ENVOL: {
        return <Tooltip title="Envol"><AirplanemodeActiveIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ETOILE: {
        return <Tooltip title="Étoile"><StarRateIcon fontSize="small" /></Tooltip>;
      }
      default: {
        return <Tooltip title="Non-défini"><RemoveIcon fontSize="small" /></Tooltip>;
      }
    }
  }

  const chooseColor = (param: any) => {
    switch (param) {
      case AllowedMatchingState.ACCEPTED: {
        return 'success.main';
      }
      case AllowedMatchingState.PENDING: {
        return 'warning.main';
      }
      case AllowedMatchingState.REFUSED: {
        return 'error.main';
      }
      default: {
        return 'primary';
      }
    }
  };

  const state = queryInfo?.data?.getMatchingListByPropertyId?.find((f) => f.state === AllowedMatchingState.PENDING || f.state === AllowedMatchingState.ACCEPTED);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {state === undefined
        && (
          <Box
            width="100%"
            bgcolor="background.paper"
            borderRadius="16px"
            marginBottom={2}
            padding={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography marginRight={3} color="primary" variant="h5">Ajouter un projet à ce bien</Typography>
            <InstantSearch searchClient={searchClient} indexName={indexName}>
              <SearchBarMatched propertyInfo={propertyInfo} matchingList={queryInfo} />
            </InstantSearch>
          </Box>
        )}
      <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
        <Box display="flex" alignItems="center">
          <Typography marginRight={3} color="primary" variant="h5">Liste des projets matchés avec ce bien</Typography>
          {!queryInfo?.isSuccess
            ? <CircularProgress size={20} />
            : (
              <Chip
                label={`nb: ${queryInfo?.data?.getMatchingListByPropertyId?.length || 'pas de résultats'}`}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
        </Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Matching</TableCell>
                <TableCell>Offre</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell align="right">Modifier matching</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queryInfo?.data?.getMatchingListByPropertyId?.map((row) => (
                <TableRow
                  key={row?.project?._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Link
                      to={`/project-list/projectcard?projectId=${row?.project?._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography color="primary" fontWeight="bold">
                        {row?.project?.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography color={chooseColor(row?.state)} fontWeight="bold">
                      {matchingStateOptions.find((f) => f.value === row?.state)?.label}
                    </Typography>
                  </TableCell>
                  <TableCell>{chooseIconForOffer(row?.project?.offer)}</TableCell>
                  <TableCell>
                    {statusOptions.find((f) => f.value === row?.project?.status)?.label}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={(
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                          <Typography variant="caption">Changer le statut du matching</Typography>
                          <Box display="flex" marginBottom={2} marginTop={2}>
                            <Button
                              onClick={() => mutate({
                                data: {
                                  state: AllowedMatchingState.ACCEPTED,
                                },
                                // @ts-ignore
                                matchingId: row?._id,
                              })}
                              color="success"
                              variant="contained"
                              size="small"
                              sx={{ textTransform: 'none' }}
                            >
                              Accepté
                            </Button>
                            <Button
                              onClick={() => mutate({
                                data: {
                                  state: AllowedMatchingState.PENDING,
                                },
                                // @ts-ignore
                                matchingId: row?._id,
                              })}
                              color="warning"
                              variant="contained"
                              size="small"
                              sx={{ marginX: 1, textTransform: 'none' }}
                            >
                              En attente
                            </Button>
                            <Button
                              onClick={() => mutate({
                                data: {
                                  state: AllowedMatchingState.REFUSED,
                                },
                                // @ts-ignore
                                matchingId: row?._id,
                              })}
                              color="error"
                              variant="contained"
                              size="small"
                              sx={{ textTransform: 'none' }}
                            >
                              Décliné
                            </Button>
                          </Box>
                        </Box>
                      )}
                    >
                      <MoreVertIcon color="primary" sx={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export {
  ProjectMatched,
};
