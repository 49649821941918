import React, { useEffect, useState } from 'react';
import { Button, Grid, ToggleButton } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { useSearchParams } from 'react-router-dom';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

function Filter({
  queryParamsValue, setQueryParamsValue,
}: any) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState<any>({
    sales: searchParams.get('sales') ? searchParams.get('sales') : null,
    hotDeal: searchParams.get('hotDeal') ? true : null,
  });

  const [salesView, setSalesView] = useState(filter.sales);
  const [hotDeal, setHotDeal] = useState(filter.hotDeal);

  useEffect(() => {
    const searchParamsTmp = new URLSearchParams();
    if (filter.sales) {
      searchParamsTmp.set('sales', filter.sales);
      setQueryParamsValue((old: any) => ({ ...old, sales: filter?.sales ?? null }));
    }
    if (filter.hotDeal) {
      searchParamsTmp.set('hotDeal', filter.hotDeal);
      setQueryParamsValue((old: any) => ({ ...old, hotDeal: filter?.hotDeal ?? null }));
    }
  }, [filter, setQueryParamsValue]);

  useEffect(() => {
    const defaultUrl = new URLSearchParams();
    Object.keys(filter).forEach((key) => {
      if (filter[key] && filter[key]?.length !== 0) {
        defaultUrl.set(key, queryParamsValue[key]);
      }
    });
    setSearchParams(defaultUrl.toString());
  }, [filter, queryParamsValue, setSearchParams]);

  return (
    <Grid container gap={1} item paddingTop={3}>
      <ToggleButton
        selected={salesView}
        value="sales"
        color="primary"
        size="small"
        sx={{ textDecoration: 'none' }}
        onChange={() => {
          setSalesView(!salesView);
          setQueryParamsValue((old: any) => ({ ...old, sales: !salesView ? 'true' : null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, sales: !salesView ? 'true' : null }));
        }}
      >
        <WorkOutlineIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton
        selected={hotDeal}
        value="hotDeal"
        color="warning"
        size="small"
        sx={{ textDecoration: 'none' }}
        onChange={() => {
          setHotDeal(!hotDeal);
          setQueryParamsValue((old: any) => ({ ...old, hotDeal: !hotDeal ? true : null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, hotDeal: !hotDeal ? true : null }));
        }}
      >
        <LocalFireDepartmentIcon fontSize="small" />
      </ToggleButton>
      <Button
        color="warning"
        endIcon={<RestartAltIcon />}
        variant="outlined"
        sx={{ maxHeight: '38.35px' }}
        size="small"
        onClick={() => {
          setQueryParamsValue({});
          setSalesView(false);
          setHotDeal(false);
          setFilter({
            sales: null,
            hotDeal: null,
          });
        }}
        disabled={!filter.sales && !filter.hotDeal}
      >
        Réinitialiser filtres
      </Button>
    </Grid>
  );
}

export {
  Filter,
};
