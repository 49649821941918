import { useUpdateLoanBudgetRecommendedMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useUpdateLoanBudgetRecommended = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useUpdateLoanBudgetRecommendedMutation(headerWithAuth, options);

  return mutate;
};

export {
  useUpdateLoanBudgetRecommended,
};
