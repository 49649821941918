import React from 'react';
import {
  Box, FormControlLabel, Switch,
} from '@mui/material';
import { Controller } from 'react-hook-form';

function SwitchCustom({
  control, name, label,
}: any) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box>
            <FormControlLabel
              labelPlacement="start"
              control={(
                <Switch
                  onChange={(e) => field.onChange(e)}
                  checked={field?.value}
                />
                )}
              label={label}
            />

          </Box>
        );
      }}
    />
  );
}

export {
  SwitchCustom,
};
