import {
  Button,
  Box,
  Grid, Paper,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { Cost } from './cost';
import { Income } from './income';
import { Saving } from './saving';
import { Taxes } from './taxes';
import { FinancialStatistics } from './financialstatistics';
import { useUpdateProject } from '../../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  amount_wages: string
  number_real_estate: string
  amount_real_estate: string
  amount_financial_products: string
  amount_available: string
  amount_invested: string
  amount_taxe: string
  amount_housing_taxe: string
  amount_renting_taxe: string
}

interface IFormInput {
  type: { value: string, label: string };
  amount: number;
}

function Finance({ data, queryInfo } : any) {
  const [searchParams] = useSearchParams();
  const [modify, setModify] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return {
        ...data?.personal_finance?.income,
        ...data?.personal_finance?.savings,
        ...data?.personal_finance?.taxes,
      };
    }, [data, searchParams.get('customerId')]),
  });

  useEffect(() => {
    reset({
      ...data?.personal_finance?.income,
      ...data?.personal_finance?.savings,
      ...data?.personal_finance?.taxes,
    });
  }, [queryInfo.isSuccess, searchParams.get('customerId')]);

  const { control, reset, handleSubmit } = methods;

  const onSubmit = (form: any): any => {
    const formValid: any = {
      data: {
        customers: [{
          customerId: data?._id,
          income: {
            amount_wages: form?.amount_wages ? Number(form?.amount_wages) : 0,
            number_real_estate: form?.number_real_estate ? Number(form?.number_real_estate) : 0,
            amount_real_estate: form?.amount_real_estate ? Number(form?.amount_real_estate) : 0,
            amount_financial_products: form?.amount_financial_products ? Number(form?.amount_financial_products) : 0,
            others: form?.others?.map((e: any) => ({ ...e, amount: Number(e.amount) })),
          },
          savings: {
            amount_available: form?.amount_available ? Number(form?.amount_available) : 0,
            amount_invested: form?.amount_invested ? Number(form?.amount_invested) : 0,
          },
          taxes: {
            amount_taxe: form?.amount_taxe ? Number(form?.amount_taxe) : 0,
            amount_housing_taxe: form?.amount_housing_taxe ? Number(form?.amount_housing_taxe) : 0,
            amount_renting_taxe: form?.amount_renting_taxe ? Number(form?.amount_renting_taxe) : 0,
          },
        }],
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
    setModify(false);
  };

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  return (
    <Grid container item>
      <FinancialStatistics queryInfo={queryInfo} />

      <FormProvider {...methods}>
        <Item elevation={0}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container item columns={2} spacing={3}>
              <Grid item xs={1}>
                <Income
                  data={data}
                  modify={modify}
                  control={control}
                />
              </Grid>
              <Grid container item columns={2} spacing={3} xs={1}>
                <Grid item xs={1}>
                  <Saving
                    modify={modify}
                    control={control}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Taxes
                    control={control}
                    modify={modify}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box width="100%" display="flex" justifyContent="flex-end">
              {modify
                ? (
                  <>
                    <Button
                      sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={() => setModify(false)}
                      color="error"
                      type="reset"
                      variant="contained"
                      endIcon={<CancelIcon />}
                    >
                      Annuler
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ fontWeight: 'bold', textTransform: 'none' }}
                      endIcon={<SaveIcon />}
                    >
                      Enregistrer
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      setModify(true);
                    }}
                    sx={{ textTransform: 'none' }}
                    variant="outlined"
                    endIcon={<EditIcon />}
                  >
                    Modifier
                  </Button>
                )}
            </Box>
          </Box>
        </Item>
      </FormProvider>

      <Grid container item>
        <Item elevation={0}>
          <Cost data={data} queryInfo={queryInfo} />
        </Item>
      </Grid>
    </Grid>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  flexDirection: 'column',
  borderRadius: '16px',
  marginTop: theme.spacing(3),
  width: '100%',
}));

export {
  Finance,
  Item,
};
