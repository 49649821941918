import {
  alpha, Box, Chip, Divider, Grid, Slider, Typography, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import icon_1 from '../../../../assets/property/little_icon/icon_1.png';
import icon_1_white from '../../../../assets/property-white/little-icon/icon_1_white.png';
import icon_2 from '../../../../assets/property/little_icon/icon_2.png';
import icon_2_white from '../../../../assets/property-white/little-icon/icon_2_white.png';
import icon_3 from '../../../../assets/property/little_icon/icon_3.png';
import icon_3_white from '../../../../assets/property-white/little-icon/icon_3_white.png';
import icon_4 from '../../../../assets/property/little_icon/icon_4.png';
import icon_4_white from '../../../../assets/property-white/little-icon/icon_4_white.png';
import icon_5 from '../../../../assets/property/little_icon/icon_5.png';
import icon_5_white from '../../../../assets/property-white/little-icon/icon_5_white.png';
import icon_7 from '../../../../assets/property/little_icon/icon_7.png';
import icon_7_white from '../../../../assets/property-white/little-icon/icon_7_white.png';
import { euro } from '../../../../common/inputs/format/euro-format';
import { LineGraphNextTenYears } from './cashflow-next-ten-years.table';
import { RichLineGraphNextTenYears } from './chart-global-enrichment';

function CashFLow({ queryInfo, getGlobalConfig }: any) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [firstSliderValue, setFirstSliderValue] = useState<any>(15);
  const dataFinance = queryInfo && queryInfo?.data?.getPropertiesById?.finance;
  const totalBudget = dataFinance?.total_budget;
  const [years, setYears] = useState<any>(getGlobalConfig?.data?.number_of_years_loan);
  const [interestRateTmp, setInterestRateTmp] = useState<any>(getGlobalConfig?.data?.interest_rate * 100);
  const [monthlyLoanBudgetTmp, setMonthlyLoanBudgetTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_loan_amount ? dataFinance?.property_cash_flow?.monthly_loan_amount : 0);
  const [equityContributionTmp, setEquityContributionTmp] = useState<any>(dataFinance && totalBudget ? 15 * totalBudget / 100 : 0);
  const [monthlyOutcomeTmp, setMonthlyOutcomeTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_outcome ? dataFinance?.property_cash_flow?.monthly_outcome : 0);
  const [loanBudgetTmp, setLoanBudgetTmp] = useState<any>(totalBudget && equityContributionTmp ? totalBudget - equityContributionTmp : 0);
  const [loanDelay, setLoanDelay] = useState<any>(0);
  const [budgetWithdelay, setBudgetWithDelay] = useState<any>(null);

  console.log({ interest: getGlobalConfig?.data?.interest_rate, interestRateTmp });
  useEffect(() => {
    setEquityContributionTmp(firstSliderValue * totalBudget / 100);
  }, [firstSliderValue, totalBudget]);

  useEffect(() => {
    if (budgetWithdelay && loanDelay > 0) {
      setMonthlyOutcomeTmp(budgetWithdelay + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees);
    } else {
      setMonthlyOutcomeTmp(monthlyLoanBudgetTmp + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees);
    }
  }, [dataFinance, monthlyLoanBudgetTmp, budgetWithdelay, loanDelay]);

  useEffect(() => {
    setLoanBudgetTmp(totalBudget - equityContributionTmp);
    if (years === getGlobalConfig?.data?.number_of_years_loan_15 && getGlobalConfig?.data?.number_of_years_loan_15 === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_15 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan_20 && getGlobalConfig?.data?.number_of_years_loan_20 === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_20 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan && getGlobalConfig?.data?.number_of_years_loan === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate * 100);
    }
    setMonthlyLoanBudgetTmp(((interestRateTmp / 100) * loanBudgetTmp) / ((1 - (1 + (interestRateTmp / 100) / 12) ** (-12 * years)) * 12));
    if (loanDelay > 0) {
      const defaultMonthlyLoan = ((totalBudget - equityContributionTmp) * (interestRateTmp / 100)) / 12;
      setBudgetWithDelay(defaultMonthlyLoan);
    } else {
      setBudgetWithDelay(null);
    }
  }, [equityContributionTmp, interestRateTmp, loanBudgetTmp, totalBudget, years, loanDelay, getGlobalConfig]);

  const dataSmallBar = [{
    title: 'Mensualités emprunt',
    value: monthlyLoanBudgetTmp ? euro.format(monthlyLoanBudgetTmp) : `${0} €`,
    icon: isDarkTheme ? icon_2_white : icon_2,
  }, {
    title: 'Taxes foncières mensualisées',
    value: dataFinance?.property_cash_flow?.monthly_property_tax ? euro.format(dataFinance?.property_cash_flow?.monthly_property_tax) : `${0} €`,
    icon: isDarkTheme ? icon_1_white : icon_1,
  }, {
    title: 'Charges de copropriété',
    value: dataFinance?.property_cash_flow?.monthly_landlord_fees ? euro.format(dataFinance?.property_cash_flow?.monthly_landlord_fees) : `${0} €`,
    icon: isDarkTheme ? icon_7_white : icon_7,
  }, {
    title: 'Assurances',
    value: dataFinance?.property_cash_flow?.monthly_insurance_fees ? euro.format(dataFinance?.property_cash_flow?.monthly_insurance_fees) : `${0} €`,
    icon: isDarkTheme ? icon_3_white : icon_3,
  }, {
    title: 'Gestion locative',
    value: dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees ? euro.format(dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees) : `${0} €`,
    icon: isDarkTheme ? icon_4_white : icon_4,
  }];

  return (
    <Box marginY={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        flexDirection="column"
        width="90%"
      >
        <SliderComponent
          title="Apport"
          titleValue={euro.format(equityContributionTmp)}
          sliderValue={firstSliderValue}
          setSliderValue={setFirstSliderValue}
          min={0}
          max={100}
          step={1}
          displayValue
        />
        <SliderComponent
          title="Durée"
          titleValue={`${years} ans`}
          sliderValue={years}
          setSliderValue={setYears}
          min={15}
          max={25}
          step={5}
        />
        <SliderComponent
          title="Taux d'intérêt"
          titleValue={interestRateTmp ? `${(interestRateTmp).toFixed(2)}%` : `${0}%`}
          sliderValue={interestRateTmp}
          setSliderValue={(val: number) => setInterestRateTmp(val)}
          min={0}
          max={6}
          step={0.1}
        />
        <LargeBar
          color="success.main"
          title="Encaissements"
          value={dataFinance?.property_cash_flow?.monthly_income ? euro.format(dataFinance?.property_cash_flow?.monthly_income) : `${0} €`}
          Icon={<ArrowDownwardIcon sx={{ color: 'background.paper', fontSize: '30px' }} />}
        />
        <Box
          width="90%"
          sx={{
            borderRadius: '5px',
            border: '0.1px solid',
            borderColor: alpha('#000000', 0.1),
          }}
          display="flex"
          marginBottom={5}
          flexDirection="column"
        >
          <SmallBar
            title="Loyers"
            color="success.main"
            value={dataFinance?.property_cash_flow?.monthly_income ? euro.format(dataFinance?.property_cash_flow?.monthly_income) : `${0} €`}
            icon={isDarkTheme ? icon_5_white : icon_5}
          />
        </Box>
        <LargeBar
          title="Décaissements"
          color="error.main"
          value={monthlyOutcomeTmp ? euro.format(monthlyOutcomeTmp) : `${0} €`}
          Icon={<ArrowUpwardIcon sx={{ color: 'background.paper', fontSize: '30px' }} />}
        />
        <Box
          width="90%"
          sx={{
            borderRadius: '5px',
            border: '0.1px solid',
            borderColor: alpha('#000000', 0.1),
          }}
          display="flex"
          marginBottom={5}
          flexDirection="column"
        >
          {dataSmallBar?.map((e, i) => (
            <SmallBar
              key={e.title + i.toString()}
              title={e?.title}
              icon={e.icon}
              bar={i + 1 !== dataSmallBar?.length}
              color="error.main"
              value={e?.value}
            />
          ))}

        </Box>
      </Box>
      <Box width="90%">
        <Divider sx={{ marginY: 5 }} />
        <Typography
          marginTop={4}
          variant="h6"
          gutterBottom
          color="text.primary"
          fontWeight="400"
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Trésorerie sur 10 ans
        </Typography>
        <Typography
          fontWeight="200"
          mt={-1}
          gutterBottom
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Cashflow mensuel prévisionnel sur les 10 prochaines années
        </Typography>
        {dataFinance?.property_cash_flow?.monthly_loan_amount ? (
          <LineGraphNextTenYears
            year={queryInfo?.data?.getPropertiesById?.createdAt}
            income={dataFinance?.property_cash_flow?.monthly_income}
            outcome={monthlyOutcomeTmp}
            interestRate={interestRateTmp}
            equityContribution={equityContributionTmp}
            totalBudget={totalBudget}
            outcomeValues={{
              monthlyLoanBudgetTmp,
              monthly_real_estate_agency_fees: dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees,
              monthly_insurance_fees: dataFinance?.property_cash_flow?.monthly_insurance_fees,
              monthly_property_tax: dataFinance?.property_cash_flow?.monthly_property_tax,
              monthly_landlord_fees: dataFinance?.property_cash_flow?.monthly_landlord_fees,
            }}
            monthlyLoanBudget={monthlyLoanBudgetTmp}
            loanDelay={loanDelay}
            inflationValue={queryInfo?.data?.getPropertiesById?.finance?.inflation}
          />
        ) : null}
        <Divider sx={{ marginY: 5 }} />
        <Typography
          marginTop={4}
          variant="h6"
          gutterBottom
          color="text.primary"
          fontWeight="400"
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Enrichissement global
        </Typography>
        <Typography
          fontWeight="200"
          mt={-1}
          gutterBottom
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Votre enrichissement total, prenant en compte capitalisation, trésorerie et plus value, en cas de revente sur
          une année donnée.
        </Typography>
        <RichLineGraphNextTenYears
          year={queryInfo?.data?.getPropertiesById.createdAt}
          totalBudget={totalBudget}
          income={queryInfo?.data?.getPropertiesById?.finance?.property_cash_flow?.monthly_income}
          outcome={(monthlyLoanBudgetTmp + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees)}
          equityContribution={equityContributionTmp}
          interestRate={interestRateTmp / 100}
          monthlyLoanBudget={monthlyLoanBudgetTmp}
          propertyPrice={queryInfo?.data?.getPropertiesById?.finance?.expected_price}
          renovationCost={queryInfo?.data?.getPropertiesById?.renovation?.renovation_cost}
          lsiFees={queryInfo?.data?.getPropertiesById?.finance?.lsi_fees}
          inflationPrice={queryInfo?.data?.getPropertiesById?.finance?.inflation_price}
          outcomeValues={{
            monthlyLoanBudgetTmp,
            monthly_real_estate_agency_fees: dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees,
            monthly_insurance_fees: dataFinance?.property_cash_flow?.monthly_insurance_fees,
            monthly_property_tax: dataFinance?.property_cash_flow?.monthly_property_tax,
            monthly_landlord_fees: dataFinance?.property_cash_flow?.monthly_landlord_fees,
          }}
          notaryFees={queryInfo?.data?.getPropertiesById?.finance?.notary_fees}
          inflationValue={queryInfo?.data?.getPropertiesById?.finance?.inflation}
        />
      </Box>
    </Box>
  );
}

function LargeBar({
  title, color, value, Icon,
}: any) {
  return (
    <Box
      width="100%"
      height="70px"
      sx={{
        borderRadius: '5px',
        border: '0.1px solid',
        borderColor: alpha('#000000', 0.1),
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom={3}
    >
      <Box display="flex" alignItems="center" paddingX={3}>
        <Box
          width={40}
          height={40}
          bgcolor={color}
          borderRadius={30}
          marginRight={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {Icon}
        </Box>
        <Typography fontWeight={500} variant="h6">{title}</Typography>
      </Box>
      <Typography fontWeight={500} paddingX={3} color={color} variant="h6">{value}</Typography>
    </Box>
  );
}

function SmallBar({
  title, color, value, bar, icon, chip = false,
}: any) {
  return (
    <>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" marginY={1}>
        <Box display="flex" alignItems="center" paddingX={3}>
          <Box
            width={40}
            height={40}
            borderRadius={30}
            border="1px solid"
            borderColor="divider"
            marginRight={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              src={icon}
              style={{
                display: 'block',
                objectFit: 'contain',
                width: '50px',
                height: '100%',
              }}
            />
          </Box>
          <Typography
            fontWeight={400}
            variant="h6"
          >
            {title}
          </Typography>
        </Box>
        {chip && <Chip label="Optionnel" />}
        <Typography
          fontWeight={500}
          paddingX={3}
          color={color}
          variant="h6"
        >
          {value}
        </Typography>
      </Box>
      {bar && <Box width="75%" margin="0 auto" height="1px" bgcolor="divider" />}
    </>
  );
}

function SliderComponent({
  sliderValue, setSliderValue, title, titleValue, min, max, step, displayValue = false,
}: any) {
  return (
    <Box width="100%">
      <Grid
        container
        columns={2}
        display="flex"
        height="40px"
      >
        <Grid item xs={1} display="flex" alignItems="center">
          <Typography fontWeight="400" variant="h6" marginRight={1}>
            {title}
          </Typography>
          {displayValue
            && (
              <Typography color="primary" fontWeight="400" variant="h6">
                {`${sliderValue}%`}
              </Typography>
            )}
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
          <Typography
            textAlign="right"
            variant="h6"
          >
            {titleValue}
          </Typography>
        </Grid>
      </Grid>
      <Box height="35px">
        <Slider
          color="primary"
          sx={{
            height: 5,
          }}
          valueLabelDisplay="auto"
          step={step}
          value={sliderValue}
          min={min}
          onChange={(e: any, value: any) => {
            setSliderValue(value);
          }}
          max={max}
        />
      </Box>
    </Box>
  );
}

function GreenCircle({
  title, value,
}: any) {
  return (
    <Box
      width="170px"
      height="70px"
      bgcolor="background.default"
      borderRadius={10}
      display="flex"
      marginX={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography marginBottom={0.5} variant="body1" fontWeight="500">{title}</Typography>
      <Typography marginTop={0.5} variant="h6" fontWeight="500">{value}</Typography>
    </Box>
  );
}

export {
  CashFLow,
};
