import { euro } from '../../../../../common/inputs/format/euro-format';

const calcTotal = (customers: any) => {
  const initialValue = 0;
  const arrayTmp = customers.map((e: any) => e?.personal_finance?.cost?.total_yearly_cost + e?.personal_finance?.taxes?.total_yearly_taxes);

  const sumWithInitial = arrayTmp?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue,
    initialValue,
  );
  return euro.format(sumWithInitial) || '0 €';
};

const data = (customers: any) => [{
  data: ['Loyer CC', ...customers?.map((e: any) => euro.format(e?.personal_finance?.cost?.total_yearly_rent) || '')],
}, {
  data: ['Dernier Impôt sur le revenu', ...customers?.map((e: any) => euro.format(e?.personal_finance?.taxes?.total_taxe) || '')],
}, {
  data: ['Taxes foncière', ...customers?.map((e: any) => euro.format(e?.personal_finance?.taxes?.total_housing_taxe) || '')],
}, {
  data: ['Taxe d’habitation', ...customers?.map((e: any) => euro.format(e?.personal_finance?.taxes?.total_renting_taxe) || '')],
}, {
  data: ['Pensions', ...customers?.map((e: any) => euro.format(e?.personal_finance?.cost?.total_yearly_pension) || '')],
}, {
  data: ['Autres', ...customers?.map((e: any) => euro.format(e?.personal_finance?.cost?.total_yearly_others) || '')],
}, {
  data: ['Total par emprunteur', ...customers?.map((e: any) => euro.format(e?.personal_finance?.cost?.total_yearly_cost + e?.personal_finance?.taxes?.total_yearly_taxes) || '')],
}, {
  data: ['Total', ...customers?.map((e: any, i: number) => (i === customers.length - 1 ? calcTotal(customers) : '' || ''))],
}];

const costFormatted = (customers: any) => data(customers).map((e) => (`
    <tr>
      ${e?.data?.map((elemData: any) => (`
      <td>
        <p style="font-weight: normal">${elemData}</p>
      </td>
    `).replaceAll(',', ''))}
    </tr>
  `).replaceAll(',', ''));

export {
  costFormatted,
};
