import { useGetAccessToken } from '../auth/get-access-token.hook';
import { useGetProjectsByUserQuery } from '../../api';

const useGetProjectsByUser = (userId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetProjectsByUserQuery(headerWithAuth, {
    userId: userId || '',
  }, { enabled: !!accessToken && !!userId });

  return queryInfo;
};

export {
  useGetProjectsByUser,
};
