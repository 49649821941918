import { euro } from '../../../../../common/inputs/format/euro-format';

const data = (customers: any, dataById: any) => [{
  data: ['Epargne disponible', ...customers?.map((e: any) => euro.format(e?.personal_finance?.savings?.amount_available) || '')],
}, {
  data: ['Epargne placée', ...customers?.map((e: any) => euro.format(e?.personal_finance?.savings?.amount_invested) || '')],
}, {
  data: ['Total par emprunteur', ...customers?.map((e: any) => euro.format(e?.personal_finance?.savings?.total_available + e?.personal_finance?.savings?.total_invested) || '')],
}, {
  data: ['Total', ...customers?.map((e: any, i: number) => (i === customers.length - 1 ? euro.format(dataById?.finance?.total_all_yearly_savings) : '' || ''))],
}];

const savingFormatted = (customers: any, dataById: any) => data(customers, dataById).map((e) => (`
    <tr>
      ${e.data.map((elemData: any) => (`
      <td>
        <p style="font-weight: normal">${elemData}</p>
      </td>
    `).replaceAll(',', ''))}
    </tr>
  `).replaceAll(',', ''));

export {
  savingFormatted,
};
