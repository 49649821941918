import {
  Box, Switch, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

function BooleanWithIcon({
  control, name, label, watch, radiusBottom = false,
}: any) {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({
          field,
        }) => {
          return (
            <Box
              width="100%"
              height="70px"
              borderRadius={radiusBottom ? '16px 16px 0 0' : '16px'}
              bgcolor="background.default"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                onClick={() => field.onChange(!field.value)}
                sx={{
                  opacity: watch(name) === false ? 0.5 : 1,
                  transition: 'all 0.3s',
                  cursor: 'pointer',
                }}
              >
                <Typography textAlign="center">{label}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Switch
                  onChange={(e) => field.onChange(e)}
                  checked={field?.value}
                />
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
}

export {
  BooleanWithIcon,
};
