import { useUpdateRealEstateAgentMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useUpdateRealEstateAgent = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useUpdateRealEstateAgentMutation(headerWithAuth, options);

  return mutate;
};

export {
  useUpdateRealEstateAgent,
};
