import { useGetMatchingListByPropertyIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetMatchingListByPropertyId = (propertyId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetMatchingListByPropertyIdQuery(headerWithAuth, {
    propertyId: propertyId || '',
  }, { enabled: !!accessToken && !!propertyId });

  return queryInfo;
};

export {
  useGetMatchingListByPropertyId,
};
