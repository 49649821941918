import {
  Autocomplete, Box, Chip, Grid, Paper, TextField, Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { Filter } from './filter';

function HeaderList({
  numberProjects,
  isSuccess,
  rowPerPageOptions,
  setRowPerPageOptions,
  queryParamsValue,
  setQueryParamsValue,
}: any) {
  return (
    <Grid item xs={1}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: '16px', padding: 3, width: '100%',
        }}
      >
        <Box style={{
          width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between',
        }}
        >
          <Box display="flex" alignItems="center">
            <Typography marginRight={3} color="primary" variant="h5">Liste de gestion des projets</Typography>
            {!isSuccess
              ? <CircularProgress size={20} />
              : (
                <Chip
                  label={`nb: ${numberProjects || 'pas de résultats'}`}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              )}
          </Box>
          <Box display="flex">
            <Autocomplete
              value={rowPerPageOptions || undefined}
              onChange={(_, value) => {
                setRowPerPageOptions(value);
              }}
              disablePortal
              disableClearable
              options={[
                { value: 20, label: '20' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
              ]}
              renderInput={
                (params) => (
                  <TextField
                    {...params}
                    label="Nb. d'éléments"
                    fullWidth
                    size="small"
                    sx={{ width: 150, marginLeft: 3 }}
                  />
                )
              }
            />
          </Box>
        </Box>
        <Filter
          queryParamsValue={queryParamsValue}
          setQueryParamsValue={setQueryParamsValue}
          rowPerPageOptions={rowPerPageOptions}
          setRowPerPageOptions={setRowPerPageOptions}
        />
      </Paper>
    </Grid>
  );
}

export {
  HeaderList,
};
