import {
  Box,
  Chip,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatInTimeZone } from 'date-fns-tz';
import {
  useGetUserJourneyBeforeAccountCreation,
} from '../../../../hooks/query/get-user-journey-before-account-creation.hook';
import { Loader } from '../../../../common/components/loader';
import digicomstoryIcon from '../../../../assets/analytics/digicomstory.svg';

function UserJourneyBeforeAccountCreation({ queryInfo }: any) {
  const queryInfoUserJourneyBeforeAccountCreation = useGetUserJourneyBeforeAccountCreation(queryInfo?.data?.getProjectById?.createdBy?._id);

  function createRow(data: any) {
    return data && data?.map((e: any, i: number) => {
      const isAds = e?.ads_event_text;

      return ({
        id: e?._id + i.toString(),
        type: isAds ? 'Ads' : 'SEO' || null,
        timestamp: isAds ? e?.ads_original_timestamp?.value : e?.context_original_timestamp?.value || null,
        medium: isAds ? e?.ads_campaign_medium : e?.context_campaign_medium || null,
        source: isAds ? e?.ads_campaign_source : e?.context_campaign_source || null,
        event_text: isAds ? e?.ads_event_text : e?.context_event_text || null,
        referrer: isAds ? e?.ads_page_referrer : e?.context_page_referrer || null,
        search: isAds ? e?.ads_page_search : e?.context_page_search || null,
      });
    });
  }

  const columns: GridColDef[] = [{
    field: 'type',
    headerName: 'Type',
    width: 65,
  }, {
    field: 'timestamp',
    headerName: 'Date',
    width: 120,
    type: 'date',
    renderCell: (params) => {
      return (
        <DisplayCell value={params.value} isDateWithHour />
      );
    },
  }, {
    field: 'medium',
    headerName: 'Medium',
    width: 130,
    renderCell: (params) => {
      return (
        <DisplayCell value={params.value} />
      );
    },
  }, {
    field: 'source',
    headerName: 'Source',
    width: 130,
    renderCell: (params) => {
      if (params.value === 'DIGICOMSTORY') {
        return (
          <Tooltip title={params.value || ''}>
            <Box component="img" width="90%" src={digicomstoryIcon} />
          </Tooltip>
        );
      }
      return (
        <DisplayCell value={params.value} />
      );
    },
  }, {
    field: 'event_text',
    headerName: 'Tracking Event',
    width: 150,
    renderCell: (params) => {
      return (
        <DisplayCell value={params.value} />
      );
    },
  }, {
    field: 'referrer',
    headerName: 'URL Origin',
    width: 200,
    renderCell: (params) => {
      return (
        <DisplayCell value={params.value} />
      );
    },
  }, {
    field: 'search',
    headerName: 'Param URL',
    width: 600,
    renderCell: (params) => {
      return (
        <DisplayCell value={params.value} />
      );
    },
  }];

  return (
    <Box>
      <Paper elevation={0} sx={{ borderRadius: '16px' }}>
        <Grid paddingY={3}>
          <Box display="flex" justifyContent="space-between" paddingX={2} paddingBottom={5}>
            <Box display="flex" alignItems="center">
              <Typography marginRight={3} textAlign="left" variant="h5" color="primary">
                ℹ️ Sources
              </Typography>
              <Chip
                label={`nb: ${queryInfoUserJourneyBeforeAccountCreation?.data?.getUserJourneyBeforeAccountCreation?.length || '0'}`}
                variant="outlined"
                color="primary"
                size="small"
              />
            </Box>
          </Box>
          <Box display="flex" width="100%" justifyContent="center">
            <Box sx={{ height: '300px', width: '95%' }}>
              {
              !queryInfoUserJourneyBeforeAccountCreation?.isFetching && queryInfoUserJourneyBeforeAccountCreation?.isSuccess
                ? (
                  <DataGrid
                    // @ts-ignore
                    density="compact"
                    rows={createRow(queryInfoUserJourneyBeforeAccountCreation?.data?.getUserJourneyBeforeAccountCreation)}
                    columns={columns}
                    hideFooter
                    loading={queryInfoUserJourneyBeforeAccountCreation?.isFetching && queryInfoUserJourneyBeforeAccountCreation?.isSuccess}
                    sx={{
                      border: 'none',
                    }}
                  />
                ) : <Loader size={40} />
            }
            </Box>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
}

function DisplayCell({ value, isDateWithHour = false }: any) {
  return (
    <Tooltip title={value || ''}>
      {isDateWithHour
        ? (
          <Typography variant="caption">
            {value
              ? formatInTimeZone(
                // @ts-ignore
                new Date(value),
                'Europe/Paris',
                'dd/MM/yyyy HH:mm',
              ) : ''}
          </Typography>
        ) : (
          <Typography
            fontWeight="400"
            variant="caption"
            color="text.primary"
          >
            {value}
          </Typography>
        )}
    </Tooltip>
  );
}

export {
  UserJourneyBeforeAccountCreation,
};
