import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, IconButton, Typography,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import AddressCustom from '../../../../common/inputs/adresscustom';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { DisplayValue } from '../../pages';
import { Transportation } from './transportation';
import { FutureTransportations } from './future-transportations';
import { transportationOptions } from '../../../../data/data';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';

interface IFormInput {
  street_address: string
  address_details: string
}

function Summary({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();

  const format = {
    ...data,
    localisation: {
      ...data?.localisation,
      transportations: data?.localisation?.transportations?.map((e: any) => ({
        transportation: transportationOptions?.find((f) => f.value === e.transportation),
        transportation_detail: e?.transportation_detail,
        walking_time: Number(e?.walking_time),
      })),
      futureTransportations: data?.localisation?.futureTransportations?.map((e: any) => ({
        transportation: transportationOptions?.find((f) => f.value === e.transportation),
        transportation_detail: e?.transportation_detail,
        walking_time: Number(e?.walking_time),
        releaseDate: e?.releaseDate,
      })),
    },
    ad_link: data?.ad_link?.map((e: any) => ({ link: e })),
  };

  const methods = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...format };
    }, [data, searchParams.get('propertyId')]),
  });
  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'ad_link',
  });

  const defaultValue = {
    link: null,
  };

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        ad_link: form?.ad_link?.map((e: any) => e?.link),
        localisation: {
          street_address: {
            address_line_1: form?.localisation?.street_address?.address_line_1,
            country: form?.localisation?.street_address?.country,
            locality: form?.localisation?.street_address?.locality,
            zipcode: form?.localisation?.street_address?.zipcode,
            region: form?.localisation?.street_address?.region || '',
          },
          transportations: form?.localisation?.transportations?.map((e: any) => ({
            transportation: e?.transportation?.value,
            transportation_detail: e?.transportation_detail,
            walking_time: Number(e?.walking_time),
          })),
          futureTransportations: form?.localisation?.futureTransportations?.map((e: any) => ({
            transportation: e?.transportation?.value,
            transportation_detail: e?.transportation_detail,
            walking_time: Number(e?.walking_time),
            releaseDate: e?.releaseDate,
          })),
          address_details: form?.localisation?.address_details,
        },
      },
    };
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <FormProvider {...methods}>
      <Box display="flex" flexDirection="column">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
            <Typography color="primary" variant="h5" marginBottom={2}>Localisation</Typography>
            <Grid container item columns={2} spacing={3}>
              <Grid item xs={1}>
                <Box paddingY={1}>
                  {toModify
                    ? (
                      <AddressCustom
                        control={control}
                        name="localisation.street_address"
                        label="Adresse du bien"
                      />
                    ) : (
                      <DisplayValue title="Adresse du bien" value={data?.localisation?.street_address?.address_line_1} />
                    )}
                </Box>
                <Box>
                  {toModify
                    ? (
                      <Box>
                        {fields.map((item, indexField) => (
                          <Box key={item + indexField.toString()} display="flex" width="100%">
                            <Box paddingY={1} width="100%" display="flex" alignItems="center" justifyContent="center">
                              <TextFieldCustom
                                size="small"
                                label={`Lien de l'annonce n°${indexField + 1}`}
                                control={control}
                                name={`ad_link[${indexField}].link`}
                              />
                              <IconButton
                                sx={{
                                  height: 40,
                                  width: 40,
                                }}
                                onClick={() => remove(indexField)}
                                color="error"
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                        <Button
                          type="button"
                          sx={{
                            cursor: 'pointer',
                            textTransform: 'none',
                            textDecoration: 'underline',
                            transition: 'opacity 0.3s',
                            fontSize: '14px',
                            width: 'fit-content',
                            ':hover': {
                              opacity: 0.8,
                            },
                          }}
                          startIcon={<AddCircleIcon />}
                          color="primary"
                          onClick={() => append(defaultValue)}
                        >
                          Ajouter un lien
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <DisplayValue
                          title="Lien de l'annonce"
                          value={' '}
                        />
                        <Box>
                          {data?.ad_link?.map((e: any, i: number) => (
                            <Box key={e + i.toString()} sx={{ maxWidth: '350px' }}>
                              <Typography
                                sx={{
                                  width: '100%',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                                key={e + i.toString()}
                                color="text.primary"
                              >
                                <Box
                                  component="a"
                                  href={e}
                                  style={{ color: 'inherit' }}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {e || '...'}
                                </Box>
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box paddingY={1}>
                  {toModify
                    ? (
                      <TextFieldCustom
                        read={!toModify}
                        control={control}
                        placeholder="Bâtiment, porte, cage d'escalier..."
                        name="localisation.address_details"
                        label="Complément d’adresse"
                      />
                    ) : (
                      <DisplayValue title="Complément d'adresse Bâtiment, porte, cage d'escalier..." value={data?.localisation?.address_details} />
                    )}
                </Box>
              </Grid>
            </Grid>
            <Transportation toModify={toModify} data={data} />
            <FutureTransportations toModify={toModify} data={data} />
            {toModify
              ? (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                    onClick={() => setToModify(false)}
                    color="error"
                    type="reset"
                    variant="contained"
                    endIcon={<CancelIcon />}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ fontWeight: 'bold', textTransform: 'none' }}
                    endIcon={<SaveIcon />}
                  >
                    Enregistrer
                  </Button>
                </Box>
              ) : (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => setToModify(true)}
                    variant="outlined"
                    sx={{ fontWeight: 'bold', textTransform: 'none' }}
                    endIcon={<EditIcon />}
                  >
                    Modifier
                  </Button>
                </Box>
              )}
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}

export {
  Summary,
};
