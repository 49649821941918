import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';

function IncomeOthers() {
  const {
    control,
  } = useFormContext<any>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'others',
  });

  const defaultValue = {
    label: null,
    amount: 0,
  };

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultValue);
    }
  }, [fields]);

  return (
    <Box>
      {fields?.map((item, indexField) => (
        <Box key={item?.id} display="flex" justifyContent="space-between" paddingY={1} alignItems="center">
          <Box>
            <TextFieldCustom
              control={control}
              name={`others[${indexField}].label`}
              label="Type de revenu"
            />
          </Box>
          <Box paddingX={1}>
            <CurrencyTextFieldCustom
              control={control}
              name={`others[${indexField}].amount`}
              label="Montant"
            />
          </Box>
          <Box>
            <Button fullWidth size="small" sx={{ height: '38.34px' }} color="error" variant="outlined" onClick={() => remove(indexField)}>
              <ClearIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
      ))}
      <Button
        size="small"
        sx={{ marginTop: 2, width: '100%', fontWeight: 'bold' }}
        variant="outlined"
        onClick={() => append(defaultValue)}
        color="secondary"
        endIcon={<AddIcon />}
      >
        Ajouter une ligne
      </Button>
    </Box>
  );
}

export {
  IncomeOthers,
};
