import { useCreateMatchingMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useCreateMatching = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useCreateMatchingMutation(headerWithAuth, options);

  return mutate;
};

export {
  useCreateMatching,
};
