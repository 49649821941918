import { Controller } from 'react-hook-form';
import { Box, Button, styled } from '@mui/material';
import React from 'react';

function RichTextCustomLogs({ control, name, watch }: any) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field,
        }) => {
          return (
            <RichText
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              placeholder="Écrivez ici ..."
            />
          );
        }}
      />
      <Box display="flex" justifyContent="flex-end" bgcolor="background.paper" borderRadius="0 0 16px 16px" paddingY={1} paddingX={3}>
        <Button type="submit" disabled={!watch(name)} sx={{ textTransform: 'none' }}>Enregistrer votre commentaire</Button>
      </Box>
    </>
  );
}

const RichText = styled('textarea')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.paper,
  borderRadius: '16px 16px 0 0',
  padding: '20px 20px 20px 20px',
  width: '100%',
  height: '100px',
  fontSize: '14px',
  letterSpacing: '2px',
  color: theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.text.primary,
  resize: 'none',
  border: 'none',
  ':focus-visible ': {
    outline: 'none',
  },
  fontFamily: [
    'Poppins',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
}));

export {
  RichTextCustomLogs,
};
