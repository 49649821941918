import { useGetLogsByPropertyQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetLogsByProperty = (propertyId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetLogsByPropertyQuery(headerWithAuth, {
    propertyId: propertyId || '',
  }, { enabled: !!accessToken });

  return queryInfo;
};

export {
  useGetLogsByProperty,
};
