import { Box, Typography } from '@mui/material';
import React from 'react';
import { SectionTitle } from '../../../../common/right-column/section-title';

function Hunter({ queryInfo }: any) {
  return (
    <Box marginY={2}>
      <SectionTitle title="chasseur" />
      <Typography marginTop={1} align="left" variant="body1" color="text.secondary">
        {`${queryInfo?.data?.getPropertiesById?.user_id?.firstName} ${queryInfo?.data?.getPropertiesById?.user_id?.lastName}`}
      </Typography>
    </Box>
  );
}

export {
  Hunter,
};
