import {
  Box, Divider, Paper,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Status } from './status';
import 'react-image-gallery/styles/css/image-gallery.css';
import { SectionTop } from './section-top';
import { ImageGalleryContainer } from './image-gallery';
import { Hunter } from './hunter';
import { Typology } from './typology';
import { ClosedStatus } from './closed-status';
import { AllowedPropertyStatus } from '../../../../api';

function RightColumn({ queryInfo }: any) {
  return (
    <Item elevation={0}>
      <Box>
        <SectionTop queryInfo={queryInfo} />
        <Divider sx={{ marginY: 2 }} />
        <Box marginTop={2}>
          {queryInfo?.isLoading ? null : <Status queryInfo={queryInfo} />}
        </Box>
        {queryInfo.data?.getPropertiesById?.status === AllowedPropertyStatus.CLOSED
          && (
            <Box marginTop={2}>
              {queryInfo?.isLoading ? null : <ClosedStatus queryInfo={queryInfo} />}
            </Box>
          )}
        {queryInfo?.data?.getPropertiesById?.user_id?.firstName && queryInfo?.data?.getPropertiesById?.user_id?.lastName
          && (
            <Hunter queryInfo={queryInfo} />
          )}
        <ImageGalleryContainer queryInfo={queryInfo} />
        <Divider sx={{ marginY: 2 }} />
        {queryInfo.isSuccess
          && <Typology queryInfo={queryInfo} />}
      </Box>
    </Item>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto',
}));

export {
  RightColumn,
};
