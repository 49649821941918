import { format } from 'date-fns';
import { euro } from '../../../../../common/inputs/format/euro-format';

const data = (customers: any) => {
  const array = customers?.map((e: any, i: number) => (
    // @ts-ignore
    e?.personal_finance?.cost?.consumer_loan?.map((elem: any) => [`${customers[i]?.personal_info?.first_name} ${customers[i]?.personal_info?.last_name}`, '', euro.format(elem?.monthly_amount), euro.format(elem?.loan_amount), euro.format(elem?.remaining_loan), format(new Date(elem?.end_date), 'dd/MM/yyyy')])
  ));
  return array.flat();
};

const consumerLoanFormatted = (customers: any) => data(customers).map((e :any) => (`
    <tr>
      ${e.map((elemData: any) => (`
      <td>
        <p style="font-weight: normal">${elemData}</p>
      </td>
    `).replaceAll(',', ''))}
    </tr>
  `).replaceAll(',', ''));

export {
  consumerLoanFormatted,
};
