import { useSnackbar } from 'notistack';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TextFieldCustom } from '../../../../../common/inputs/textfieldcustom';
import { PhoneCustom } from '../../../../../common/inputs/phonecustom';
import { useUpdateRealEstateAgency } from '../../../../../hooks/mutation/update-real-estate-agency-mutation.hook';

function AddAgentContainer({
  setOpen, agencyId, queryAgency, setValue,
}: any) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control, handleSubmit, watch,
  } = useForm<any>({
    mode: 'onChange',
  });

  const countryCode = parsePhoneNumber('+33000000000', 'FR');
  const [country, setCountry] = useState<any>({
    countryCode: countryCode?.country ? countryCode?.country : 'FR',
    dialCode: countryCode?.countryCallingCode ? countryCode?.countryCallingCode : '33',
  });

  const mutate = useUpdateRealEstateAgency({
    onSuccess: (resp: any) => {
      const agentArray = resp?.updateRealEstateAgency?.realEstateAgents[resp?.updateRealEstateAgency?.realEstateAgents.length - 1];
      queryAgency.refetch();
      setValue('realEstateAgent', { value: agentArray?._id, label: agentArray?.name });
      setOpen(false);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      updateRealEstateAgencyInput: {
        _id: agencyId,
        realEstateAgents: {
          email: form?.email,
          name: form?.name,
          phone: form?.phone?.replaceAll(' ', ''),
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <Box marginBottom={3}>
      <Box paddingBottom={1}>
        <TextFieldCustom
          name="name"
          required
          control={control}
          rules={{
            required: 'Veuillez renseigner un nom',
          }}
          label="Nom de l'agent immobilier"
        />
      </Box>
      <Box paddingY={1}>
        <TextFieldCustom
          type="email"
          name="email"
          control={control}
          label="Email de l'agent immobilier"
        />
      </Box>
      <Box paddingY={1}>
        <PhoneCustom
          fullWidth
          control={control}
          name="phone"
          setCountry={setCountry}
          rules={{
            validate: (value: string) => {
              if (value) {
                return isValidPhoneNumber(value, country?.countryCode?.toUpperCase()) || `Merci de renseigner un format valide (+${country?.dialCode} XX XX XX XX).`;
              }
              return null;
            },
          }}
        />
      </Box>
      <Box display="flex" paddingY={1}>
        <Button
          color="error"
          type="reset"
          onClick={() => setOpen(false)}
          sx={{
            marginRight: 1,
            textTransform: 'none',
          }}
        >
          Annuler
        </Button>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          endIcon={<AddIcon />}
          disabled={!watch('name')}
          sx={{
            marginLeft: 1,
            textTransform: 'none',
            color: (theme) => theme.palette.background.paper,
          }}
        >
          Ajouter agent immobilier
        </Button>
      </Box>
    </Box>
  );
}

export {
  AddAgentContainer,
};
