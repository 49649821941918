import Box from '@mui/material/Box';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead, TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useSearchParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { HousingLoan } from './housingloan';
import { Consumerloan } from './consumerloan';
import { euro } from '../../../../common/inputs/format/euro-format';
import { useUpdateProject } from '../../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  amount_rent: string
  amount_pension: string
  amount_others: string
}

interface IFormInput {
  type: { value: string, label: string };
  amount: number;
}

function Cost({ data, queryInfo }: any) {
  const [modify, setModify] = useState(false);
  const [searchParams] = useSearchParams();

  const methods = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return data?.personal_finance?.cost;
    }, [data, searchParams.get('customerId')]),
  });
  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    reset(data?.personal_finance?.cost);
  }, [queryInfo.isSuccess, searchParams.get('customerId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      data: {
        customers: [{
          customerId: data?._id,
          cost: {
            amount_rent: form?.amount_rent ? Number(form?.amount_rent) : 0,
            amount_pension: form?.amount_pension ? Number(form?.amount_pension) : 0,
            amount_others: form?.amount_others ? Number(form?.amount_others) : 0,
            housing_loan: form?.housing_loan?.map((e: any) => ({
              monthly_amount: Number(e.monthly_amount), loan_amount: Number(e.loan_amount), remaining_loan: Number(e.remaining_loan), end_date: e.end_date,
            })),
            consumer_loan: form?.consumer_loan?.map((e: any) => ({
              monthly_amount: Number(e.monthly_amount), loan_amount: Number(e.loan_amount), remaining_loan: Number(e.remaining_loan), end_date: e.end_date,
            })),
          },
        }],
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
    setModify(false);
  };

  return (
    <FormProvider {...methods}>
      <>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography paddingLeft={1} variant="h5" color="primary">Charges</Typography>
          <Box paddingY={1} display="flex" width="100%">
            <Box paddingY={1} paddingRight={2}>
              <CurrencyTextFieldCustom
                control={control}
                label="Le(s) loyers mensuels"
                name="amount_rent"
                read={!modify}
              />
            </Box>
            <Box paddingY={1} paddingRight={2}>
              <CurrencyTextFieldCustom
                control={control}
                label="Pensions mensuelles"
                name="amount_pension"
                read={!modify}
              />
            </Box>
            <Box paddingY={1} paddingRight={2}>
              <CurrencyTextFieldCustom
                control={control}
                label="Autres charges mensuelles"
                name="amount_others"
                read={!modify}
              />
            </Box>
          </Box>
          <Box>
            <Box paddingY={1}>
              <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom={1}>
                <Typography textAlign="left" variant="h6" color="text.primary">Prêt immobilier</Typography>
              </Box>
              <Box marginBottom={2}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell width={200}>Mensualité</TableCell>
                        <TableCell width={200}>Capital emprunté</TableCell>
                        <TableCell width={200}>Capital restant dû</TableCell>
                        <TableCell width={200}>Date de fin</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.personal_finance?.cost?.housing_loan?.map((row: any, i: any) => (
                        <TableRow
                          key={row + i.toString()}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.monthly_amount ? euro.format(row.monthly_amount) : null}</TableCell>
                          <TableCell>{row.loan_amount ? euro.format(row.loan_amount) : null}</TableCell>
                          <TableCell>{row.remaining_loan ? euro.format(row.remaining_loan) : null}</TableCell>
                          <TableCell>{row.end_date ? format(new Date(row.end_date), 'dd/MM/yyyy') : null}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Typography marginTop={2} variant="body2" paddingLeft={1} textAlign="right">
                    {`Total ${euro.format(data?.personal_finance?.cost?.total_yearly_housing_loan)}`}
                  </Typography>
                </TableContainer>
              </Box>
              {modify
            && (
              <HousingLoan />
            )}
            </Box>
            <Box paddingY={1}>
              <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom={1}>
                <Typography textAlign="left" variant="h6" color="text.primary">Prêt à la consommation</Typography>
              </Box>
              <Box marginBottom={2}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell width={200}>Mensualité</TableCell>
                        <TableCell width={200}>Capital emprunté</TableCell>
                        <TableCell width={200}>Capital restant dû</TableCell>
                        <TableCell width={200}>Date de fin</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.personal_finance?.cost?.consumer_loan?.map((row: any, i: any) => (
                        <TableRow
                          key={row + i.toString()}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.monthly_amount ? euro.format(row.monthly_amount) : null}</TableCell>
                          <TableCell>{row.loan_amount ? euro.format(row.loan_amount) : null}</TableCell>
                          <TableCell>{row.remaining_loan ? euro.format(row.remaining_loan) : null}</TableCell>
                          <TableCell>{row.end_date ? format(new Date(row.end_date), 'dd/MM/yyyy') : null}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Typography marginTop={2} variant="body2" paddingLeft={1} textAlign="right">
                    {`Total ${euro.format(data?.personal_finance?.cost?.total_yearly_consumer_loan)}`}
                  </Typography>
                </TableContainer>
              </Box>
              {modify
            && (
              <Consumerloan />
            )}
            </Box>
          </Box>
          <Box width="100%" display="flex" justifyContent="flex-end">
            {modify
              ? (
                <>
                  <Button
                    sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                    onClick={() => setModify(false)}
                    type="reset"
                    color="error"
                    variant="contained"
                    endIcon={<CancelIcon />}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ fontWeight: 'bold', textTransform: 'none' }}
                    endIcon={<SaveIcon />}
                  >
                    Enregistrer
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => {
                    setModify(true);
                  }}
                  sx={{ textTransform: 'none' }}
                  variant="outlined"
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              )}
          </Box>
        </Box>
      </>
    </FormProvider>
  );
}

export {
  Cost,
};
