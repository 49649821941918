import {
  alpha,
  Box, Grid, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import TimerIcon from '@mui/icons-material/Timer';
import location from '../../../../assets/property/little_icon/location.png';
import location_white from '../../../../assets/property-white/little-icon/location_white.png';
import status from '../../../../assets/property/little_icon/status.png';
import status_white from '../../../../assets/property-white/little-icon/status_white.png';
import surface from '../../../../assets/property/little_icon/surface.png';
import surface_white from '../../../../assets/property-white/little-icon/surface_white.png';
import prix_a_negocier from '../../../../assets/property/little_icon/prix_a_negocier.png';
import prix_a_negocier_white from '../../../../assets/property-white/little-icon/prix_a_negocier_white.png';
import rentabilite from '../../../../assets/property/little_icon/rentabilite.png';
import rentabilite_white from '../../../../assets/property-white/little-icon/rentabilite_white.png';
import flat from '../../../../assets/property/appartement.png';
import house from '../../../../assets/property/maison.png';
import building from '../../../../assets/property/immeuble.png';
import flat_white from '../../../../assets/property-white/appartement_white.png';
import house_white from '../../../../assets/property-white/maison_white.png';
import building_white from '../../../../assets/property-white/immeuble_white.png';
import land_white from '../../../../assets/property-white/terrain_white.png';
import land from '../../../../assets/property/terrain.png';
import { AllowedPropertyType } from '../../../../api';
import { levelOfUrgencyOptions, propertyTypeOptions } from '../../../../data/data';
import { euro } from '../../../../common/inputs/format/euro-format';

function SummarySimulation({ queryInfo }: any) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const data = queryInfo?.data?.getPropertiesById;

  const chooseTypeOfProperty = (type: any) => {
    switch (type) {
      case AllowedPropertyType.FLAT: {
        return isDarkTheme ? flat_white : flat;
      }
      case AllowedPropertyType.HOUSE: {
        return isDarkTheme ? house_white : house;
      }
      case AllowedPropertyType.BUILDING: {
        return isDarkTheme ? building_white : building;
      }
      case AllowedPropertyType.LAND: {
        return isDarkTheme ? land_white : land;
      }
      default: {
        return flat;
      }
    }
  };

  return (
    <Box marginBottom={2}>
      <Grid
        container
        item
        columns={5}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        marginY={1}
      >
        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center" paddingY={2}>
          <img
            style={{
              display: 'block', objectFit: 'cover', height: '25px', marginRight: 15,
            }}
            src={chooseTypeOfProperty(data?.type)}
          />
          <Typography>{propertyTypeOptions?.find((f) => f.value === data?.type)?.label}</Typography>
        </Grid>
        {data?.localisation?.street_address?.locality && data?.localisation?.street_address?.zipcode
          && (
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
              <img
                style={{
                  display: 'block', objectFit: 'cover', height: '35px', marginRight: 15,
                }}
                src={isDarkTheme ? location_white : location}
              />
              <Typography>{`${data?.localisation?.street_address?.locality} ${data?.localisation?.street_address?.zipcode}`}</Typography>
            </Grid>
          )}
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
          <img
            style={{
              display: 'block', objectFit: 'cover', height: '25px', marginRight: 15,
            }}
            src={isDarkTheme ? surface_white : surface}
          />
          <Typography>{`${data?.description?.surface}m2`}</Typography>
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
          <img
            style={{
              display: 'block', objectFit: 'cover', height: '25px', marginRight: 15,
            }}
            src={isDarkTheme ? status_white : status}
          />
          <Typography>Status</Typography>
        </Grid>
        {data?.conclusion?.level_of_urgency
          && (
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
              <TimerIcon sx={{
                height: '25px',
                marginRight: '15px',
                color: levelOfUrgencyOptions.find((f) => f.value === data?.conclusion?.level_of_urgency)?.color,
              }}
              />
              <Typography
                color={levelOfUrgencyOptions.find((f) => f.value === data?.conclusion?.level_of_urgency)?.color}
              >
                {levelOfUrgencyOptions.find((f) => f.value === data?.conclusion?.level_of_urgency)?.label}
              </Typography>
            </Grid>
          )}
      </Grid>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="row"
        marginY={3}
      >
        <BoxDetails
          darkIcon={prix_a_negocier}
          lightIcon={prix_a_negocier_white}
          title="Prix à négocier"
          colorValue={data?.finance?.lsi_profitability}
          displayedValue={euro.format(data?.finance?.expected_price)}
        />
        <BoxDetails
          darkIcon={rentabilite}
          lightIcon={rentabilite_white}
          title="Rentabilité"
          colorValue={data?.finance?.lsi_profitability}
          displayedValue={`${data?.finance?.lsi_profitability?.toFixed(1)}%`}
        />
      </Box>
    </Box>
  );
}

function BoxDetails({
  darkIcon, lightIcon, title, colorValue, displayedValue,
}:any) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  return (
    <Box
      sx={{
        borderRadius: '5px',
        border: '0.1px solid',
        borderColor: alpha('#000000', 0.1),
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingY={2}
      paddingX={4}
      width="40%"
    >
      <Box
        display="flex"
        marginRight={4}
        alignItems="center"
      >
        <Box
          component="img"
          src={isDarkTheme ? lightIcon : darkIcon}
          width="25px"
          style={{ objectFit: 'contain', marginRight: '15px' }}
        />
        <Typography fontWeight="bold" variant="h6">
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{ color: colorValue?.toFixed(1) >= 5 ? 'success.main' : 'secondary.main' }}
        >
          {displayedValue || ''}
        </Typography>
      </Box>
    </Box>
  );
}

export {
  SummarySimulation,
};
