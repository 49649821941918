import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { offersOptions } from '../../../data/data';

function ProjectsDetails({ task, modal, chooseOffersColor }: any) {
  const navigate = useNavigate();

  return (
    <Box padding="8px">
      <Box display="flex" justifyContent="space-between" paddingY={0.5}>
        <Typography
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          variant="body2"
          fontWeight="bold"
          color="primary"
        >
          {task?.title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start" paddingY={0.5}>
        <Typography fontWeight="500" color="text.primary" variant="body2">{`🐣 ${task?.content?.createdAt ? format(new Date(task?.content?.createdAt), 'dd/MM/yyyy') : ''}`}</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-start" paddingY={0.5}>
        <Typography
          fontWeight="bold"
          variant="body2"
          sx={{
            color: chooseOffersColor(task?.content?.offer),
          }}
        >
          {`💰 ${offersOptions.find((f) => f.value === task?.content?.offer)?.label || 'Non défini'}`}
        </Typography>
      </Box>
      {modal
        && (
          <Box display="flex" justifyContent="center" alignItems="center" paddingY={1}>
            <Button
              onClick={() => navigate(`/project-list/projectcard?projectId=${task?.id}`)}
              disabled={!task?.id}
              sx={{ fontWeight: 'bold' }}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Aller vers projet
            </Button>
          </Box>
        )}
    </Box>
  );
}

export {
  ProjectsDetails,
};
