import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type AddCommentPropertyInput = {
  comment: Scalars['String'];
  propertyId: Scalars['String'];
};

/** All the types of user account. */
export enum AllowedAccountType {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  HUNTER = 'HUNTER'
}

/** All the appointment type available for the field appointment. */
export enum AllowedAppointmentType {
  CLOSING = 'CLOSING',
  FINANCEMENT = 'FINANCEMENT',
  INFORMATION = 'INFORMATION',
  PROJETS = 'PROJETS'
}

/** All the closed status available. */
export enum AllowedClosedStatus {
  DISCONTINUED = 'DISCONTINUED',
  GOOD_PROFILE = 'GOOD_PROFILE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  OUTSIDE_IDF = 'OUTSIDE_IDF',
  UNDESIRABLE = 'UNDESIRABLE'
}

/** All the types available for Collective Ownership Works. */
export enum AllowedCollectiveOwnershipWorks {
  DISCUSSED = 'DISCUSSED',
  PAID = 'PAID',
  VOTED = 'VOTED'
}

/** Where the user comes from when he arrives on our site. */
export enum AllowedCustomerSource {
  BUSINESS_PROVIDER = 'BUSINESS_PROVIDER',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  OTHER = 'OTHER',
  SEO = 'SEO',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH'
}

/** All the document label available. */
export enum AllowedDocumentLabel {
  CONTRAT = 'CONTRAT',
  ID_DOC = 'ID_DOC',
  OTHER_DOC = 'OTHER_DOC',
  WEDDING_CONTRAT_DOC = 'WEDDING_CONTRAT_DOC'
}

/** All the types available of dpe_audit or ges_audit. */
export enum AllowedDpeGes {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G'
}

/** All the employment contract available. */
export enum AllowedEmploymentContract {
  CDD = 'CDD',
  CDI = 'CDI',
  ENTREPRENEUR = 'ENTREPRENEUR',
  INDEPENDENT = 'INDEPENDENT',
  LIBERAL_PROFESSION = 'LIBERAL_PROFESSION',
  OTHER = 'OTHER'
}

/** All the types available for exploitation key. */
export enum AllowedExploitation {
  EMPTY_RENTAL = 'EMPTY_RENTAL',
  FLAT_SHARE = 'FLAT_SHARE',
  FURNISHED_RENTAL = 'FURNISHED_RENTAL',
  PRO_RENTAL = 'PRO_RENTAL',
  SEASONAL_RENTAL = 'SEASONAL_RENTAL'
}

/** All the types available of flat_type. */
export enum AllowedFlatType {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6 = 'T6',
  T7 = 'T7',
  T8 = 'T8',
  T9 = 'T9',
  T10 = 'T10'
}

/** All the gender available. */
export enum AllowedGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

/** All the types available for InternetFiber. */
export enum AllowedInternetFiber {
  NO = 'NO',
  YES = 'YES',
  YES_IN_PROPERTY = 'YES_IN_PROPERTY'
}

/** All the investment method available for the field investment_method. */
export enum AllowedInvestmentMethod {
  DEFICIT_FONCIER = 'DEFICIT_FONCIER',
  LMNP = 'LMNP',
  NOM_PROPRE = 'NOM_PROPRE',
  SARL_DE_FAMILLE = 'SARL_DE_FAMILLE',
  SAS = 'SAS',
  SCI_IR = 'SCI_IR',
  SCI_IS = 'SCI_IS'
}

/** All the kanban type available. */
export enum AllowedKanbanType {
  PROJECTS = 'PROJECTS',
  PROPERTIES = 'PROPERTIES',
  SALES = 'SALES'
}

/** All the types available for Landlord Fees. */
export enum AllowedLandlordFees {
  COLD_WATER = 'COLD_WATER',
  GAS = 'GAS',
  HEATING = 'HEATING',
  HOT_WATER = 'HOT_WATER'
}

/** All the types available of level_of_urgency. */
export enum AllowedLevelOfUrgency {
  BETWEEN_48_AND_A_WEEK = 'BETWEEN_48_AND_A_WEEK',
  MORE_THAN_A_WEEK = 'MORE_THAN_A_WEEK',
  UNDER_48H = 'UNDER_48H'
}

/** All the types available for the current state of the matching. */
export enum AllowedMatchingState {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REFUSED = 'REFUSED'
}

/** All the offers available. */
export enum AllowedOffer {
  ENVOL = 'ENVOL',
  ETINCELLE = 'ETINCELLE',
  ETOILE = 'ETOILE',
  INELIGIBLE = 'INELIGIBLE'
}

/** All the types available for Orientation. */
export enum AllowedOrientation {
  EAST = 'EAST',
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTH_EAST',
  NORTH_WEST = 'NORTH_WEST',
  SOUTH = 'SOUTH',
  SOUTH_EAST = 'SOUTH_EAST',
  SOUTH_WEST = 'SOUTH_WEST',
  WEST = 'WEST'
}

/** All the types available for property closed status. */
export enum AllowedPropertyClosedStatus {
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  REJECTED_OFFER = 'REJECTED_OFFER',
  RETRACTION = 'RETRACTION'
}

/** All the types available of documents labels for property. */
export enum AllowedPropertyDocumentsLabels {
  BUILDING_BLUEPRINT = 'BUILDING_BLUEPRINT',
  BUILDING_STATE_DATE = 'BUILDING_STATE_DATE',
  CONDOMINIUM_FEES = 'CONDOMINIUM_FEES',
  CO_OWNERSHIP_REGULATIONS = 'CO_OWNERSHIP_REGULATIONS',
  DIAGNOSIS = 'DIAGNOSIS',
  GENERAL_ASSEMBLY_NOTICE = 'GENERAL_ASSEMBLY_NOTICE',
  HOUSING_TAX = 'HOUSING_TAX',
  MAINTENANCE_BOOKLET = 'MAINTENANCE_BOOKLET',
  OTHERS = 'OTHERS',
  PROPERTY_PICTURE = 'PROPERTY_PICTURE'
}

/** All the states available for property state. */
export enum AllowedPropertyState {
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  MATCHED = 'MATCHED'
}

/** All the types available for property status. */
export enum AllowedPropertyStatus {
  ACCEPTED_OFFER = 'ACCEPTED_OFFER',
  CLOSED = 'CLOSED',
  NEW_PROPERTY = 'NEW_PROPERTY',
  PENDING_MATCHING = 'PENDING_MATCHING',
  PROPERTY_MATCHED = 'PROPERTY_MATCHED',
  PROPERTY_UNDER_NEGOTIATION = 'PROPERTY_UNDER_NEGOTIATION',
  QUALIFIED_PROPERTY = 'QUALIFIED_PROPERTY',
  WAITING_TO_BE_MATCHED = 'WAITING_TO_BE_MATCHED'
}

/** All the types available of property_type. */
export enum AllowedPropertyType {
  BUILDING = 'BUILDING',
  FLAT = 'FLAT',
  HOUSE = 'HOUSE',
  LAND = 'LAND'
}

/** All the types available of selling_reason. */
export enum AllowedSellingReason {
  DEATHS = 'DEATHS',
  DIVORCE = 'DIVORCE',
  FINANCIAL = 'FINANCIAL',
  MOVING_OUT = 'MOVING_OUT',
  OTHER = 'OTHER',
  SEPARATION = 'SEPARATION'
}

/** All the seniority available. */
export enum AllowedSeniority {
  MORE_THREE_YEARS = 'MORE_THREE_YEARS',
  OUT_OF_TRIAL_PERIOD = 'OUT_OF_TRIAL_PERIOD',
  TRIAL_PERIOD = 'TRIAL_PERIOD',
  UNDER_THREE_YEARS = 'UNDER_THREE_YEARS'
}

/** All the status available. */
export enum AllowedStatus {
  ACTE_AUTHENTIQUE_SIGNE = 'ACTE_AUTHENTIQUE_SIGNE',
  ANALYSE_SIMULATIONS = 'ANALYSE_SIMULATIONS',
  COMPROMIS_A_VERIFIER = 'COMPROMIS_A_VERIFIER',
  COMPROMIS_EN_REDACTION = 'COMPROMIS_EN_REDACTION',
  COMPROMIS_SIGNE = 'COMPROMIS_SIGNE',
  COMPROMIS_VERIFIE = 'COMPROMIS_VERIFIE',
  DOSSIERS_BANCAIRES_ENVOYES = 'DOSSIERS_BANCAIRES_ENVOYES',
  EN_ATTENTE_DE_BIEN = 'EN_ATTENTE_DE_BIEN',
  MATCHING_ACCEPTE = 'MATCHING_ACCEPTE',
  MATCHING_EN_COURS = 'MATCHING_EN_COURS',
  MONTAGE_FINANCEMENT = 'MONTAGE_FINANCEMENT',
  NOUVEAU_LEAD = 'NOUVEAU_LEAD',
  OFFRE_DACHAT_SIGNEE = 'OFFRE_DACHAT_SIGNEE',
  OFFRE_DE_PRETSIGNEE = 'OFFRE_DE_PRETSIGNEE',
  OFFRE_DE_PRET_VERIFIEE = 'OFFRE_DE_PRET_VERIFIEE',
  PROJET_FERME = 'PROJET_FERME',
  PROJET_LIVRE = 'PROJET_LIVRE',
  PROSPECT = 'PROSPECT',
  TRAVAUX_EN_COURS = 'TRAVAUX_EN_COURS'
}

/** All the types available for transportation key. */
export enum AllowedTransportation {
  AUTOBUS = 'AUTOBUS',
  RER = 'RER',
  SUBWAY = 'SUBWAY',
  TRAMWAY = 'TRAMWAY',
  TRANSILIEN = 'TRANSILIEN'
}

/** All the wedding plan available. */
export enum AllowedWeddingPlan {
  COMMUNITY_REDUCED_TO_ACQUISITIONS = 'COMMUNITY_REDUCED_TO_ACQUISITIONS',
  PACS_INDIVISION = 'PACS_INDIVISION',
  PACS_SEPARATION = 'PACS_SEPARATION',
  PARTICIPATION_IN_ACQUISITIONS = 'PARTICIPATION_IN_ACQUISITIONS',
  SEPARATION_OF_PROPERTY = 'SEPARATION_OF_PROPERTY',
  UNIVERSAL_COMMUNITY = 'UNIVERSAL_COMMUNITY'
}

/** All the status of a signature procedure */
export enum AllowedYousignStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  NOT_FOUND = 'NOT_FOUND',
  REFUSED = 'REFUSED'
}

export type AnalyticUserJourneyBeforeAccountCreationOutput = {
  __typename?: 'AnalyticUserJourneyBeforeAccountCreationOutput';
  _id?: Maybe<Scalars['String']>;
  ads_campaign_medium?: Maybe<Scalars['String']>;
  ads_campaign_source?: Maybe<Scalars['String']>;
  ads_event_text?: Maybe<Scalars['String']>;
  ads_original_timestamp?: Maybe<OriginalTimestamp>;
  ads_page_referrer?: Maybe<Scalars['String']>;
  ads_page_search?: Maybe<Scalars['String']>;
  anonymous_id?: Maybe<Scalars['String']>;
  context_campaign_medium?: Maybe<Scalars['String']>;
  context_campaign_source?: Maybe<Scalars['String']>;
  context_event_text?: Maybe<Scalars['String']>;
  context_original_timestamp?: Maybe<OriginalTimestamp>;
  context_page_referrer?: Maybe<Scalars['String']>;
  context_page_search?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AppointmentDto = {
  __typename?: 'AppointmentDto';
  date?: Maybe<Scalars['DateTime']>;
  type?: Maybe<AllowedAppointmentType>;
  withEmail?: Maybe<Scalars['Boolean']>;
};

export type AppointmentDtoInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<AllowedAppointmentType>;
  withEmail?: InputMaybe<Scalars['Boolean']>;
};

export type Arrangement = {
  __typename?: 'Arrangement';
  balcony_number?: Maybe<Scalars['Float']>;
  balcony_surface?: Maybe<Scalars['Float']>;
  basement_number?: Maybe<Scalars['Float']>;
  box_number?: Maybe<Scalars['Float']>;
  caretaker_number?: Maybe<Scalars['Boolean']>;
  elevator_number?: Maybe<Scalars['Float']>;
  floors?: Maybe<Array<Floor>>;
  futureLayout?: Maybe<Floor>;
  garden_number?: Maybe<Scalars['Float']>;
  garden_surface?: Maybe<Scalars['Float']>;
  intercom_system_digital_code?: Maybe<Scalars['String']>;
  intercom_system_name?: Maybe<Scalars['String']>;
  internet_fiber?: Maybe<AllowedInternetFiber>;
  parking_lot_number?: Maybe<Scalars['Float']>;
  terrace_number?: Maybe<Scalars['Float']>;
  terrace_surface?: Maybe<Scalars['Float']>;
};

export type ArrangementInput = {
  balcony_number?: InputMaybe<Scalars['Float']>;
  balcony_surface?: InputMaybe<Scalars['Float']>;
  basement_number?: InputMaybe<Scalars['Float']>;
  box_number?: InputMaybe<Scalars['Float']>;
  caretaker_number?: InputMaybe<Scalars['Boolean']>;
  elevator_number?: InputMaybe<Scalars['Float']>;
  floors?: InputMaybe<Array<FloorInput>>;
  futureLayout?: InputMaybe<FloorInput>;
  garden_number?: InputMaybe<Scalars['Float']>;
  garden_surface?: InputMaybe<Scalars['Float']>;
  intercom_system_digital_code?: InputMaybe<Scalars['String']>;
  intercom_system_name?: InputMaybe<Scalars['String']>;
  internet_fiber?: InputMaybe<AllowedInternetFiber>;
  parking_lot_number?: InputMaybe<Scalars['Float']>;
  terrace_number?: InputMaybe<Scalars['Float']>;
  terrace_surface?: InputMaybe<Scalars['Float']>;
};

export type BusinessProvider = {
  __typename?: 'BusinessProvider';
  name: Scalars['String'];
};

export type CalendlyEventDto = {
  __typename?: 'CalendlyEventDto';
  cancel_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  questions_and_answers: Array<Maybe<QuestionsAndAnswer>>;
  reschedule_url?: Maybe<Scalars['String']>;
  rescheduled?: Maybe<Scalars['Boolean']>;
  start_time?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type CashFlowProperty = {
  __typename?: 'CashFlowProperty';
  cash_flow?: Maybe<Scalars['Float']>;
  inflation?: Maybe<Scalars['Float']>;
  inflation_price?: Maybe<Scalars['Float']>;
  maintenance_monthly_fees?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  monthly_insurance_fees?: Maybe<Scalars['Float']>;
  monthly_landlord_fees?: Maybe<Scalars['Float']>;
  monthly_loan_amount?: Maybe<Scalars['Float']>;
  monthly_outcome?: Maybe<Scalars['Float']>;
  monthly_property_tax?: Maybe<Scalars['Float']>;
  monthly_real_estate_agency_fees?: Maybe<Scalars['Float']>;
  monthly_unpaid_insurance_fees?: Maybe<Scalars['Float']>;
};

export type CashFlowPropertyInput = {
  cash_flow?: InputMaybe<Scalars['Float']>;
  inflation?: InputMaybe<Scalars['Float']>;
  inflation_price?: InputMaybe<Scalars['Float']>;
  maintenance_monthly_fees?: InputMaybe<Scalars['Float']>;
  monthly_income?: InputMaybe<Scalars['Float']>;
  monthly_insurance_fees?: InputMaybe<Scalars['Float']>;
  monthly_landlord_fees?: InputMaybe<Scalars['Float']>;
  monthly_loan_amount?: InputMaybe<Scalars['Float']>;
  monthly_outcome?: InputMaybe<Scalars['Float']>;
  monthly_property_tax?: InputMaybe<Scalars['Float']>;
  monthly_real_estate_agency_fees?: InputMaybe<Scalars['Float']>;
  monthly_unpaid_insurance_fees?: InputMaybe<Scalars['Float']>;
};

export type CommentOutput = {
  __typename?: 'CommentOutput';
  comment: Scalars['String'];
};

export type CommentsInput = {
  comment: Scalars['String'];
  projectId: Scalars['String'];
};

export type CommentsUserInput = {
  comment: Scalars['String'];
  userId: Scalars['String'];
};

export type Conclusion = {
  __typename?: 'Conclusion';
  comment?: Maybe<Scalars['String']>;
  expected_property_selling_price?: Maybe<Scalars['Float']>;
  level_of_urgency?: Maybe<AllowedLevelOfUrgency>;
  property_offer?: Maybe<AllowedOffer>;
  selling_reason?: Maybe<AllowedSellingReason>;
};

export type ConclusionInput = {
  comment?: InputMaybe<Scalars['String']>;
  expected_property_selling_price?: InputMaybe<Scalars['Float']>;
  level_of_urgency?: InputMaybe<AllowedLevelOfUrgency>;
  property_offer?: InputMaybe<AllowedOffer>;
  selling_reason?: InputMaybe<AllowedSellingReason>;
};

export type ConnectionArgs = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['String']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['String']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Float']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Float']>;
};

export type CreateMatchingInput = {
  projectId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type CreateRealEstateAgency = {
  __typename?: 'CreateRealEstateAgency';
  _id?: Maybe<Scalars['String']>;
  realEstateAgencyAddress?: Maybe<Scalars['String']>;
  realEstateAgencyName?: Maybe<Scalars['String']>;
  realEstateAgents?: Maybe<Array<CreateRealEstateAgentOutput>>;
};

export type CreateRealEstateAgencyInput = {
  realEstateAgencyAddress: Scalars['String'];
  realEstateAgencyName: Scalars['String'];
};

export type CreateRealEstateAgentInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateRealEstateAgentOutput = {
  __typename?: 'CreateRealEstateAgentOutput';
  _id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  _id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
};

export type CustomerIdentifier = {
  __typename?: 'CustomerIdentifier';
  cio_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CustomerInput = {
  personal_info: CustomerPersonalInfosInput;
};

export type CustomerInputFromCrm = {
  personal_info: CustomerPersonalInfosFromCrmInput;
};

export type CustomerOutput = {
  __typename?: 'CustomerOutput';
  _id?: Maybe<Scalars['ID']>;
  personal_finance?: Maybe<PersonalFinanceOutput>;
  personal_info: GlobalCustomerInfo;
};

export type CustomerPersonalInfosFromCrmInput = {
  birthdate?: InputMaybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  gender?: InputMaybe<AllowedGender>;
  is_married?: InputMaybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  number_of_children?: InputMaybe<Scalars['Float']>;
  place_of_birth?: InputMaybe<Scalars['String']>;
  wedding_plan?: InputMaybe<AllowedWeddingPlan>;
};

export type CustomerPersonalInfosInput = {
  birthdate: Scalars['DateTime'];
  first_name: Scalars['String'];
  gender: AllowedGender;
  is_married?: InputMaybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  nationality: Scalars['String'];
  number_of_children?: InputMaybe<Scalars['Float']>;
  place_of_birth: Scalars['String'];
  wedding_plan?: InputMaybe<AllowedWeddingPlan>;
};

export type Description = {
  __typename?: 'Description';
  already_rent?: Maybe<Scalars['Boolean']>;
  already_visited?: Maybe<Scalars['Boolean']>;
  current_rent?: Maybe<Scalars['Float']>;
  dpe_audit?: Maybe<AllowedDpeGes>;
  expected_rent?: Maybe<Scalars['Float']>;
  exploitation?: Maybe<AllowedExploitation>;
  flat_number?: Maybe<Scalars['String']>;
  flat_type?: Maybe<AllowedFlatType>;
  ges_audit?: Maybe<AllowedDpeGes>;
  orientation?: Maybe<AllowedOrientation>;
  property_story?: Maybe<Scalars['Float']>;
  rentingEndDate?: Maybe<Scalars['DateTime']>;
  surface?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  upfront_price?: Maybe<Scalars['Float']>;
  visited_date?: Maybe<Scalars['DateTime']>;
};

export type DescriptionInput = {
  already_rent?: InputMaybe<Scalars['Boolean']>;
  already_visited?: InputMaybe<Scalars['Boolean']>;
  current_rent?: InputMaybe<Scalars['Float']>;
  dpe_audit?: InputMaybe<AllowedDpeGes>;
  expected_rent?: InputMaybe<Scalars['Float']>;
  exploitation?: InputMaybe<AllowedExploitation>;
  flat_number?: InputMaybe<Scalars['String']>;
  flat_type?: InputMaybe<AllowedFlatType>;
  ges_audit?: InputMaybe<AllowedDpeGes>;
  orientation?: InputMaybe<AllowedOrientation>;
  property_story?: InputMaybe<Scalars['Float']>;
  rentingEndDate?: InputMaybe<Scalars['DateTime']>;
  surface?: InputMaybe<Scalars['Float']>;
  tax?: InputMaybe<Scalars['Float']>;
  upfront_price?: InputMaybe<Scalars['Float']>;
  visited_date?: InputMaybe<Scalars['DateTime']>;
};

export type DocumentsDto = {
  __typename?: 'DocumentsDto';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<AllowedDocumentLabel>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentsPropertyDto = {
  __typename?: 'DocumentsPropertyDto';
  _id?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  isPreview?: Maybe<Scalars['Boolean']>;
  mimeType?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  type?: Maybe<AllowedPropertyDocumentsLabels>;
  url: Scalars['String'];
};

export type EmailCustomerIo = {
  __typename?: 'EmailCustomerIO';
  action_id?: Maybe<Scalars['Float']>;
  broadcast_id?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  content_id?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['String']>;
  customer_identifiers?: Maybe<CustomerIdentifier>;
  deduplicate_id?: Maybe<Scalars['String']>;
  failure_message?: Maybe<Scalars['String']>;
  forgotten?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  metrics?: Maybe<Metrics>;
  msg_template_id?: Maybe<Scalars['Float']>;
  newsletter_id?: Maybe<Scalars['Float']>;
  parent_action_id?: Maybe<Scalars['Float']>;
  recipient?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  transactional_message_id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type Finance = {
  __typename?: 'Finance';
  equity_contribution?: Maybe<Scalars['Float']>;
  loan_budget_recommended?: Maybe<Scalars['Float']>;
  loan_max_value?: Maybe<Scalars['Float']>;
  /** @deprecated Use total_max_budget instead */
  max_loan_budget?: Maybe<Scalars['Float']>;
  monthly_loan_max?: Maybe<Scalars['Float']>;
  monthly_loan_recommended?: Maybe<Scalars['Float']>;
  total_all_monthly_cost?: Maybe<Scalars['Float']>;
  total_all_monthly_income?: Maybe<Scalars['Float']>;
  total_all_monthly_savings?: Maybe<Scalars['Float']>;
  total_all_monthly_taxes?: Maybe<Scalars['Float']>;
  total_all_yearly_cost?: Maybe<Scalars['Float']>;
  total_all_yearly_income?: Maybe<Scalars['Float']>;
  total_all_yearly_savings?: Maybe<Scalars['Float']>;
  total_all_yearly_taxes?: Maybe<Scalars['Float']>;
  total_max_budget?: Maybe<Scalars['Float']>;
  viability?: Maybe<Scalars['Float']>;
};

export type FinanceInput = {
  equity_contribution?: InputMaybe<Scalars['Float']>;
  loan_budget_recommended?: InputMaybe<Scalars['Float']>;
  loan_max_value?: InputMaybe<Scalars['Float']>;
  max_loan_budget?: InputMaybe<Scalars['Float']>;
  monthly_loan_max?: InputMaybe<Scalars['Float']>;
  monthly_loan_recommended?: InputMaybe<Scalars['Float']>;
  total_all_monthly_cost?: InputMaybe<Scalars['Float']>;
  total_all_monthly_income?: InputMaybe<Scalars['Float']>;
  total_all_monthly_savings?: InputMaybe<Scalars['Float']>;
  total_all_monthly_taxes?: InputMaybe<Scalars['Float']>;
  total_all_yearly_cost?: InputMaybe<Scalars['Float']>;
  total_all_yearly_income?: InputMaybe<Scalars['Float']>;
  total_all_yearly_savings?: InputMaybe<Scalars['Float']>;
  total_all_yearly_taxes?: InputMaybe<Scalars['Float']>;
  total_max_budget?: InputMaybe<Scalars['Float']>;
  viability?: InputMaybe<Scalars['Float']>;
};

export type FirstCalendlyEventBooked = {
  __typename?: 'FirstCalendlyEventBooked';
  start_time?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Floor = {
  __typename?: 'Floor';
  bathroom_number?: Maybe<Scalars['Float']>;
  bedroom_number?: Maybe<Scalars['Float']>;
  dining_room_number?: Maybe<Scalars['Float']>;
  kitchen_number?: Maybe<Scalars['Float']>;
  laundry_room_number?: Maybe<Scalars['Float']>;
  living_room_number?: Maybe<Scalars['Float']>;
  office_number?: Maybe<Scalars['Float']>;
  shower_room_number?: Maybe<Scalars['Float']>;
  water_closet_number?: Maybe<Scalars['Float']>;
};

export type FloorInput = {
  bathroom_number?: InputMaybe<Scalars['Float']>;
  bedroom_number?: InputMaybe<Scalars['Float']>;
  dining_room_number?: InputMaybe<Scalars['Float']>;
  kitchen_number?: InputMaybe<Scalars['Float']>;
  laundry_room_number?: InputMaybe<Scalars['Float']>;
  living_room_number?: InputMaybe<Scalars['Float']>;
  office_number?: InputMaybe<Scalars['Float']>;
  shower_room_number?: InputMaybe<Scalars['Float']>;
  water_closet_number?: InputMaybe<Scalars['Float']>;
};

export type GeneralInformation = {
  __typename?: 'GeneralInformation';
  building_stories_number?: Maybe<Scalars['Float']>;
  building_year?: Maybe<Scalars['Float']>;
  collective_ownership_works?: Maybe<AllowedCollectiveOwnershipWorks>;
  landlord_fees?: Maybe<Scalars['Float']>;
  landlord_surface_rate?: Maybe<Scalars['Float']>;
  planned_renovation_price?: Maybe<Scalars['String']>;
  planned_renovation_work?: Maybe<Scalars['Boolean']>;
  shared_flat_available?: Maybe<Scalars['Boolean']>;
  type_landlord_fees?: Maybe<Array<AllowedLandlordFees>>;
};

export type GeneralInformationInput = {
  building_stories_number?: InputMaybe<Scalars['Float']>;
  building_year?: InputMaybe<Scalars['Float']>;
  collective_ownership_works?: InputMaybe<AllowedCollectiveOwnershipWorks>;
  landlord_fees?: InputMaybe<Scalars['Float']>;
  landlord_surface_rate?: InputMaybe<Scalars['Float']>;
  planned_renovation_price?: InputMaybe<Scalars['String']>;
  planned_renovation_work?: InputMaybe<Scalars['Boolean']>;
  shared_flat_available?: InputMaybe<Scalars['Boolean']>;
  type_landlord_fees?: InputMaybe<Array<AllowedLandlordFees>>;
};

export type GenericSuccessReturn = {
  __typename?: 'GenericSuccessReturn';
  success: Scalars['Boolean'];
};

export type GlobalCustomerInfo = {
  __typename?: 'GlobalCustomerInfo';
  birthdate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  employer_name?: Maybe<Scalars['String']>;
  employment_contract?: Maybe<AllowedEmploymentContract>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<AllowedGender>;
  is_married?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  main_bank?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  number_of_children?: Maybe<Scalars['Float']>;
  other_banks?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  place_of_birth?: Maybe<Scalars['String']>;
  seniority?: Maybe<AllowedSeniority>;
  street_address?: Maybe<StreetAddress>;
  wedding_plan?: Maybe<AllowedWeddingPlan>;
};

export type KanbanDto = {
  __typename?: 'KanbanDto';
  columnOrder: Array<Scalars['String']>;
  columns: Scalars['JSON'];
  tasks: Scalars['JSON'];
};

export type KanbanTypeInput = {
  type: AllowedKanbanType;
};

export type LocalisationInput = {
  address_details?: InputMaybe<Scalars['String']>;
  futureTransportations?: InputMaybe<Array<TransportationInput>>;
  street_address?: InputMaybe<StreetAddressInput>;
  transportations?: InputMaybe<Array<TransportationInput>>;
};

export type LocalisationOutput = {
  __typename?: 'LocalisationOutput';
  address_details?: Maybe<Scalars['String']>;
  futureTransportations?: Maybe<Array<TransportationOutput>>;
  street_address?: Maybe<StreetAddress>;
  transportations?: Maybe<Array<TransportationOutput>>;
};

export type LogsInput = {
  action: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
};

export type LogsOutput = {
  __typename?: 'LogsOutput';
  _id?: Maybe<Scalars['ID']>;
  action: ResultUnion;
  createdAt: Scalars['String'];
  createdBy?: Maybe<CreateUserOutput>;
  project?: Maybe<Project>;
  user?: Maybe<CreateUserOutput>;
};

export type MatchingOutput = {
  __typename?: 'MatchingOutput';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  project: Project;
  property: PropertyOutput;
  state: AllowedMatchingState;
};

export type Metrics = {
  __typename?: 'Metrics';
  bounced?: Maybe<Scalars['String']>;
  converted?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['String']>;
  spammed?: Maybe<Scalars['String']>;
  unsubscribed?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCommentOnProperty: GenericSuccessReturn;
  create: User;
  createComment: GenericSuccessReturn;
  createCommentForClientOnly: GenericSuccessReturn;
  createContract: Scalars['String'];
  createCustomer: CustomerOutput;
  createLog: LogsOutput;
  createMatching: MatchingOutput;
  createProject: Project;
  createProjectFromCrm: Project;
  createProperty: PropertyOutput;
  createRealEstateAgency: CreateRealEstateAgency;
  deleteDocumentsFromProject: GenericSuccessReturn;
  deleteDocumentsFromProperty: GenericSuccessReturn;
  firstCalendlyMeetingBooked: FirstCalendlyEventBooked;
  removeRealEstateAgency: GenericSuccessReturn;
  updateLoanBudgetRecommended: GenericSuccessReturn;
  updateMatchingState: MatchingOutput;
  updatePersonalInfo: CustomerOutput;
  updateProject: Project;
  updateProjectOffer: Project;
  updateProjectStatus: Project;
  updateProperty: PropertyOutput;
  updateRealEstateAgency: CreateRealEstateAgency;
  updateRealEstateAgent: CreateRealEstateAgentOutput;
  updateUser: GenericSuccessReturn;
  updateUserFromTypeform: GenericSuccessReturn;
};


export type MutationAddCommentOnPropertyArgs = {
  data: AddCommentPropertyInput;
};


export type MutationCreateArgs = {
  createUserDto: UserInput;
};


export type MutationCreateCommentArgs = {
  data: CommentsInput;
};


export type MutationCreateCommentForClientOnlyArgs = {
  data: CommentsUserInput;
};


export type MutationCreateContractArgs = {
  projectId: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  customer: CustomerInput;
};


export type MutationCreateLogArgs = {
  log: LogsInput;
};


export type MutationCreateMatchingArgs = {
  data: CreateMatchingInput;
};


export type MutationCreateProjectArgs = {
  data: ProjectInput;
};


export type MutationCreateProjectFromCrmArgs = {
  data: ProjectInputFromCrm;
  userId: Scalars['String'];
};


export type MutationCreatePropertyArgs = {
  data: PropertyInput;
};


export type MutationCreateRealEstateAgencyArgs = {
  createRealEstateAgencyInput: CreateRealEstateAgencyInput;
};


export type MutationDeleteDocumentsFromProjectArgs = {
  documentId: Scalars['String'];
};


export type MutationDeleteDocumentsFromPropertyArgs = {
  documentId: Scalars['String'];
};


export type MutationFirstCalendlyMeetingBookedArgs = {
  calendlyUrl: Scalars['String'];
};


export type MutationRemoveRealEstateAgencyArgs = {
  id: Scalars['String'];
};


export type MutationUpdateLoanBudgetRecommendedArgs = {
  loan_budget_recommended: Scalars['Float'];
  projectId: Scalars['String'];
};


export type MutationUpdateMatchingStateArgs = {
  data: UpdateMatchingInput;
  matchingId: Scalars['String'];
};


export type MutationUpdatePersonalInfoArgs = {
  customerId: Scalars['String'];
  personal_informations: UpdateCustomerPersonalInfosInput;
};


export type MutationUpdateProjectArgs = {
  data: UpdateProjectInput;
  projectId: Scalars['String'];
};


export type MutationUpdateProjectOfferArgs = {
  offer: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationUpdateProjectStatusArgs = {
  projectId: Scalars['String'];
  status: Scalars['String'];
};


export type MutationUpdatePropertyArgs = {
  data: UpdatePropertyInput;
  propertyId: Scalars['String'];
};


export type MutationUpdateRealEstateAgencyArgs = {
  updateRealEstateAgencyInput: UpdateRealEstateAgentInput;
};


export type MutationUpdateRealEstateAgentArgs = {
  id: Scalars['String'];
  updateRealEstateAgent: CreateRealEstateAgentInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  userId: Scalars['String'];
};


export type MutationUpdateUserFromTypeformArgs = {
  typeformInfo: TypeformInfoInput;
};

export type OfferOutput = {
  __typename?: 'OfferOutput';
  offer: Scalars['String'];
};

export type OriginalTimestamp = {
  __typename?: 'OriginalTimestamp';
  value?: Maybe<Scalars['String']>;
};

export type PageDataDto = {
  __typename?: 'PageDataDto';
  count: Scalars['Float'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type PersonalFinanceCost = {
  __typename?: 'PersonalFinanceCost';
  amount_others?: Maybe<Scalars['Float']>;
  amount_pension?: Maybe<Scalars['Float']>;
  amount_rent?: Maybe<Scalars['Float']>;
  consumer_loan?: Maybe<Array<PersonalFinanceDataTypeCost>>;
  housing_loan?: Maybe<Array<PersonalFinanceDataTypeCost>>;
  total_monthly_consumer_loan?: Maybe<Scalars['Float']>;
  total_monthly_cost?: Maybe<Scalars['Float']>;
  total_monthly_housing_loan?: Maybe<Scalars['Float']>;
  total_monthly_others?: Maybe<Scalars['Float']>;
  total_monthly_pension?: Maybe<Scalars['Float']>;
  total_monthly_rent?: Maybe<Scalars['Float']>;
  total_yearly_consumer_loan?: Maybe<Scalars['Float']>;
  total_yearly_cost?: Maybe<Scalars['Float']>;
  total_yearly_housing_loan?: Maybe<Scalars['Float']>;
  total_yearly_others?: Maybe<Scalars['Float']>;
  total_yearly_pension?: Maybe<Scalars['Float']>;
  total_yearly_rent?: Maybe<Scalars['Float']>;
};

export type PersonalFinanceCostInput = {
  amount_others?: InputMaybe<Scalars['Float']>;
  amount_pension?: InputMaybe<Scalars['Float']>;
  amount_rent?: InputMaybe<Scalars['Float']>;
  consumer_loan?: InputMaybe<Array<PersonalFinanceDataTypeCostInput>>;
  housing_loan?: InputMaybe<Array<PersonalFinanceDataTypeCostInput>>;
  total_monthly_consumer_loan?: InputMaybe<Scalars['Float']>;
  total_monthly_cost?: InputMaybe<Scalars['Float']>;
  total_monthly_housing_loan?: InputMaybe<Scalars['Float']>;
  total_monthly_others?: InputMaybe<Scalars['Float']>;
  total_monthly_pension?: InputMaybe<Scalars['Float']>;
  total_monthly_rent?: InputMaybe<Scalars['Float']>;
  total_yearly_consumer_loan?: InputMaybe<Scalars['Float']>;
  total_yearly_cost?: InputMaybe<Scalars['Float']>;
  total_yearly_housing_loan?: InputMaybe<Scalars['Float']>;
  total_yearly_others?: InputMaybe<Scalars['Float']>;
  total_yearly_pension?: InputMaybe<Scalars['Float']>;
  total_yearly_rent?: InputMaybe<Scalars['Float']>;
};

export type PersonalFinanceDataTypeCost = {
  __typename?: 'PersonalFinanceDataTypeCost';
  end_date?: Maybe<Scalars['DateTime']>;
  loan_amount?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  remaining_loan?: Maybe<Scalars['Float']>;
};

export type PersonalFinanceDataTypeCostInput = {
  end_date?: InputMaybe<Scalars['DateTime']>;
  loan_amount?: InputMaybe<Scalars['Float']>;
  monthly_amount?: InputMaybe<Scalars['Float']>;
  remaining_loan?: InputMaybe<Scalars['Float']>;
};

export type PersonalFinanceDefaultDataType = {
  __typename?: 'PersonalFinanceDefaultDataType';
  amount?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PersonalFinanceDefaultDataTypeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PersonalFinanceIncome = {
  __typename?: 'PersonalFinanceIncome';
  amount_financial_products?: Maybe<Scalars['Float']>;
  amount_real_estate?: Maybe<Scalars['Float']>;
  amount_wages?: Maybe<Scalars['Float']>;
  number_real_estate?: Maybe<Scalars['Float']>;
  others?: Maybe<Array<PersonalFinanceDefaultDataType>>;
  total_financial_products?: Maybe<Scalars['Float']>;
  total_monthly_income?: Maybe<Scalars['Float']>;
  total_monthly_wages?: Maybe<Scalars['Float']>;
  total_others?: Maybe<Scalars['Float']>;
  total_real_estate?: Maybe<Scalars['Float']>;
  total_yearly_income?: Maybe<Scalars['Float']>;
  total_yearly_wages?: Maybe<Scalars['Float']>;
};

export type PersonalFinanceIncomeInput = {
  amount_financial_products?: InputMaybe<Scalars['Float']>;
  amount_real_estate?: InputMaybe<Scalars['Float']>;
  amount_wages?: InputMaybe<Scalars['Float']>;
  number_real_estate?: InputMaybe<Scalars['Float']>;
  others?: InputMaybe<Array<PersonalFinanceDefaultDataTypeInput>>;
  total_financial_products?: InputMaybe<Scalars['Float']>;
  total_monthly_income?: InputMaybe<Scalars['Float']>;
  total_monthly_wages?: InputMaybe<Scalars['Float']>;
  total_others?: InputMaybe<Scalars['Float']>;
  total_real_estate?: InputMaybe<Scalars['Float']>;
  total_yearly_income?: InputMaybe<Scalars['Float']>;
  total_yearly_wages?: InputMaybe<Scalars['Float']>;
};

export type PersonalFinanceOutput = {
  __typename?: 'PersonalFinanceOutput';
  cost?: Maybe<PersonalFinanceCost>;
  income?: Maybe<PersonalFinanceIncome>;
  savings?: Maybe<PersonalFinanceSavings>;
  taxes?: Maybe<PersonalFinanceTaxes>;
};

export type PersonalFinanceSavings = {
  __typename?: 'PersonalFinanceSavings';
  amount_available?: Maybe<Scalars['Float']>;
  amount_invested?: Maybe<Scalars['Float']>;
  total_available?: Maybe<Scalars['Float']>;
  total_invested?: Maybe<Scalars['Float']>;
  total_monthly_savings?: Maybe<Scalars['Float']>;
  total_yearly_savings?: Maybe<Scalars['Float']>;
};

export type PersonalFinanceSavingsInput = {
  amount_available?: InputMaybe<Scalars['Float']>;
  amount_invested?: InputMaybe<Scalars['Float']>;
  total_available?: InputMaybe<Scalars['Float']>;
  total_invested?: InputMaybe<Scalars['Float']>;
  total_monthly_savings?: InputMaybe<Scalars['Float']>;
  total_yearly_savings?: InputMaybe<Scalars['Float']>;
};

export type PersonalFinanceTaxes = {
  __typename?: 'PersonalFinanceTaxes';
  amount_housing_taxe?: Maybe<Scalars['Float']>;
  amount_renting_taxe?: Maybe<Scalars['Float']>;
  amount_taxe?: Maybe<Scalars['Float']>;
  total_housing_taxe?: Maybe<Scalars['Float']>;
  total_monthly_taxes?: Maybe<Scalars['Float']>;
  total_renting_taxe?: Maybe<Scalars['Float']>;
  total_taxe?: Maybe<Scalars['Float']>;
  total_yearly_taxes?: Maybe<Scalars['Float']>;
};

export type PersonalFinanceTaxesInput = {
  amount_housing_taxe?: InputMaybe<Scalars['Float']>;
  amount_renting_taxe?: InputMaybe<Scalars['Float']>;
  amount_taxe?: InputMaybe<Scalars['Float']>;
  total_housing_taxe?: InputMaybe<Scalars['Float']>;
  total_monthly_taxes?: InputMaybe<Scalars['Float']>;
  total_renting_taxe?: InputMaybe<Scalars['Float']>;
  total_taxe?: InputMaybe<Scalars['Float']>;
  total_yearly_taxes?: InputMaybe<Scalars['Float']>;
};

export type ProfessionNeededInput = {
  electricity?: InputMaybe<Scalars['Boolean']>;
  electricityPrice?: InputMaybe<Scalars['Float']>;
  elevation?: InputMaybe<Scalars['Boolean']>;
  elevationPrice?: InputMaybe<Scalars['Float']>;
  facilities?: InputMaybe<Scalars['Boolean']>;
  facilitiesPrice?: InputMaybe<Scalars['Float']>;
  floors?: InputMaybe<Scalars['Boolean']>;
  floorsPrice?: InputMaybe<Scalars['Float']>;
  furnitureOnDemand?: InputMaybe<Scalars['Boolean']>;
  furnitureOnDemandPrice?: InputMaybe<Scalars['Float']>;
  plastering?: InputMaybe<Scalars['Boolean']>;
  plasteringPrice?: InputMaybe<Scalars['Float']>;
  plumbing?: InputMaybe<Scalars['Boolean']>;
  plumbingPrice?: InputMaybe<Scalars['Float']>;
  roofing?: InputMaybe<Scalars['Boolean']>;
  roofingPrice?: InputMaybe<Scalars['Float']>;
  walls?: InputMaybe<Scalars['Boolean']>;
  wallsPrice?: InputMaybe<Scalars['Float']>;
  woodWork?: InputMaybe<Scalars['Boolean']>;
  woodWorkPrice?: InputMaybe<Scalars['Float']>;
};

export type ProfessionNeededOutput = {
  __typename?: 'ProfessionNeededOutput';
  electricity?: Maybe<Scalars['Boolean']>;
  electricityPrice?: Maybe<Scalars['Float']>;
  elevation?: Maybe<Scalars['Boolean']>;
  elevationPrice?: Maybe<Scalars['Float']>;
  facilities?: Maybe<Scalars['Boolean']>;
  facilitiesPrice?: Maybe<Scalars['Float']>;
  floors?: Maybe<Scalars['Boolean']>;
  floorsPrice?: Maybe<Scalars['Float']>;
  furnitureOnDemand?: Maybe<Scalars['Boolean']>;
  furnitureOnDemandPrice?: Maybe<Scalars['Float']>;
  plastering?: Maybe<Scalars['Boolean']>;
  plasteringPrice?: Maybe<Scalars['Float']>;
  plumbing?: Maybe<Scalars['Boolean']>;
  plumbingPrice?: Maybe<Scalars['Float']>;
  roofing?: Maybe<Scalars['Boolean']>;
  roofingPrice?: Maybe<Scalars['Float']>;
  walls?: Maybe<Scalars['Boolean']>;
  wallsPrice?: Maybe<Scalars['Float']>;
  woodWork?: Maybe<Scalars['Boolean']>;
  woodWorkPrice?: Maybe<Scalars['Float']>;
};

export type Project = {
  __typename?: 'Project';
  _id: Scalars['ID'];
  appointments: Array<AppointmentDto>;
  appointmentsReminder: Array<AppointmentDto>;
  business_provider?: Maybe<Scalars['String']>;
  closedStatus?: Maybe<AllowedClosedStatus>;
  completion_rate?: Maybe<Scalars['Float']>;
  config?: Maybe<ProjectConfig>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer_source?: Maybe<AllowedCustomerSource>;
  customers?: Maybe<Array<CustomerOutput>>;
  documents: Array<DocumentsDto>;
  finance?: Maybe<Finance>;
  hotDeal?: Maybe<Scalars['Boolean']>;
  investmentMethod?: Maybe<Scalars['String']>;
  lastAppointmentType?: Maybe<AllowedAppointmentType>;
  matched?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nextAppointment?: Maybe<Scalars['DateTime']>;
  nextReminder?: Maybe<Scalars['DateTime']>;
  offer?: Maybe<AllowedOffer>;
  previousAppointment?: Maybe<Scalars['DateTime']>;
  reminderAlarmColor?: Maybe<Scalars['String']>;
  status?: Maybe<AllowedStatus>;
  totalReminder?: Maybe<Scalars['Float']>;
};

export type ProjectConfig = {
  __typename?: 'ProjectConfig';
  dateSignedContract?: Maybe<Scalars['DateTime']>;
  isDefaultProject?: Maybe<Scalars['Boolean']>;
  yousign_status?: Maybe<AllowedYousignStatus>;
};

export type ProjectFilters = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hotDeal?: InputMaybe<Scalars['Boolean']>;
  lastAppointmentType?: InputMaybe<AllowedAppointmentType>;
  matched?: InputMaybe<Scalars['Boolean']>;
  matching?: InputMaybe<Scalars['Boolean']>;
  offer?: InputMaybe<AllowedOffer>;
  prospection?: InputMaybe<Scalars['Boolean']>;
  sales?: InputMaybe<Scalars['String']>;
  salesReferral?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<AllowedStatus>>;
};

export type ProjectInput = {
  customers: Array<CustomerInput>;
};

export type ProjectInputFromCrm = {
  customers?: InputMaybe<Array<CustomerInputFromCrm>>;
};

export type ProjectOutputConnection = {
  __typename?: 'ProjectOutputConnection';
  edges?: Maybe<Array<ProjectOutputEdge>>;
  pageInfo?: Maybe<ProjectOutputPageInfo>;
};

export type ProjectOutputEdge = {
  __typename?: 'ProjectOutputEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Project>;
};

export type ProjectOutputPageInfo = {
  __typename?: 'ProjectOutputPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ProjectPaginated = {
  __typename?: 'ProjectPaginated';
  page: ProjectOutputConnection;
  pageData?: Maybe<PageDataDto>;
};

export type PropertiesFilters = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  hotDeal?: InputMaybe<Scalars['Boolean']>;
  matched?: InputMaybe<Scalars['Boolean']>;
  propertyOffer?: InputMaybe<AllowedOffer>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<AllowedPropertyState>;
  status?: InputMaybe<Array<AllowedPropertyStatus>>;
};

export type PropertiesPaginatedOutput = {
  __typename?: 'PropertiesPaginatedOutput';
  page: PropertyOutputConnection;
  pageData?: Maybe<PageDataDto>;
};

export type PropertyFinance = {
  __typename?: 'PropertyFinance';
  district_price_per_m2?: Maybe<Scalars['Float']>;
  expected_price?: Maybe<Scalars['Float']>;
  expected_price_per_m2?: Maybe<Scalars['Float']>;
  expected_price_per_m2_after_renovation?: Maybe<Scalars['Float']>;
  inflation?: Maybe<Scalars['Float']>;
  inflation_price?: Maybe<Scalars['Float']>;
  lsi_fees?: Maybe<Scalars['Float']>;
  lsi_profitability?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  notary_fees?: Maybe<Scalars['Float']>;
  property_cash_flow?: Maybe<CashFlowProperty>;
  property_selling_fees?: Maybe<Scalars['Float']>;
  total_budget?: Maybe<Scalars['Float']>;
};

export type PropertyFinanceInput = {
  district_price_per_m2?: InputMaybe<Scalars['Float']>;
  expected_price?: InputMaybe<Scalars['Float']>;
  expected_price_per_m2?: InputMaybe<Scalars['Float']>;
  expected_price_per_m2_after_renovation?: InputMaybe<Scalars['Float']>;
  inflation?: InputMaybe<Scalars['Float']>;
  inflation_price?: InputMaybe<Scalars['Float']>;
  lsi_fees?: InputMaybe<Scalars['Float']>;
  lsi_profitability?: InputMaybe<Scalars['Float']>;
  monthly_income?: InputMaybe<Scalars['Float']>;
  notary_fees?: InputMaybe<Scalars['Float']>;
  property_cash_flow?: InputMaybe<CashFlowPropertyInput>;
  property_selling_fees?: InputMaybe<Scalars['Float']>;
  total_budget?: InputMaybe<Scalars['Float']>;
};

export type PropertyInput = {
  ad_link?: InputMaybe<Array<Scalars['String']>>;
  arrangement?: InputMaybe<ArrangementInput>;
  closedStatus?: InputMaybe<AllowedPropertyClosedStatus>;
  conclusion?: InputMaybe<ConclusionInput>;
  description?: InputMaybe<DescriptionInput>;
  finance?: InputMaybe<PropertyFinanceInput>;
  general_information?: InputMaybe<GeneralInformationInput>;
  hotDeal?: InputMaybe<Scalars['Boolean']>;
  localisation: LocalisationInput;
  realEstateAgency?: InputMaybe<Scalars['ID']>;
  realEstateAgent?: InputMaybe<Scalars['ID']>;
  renovation?: InputMaybe<RenovationInput>;
  status?: InputMaybe<AllowedPropertyStatus>;
  type: AllowedPropertyType;
};

export type PropertyLogsOutput = {
  __typename?: 'PropertyLogsOutput';
  _id?: Maybe<Scalars['ID']>;
  action: ResultUnion;
  createdAt: Scalars['String'];
  property?: Maybe<PropertyOutput>;
  user?: Maybe<CreateUserOutput>;
};

export type PropertyOutput = {
  __typename?: 'PropertyOutput';
  _id: Scalars['ID'];
  ad_link?: Maybe<Array<Scalars['String']>>;
  arrangement?: Maybe<Arrangement>;
  closedStatus?: Maybe<AllowedPropertyClosedStatus>;
  conclusion?: Maybe<Conclusion>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Description>;
  documents: Array<DocumentsPropertyDto>;
  finance?: Maybe<PropertyFinance>;
  general_information?: Maybe<GeneralInformation>;
  hotDeal?: Maybe<Scalars['Boolean']>;
  localisation: LocalisationOutput;
  matched?: Maybe<Scalars['Boolean']>;
  priceHubbleId?: Maybe<Scalars['JSON']>;
  project?: Maybe<Project>;
  propertyDocuments: Array<DocumentsPropertyDto>;
  propertyPictureDocuments?: Maybe<Array<DocumentsPropertyDto>>;
  realEstateAgency?: Maybe<RealEstateAgencyOutput>;
  realEstateAgent?: Maybe<CreateRealEstateAgentOutput>;
  renovation?: Maybe<RenovationOutput>;
  status?: Maybe<AllowedPropertyStatus>;
  type: AllowedPropertyType;
  user_id: User;
};

export type PropertyOutputConnection = {
  __typename?: 'PropertyOutputConnection';
  edges?: Maybe<Array<PropertyOutputEdge>>;
  pageInfo?: Maybe<PropertyOutputPageInfo>;
};

export type PropertyOutputEdge = {
  __typename?: 'PropertyOutputEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PropertyOutput>;
};

export type PropertyOutputPageInfo = {
  __typename?: 'PropertyOutputPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  findAllBusinessProviders: Array<BusinessProvider>;
  /** @deprecated Deprecated */
  findAllClient: UsersPaginated;
  findAllCustomers: CustomerOutput;
  findClientById: User;
  findUserLoggedIn: User;
  getAgencyById: CreateRealEstateAgency;
  getAllProjects: ProjectPaginated;
  getAllProperties: PropertiesPaginatedOutput;
  getAllPropertiesByUser: PropertiesPaginatedOutput;
  getAllRealEstateAgencies: Array<CreateRealEstateAgency>;
  getEmailsSentFromCustomerIO: Array<EmailCustomerIo>;
  getKanban: KanbanDto;
  getLogsByProject: Array<LogsOutput>;
  getLogsByProperty: Array<PropertyLogsOutput>;
  getLogsByUser: Array<LogsOutput>;
  getMatchingById: MatchingOutput;
  getMatchingListByProjectId: Array<MatchingOutput>;
  getMatchingListByPropertyId: Array<MatchingOutput>;
  getProjectById?: Maybe<Project>;
  getProjectsByCurrentUser: Array<Project>;
  getProjectsByIds?: Maybe<Array<Project>>;
  getProjectsByUser?: Maybe<Array<Project>>;
  getPropertiesById: PropertyOutput;
  getSearch: SearchOutput;
  getUserJourneyBeforeAccountCreation: Array<AnalyticUserJourneyBeforeAccountCreationOutput>;
};


export type QueryFindAllClientArgs = {
  args: ConnectionArgs;
  filters?: InputMaybe<UsersFiltersDto>;
};


export type QueryFindClientByIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetAgencyByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAllProjectsArgs = {
  args: ConnectionArgs;
  filters?: InputMaybe<ProjectFilters>;
};


export type QueryGetAllPropertiesArgs = {
  args: ConnectionArgs;
  filters?: InputMaybe<PropertiesFilters>;
};


export type QueryGetAllPropertiesByUserArgs = {
  args: ConnectionArgs;
  filters?: InputMaybe<PropertiesFilters>;
};


export type QueryGetEmailsSentFromCustomerIoArgs = {
  email: Scalars['String'];
};


export type QueryGetKanbanArgs = {
  data: KanbanTypeInput;
};


export type QueryGetLogsByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetLogsByPropertyArgs = {
  propertyId: Scalars['String'];
};


export type QueryGetLogsByUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetMatchingByIdArgs = {
  matchingId: Scalars['String'];
};


export type QueryGetMatchingListByProjectIdArgs = {
  projectId: Scalars['String'];
};


export type QueryGetMatchingListByPropertyIdArgs = {
  propertyId: Scalars['String'];
};


export type QueryGetProjectByIdArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectsByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGetProjectsByUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetPropertiesByIdArgs = {
  propertyId: Scalars['String'];
};


export type QueryGetSearchArgs = {
  searchQuery: Scalars['String'];
};


export type QueryGetUserJourneyBeforeAccountCreationArgs = {
  userId: Scalars['String'];
};

export type QuestionsAndAnswer = {
  __typename?: 'QuestionsAndAnswer';
  answer?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  question?: Maybe<Scalars['String']>;
};

export type RealEstateAgencyOutput = {
  __typename?: 'RealEstateAgencyOutput';
  _id?: Maybe<Scalars['String']>;
  realEstateAgencyAddress?: Maybe<Scalars['String']>;
  realEstateAgencyName?: Maybe<Scalars['String']>;
};

export type RenovationInput = {
  furniture_cost?: InputMaybe<Scalars['Float']>;
  professionNeeded?: InputMaybe<ProfessionNeededInput>;
  renovationDuration?: InputMaybe<Scalars['Float']>;
  renovation_cost?: InputMaybe<Scalars['Float']>;
};

export type RenovationOutput = {
  __typename?: 'RenovationOutput';
  furniture_cost?: Maybe<Scalars['Float']>;
  professionNeeded?: Maybe<ProfessionNeededOutput>;
  renovationDuration?: Maybe<Scalars['Float']>;
  renovation_cost?: Maybe<Scalars['Float']>;
};

export type ResultUnion = CommentOutput | OfferOutput | StatusOutput;

export type SearchOutput = {
  __typename?: 'SearchOutput';
  projects: Scalars['JSON'];
  properties: Scalars['JSON'];
};

export type StatusOutput = {
  __typename?: 'StatusOutput';
  status: Scalars['String'];
};

export type StreetAddress = {
  __typename?: 'StreetAddress';
  address_line_1: Scalars['String'];
  address_line_2?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  zipcode: Scalars['String'];
};

export type StreetAddressInput = {
  address_line_1: Scalars['String'];
  address_line_2?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  locality?: InputMaybe<Scalars['String']>;
  region: Scalars['String'];
  zipcode: Scalars['String'];
};

export type TransportationInput = {
  releaseDate?: InputMaybe<Scalars['String']>;
  transportation?: InputMaybe<AllowedTransportation>;
  transportation_detail?: InputMaybe<Scalars['String']>;
  walking_time?: InputMaybe<Scalars['Float']>;
};

export type TransportationOutput = {
  __typename?: 'TransportationOutput';
  releaseDate?: Maybe<Scalars['String']>;
  transportation?: Maybe<AllowedTransportation>;
  transportation_detail?: Maybe<Scalars['String']>;
  walking_time?: Maybe<Scalars['Float']>;
};

export type TypeformInfo = {
  __typename?: 'TypeformInfo';
  budget?: Maybe<Scalars['String']>;
  employeeIncome?: Maybe<Scalars['String']>;
  employeesIncomes?: Maybe<Scalars['String']>;
  employment?: Maybe<Scalars['String']>;
  freelanceIncome?: Maybe<Scalars['String']>;
  freelanceIncomes?: Maybe<Scalars['String']>;
  groupSavings?: Maybe<Scalars['String']>;
  investor?: Maybe<Scalars['String']>;
  offer?: Maybe<Scalars['String']>;
  recorded?: Maybe<Scalars['Boolean']>;
  referralCode?: Maybe<Scalars['String']>;
  savings?: Maybe<Scalars['String']>;
  timing?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};

export type TypeformInfoInput = {
  budget?: InputMaybe<Scalars['String']>;
  employeeIncome?: InputMaybe<Scalars['String']>;
  employeesIncomes?: InputMaybe<Scalars['String']>;
  employment?: InputMaybe<Scalars['String']>;
  freelanceIncome?: InputMaybe<Scalars['String']>;
  freelanceIncomes?: InputMaybe<Scalars['String']>;
  groupSavings?: InputMaybe<Scalars['String']>;
  investor?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  savings?: InputMaybe<Scalars['String']>;
  timing?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerPersonalInfosInput = {
  birthdate?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  employer_name?: InputMaybe<Scalars['String']>;
  employment_contract?: InputMaybe<AllowedEmploymentContract>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<AllowedGender>;
  is_married?: InputMaybe<Scalars['Boolean']>;
  job?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  main_bank?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  number_of_children?: InputMaybe<Scalars['Float']>;
  other_banks?: InputMaybe<Array<Scalars['String']>>;
  phone?: InputMaybe<Scalars['String']>;
  place_of_birth?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<AllowedSeniority>;
  street_address?: InputMaybe<StreetAddressInput>;
  wedding_plan?: InputMaybe<AllowedWeddingPlan>;
};

export type UpdateFinanceForCustomer = {
  cost?: InputMaybe<PersonalFinanceCostInput>;
  customerId?: InputMaybe<Scalars['String']>;
  income?: InputMaybe<PersonalFinanceIncomeInput>;
  savings?: InputMaybe<PersonalFinanceSavingsInput>;
  taxes?: InputMaybe<PersonalFinanceTaxesInput>;
};

export type UpdateMatchingInput = {
  state: AllowedMatchingState;
};

export type UpdateProjectInput = {
  appointmentDate?: InputMaybe<AppointmentDtoInput>;
  appointmentDateReminder?: InputMaybe<Scalars['DateTime']>;
  business_provider?: InputMaybe<Scalars['String']>;
  closedStatus?: InputMaybe<AllowedClosedStatus>;
  customer_source?: InputMaybe<AllowedCustomerSource>;
  customers?: InputMaybe<Array<UpdateFinanceForCustomer>>;
  hotDeal?: InputMaybe<Scalars['Boolean']>;
  investmentMethod?: InputMaybe<AllowedInvestmentMethod>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdatePropertyDocumentInput = {
  _id: Scalars['ID'];
  isPreview: Scalars['Boolean'];
};

export type UpdatePropertyInput = {
  ad_link?: InputMaybe<Array<Scalars['String']>>;
  arrangement?: InputMaybe<ArrangementInput>;
  closedStatus?: InputMaybe<AllowedPropertyClosedStatus>;
  conclusion?: InputMaybe<ConclusionInput>;
  description?: InputMaybe<DescriptionInput>;
  document?: InputMaybe<UpdatePropertyDocumentInput>;
  finance?: InputMaybe<PropertyFinanceInput>;
  general_information?: InputMaybe<GeneralInformationInput>;
  hotDeal?: InputMaybe<Scalars['Boolean']>;
  localisation?: InputMaybe<LocalisationInput>;
  realEstateAgency?: InputMaybe<Scalars['ID']>;
  realEstateAgent?: InputMaybe<Scalars['ID']>;
  renovation?: InputMaybe<RenovationInput>;
  status?: InputMaybe<AllowedPropertyStatus>;
  type?: InputMaybe<AllowedPropertyType>;
};

export type UpdateRealEstateAgentInput = {
  _id: Scalars['String'];
  realEstateAgencyAddress?: InputMaybe<Scalars['String']>;
  realEstateAgencyName?: InputMaybe<Scalars['String']>;
  realEstateAgents?: InputMaybe<CreateRealEstateAgentInput>;
};

export type UpdateUserInput = {
  account_type?: InputMaybe<AllowedAccountType>;
  appointmentDate?: InputMaybe<Scalars['DateTime']>;
  appointmentDateReminder?: InputMaybe<Scalars['DateTime']>;
  calendlyName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  family_name?: InputMaybe<Scalars['String']>;
  first_event_taken?: InputMaybe<Scalars['Boolean']>;
  given_name?: InputMaybe<Scalars['String']>;
  isCustomerAlready?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  referralCall?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_metadata?: InputMaybe<UserMetaData>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['String']>;
  account_type?: Maybe<AllowedAccountType>;
  calendlyEvents: Array<CalendlyEventDto>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  first_event_taken?: Maybe<Scalars['Boolean']>;
  isCustomerAlready: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  projects: Array<Maybe<Scalars['String']>>;
  referralCall: Scalars['Boolean'];
  referralCode: Scalars['String'];
  referralProgramAvailable?: Maybe<Scalars['Boolean']>;
  referrerId?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  typeformInfo?: Maybe<TypeformInfo>;
  user_id?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<UserEdge>>;
  pageInfo?: Maybe<UserPageInfo>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<User>;
};

export type UserPageInfo = {
  __typename?: 'UserPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type UsersFiltersDto = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  first_event_taken?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UsersPaginated = {
  __typename?: 'UsersPaginated';
  page: UserConnection;
  pageData?: Maybe<PageDataDto>;
};

export type UserInput = {
  account_type: AllowedAccountType;
  email: Scalars['String'];
  email_verified: Scalars['Boolean'];
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  picture: Scalars['String'];
  tenant: Scalars['String'];
  user_id: Scalars['String'];
  user_metadata?: InputMaybe<UserMetaData>;
};

export type UserMetaData = {
  phone?: InputMaybe<Scalars['String']>;
};

export type GetAllProjectsQueryVariables = Exact<{
  args: ConnectionArgs;
  filters?: InputMaybe<ProjectFilters>;
}>;


export type GetAllProjectsQuery = { __typename?: 'Query', getAllProjects: { __typename?: 'ProjectPaginated', page: { __typename?: 'ProjectOutputConnection', edges?: Array<{ __typename?: 'ProjectOutputEdge', cursor?: string | null, node?: { __typename?: 'Project', _id: string, name?: string | null, offer?: AllowedOffer | null, status?: AllowedStatus | null, matched?: boolean | null, createdAt?: any | null, totalReminder?: number | null, nextAppointment?: any | null, nextReminder?: any | null, hotDeal?: boolean | null, previousAppointment?: any | null, lastAppointmentType?: AllowedAppointmentType | null, reminderAlarmColor?: string | null, customers?: Array<{ __typename?: 'CustomerOutput', _id?: string | null }> | null, config?: { __typename?: 'ProjectConfig', isDefaultProject?: boolean | null } | null, createdBy?: { __typename?: 'User', _id?: string | null, first_event_taken?: boolean | null, isCustomerAlready: boolean, phoneNumber?: string | null, email?: string | null, typeformInfo?: { __typename?: 'TypeformInfo', offer?: string | null, investor?: string | null, timing?: string | null } | null, calendlyEvents: Array<{ __typename?: 'CalendlyEventDto', name?: string | null }> } | null, finance?: { __typename?: 'Finance', total_max_budget?: number | null, loan_budget_recommended?: number | null } | null } | null }> | null, pageInfo?: { __typename?: 'ProjectOutputPageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null }, pageData?: { __typename?: 'PageDataDto', count: number, limit: number, offset: number } | null } };

export type GetAllPropertiesQueryVariables = Exact<{
  args: ConnectionArgs;
  filters?: InputMaybe<PropertiesFilters>;
}>;


export type GetAllPropertiesQuery = { __typename?: 'Query', getAllProperties: { __typename?: 'PropertiesPaginatedOutput', page: { __typename?: 'PropertyOutputConnection', edges?: Array<{ __typename?: 'PropertyOutputEdge', cursor?: string | null, node?: { __typename?: 'PropertyOutput', _id: string, matched?: boolean | null, status?: AllowedPropertyStatus | null, ad_link?: Array<string> | null, createdAt: any, hotDeal?: boolean | null, project?: { __typename?: 'Project', _id: string, name?: string | null } | null, description?: { __typename?: 'Description', surface?: number | null, upfront_price?: number | null } | null, finance?: { __typename?: 'PropertyFinance', expected_price?: number | null, total_budget?: number | null } | null, conclusion?: { __typename?: 'Conclusion', property_offer?: AllowedOffer | null } | null, localisation: { __typename?: 'LocalisationOutput', street_address?: { __typename?: 'StreetAddress', address_line_1: string, address_line_2?: string | null, country: string, locality?: string | null, region: string, zipcode: string } | null } } | null }> | null, pageInfo?: { __typename?: 'PropertyOutputPageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null }, pageData?: { __typename?: 'PageDataDto', count: number, limit: number, offset: number } | null } };


export const GetAllProjectsDocument = `
    query GetAllProjects($args: ConnectionArgs!, $filters: ProjectFilters) {
  getAllProjects(args: $args, filters: $filters) {
    page {
      edges {
        cursor
        node {
          _id
          name
          offer
          status
          matched
          createdAt
          totalReminder
          nextAppointment
          nextReminder
          hotDeal
          previousAppointment
          lastAppointmentType
          reminderAlarmColor
          customers {
            _id
          }
          config {
            isDefaultProject
          }
          createdBy {
            _id
            first_event_taken
            isCustomerAlready
            phoneNumber
            email
            typeformInfo {
              offer
              investor
              timing
            }
            calendlyEvents {
              name
            }
          }
          finance {
            total_max_budget
            loan_budget_recommended
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
    pageData {
      count
      limit
      offset
    }
  }
}
    `;
export const useGetAllProjectsQuery = <
      TData = GetAllProjectsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: GetAllProjectsQueryVariables,
      options?: UseQueryOptions<GetAllProjectsQuery, TError, TData>
    ) =>
    useQuery<GetAllProjectsQuery, TError, TData>(
      ['GetAllProjects', variables],
      fetcher<GetAllProjectsQuery, GetAllProjectsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetAllProjectsDocument, variables),
      options
    );

useGetAllProjectsQuery.getKey = (variables: GetAllProjectsQueryVariables) => ['GetAllProjects', variables];
;

export const useInfiniteGetAllProjectsQuery = <
      TData = GetAllProjectsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      pageParamKey: keyof GetAllProjectsQueryVariables,
      variables: GetAllProjectsQueryVariables,
      options?: UseInfiniteQueryOptions<GetAllProjectsQuery, TError, TData>
    ) =>
    useInfiniteQuery<GetAllProjectsQuery, TError, TData>(
      ['GetAllProjects.infinite', variables],
      (metaData) => fetcher<GetAllProjectsQuery, GetAllProjectsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetAllProjectsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );


useInfiniteGetAllProjectsQuery.getKey = (variables: GetAllProjectsQueryVariables) => ['GetAllProjects.infinite', variables];
;

export const GetAllPropertiesDocument = `
    query GetAllProperties($args: ConnectionArgs!, $filters: PropertiesFilters) {
  getAllProperties(args: $args, filters: $filters) {
    page {
      edges {
        cursor
        node {
          _id
          matched
          status
          ad_link
          createdAt
          project {
            _id
            name
          }
          hotDeal
          description {
            surface
            upfront_price
          }
          finance {
            expected_price
            total_budget
          }
          conclusion {
            property_offer
          }
          localisation {
            street_address {
              address_line_1
              address_line_2
              country
              locality
              region
              zipcode
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
    pageData {
      count
      limit
      offset
    }
  }
}
    `;
export const useGetAllPropertiesQuery = <
      TData = GetAllPropertiesQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: GetAllPropertiesQueryVariables,
      options?: UseQueryOptions<GetAllPropertiesQuery, TError, TData>
    ) =>
    useQuery<GetAllPropertiesQuery, TError, TData>(
      ['GetAllProperties', variables],
      fetcher<GetAllPropertiesQuery, GetAllPropertiesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetAllPropertiesDocument, variables),
      options
    );

useGetAllPropertiesQuery.getKey = (variables: GetAllPropertiesQueryVariables) => ['GetAllProperties', variables];
;

export const useInfiniteGetAllPropertiesQuery = <
      TData = GetAllPropertiesQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      pageParamKey: keyof GetAllPropertiesQueryVariables,
      variables: GetAllPropertiesQueryVariables,
      options?: UseInfiniteQueryOptions<GetAllPropertiesQuery, TError, TData>
    ) =>
    useInfiniteQuery<GetAllPropertiesQuery, TError, TData>(
      ['GetAllProperties.infinite', variables],
      (metaData) => fetcher<GetAllPropertiesQuery, GetAllPropertiesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetAllPropertiesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    );


useInfiniteGetAllPropertiesQuery.getKey = (variables: GetAllPropertiesQueryVariables) => ['GetAllProperties.infinite', variables];
;
