import {
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult, QueryObserverSuccessResult,
} from '@tanstack/react-query';
import { GetProjectByIdQuery, useGetProjectByIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetProjectById = (projectId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo:
    | QueryObserverLoadingErrorResult<GetProjectByIdQuery, unknown>
    | QueryObserverLoadingResult<GetProjectByIdQuery, unknown>
    | QueryObserverRefetchErrorResult<GetProjectByIdQuery, unknown>
    | QueryObserverSuccessResult<GetProjectByIdQuery, unknown> = useGetProjectByIdQuery(
      headerWithAuth,
      {
        projectId: projectId || '',
      },
      { enabled: !!accessToken && !!projectId },
    );

  return queryInfo;
};

export { useGetProjectById };
