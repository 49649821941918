import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button, ButtonGroup, Grid, Typography, alpha, Divider,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { AddOrRemoveField } from '../../../../common/inputs/addorremovefield';
import { DisplayValue } from '../../pages';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';

interface IFormInput {
  bedroom_number: number
  living_room_number: number
  dining_room_number: number
  bathroom_number: number
  shower_room_number: number
  kitchen_number: number
  laundry_room_number: number
  office_number: number
  water_closet_number: number
}

function Arrangement({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [toModifyArrangement, setToModifyArrangement] = useState(false);
  const [searchParams] = useSearchParams();

  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return data?.arrangement;
    }, [data, searchParams.get('propertyId')]),
  });

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'floors',
  });

  const defaultValue = {
    bedroom_number: 0,
    living_room_number: 0,
    dining_room_number: 0,
    bathroom_number: 0,
    shower_room_number: 0,
    kitchen_number: 0,
    laundry_room_number: 0,
    office_number: 0,
    water_closet_number: 0,
  };

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultValue);
    }
  }, [fields]);

  useEffect(() => {
    reset(data?.arrangement);
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        arrangement: {
          floors: [
            ...form.floors,
          ],
        },
      },
    };
    mutate({ ...formValid });
    setToModify(false);
  };

  const text = {
    title1: 'Pièce de vie',
    title2: 'Pièce d\'eau',
    title3: 'Autres',
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" marginBottom={2}>
      <Accordion
        elevation={0}
        sx={{
          width: '100%',
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px !important',
          ':before': {
            content: 'none',
          },
        }}
      >
        <AccordionSummary
          sx={{
            paddingX: 3,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            color="primary"
            variant="h5"
          >
            Aménagement
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          bgcolor: 'background.paper',
          borderRadius: '16px !important',
        }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {fields.map((item, indexField) => (
              <Box key={item + indexField.toString()} width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                { indexField > 0 && <Divider sx={{ marginY: 3, width: '90%' }} />}
                <Typography variant="h5" textAlign="center">{indexField === 0 ? '' : `Étage n°${indexField}`}</Typography>
                <Grid container item columns={3} spacing={3} marginTop={2}>
                  <Grid item xs={1} display="flex" flexDirection="column" alignItems="center">
                    <Typography marginBottom={1} width="50%" variant="h6" fontWeight="bold">{text.title1}</Typography>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].bedroom_number`}
                            watch={watch}
                            label="Chambre"
                          />
                        ) : (
                          // @ts-ignore
                          <DisplayValue title="Chambre" value={data?.arrangement?.floors[indexField]?.bedroom_number} />
                        )}
                    </Box>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].living_room_number`}
                            watch={watch}
                            label="Salon"
                          />
                        ) : (<DisplayValue title="Salon" value={data?.arrangement?.floors[indexField]?.living_room_number} />)}
                    </Box>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].dining_room_number`}
                            watch={watch}
                            label="Salle à manger"
                          />
                        ) : (<DisplayValue title="Salle à manger" value={data?.arrangement?.floors[indexField]?.dining_room_number} />)}
                    </Box>
                  </Grid>
                  <Grid item xs={1} display="flex" flexDirection="column" alignItems="center">
                    <Typography marginBottom={1} width="50%" variant="h6" fontWeight="bold">{text.title2}</Typography>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].bathroom_number`}
                            watch={watch}
                            label="Salle de bain"
                          />
                        ) : (
                      // @ts-ignore
                          <DisplayValue title="Salle de bain" value={data?.arrangement?.floors[indexField]?.bathroom_number} />
                        )}
                    </Box>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].shower_room_number`}
                            watch={watch}
                            label="Salle d’eau"
                          />
                        ) : (<DisplayValue title="Salle d’eau" value={data?.arrangement?.floors[indexField]?.shower_room_number} />)}
                    </Box>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].kitchen_number`}
                            watch={watch}
                            label="Cuisine"
                          />
                        ) : (<DisplayValue title="Cuisine" value={data?.arrangement?.floors[indexField]?.kitchen_number} />)}
                    </Box>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].water_closet_number`}
                            watch={watch}
                            label="WC"
                          />
                        ) : (<DisplayValue title="WC" value={data?.arrangement?.floors[indexField]?.water_closet_number} />)}
                    </Box>
                  </Grid>
                  <Grid item xs={1} display="flex" flexDirection="column" alignItems="center">
                    <Typography marginBottom={1} width="50%" variant="h6" fontWeight="bold">{text.title3}</Typography>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].laundry_room_number`}
                            watch={watch}
                            label="Buanderie"
                          />
                        ) : (
                          // @ts-ignore
                          <DisplayValue title="Buanderie" value={data?.arrangement?.floors[indexField]?.laundry_room_number} />
                        )}
                    </Box>
                    <Box paddingY={1} width="50%">
                      {toModify
                        ? (
                          <AddOrRemoveField
                            control={control}
                            name={`floors[${indexField}].office_number`}
                            watch={watch}
                            label="Bureau"
                          />
                        ) : (<DisplayValue title="Bureau" value={data?.arrangement?.floors[indexField]?.office_number} />)}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
            {toModify
              ? (
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                  <Box>
                    <Button
                      sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={() => setToModify(false)}
                      color="error"
                      type="reset"
                      variant="contained"
                      endIcon={<CancelIcon />}
                    >
                      Annuler
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ fontWeight: 'bold', textTransform: 'none' }}
                      endIcon={<SaveIcon />}
                    >
                      Enregistrer
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" justifyContent="flex-end">
                  {fields.length > 0
                    && (
                    <Button
                      onClick={() => setToModify(true)}
                      variant="outlined"
                      sx={{ fontWeight: 'bold', textTransform: 'none' }}
                      endIcon={<EditIcon />}
                    >
                      Modifier
                    </Button>
                    )}
                </Box>
              )}
          </Box>
        </AccordionDetails>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          }}
          borderRadius="0 0 16px 16px"
          p={2}
        >
          {!toModifyArrangement ? (
            <Button
              onClick={() => setToModifyArrangement(true)}
              variant="outlined"
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
              endIcon={<EditIcon />}
            >
              Ajouter ou supprimer un étage
            </Button>
          ) : (
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box display="flex" justifyContent="center" alignItems="center">
                <ButtonGroup
                  sx={{ marginLeft: 1, display: fields?.length <= 1 ? 'none' : 'inherit' }}
                  color="error"
                >
                  {fields?.map((e, i) => (
                    <Button
                      key={e?.id}
                      disabled={i === 0}
                      onClick={() => remove(i)}
                      endIcon={i > 0 && <DeleteForeverIcon />}
                    >
                      {i === 0 ? 'Suprimer étage :' : `n°${i}`}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>
              <Box>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModifyArrangement(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<AddCircleIcon />}
                  color="primary"
                  onClick={() => append(defaultValue)}
                >
                  Ajouter un étage
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Accordion>
    </Box>
  );
}

export {
  Arrangement,
};
