import { useGetLogsByProjectQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetLogsByProject = (projectId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();

  const queryInfo = useGetLogsByProjectQuery(headerWithAuth, {
    projectId: projectId || '',
  }, { enabled: !!accessToken });

  return queryInfo;
};

export {
  useGetLogsByProject,
};
