import { useFindClientByIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useFindClientById = (userId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();

  const queryInfo = useFindClientByIdQuery(headerWithAuth, {
    userId: userId || '',
  }, { enabled: !!accessToken && !!userId });

  return queryInfo;
};

export {
  useFindClientById,
};
