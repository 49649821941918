import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { addYears, eachYearOfInterval } from 'date-fns';

export const CashflowNextTenYearsTable = ({ headers, rows }: { headers: string[], rows: number[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers?.map((header: string, index: number) => (
              <TableCell key={`${index}-${header}`}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {rows?.map((row: number, index: number) => (
              <TableCell component="th" scope="row" key={`${index}-${row}`}>
                {`${row.toFixed(2)} €`}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const LineGraphNextTenYears = ({
  year,
  income,
  outcome,
  monthlyLoanBudget,
  outcomeValues,
  totalBudget,
  equityContribution,
  interestRate,
  loanDelay,
  inflationValue = 1,
  isYearly,
}: any) => {
  const YEARS_AMOUNT = 9;
  const [labels, setLabels] = useState<any>([]);
  const [cashFlowData, setCashFlowData] = useState<any>([]);

  const createArrayYears = (date: Date) => {
    const arrayYears = eachYearOfInterval({
      start: new Date(date),
      end: addYears(new Date(date), YEARS_AMOUNT),
    }).map((_: Date, index) => `Année ${index + 1}`);
    setLabels(arrayYears);
  };

  const createArrayCashFlow = () => {
    const inflation = 1 + Number(inflationValue);
    const cashflow: number[] = [];
    let cumulatedYearly: number = 0;
    const cumulatedYearlyValues: number[] = [];
    const prevIncomes: number[] = [];

    labels.forEach((_: number, index: number) => {
      if (index === 0) {
        let c = income - outcome;
        if (loanDelay && loanDelay > 0) {
          c = income - ((((totalBudget - equityContribution) * interestRate) / 12) + outcomeValues?.monthly_real_estate_agency_fees + outcomeValues?.monthly_insurance_fees + outcomeValues?.monthly_property_tax + outcomeValues?.monthly_landlord_fees);
        }
        cashflow.push(c);
        cumulatedYearly = c;
        cumulatedYearlyValues.push(cumulatedYearly * 12);
        prevIncomes.push(income);
      } else {
        const incomeVal = prevIncomes[prevIncomes.length - 1] * inflation;
        let defaultMonthlyLoan = outcomeValues.monthlyLoanBudgetTmp;
        // @ts-ignore
        if ((loanDelay === 24 && index <= 1) || (loanDelay === 36 && index <= 2)) {
          defaultMonthlyLoan = (((totalBudget - equityContribution) * interestRate) / 12);
        }
        const outcomeValueTmp = defaultMonthlyLoan + (outcomeValues?.monthly_real_estate_agency_fees * (inflation ** index)) + outcomeValues?.monthly_insurance_fees + (outcomeValues?.monthly_property_tax * (inflation ** index)) + (outcomeValues?.monthly_landlord_fees * (inflation ** index));
        cumulatedYearly += (incomeVal * (inflation ** index)) - outcomeValueTmp;
        prevIncomes.push(incomeVal);

        cashflow.push(cumulatedYearly / (index + 1));
        cumulatedYearlyValues.push(cumulatedYearlyValues[cumulatedYearlyValues.length - 1] + ((cumulatedYearly / (index + 1)) * 12));
      }
    });

    setCashFlowData(isYearly ? cumulatedYearlyValues : cashflow);
  };

  useEffect(() => {
    if (!year || !income || !outcome) return;
    createArrayYears(year);
    createArrayCashFlow();
  }, [year, income, outcome, monthlyLoanBudget, loanDelay]);

  return (
    <CashflowNextTenYearsTable rows={cashFlowData} headers={labels} />
  );
};
