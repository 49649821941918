import {
  Box, IconButton, List, ListItemText, Switch, TextField, Tooltip, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';
import { AsYouType, parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { SectionTitle } from '../../../../common/right-column/section-title';
import { useUpdateUser } from '../../../../hooks/mutation/update-user-mutation.hook';

function CalendlyInfo({ queryInfo }: any) {
  const [editName, setEditName] = useState<boolean>(false);
  const [calendlyName, setCalendlyName] = useState<string>('');

  // eslint-disable-next-line no-unsafe-optional-chaining
  const calendlyData = queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents[queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents?.length - 1];

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateUser({
    onSuccess: () => {
      queryInfo.refetch();
      setCalendlyName('');
      setEditName(false);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const phoneNumber = queryInfo?.data?.getProjectById?.createdBy?.phoneNumber;
  const parsedPhoneNumber = phoneNumber && isValidPhoneNumber(phoneNumber) ? parsePhoneNumber(phoneNumber) : null;

  return (
    <Box>
      <SectionTitle title="infos contact" />
      {phoneNumber
        && (
          <List sx={{ padding: 0 }}>
            <ListItemText
              primary={<Typography variant="body2" fontWeight="500">Téléphone</Typography>}
              secondary={(
                <Typography align="left" variant="body2" color="text.secondary">
                  <Box
                    component="a"
                    target="_blank"
                    href={`tel: ${phoneNumber}`}
                    rel="noreferrer"
                    sx={{
                      color: 'inherit',
                    }}
                  >
                    {new AsYouType(parsedPhoneNumber?.country || 'FR')?.input(phoneNumber)}
                  </Box>
                </Typography>
              )}
            />
          </List>
        )}
      {queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents && queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents?.length > 0
        && (
          <List sx={{ padding: 0 }}>
            <ListItemText
              secondaryTypographyProps={{ component: 'div' }}
              primary={<Typography variant="body2" fontWeight="500">Nom</Typography>}
              secondary={(
                <Box display="flex" alignItems="center">
                  <Typography align="left" variant="body2" color="text.secondary">
                    {calendlyData?.name ? calendlyData?.name : '...'}
                  </Typography>
                  <Tooltip title={!editName ? 'Editer nom calendly' : 'Annuler'}>
                    <IconButton
                      onClick={() => {
                        setEditName(!editName);
                      }}
                      size="small"
                      sx={{ marginLeft: 1 }}
                    >
                      {!editName ? <EditIcon fontSize="inherit" /> : <EditOffIcon fontSize="inherit" />}
                    </IconButton>
                  </Tooltip>
                  {editName
                    && (
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <TextField
                          size="small"
                          label="Nouveau nom"
                          value={calendlyName}
                          onChange={(e) => {
                            setCalendlyName(e.target.value);
                          }}
                          sx={{ marginLeft: 1 }}
                        />
                        <Tooltip title="Enregistrer nouveau nom calendly">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              const formValid: any = {
                                data: {
                                  calendlyName,
                                },
                                userId: queryInfo?.data?.getProjectById?.createdBy?._id,
                              };
                              mutate({ ...formValid });
                            }}
                            size="small"
                            sx={{ marginLeft: 1 }}
                          >
                            <SaveIcon
                              fontSize="inherit"
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                </Box>
              )}
            />
          </List>
        )}
      <Box display="flex" alignItems="center">
        {queryInfo?.data?.getProjectById?.createdBy?.first_event_taken
          ? (
            <EventAvailableIcon
              fontSize="small"
              color="primary"
            />
          ) : (
            <EventBusyIcon
              fontSize="small"
              color="inherit"
            />
          )}
        <Typography
          color="warning"
          variant="body2"
          fontWeight="500"
          display="flex"
          alignItems="center"
          marginRight={2}
          marginLeft={1}
        >
          Rendez-vous
        </Typography>
        <Switch
          size="small"
          checked={queryInfo?.data?.getProjectById?.createdBy?.first_event_taken}
          onChange={(e, value) => {
            const formValid: any = {
              data: {
                first_event_taken: value,
              },
              userId: queryInfo?.data?.getProjectById?.createdBy?._id,
            };
            mutate({ ...formValid });
          }}
        />

      </Box>
      {queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents && queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents?.length > 0
        && (
          <List sx={{ padding: 0 }}>
            <ListItemText
              primary={<Typography variant="body2" fontWeight="500">Date de rdv</Typography>}
              secondary={(
                <Typography align="left" variant="body2" color="text.secondary">
                  {calendlyData?.start_time ? format(new Date(calendlyData?.start_time), 'dd/MM/yyyy HH:mm') : '...'}
                </Typography>
              )}
            />
          </List>
        )}
      {queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents && queryInfo?.data?.getProjectById?.createdBy?.calendlyEvents?.length > 0
        && (
          <List sx={{ width: '100%', padding: 0 }}>
            <ListItemText
              primary={<Typography variant="body2" fontWeight="500">Réponse Calendly</Typography>}
              secondary={(
                <Typography align="left" variant="body2" color="text.secondary">
                  {calendlyData?.questions_and_answers[0]?.answer ? calendlyData?.questions_and_answers[0]?.answer : '...'}
                </Typography>
              )}
            />
          </List>
        )}
    </Box>
  );
}

export {
  CalendlyInfo,
};
