import {
  Box, Button, Chip, Grid, Slider, TextField, Typography, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import rentabilite from '../../../../assets/property/little_icon/rentabilite.png';
import rentabilite_white from '../../../../assets/property-white/little-icon/rentabilite_white.png';
import prix from '../../../../assets/property/little_icon/price.png';
import prix_white from '../../../../assets/property-white/little-icon/price_white.png';
import prix_a_negocier from '../../../../assets/property/little_icon/prix_a_negocier.png';
import prix_a_negocier_white from '../../../../assets/property-white/little-icon/prix_a_negocier_white.png';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { euro } from '../../../../common/inputs/format/euro-format';
import { NumberFormatCustom } from '../../../../common/inputs/format/number-format-custom';

function SimulationContainer({ queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const [lsiExpectedPriceProfitability, setLsiExpectedPriceProfitability] = useState<any>(queryInfo?.data?.getPropertiesById?.finance?.lsi_profitability);
  const [lsiTotalBudgetProfitability, setLsiTotalBudgetProfitability] = useState<any>(lsiExpectedPriceProfitability);
  const [expectedPrice, setExpectedPrice] = useState<any>(queryInfo?.data?.getPropertiesById?.finance?.expected_price);
  const [inflation, setInflation] = useState<any>(queryInfo?.data?.getPropertiesById?.finance?.inflation * 100);
  const [inflation_price, setInflationPrice] = useState<any>(queryInfo?.data?.getPropertiesById?.finance?.inflation_price * 100);
  const [toModify, setToModify] = useState<any>(false);
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const rent = !queryInfo?.data?.getPropertiesById?.description?.expected_rent
    || queryInfo?.data?.getPropertiesById?.description?.expected_rent === 0
    ? queryInfo?.data?.getPropertiesById?.description?.current_rent
    : queryInfo?.data?.getPropertiesById?.description?.expected_rent;

  const tax = queryInfo?.data?.getPropertiesById?.description?.tax;

  const furniture = queryInfo?.data?.getPropertiesById?.renovation?.furniture_cost;
  const renov = queryInfo?.data?.getPropertiesById?.renovation?.renovation_cost;

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const deltaVar = ((queryInfo?.data?.getPropertiesById?.description?.upfront_price - queryInfo?.data?.getPropertiesById?.finance?.expected_price) / queryInfo?.data?.getPropertiesById?.description?.upfront_price * 100).toFixed(1);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
        <Typography color="primary" variant="h5" marginBottom={2}>Simulation financière</Typography>
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="40px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <img
              src={isDarkTheme ? rentabilite_white : rentabilite}
              width="25px"
              style={{ objectFit: 'contain', marginRight: 15 }}
            />
            <Typography fontWeight="bold" variant="h6">
              Rentabilité
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              textAlign="right"
              fontWeight="bold"
              variant="h6"
              sx={{ color: lsiTotalBudgetProfitability?.toFixed(1) >= 5 ? 'success.main' : 'secondary.main' }}
            >
              {`${lsiTotalBudgetProfitability?.toFixed(1)}%`}
            </Typography>
          </Grid>
        </Grid>
        <Box height="60px">
          <Slider
            step={0.1}
            value={lsiExpectedPriceProfitability}
            onChange={(e, value) => {
              setLsiExpectedPriceProfitability(value);
              const expectedPriceTmp = ((rent * 12) - tax) * 100 / (lsiExpectedPriceProfitability ?? 1);
              const notaryFees = expectedPriceTmp * 0.07;
              const lsiFees = (expectedPriceTmp + furniture + renov) * 0.12;
              const total = expectedPriceTmp + furniture + renov + notaryFees + lsiFees;
              const LsiTotalBudgetProfitabilityTmp = ((rent * 12) - tax) * 100 / (total ?? 1);
              setExpectedPrice(expectedPriceTmp);
              setLsiTotalBudgetProfitability(LsiTotalBudgetProfitabilityTmp);
            }}
            min={2}
            max={12}
          />
        </Box>
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="40px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <img
              src={isDarkTheme ? rentabilite_white : rentabilite}
              width="25px"
              style={{ objectFit: 'contain', marginRight: 15 }}
            />
            <Typography fontWeight="bold" variant="h6">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Indexation des loyers
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              textAlign="right"
              fontWeight="bold"
              variant="h6"
              sx={{ color: lsiTotalBudgetProfitability?.toFixed(1) >= 5 ? 'success.main' : 'secondary.main' }}
            >
              {`${inflation?.toFixed(1)}%`}
            </Typography>
          </Grid>
        </Grid>
        <Box height="60px">
          <Slider
            step={0.1}
            value={inflation}
            onChange={(e, value) => {
              setInflation(value);
            }}
            min={0}
            max={10}
          />
        </Box>
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="40px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <img
              src={isDarkTheme ? rentabilite_white : rentabilite}
              width="25px"
              style={{ objectFit: 'contain', marginRight: 15 }}
            />
            <Typography fontWeight="bold" variant="h6">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Évolution des prix
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              textAlign="right"
              fontWeight="bold"
              variant="h6"
              sx={{ color: lsiTotalBudgetProfitability?.toFixed(1) >= 5 ? 'success.main' : 'secondary.main' }}
            >
              {`${inflation_price?.toFixed(1)}%`}
            </Typography>
          </Grid>
        </Grid>
        <Box height="60px">
          <Slider
            step={0.1}
            value={inflation_price}
            onChange={(e, value) => {
              setInflationPrice(value);
            }}
            min={0}
            max={10}
          />
        </Box>
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="60px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <img src={isDarkTheme ? prix_white : prix} width="25px" style={{ objectFit: 'contain', marginRight: 15 }} />
            <Typography fontWeight="bold" variant="h6">
              Prix affiché
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              textAlign="right"
              fontWeight="bold"
              variant="h6"
              color="primary"
            >
              {euro.format(queryInfo?.data?.getPropertiesById?.description?.upfront_price)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="60px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <img
              src={isDarkTheme ? prix_a_negocier_white : prix_a_negocier}
              width="25px"
              style={{ objectFit: 'contain', marginRight: 15 }}
            />
            <Typography fontWeight="bold" variant="h6">
              Prix à négocier
            </Typography>
            <Chip
              sx={{ marginLeft: 1 }}
              size="small"
              label={`${Math.abs(Number(deltaVar))}% ${Number(deltaVar) >= 0 ? 'Moins cher' : 'Plus cher'}`}
            />
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            {toModify
              ? (
                <Box
                  width="310px"
                  flexDirection="row"
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <TextField
                    value={`${expectedPrice}` || ''}
                    sx={{ width: '150px' }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      setExpectedPrice(Number(e.target.value));
                    }}
                  />
                  <Button
                    sx={{
                      width: '150px', textTransform: 'none', height: '40px',
                    }}
                    variant="outlined"
                    onClick={() => {
                      const notaryFees = expectedPrice * 0.07;
                      const lsiFees = (expectedPrice + furniture + renov) * 0.12;
                      const lsiExpectedPriceProfit = ((rent * 12) - tax) * 100 / (expectedPrice ?? 1);
                      const total = expectedPrice + furniture + renov + notaryFees + lsiFees;
                      const totalBudgetProfitability = ((rent * 12) - tax) * 100 / (total ?? 1);
                      setLsiExpectedPriceProfitability(lsiExpectedPriceProfit);
                      setLsiTotalBudgetProfitability(totalBudgetProfitability);
                      setToModify(false);
                    }}
                  >
                    Enregistrer
                  </Button>
                </Box>
              ) : (
                <Typography
                  textAlign="right"
                  fontWeight="bold"
                  variant="h6"
                  sx={{ color: lsiTotalBudgetProfitability?.toFixed(1) >= 5 ? 'success.main' : 'secondary.main' }}
                >
                  {euro.format(expectedPrice)}
                </Typography>
              )}
          </Grid>
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          marginTop={2}
          justifyContent="flex-end"
        >
          <Button
            onClick={() => (toModify ? setToModify(false) : setToModify(true))}
            variant="outlined"
            color={toModify ? 'error' : 'primary'}
            sx={{ textTransform: 'none', marginRight: toModify ? 0 : 1 }}
          >
            {toModify ? 'Annuler' : 'Modifier le prix à négocier'}
          </Button>
          {!toModify
            && (
              <Button
                variant="contained"
                disabled={Number(queryInfo?.data?.getPropertiesById?.finance?.expected_price) === Number(expectedPrice) && Number(queryInfo?.data?.getPropertiesById?.finance?.inflation) === Number(inflation)
                  && Number(queryInfo?.data?.getPropertiesById?.finance?.inflation_price) === Number(inflation_price)}
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  const formValid: any = {
                    propertyId: searchParams.get('propertyId'),
                    data: {
                      finance: {
                        expected_price: Number(expectedPrice),
                        inflation: Number(inflation) / 100,
                        inflation_price: Number(inflation_price) / 100,
                      },
                    },
                  };
                  mutate({ ...formValid });
                }}
              >
                Enregistrer
              </Button>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  SimulationContainer,
};
