import React from 'react';
import {
  Box, Switch, Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import { SectionTitle } from '../../../../common/right-column/section-title';
import { useUpdateUser } from '../../../../hooks/mutation/update-user-mutation.hook';

function Referral({ queryInfo }: any) {
  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateUser({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  return (
    <Box>
      <SectionTitle title="parrainage" />
      <Box display="flex" flexDirection="column">

        <Box display="flex" marginBottom={1} alignItems="center">
          <VpnKeyIcon
            fontSize="small"
          />
          <Typography
            variant="body2"
            fontWeight="500"
            display="flex"
            alignItems="center"
            marginRight={2}
            marginLeft={1}
          >
            {`Code de parrainage : ${queryInfo?.data?.getProjectById?.createdBy?.referralCode}`}
          </Typography>
        </Box>
        {queryInfo?.data?.getProjectById?.matched
        && (
        <Box display="flex" alignItems="center">
          <PhoneCallbackIcon
            fontSize="small"
            color={queryInfo?.data?.getProjectById?.createdBy?.referralCall ? 'success' : 'inherit'}
          />
          <Typography
            variant="body2"
            fontWeight="500"
            display="flex"
            alignItems="center"
            marginRight={2}
            marginLeft={1}
          >
            Appel de présentation
          </Typography>
          <Switch
            checked={queryInfo?.data?.getProjectById?.createdBy?.referralCall}
            size="small"
            onChange={(e, value) => {
              const formValid: any = {
                data: {
                  referralCall: value,
                },
                userId: queryInfo?.data?.getProjectById?.createdBy?._id,
              };
              mutate({ ...formValid });
            }}
          />
        </Box>
        )}
      </Box>
    </Box>
  );
}

export {
  Referral,
};
