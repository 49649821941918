import React from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import { TextField, TextFieldProps } from '@mui/material';

function CalendarFilter({
  value, disabled, onChange, label, width,
}: any) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={frLocale}
    >
      <DesktopDatePicker
        value={value}
        disabled={disabled}
        onChange={onChange}
        label={label}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...params}
            fullWidth
            size="small"
            sx={{ width }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export {
  CalendarFilter,
};
