import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { alpha } from '@mui/material/styles';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import FlareIcon from '@mui/icons-material/Flare';
import StarRateIcon from '@mui/icons-material/StarRate';
import CancelIcon from '@mui/icons-material/Cancel';
import { offersOptions } from '../../../../../data/data';
import { AllowedOffer } from '../../../../../api';

function DisplayOffer({ data, index, queryInfoLogs }: any) {
  const text = 'a modifié l\'offre en';

  // eslint-disable-next-line no-shadow
  const chooseIcon = (data: any) => {
    switch (data) {
      case AllowedOffer.INELIGIBLE:
        return <CancelIcon />;
      case AllowedOffer.ETINCELLE:
        return <FlareIcon />;
      case AllowedOffer.ENVOL:
        return <AirplanemodeActiveIcon />;
      case AllowedOffer.ETOILE:
        return <StarRateIcon />;
      default:
        return <CancelIcon />;
    }
  };

  return (
    <Box
      bgcolor="background.paper"
      borderRadius="16px"
      paddingY={2}
      paddingX={3}
      marginY={3}
      alignItems="center"
      justifyContent="space-between"
      display="flex"
      position="relative"
    >
      <Box>
        <Box
          sx={{
            bgcolor: (theme) => alpha(theme.palette.warning.main, 0.2),
          }}
          height="100%"
          width="10px"
          position="absolute"
          top={0}
          left={0}
          borderRadius="16px 0 0 16px "
        />
        <Typography variant="body2" color="text.secondary">
          <Text>{data.user.email}</Text>
          {' '}
          {text}
          {' '}
          <Highlight>{offersOptions.find((f) => f.value === data?.action?.offer)?.label}</Highlight>
          {' '}
          le
          {' '}
          <Text>
            {data.createdAt && format(new Date(Number(data.createdAt)), 'dd MMMM yyyy', { locale: fr })}
          </Text>
        </Typography>
      </Box>
      <Box color="primary.main">
        {chooseIcon(data?.action?.offer)}
      </Box>
      <Box
        bgcolor="divider"
        width="2px"
        height="24px"
        position="absolute"
        top="100%"
        left={80}
        display={index + 1 === queryInfoLogs?.data?.getLogsByProject?.length ? 'none' : 'inherit'}
      />
    </Box>
  );
}

const Highlight = styled('strong')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.warning.main, 0.2),
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  paddingRight: 2,
  paddingLeft: 2,
  borderRadius: 5,
}));

const Text = styled('strong')(({ theme }) => ({
  fontWeight: 'bold',
}));

export {
  DisplayOffer,
};
