import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { alpha } from '@mui/material/styles';
import AutorenewIcon from '@mui/icons-material/Autorenew';

function DisplayStatus({
  label, mail, createdAt, display,
}: any) {
  return (
    <Box
      bgcolor="background.paper"
      borderRadius="16px"
      paddingY={2}
      paddingX={3}
      marginY={3}
      alignItems="center"
      justifyContent="space-between"
      display="flex"
      position="relative"
    >
      <Box>
        <Box
          sx={{
            bgcolor: (theme) => alpha(theme.palette.success.main, 0.2),
          }}
          height="100%"
          width="10px"
          position="absolute"
          top={0}
          left={0}
          borderRadius="16px 0 0 16px "
        />
        <Typography variant="body2" color="text.secondary">
          <Text>{mail}</Text>
          {' '}
          a modifié le statut en
          {' '}
          <Highlight>{label}</Highlight>
          {' '}
          le
          {' '}
          <Text>
            {createdAt}
          </Text>
        </Typography>
      </Box>
      <Box color="primary.main">
        <AutorenewIcon />
      </Box>
      <Box
        bgcolor="divider"
        width="2px"
        height="24px"
        position="absolute"
        top="100%"
        left={80}
        display={display}
      />
    </Box>
  );
}

const Highlight = styled('strong')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.success.main, 0.2),
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  paddingRight: 2,
  paddingLeft: 2,
  borderRadius: 5,
}));

const Text = styled('strong')(({ theme }) => ({
  fontWeight: 'bold',
}));

export {
  DisplayStatus,
};
