import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { format } from 'date-fns';
import { DisplayValue } from '../../pages';
import {
  collectiveOwnershipWorksOptions,
  landlordFeesOptions,
} from '../../../../data/data';
import { AutocompleteCustom } from '../../../../common/inputs/autocompletecustom';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { SwitchCustom } from '../../../../common/inputs/switchcustom';
import { PercentageTextField } from '../../../../common/inputs/percentagetextfield';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { euro } from '../../../../common/inputs/format/euro-format';

interface IFormInput {
  type_landlord_fees: []
  landlord_fees: string
  landlord_surface_rate: string
  building_stories_number: string
  building_year: string
  shared_flat_available: string
  planned_renovation_work: string
  collective_ownership_works: string
  planned_renovation_price: string
}

function GeneralInformation({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();

  const formatted = {
    ...data?.general_information,
    type_landlord_fees: data?.general_information?.type_landlord_fees
      ?.map((e: string) => landlordFeesOptions?.find((elem) => elem.value === e))
      || [],
    collective_ownership_works: collectiveOwnershipWorksOptions.find((f) => f.value === data?.general_information?.collective_ownership_works),
  };

  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return { ...formatted };
    }, [data, searchParams.get('propertyId')]),
  });

  useEffect(() => {
    reset({ ...formatted });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        general_information: {
          type_landlord_fees: form?.type_landlord_fees?.map((e: any) => e?.value),
          landlord_fees: Number(form?.landlord_fees),
          landlord_surface_rate: Number(form?.landlord_surface_rate),
          building_stories_number: Number(form?.building_stories_number),
          building_year: Number(form?.building_year),
          shared_flat_available: form?.shared_flat_available,
          planned_renovation_work: form?.planned_renovation_work,
          collective_ownership_works: form?.collective_ownership_works?.value,
          planned_renovation_price: form?.planned_renovation_price,
        },
      },
    };
    Object.keys(formValid.data?.general_information).forEach((key) => {
      if (formValid.data?.general_information[key] === undefined || formValid.data?.general_information[key] === null || Number.isNaN(formValid.data?.general_information[key])) {
        delete formValid.data?.general_information[key];
      }
    });
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
          <Typography color="primary" variant="h5" marginBottom={2}>
            Informations sur la copropriété
          </Typography>
          <Grid container item columns={3} spacing={3}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      tab={landlordFeesOptions}
                      name="type_landlord_fees"
                      label="Contenu des charges de copropriété"
                      multiple
                    />
                  ) : (
                    <DisplayValue
                      title="Contenu des charges de copropriété"
                      // @ts-ignore
                      value={`${data?.general_information?.type_landlord_fees?.map((e: string) => landlordFeesOptions.find((elem) => elem.value === e)?.label)}`}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="landlord_fees"
                      label="Charges annuelles de copropriété"
                    />
                  ) : (
                    <DisplayValue
                      title="Charges annuelles de copropriété"
                      value={data?.general_information?.landlord_fees ? euro.format(data?.general_information?.landlord_fees) : 0}
                    />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <PercentageTextField
                      control={control}
                      name="landlord_surface_rate"
                      label="Quote part du lot"
                    />
                  ) : (
                    // @ts-ignore
                    <DisplayValue
                      title="Quote part du lot"
                      value={data?.general_information?.landlord_surface_rate ? `${data?.general_information?.landlord_surface_rate} %` : 0}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <TextFieldCustom
                      control={control}
                      name="building_stories_number"
                      label="Nombre d'étages de l'immeuble"
                      type="number"
                      numberMax={100}
                    />
                  ) : (
                    <DisplayValue title="Nombre d'étages de l'immeuble" value={data?.general_information?.building_stories_number ? data?.general_information?.building_stories_number : '0'} />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <TextFieldCustom
                      control={control}
                      name="building_year"
                      label="Année de construction"
                      type="number"
                      numberMin={1700}
                      numberMax={Number(format(new Date(), 'yyyy'))}
                    />
                  ) : (
                    <DisplayValue title="Année de construction" value={data?.general_information?.building_year ? data?.general_information?.building_year : '0'} />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <SwitchCustom
                      control={control}
                      name="planned_renovation_work"
                      label="Travaux prévus dans la copropriété"
                    />
                  ) : (
                    <DisplayValue
                      title="Travaux prévus dans la copropriété"
                      value={data?.general_information?.planned_renovation_work ? data?.general_information?.planned_renovation_work ? 'Oui' : 'Non' : ''}
                    />
                  )}
              </Box>
              {
                // @ts-ignore
                watch('planned_renovation_work') === true
                && (
                <Box paddingY={1}>
                  {toModify
                    ? (
                      <AutocompleteCustom
                        control={control}
                        tab={collectiveOwnershipWorksOptions}
                        name="collective_ownership_works"
                        label="Travaux de copropriété"
                      />
                    ) : (
                      <DisplayValue
                        title="Travaux de copropriété"
                      // @ts-ignore
                        value={data?.general_information?.collective_ownership_works ? collectiveOwnershipWorksOptions.find((f: any) => f.value === data?.general_information?.collective_ownership_works).label : ''}
                      />
                    )}
                </Box>
                )
              }
              {
                // @ts-ignore
                watch('planned_renovation_work') === true
                && (
                  <Box paddingY={1}>
                    {toModify
                      ? (
                        <CurrencyTextFieldCustom
                          control={control}
                          name="planned_renovation_price"
                          label="Estimation prix des travaux copro"
                        />
                      ) : (
                        <DisplayValue
                          title="Estimation prix des travaux copro"
                          value={data?.general_information?.planned_renovation_price ? euro.format(data?.general_information?.planned_renovation_price) : 0}
                        />
                      )}
                  </Box>
                )
              }
              <Box paddingY={1}>
                {toModify
                  ? (
                    <SwitchCustom
                      control={control}
                      name="shared_flat_available"
                      label="Colocation possible dans le règlement de copropriété"
                    />
                  ) : (
                    <DisplayValue
                      title="Colocation possible dans le règlement de copropriété"
                      value={data?.general_information?.shared_flat_available ? data?.general_information?.shared_flat_available ? 'Oui' : 'Non' : ''}
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
          {toModify
            ? (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setToModify(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  GeneralInformation,
};
