import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

function AutocompleteFilter({
  multiple = false,
  disabled = false,
  value,
  onChange,
  options,
  label,
  width,
}: any) {
  return (
    <Autocomplete
      multiple={multiple}
      disabled={disabled}
      disablePortal
      value={value}
      onChange={onChange}
      options={options}
      renderInput={
        (params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            size="small"
            sx={{ width }}
          />
        )
      }
    />
  );
}

export {
  AutocompleteFilter,
};
