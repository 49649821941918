import { euro } from '../../../../../common/inputs/format/euro-format';
import {
  employmentContractOptions, seniorityMergedOptions,
} from '../../../../../data/data';

const data = (customers: any, dataById: any) => [{
  data: ['Profession', ...customers?.map((e: any) => e?.personal_info?.job || '')],
}, {
  data: ['Employeur', ...customers?.map((e: any) => e?.personal_info?.employer_name || '')],
}, {
  data: ['Contrat de travail', ...customers?.map((e: any) => employmentContractOptions?.find((f) => f.value === e?.personal_info?.employment_contract)?.label || '')],
}, {
  data: ['Ancienneté', ...customers?.map((e: any) => seniorityMergedOptions?.find((f) => f.value === e?.personal_info?.seniority)?.label || '')],
}, {
  data: ['Salaire ou CA après IR\n(si variations : moyenne sur 3 ans)', ...customers?.map((e: any) => euro.format(e?.personal_finance?.income?.total_yearly_wages) || 'jaune')],
}, {
  data: ['Salaire ou CA imposable\n(si variations : moyenne sur 3 ans)', ...customers?.map(() => '€' || '')],
}, {
  data: ['Revenu foncier\n(sur 1 seul conjoint si en commun)', ...customers?.map((e: any) => euro.format(e?.personal_finance?.income?.total_real_estate) || '')],
}, {
  data: ['Revenu financier\n(sur 1 seul conjoint si en commun)', ...customers?.map((e: any) => euro.format(e?.personal_finance?.income?.total_financial_products) || '')],
}, {
  data: ['Autre revenu\n(sur 1 seul conjoint si en commun)', ...customers?.map((e: any) => euro.format(e?.personal_finance?.income?.total_others) || '')],
}, {
  data: ['Total par emprunteur', ...customers?.map((e: any) => euro.format(e?.personal_finance?.income?.total_yearly_income) || '')],
}, {
  data: ['Total', ...customers?.map((e: any, i: number) => (i === customers.length - 1 ? euro.format(dataById?.finance?.total_all_yearly_income) : '' || ''))],
}];

const incomeFormatted = (customers: any, dataById: any) => data(customers, dataById).map((e, i) => {
  if (i === 3 || i === 2 || i === 4 || i === 5) {
    return (`
    <tr>
      ${e.data.map((elemData: any) => (`
      <td style="background-color: yellow">
        <p style="font-weight: normal;white-space: pre">${elemData}</p>
      </td>
    `).replaceAll(',', ''))}
    </tr>
  `).replaceAll(',', '');
  }
  return (`
    <tr>
      ${e.data.map((elemData: any) => (`
      <td >
        <p style="font-weight: normal;white-space: pre">${elemData}</p>
      </td>
    `).replaceAll(',', ''))}
    </tr>
  `).replaceAll(',', '');
});

export {
  incomeFormatted,
};
