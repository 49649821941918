import {
  Box, Button, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { red, yellow } from '@mui/material/colors';
import { alpha, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { offersOptions } from '../../../data/data';
import { AllowedStatus } from '../../../api';
import { euro } from '../../../common/inputs/format/euro-format';

function SalesDetails({ task, modal, chooseOffersColor }: any) {
  const navigate = useNavigate();
  const darkTheme = useTheme();
  const chooseSalesColor = (param: any) => {
    switch (param) {
      case 'red': {
        return darkTheme.palette.mode === 'dark' ? red[900] : alpha(red[50], 0.6);
      }
      case 'yellow': {
        return darkTheme.palette.mode === 'dark' ? yellow[900] : alpha(yellow[50], 0.6);
      }
      default: {
        return (theme: any) => theme?.palette?.background.paper;
      }
    }
  };
  const chooseDifferenceInDays = (days: any) => {
    if (!days) {
      return '⏱ Sans rendez-vous';
    }
    if (days === 0) {
      return '⭐ Aujourd‘hui';
    }
    if (days < 0) {
      return `⏱ Passé de ${Math.abs(days)} jours`;
    }
    return `⏱ Dans ${days} jours`;
  };

  return (
    <Box
      padding="8px"
      borderRadius="5px"
      bgcolor={modal ? 'background?.paper' : chooseSalesColor(task?.content?.appointment?.color)}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={0.5} width="100%">
        <Typography
          sx={{
            width: task.content.status === AllowedStatus.EN_ATTENTE_DE_BIEN ? '85%' : '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          variant="body2"
          fontWeight="bold"
          color="text.primary"
        >
          {task?.title}
        </Typography>
        {task.content.status === AllowedStatus.EN_ATTENTE_DE_BIEN
          && (
            <Box width="10%" display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body2">🏠</Typography>
            </Box>
          )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start" paddingY={0.5}>
        <Typography
          fontWeight="500"
          color="text.primary"
          variant="body2"
        >
          {`📅 ${task?.content?.appointment?.date ? format(new Date(task?.content?.appointment?.date), 'dd/MM/yyyy') : 'Sans rendez-vous'}`}
        </Typography>
      </Box>
      {task?.content?.appointment?.differenceInDays
        && (
          <Box display="flex" width="100%" justifyContent="flex-start" paddingY={0.5}>
            <Typography
              fontWeight="500"
              color="text.primary"
              variant="body2"
            >
              {chooseDifferenceInDays(task?.content?.appointment?.differenceInDays)}
            </Typography>
          </Box>
        )}
      {(task?.content?.finance?.loan_budget_recommended || task?.content?.finance?.total_max_budget)
        && (
          <Box display="flex" width="100%" justifyContent="flex-start" paddingY={0.5}>
            <Typography
              fontWeight="500"
              color="text.primary"
              variant="body2"
            >
              {task?.content?.finance?.loan_budget_recommended
                ? `🏦 ${euro.format(task?.content?.finance?.loan_budget_recommended)}`
                : `🏦 ${euro.format(task?.content?.finance?.total_max_budget)}`}
            </Typography>
          </Box>
        )}
      <Box display="flex" justifyContent="flex-start" paddingY={0.5}>
        <Typography
          fontWeight="bold"
          sx={{
            color: chooseOffersColor(task?.content?.offer),
          }}
          variant="body2"
        >
          {`💰 ${offersOptions?.find((f) => f.value === task?.content?.offer)?.label || 'Non défini'}`}
        </Typography>
      </Box>
      {modal
        && (
          <Box display="flex" justifyContent="center" alignItems="center" paddingY={1}>
            <Button
              onClick={() => navigate(`/project-list/projectcard?projectId=${task?.id}`)}
              disabled={!task?.id}
              sx={{ fontWeight: 'bold' }}
              color="secondary"
              fullWidth
              variant="contained"
            >
              Aller vers projet
            </Button>
          </Box>
        )}
    </Box>
  );
}

export {
  SalesDetails,
};
