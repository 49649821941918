import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Box, Button, Grid, Paper, Tooltip, Typography,
} from '@mui/material';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import FlareIcon from '@mui/icons-material/Flare';
import StarRateIcon from '@mui/icons-material/StarRate';
import RemoveIcon from '@mui/icons-material/Remove';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { AllowedOffer } from '../../../api';
import { appointmentTypeOptions, statusOptions } from '../../../data/data';
import { ProjectOutputEdge } from '../../../api/infinite_scroll_index';
import { HeaderList } from '../components/header-list';
import { useInfiniteGetAllProjects } from '../../../hooks/query/infinite-get-all-projects-query.hook';
import { euro } from '../../../common/inputs/format/euro-format';
import { Loader } from '../../../common/components/loader';

// eslint-disable-next-line no-shadow
export enum TimingString {
  ASAP = 'j’aimerais investir dès maintenant',
  SOON = 'je compte investir, mais pas tout de suite',
  UNKNOWN = 'je ne sais pas encore si je souhaite investir dans l’immobilier locatif'
}

function ProjectsList() {
  const [pageIndex, setPageIndex] = useState(0);
  const [rowPerPageOptions, setRowPerPageOptions] = useState<{ value: number, label: string }>({
    value: 100,
    label: '100',
  });
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const [queryParamsValue, setQueryParamsValue] = useState({});

  const freshFilter = Object?.fromEntries(Object?.entries(queryParamsValue)?.filter(([_, v]) => {
    // @ts-ignore
    return v !== null && v?.length !== 0;
  }));

  useEffect(() => {
    setPageIndex(0);
  }, [rowPerPageOptions]);

  const {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetAllProjects({
    args: { first: rowPerPageOptions?.value },
    filters: {
      ...freshFilter,
    },
  }, rowPerPageOptions);

  // eslint-disable-next-line no-shadow
  function createRow(data: ProjectOutputEdge[]) {
    return data && data?.map((e: any) => ({
      ...e?.node,
      id: e?.node?._id,
      email: e?.node?.createdBy?.email,
      typeformOffer: e?.node?.createdBy?.typeformInfo?.offer,
      calendlyName: e?.node?.createdBy?.calendlyEvents?.length > 0 && e?.node?.createdBy?.calendlyEvents[0]?.name,
      firstEventTaken: e?.node?.createdBy?.first_event_taken,
      status: statusOptions.find((f) => f.value === e?.node?.status)?.label,
      total_max_budget: Number(e?.node?.finance?.total_max_budget),
      loan_budget_recommended: Number(e?.node?.finance?.loan_budget_recommended),
      matched: e?.node?.matched,
      customers_number: Number(e?.node?.customers?.length),
      creation_date: new Date(e?.node?.createdAt),
      nextAppointment: e?.node?.nextAppointment ? new Date(e?.node?.nextAppointment) : '',
      previousAppointment: e?.node?.previousAppointment ? new Date(e?.node?.previousAppointment) : '',
      lastAppointmentType: e?.node?.lastAppointmentType,
      nextReminder: e?.node?.nextReminder ? new Date(e?.node?.nextReminder) : '',
      totalReminder: e?.node?.totalReminder ? e?.node?.totalReminder : '',
      phoneNumber: e?.node?.createdBy?.phoneNumber,
      profil: e?.node?.createdBy?.typeformInfo?.investor?.toLowerCase(),
      timing: e?.node?.createdBy?.typeformInfo?.timing?.toLowerCase(),
    }
    ));
  }

  const numberProjects = data?.pages[0]?.getAllProjects?.pageData?.count;

  function chooseIconForOffer(value: string) {
    switch (value) {
      case AllowedOffer.INELIGIBLE: {
        return <Tooltip title="Inéligible"><CancelIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ETINCELLE: {
        return <Tooltip title="Etincelle"><FlareIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ENVOL: {
        return <Tooltip title="Envol"><AirplanemodeActiveIcon fontSize="small" /></Tooltip>;
      }
      case AllowedOffer.ETOILE: {
        return <Tooltip title="Étoile"><StarRateIcon fontSize="small" /></Tooltip>;
      }
      default: {
        return <Tooltip title="Non-défini"><RemoveIcon fontSize="small" /></Tooltip>;
      }
    }
  }

  function chooseIconForTiming(value: string) {
    switch (value) {
      case TimingString.ASAP: {
        return (
          <Tooltip title={TimingString.ASAP}>
            <Typography
              fontWeight="bold"
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'>>>'}
            </Typography>
          </Tooltip>
        );
      }
      case TimingString.SOON: {
        return (
          <Tooltip title={TimingString.SOON}>
            <Typography
              fontWeight="bold"
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'>>'}
            </Typography>
          </Tooltip>
        );
      }
      case TimingString.UNKNOWN: {
        return (
          <Tooltip title={TimingString.UNKNOWN}>
            <Typography
              fontWeight="bold"
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'>'}
            </Typography>
          </Tooltip>
        );
      }
      default: {
        return (
          <Tooltip title="Non-défini">
            <Typography
              fontWeight="bold"
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              -
            </Typography>
          </Tooltip>
        );
      }
    }
  }

  function chooseInvestorProfil(value: string) {
    switch (value) {
      case 'oui': {
        return (
          <Tooltip title="Investisseur confirmé">
            <Typography
              fontWeight="bold"
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              🏛️
            </Typography>
          </Tooltip>
        );
      }
      default: {
        return (
          <Tooltip title="Primo-accédant">
            <Typography
              fontWeight="bold"
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              🏠
            </Typography>
          </Tooltip>
        );
      }
    }
  }

  function chooseColorName(value: string) {
    switch (value) {
      case 'isDefaultProject': {
        return (theme: any) => theme.palette.warning.main;
      }
      case 'warning': {
        return (theme: any) => theme.palette.warning.main;
      }
      case 'error': {
        return (theme: any) => theme.palette.error.main;
      }
      default: {
        return (theme: any) => theme.palette.primary.main;
      }
    }
  }

  const columns: GridColDef[] = [{
    field: 'name',
    headerName: 'Nom',
    width: 200,
    renderCell: (params) => {
      const parseName = (name: string) => {
        const defaultString = name;
        const stringTmp = defaultString.split('-');
        if (stringTmp[0] === 'DEFAULT' && stringTmp[1] === 'PROJECT') {
          return `✯ Nouveau-lead-${stringTmp[2]}`;
        }
        return '';
      };

      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Link
            style={{ textDecoration: 'none' }}
            to={`/project-list/projectcard?projectId=${params?.id}&customerId=${params.row.customers[0]?._id}`}
          >
            <Tooltip title={params.row.config.isDefaultProject ? parseName(params.value) : params.value}>
              <Typography
                fontWeight="bold"
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: chooseColorName(params?.row?.reminderAlarmColor),
                }}
              >
                {params.row?.hotDeal
                  && <LocalFireDepartmentIcon color="warning" sx={{ marginRight: 0.3, fontSize: 15 }} />}
                {params.row?.createdBy?.isCustomerAlready
                  && <LocalPoliceIcon color="warning" fontSize="small" sx={{ marginRight: 0.3, fontSize: 15 }} />}
                {params.row.config.isDefaultProject ? parseName(params.value) : params.value}
              </Typography>
            </Tooltip>
          </Link>
          <Typography
            variant="caption"
            fontWeight="bold"
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {params?.row?.email ? params?.row?.email : ''}
          </Typography>
        </Box>
      );
    },
  }, {
    field: 'profil',
    headerName: 'Profil',
    width: 30,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {chooseInvestorProfil(params.value)}
        </Box>
      );
    },
  }, {
    field: 'offer',
    headerName: 'Offre',
    width: 65,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {chooseIconForOffer(params.value)}
        </Box>
      );
    },
  }, {
    field: 'timing',
    headerName: 'Timing',
    width: 65,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {chooseIconForTiming(params.value)}
        </Box>
      );
    },
  }, {
    field: 'status',
    headerName: 'Statut',
    width: 150,
    renderCell: (params) => {
      return (
        <Box
          display="flex"
          sx={{
            width: '95%',
          }}
        >
          <Tooltip title={params?.value ? params?.value : ''}>
            <Typography
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              variant="caption"
            >
              {params?.value}
            </Typography>
          </Tooltip>
        </Box>
      );
    },
  }, {
    field: 'phoneNumber',
    headerName: 'Tél',
    width: 10,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <Box display="flex" justifyContent="center" width="100%">
            {params.value ? <PhoneIcon color="success" fontSize="small" />
              : <PhoneDisabledIcon color="error" fontSize="small" />}
          </Box>
        </Tooltip>
      );
    },
  }, {
    field: 'lastAppointmentType',
    headerName: 'Type',
    width: 100,
    renderCell: (params) => {
      return (
        <Box display="flex">
          <Typography variant="caption">
            {appointmentTypeOptions?.find((f) => f.value === params.value)?.label}
          </Typography>
        </Box>
      );
    },
  }, {
    field: 'total_max_budget',
    headerName: 'Budget',
    width: 110,
    type: 'number',
    renderCell: (params) => {
      return (
        <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center">
          <Typography variant="caption" color="primary">{params?.value ? euro.format(params?.value) : ''}</Typography>
          <Typography
            variant="caption"
            color="success.main"
          >
            {params?.row?.loan_budget_recommended ? euro.format(params?.row?.loan_budget_recommended) : ''}
          </Typography>
        </Box>
      );
    },
  }, {
    field: 'firstEventTaken',
    headerName: 'Rdv',
    width: 60,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {params.value === true ? <CheckIcon color="success" fontSize="small" />
            : <CloseIcon color="error" fontSize="small" />}
        </Box>
      );
    },
  }, {
    field: 'previousAppointment',
    headerName: 'D. Rdv',
    width: 75,
    type: 'date',
    renderCell: (params) => {
      return (
        <Box display="flex">
          <Typography variant="caption">
            {params?.value
              ? formatInTimeZone(
                // @ts-ignore
                new Date(params?.value),
                'Europe/Paris',
                'dd/MM/yyyy',
              ) : ''}
          </Typography>
        </Box>
      );
    },
  }, {
    field: 'nextAppointment',
    headerName: 'Pro. Rdv',
    width: 110,
    type: 'date',
    renderCell: (params) => {
      return (
        <Box display="flex">
          <Typography variant="caption">
            {params?.value
              ? formatInTimeZone(
                // @ts-ignore
                new Date(params?.value),
                'Europe/Paris',
                'dd/MM/yyyy HH:mm',
              ) : ''}
          </Typography>
        </Box>
      );
    },
  }, {
    field: 'nextReminder',
    headerName: 'Relance',
    width: 75,
    type: 'date',
    renderCell: (params) => {
      return (
        <Box display="flex">
          <Typography variant="caption">
            {params?.value
              ? formatInTimeZone(
                // @ts-ignore
                new Date(params?.value),
                'Europe/Paris',
                'dd/MM/yyyy',
              ) : ''}
          </Typography>
        </Box>
      );
    },
  }, {
    field: 'totalReminder',
    headerName: 'Nbre',
    width: 65,
    type: 'number',
  }, {
    field: 'matched',
    headerName: 'Match',
    width: 65,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {params.value === true ? <CheckIcon color="success" fontSize="small" />
            : <CloseIcon color="error" fontSize="small" />}
        </Box>
      );
    },
  }, {
    field: 'customers_number',
    headerName: 'Inv.',
    width: 40,
    type: 'number',
  }, {
    field: 'creation_date',
    headerName: 'Création',
    width: 75,
    type: 'date',
    renderCell: (params) => {
      return (
        <Box display="flex">
          <Typography variant="caption">
            {params?.value
              ? formatInTimeZone(
                // @ts-ignore
                new Date(params?.value),
                'Europe/Paris',
                'dd/MM/yyyy',
              ) : ''}
          </Typography>
        </Box>
      );
    },
  }, {
    field: 'typeformOffer',
    headerName: 'Typeform',
    width: 80,
    renderCell: (params) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          {chooseIconForOffer(params.value)}
        </Box>
      );
    },
  }, {
    field: 'calendlyName',
    headerName: 'Nom Calendly',
    width: 140,
    renderCell: (params) => {
      return (
        <Tooltip title={params?.value ? params?.value : ''}>
          <Typography
            fontWeight="400"
            variant="caption"
            color="text.primary"
          >
            {params?.value}
          </Typography>
        </Tooltip>
      );
    },
  }];

  // eslint-disable-next-line no-unsafe-optional-chaining
  const totalPages = data ? Math.trunc(data?.pages[0]?.getAllProjects.pageData.count / rowPerPageOptions?.value) : 0;

  return (
    <Grid container item columns={1} spacing={2} paddingX={2}>
      <HeaderList
        numberProjects={numberProjects}
        selectionModel={selectionModel}
        isSuccess={isSuccess}
        rowPerPageOptions={rowPerPageOptions}
        setRowPerPageOptions={setRowPerPageOptions}
        queryParamsValue={queryParamsValue}
        setQueryParamsValue={setQueryParamsValue}
        setPageIndex={setPageIndex}
      />
      <Grid item xs={1}>
        <Paper elevation={0} sx={{ borderRadius: '16px', padding: 2, width: '100%' }}>
          <Box sx={{ height: 'calc(100vh - 340px)', width: '100%' }}>
            {
              !isFetching && isSuccess
                ? (
                  <DataGrid
                    rows={createRow(data?.pages[pageIndex]?.getAllProjects?.page?.edges) || []}
                    columns={columns}
                    pageSize={rowPerPageOptions?.value}
                    hideFooter
                    onSelectionModelChange={(e: any) => {
                      setSelectionModel(e);
                    }}
                    loading={isFetching && isSuccess}
                    rowsPerPageOptions={[rowPerPageOptions?.value]}
                    checkboxSelection
                    sx={{
                      border: 'none',
                    }}
                  />
                ) : <Loader />
            }
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop={2}>
            <Button
              type="button"
              disabled={pageIndex === 0}
              size="small"
              startIcon={<ArrowBackIosIcon />}
              sx={{
                marginRight: 3,
                width: 150,
                textTransform: 'none',
              }}
              onClick={() => {
                setPageIndex(() => pageIndex - 1);
              }}
            >
              Précédent
            </Button>
            <Button
              disabled={data && pageIndex === totalPages}
              endIcon={<ArrowForwardIosIcon />}
              size="small"
              sx={{
                marginRight: 3,
                width: 150,
                textTransform: 'none',
              }}
              onClick={() => {
                if (hasNextPage) {
                  fetchNextPage();
                }
                setPageIndex(() => pageIndex + 1);
              }}
            >
              Suivant
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export {
  ProjectsList,
};
