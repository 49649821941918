import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { DisplayValue } from '../../pages';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { euro } from '../../../../common/inputs/format/euro-format';

interface IFormInput {
  furniture_cost: string
  renovation_cost: string
  renovationDuration: number
}

function RenovationItem({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();

  const professionNeeded = data?.renovation?.professionNeeded;

  const getAllprofessionNeededPrice = () => {
    const professionNeededArray = [
      professionNeeded?.electricityPrice || 0,
      professionNeeded?.elevationPrice || 0,
      professionNeeded?.facilitiesPrice || 0,
      professionNeeded?.floorsPrice || 0,
      professionNeeded?.furnitureOnDemandPrice || 0,
      professionNeeded?.plasteringPrice || 0,
      professionNeeded?.plumbingPrice || 0,
      professionNeeded?.roofingPrice || 0,
      professionNeeded?.wallsPrice || 0,
      professionNeeded?.woodWorkPrice || 0,
    ];

    return professionNeededArray.reduce((a, b) => {
      return a + b;
    });
  };

  const professionNeededPrice = getAllprofessionNeededPrice();
  const renovationCost = data?.renovation?.renovation_cost;

  const {
    control, reset, handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return {
        ...data?.renovation,
      };
    }, [data, searchParams.get('propertyId')]),
  });

  useEffect(() => {
    reset({
      ...data?.renovation,
    });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        renovation: {
          furniture_cost: Number(form?.furniture_cost),
          renovation_cost: Number(form?.renovation_cost),
          renovationDuration: Number(form?.renovationDuration),
        },
      },
    };
    Object.keys(formValid.data?.renovation).forEach((key) => {
      if (formValid.data?.renovation[key] === undefined || formValid.data?.renovation[key] === null || Number.isNaN(formValid.data?.renovation[key])) {
        delete formValid.data?.renovation[key];
      }
    });
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
          <Typography color="primary" variant="h5" marginBottom={2}>Travaux</Typography>
          <Grid container item columns={3} spacing={3}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <CurrencyTextFieldCustom
                      label="Prix des travaux"
                      control={control}
                      name="renovation_cost"
                    />
                  ) : (
                    <DisplayValue
                      title="Prix des travaux"
                      value={euro.format(data?.renovation?.renovation_cost) || 0}
                    >
                      {(professionNeededPrice !== renovationCost)
                        ? <ClearIcon sx={{ marginRight: '3px' }} fontSize="small" color="error" />
                        : <CheckIcon sx={{ marginRight: '3px' }} fontSize="small" color="success" />}
                    </DisplayValue>
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="furniture_cost"
                      label="Prix des meubles"
                    />
                  ) : (<DisplayValue title="Prix des meubles" value={data?.renovation?.furniture_cost ? euro.format(data?.renovation?.furniture_cost) : 0} />)}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <TextFieldCustom
                      control={control}
                      name="renovationDuration"
                      label="Durée des travaux (mois)"
                      type="number"
                    />
                  ) : (<DisplayValue title="Durée des travaux" value={data?.renovation?.renovationDuration ? data?.renovation?.renovationDuration : null} />)}
              </Box>
            </Grid>
          </Grid>
          {toModify
            ? (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setToModify(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  RenovationItem,
};
