import { useGetLogsByUserQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetLogsByUser = (userId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetLogsByUserQuery(headerWithAuth, {
    userId: userId || '',
  }, { enabled: !!accessToken });
  return queryInfo;
};

export {
  useGetLogsByUser,
};
