import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { DisplayValue } from '../../pages';
import { AddOrRemoveField } from '../../../../common/inputs/addorremovefield';
import { SurfaceTextField } from '../../../../common/inputs/surfacetextfield';
import { BooleanWithIcon } from '../../../../common/inputs/booleanwithicon';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { AutocompleteCustom } from '../../../../common/inputs/autocompletecustom';
import { internetFiberOptions } from '../../../../data/data';

interface IFormInput {
  basement_number: number
  parking_lot_number: number
  elevator_number: number
  garden_number: number
  box_number: number
  terrace_number: number,
  balcony_number: number,
  caretaker_number: boolean,
  internet_fiber: boolean,
  terrace_surface: string
  garden_surface: string,
  balcony_surface: string,
  intercom_system_digital_code: string,
  intercom_system_name: string,
}

function Facilities({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();

  const format = {
    ...data?.arrangement,
    internet_fiber: internetFiberOptions?.find((f) => f?.value === data?.arrangement?.internet_fiber),
    basement_number: data?.arrangement?.basement_number || 0,
    parking_lot_number: data?.arrangement?.parking_lot_number || 0,
    elevator_number: data?.arrangement?.elevator_number || 0,
    garden_number: data?.arrangement?.garden_number || 0,
    box_number: data?.arrangement?.box_number || 0,
    terrace_number: data?.arrangement?.terrace_number || 0,
    balcony_number: data?.arrangement?.balcony_number || 0,
  };

  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...format };
    }, [data, searchParams.get('propertyId')]),
  });

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        arrangement: {
          basement_number: Number(form?.basement_number),
          parking_lot_number: Number(form?.parking_lot_number),
          elevator_number: Number(form?.elevator_number),
          garden_number: Number(form?.garden_number),
          box_number: Number(form?.box_number),
          balcony_number: Number(form?.balcony_number),
          terrace_number: Number(form?.terrace_number),
          terrace_surface: Number(form?.terrace_surface),
          garden_surface: Number(form?.garden_surface),
          balcony_surface: Number(form?.balcony_surface),
          caretaker_number: form?.caretaker_number,
          internet_fiber: form?.internet_fiber?.value,
          intercom_system_digital_code: form?.intercom_system_digital_code,
          intercom_system_name: form?.intercom_system_name,
        },
      },
    };
    Object.keys(formValid.data?.arrangement).forEach((key) => {
      if (formValid.data?.arrangement[key] === undefined || formValid.data?.arrangement[key] === null || Number.isNaN(formValid.data?.arrangement[key])) {
        delete formValid.data?.arrangement[key];
      }
    });
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
          <Typography color="primary" variant="h5" marginBottom={2}>Équipement</Typography>
          <Grid container item columns={3} spacing={3}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="basement_number"
                      watch={watch}
                      label="Cave"
                    />
                  ) : (
                    <DisplayValue title="Cave" value={data?.arrangement?.basement_number} />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="parking_lot_number"
                      watch={watch}
                      label="Parking"
                    />
                  ) : (<DisplayValue title="Parking" value={data?.arrangement?.parking_lot_number} />)}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="elevator_number"
                      watch={watch}
                      label="Ascenseur"
                    />
                  ) : (
                    <DisplayValue title="Ascenseur" value={data?.arrangement?.elevator_number} />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="garden_number"
                      watch={watch}
                      label="Jardin"
                    />
                  ) : (
                    <DisplayValue title="Jardin" value={data?.arrangement?.garden_number} />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="box_number"
                      watch={watch}
                      label="Box"
                    />
                  ) : (
                    <DisplayValue title="Box" value={data?.arrangement?.box_number} />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <BooleanWithIcon
                      control={control}
                      name="caretaker_number"
                      watch={watch}
                      label="Gardien"
                    />
                  ) : (
                    <DisplayValue title="Gardien" value={data?.arrangement?.caretaker_number ? 'Oui' : 'Non'} />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="terrace_number"
                      watch={watch}
                      label="Terrasse"
                    />
                  ) : (
                    <DisplayValue title="Terrasse" value={data?.arrangement?.terrace_number} />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="balcony_number"
                      watch={watch}
                      label="Balcon"
                    />
                  ) : (
                    <DisplayValue title="Balcon" value={data?.arrangement?.balcony_number} />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <TextFieldCustom
                      control={control}
                      name="intercom_system_name"
                      label="Nom interphone"
                    />
                  ) : (
                    <DisplayValue title="Nom interphone" value={data?.arrangement?.intercom_system_name ? data?.arrangement?.intercom_system_name : '...'} />
                  )}
              </Box>
              <Box>
                { watch('terrace_number') > 0
                  && (
                    <Box paddingY={1}>
                      {toModify
                        ? (
                          <SurfaceTextField
                            control={control}
                            name="terrace_surface"
                            label="Superficie Terrasse"
                          />
                        ) : (
                          <DisplayValue title="Superficie Terrasse" value={`${data?.arrangement?.terrace_surface} m2`} />
                        )}
                    </Box>
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <TextFieldCustom
                      control={control}
                      name="intercom_system_digital_code"
                      label="Digicode"
                    />
                  ) : (
                    <DisplayValue title="Digicode" value={data?.arrangement?.intercom_system_digital_code ? data?.arrangement?.intercom_system_digital_code : '...'} />
                  )}
              </Box>
              <Box>
                { watch('garden_number') > 0
                && (
                <Box paddingY={1}>
                  {toModify
                    ? (
                      <SurfaceTextField
                        control={control}
                        name="garden_surface"
                        label="Superficie Jardin"
                      />
                    ) : (
                      <DisplayValue title="Superficie Jardin" value={`${data?.arrangement?.garden_surface} m2`} />
                    )}
                </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AutocompleteCustom
                      control={control}
                      name="internet_fiber"
                      tab={internetFiberOptions}
                      label="Fibre"
                    />
                  ) : (
                    <DisplayValue title="Fibre" value={internetFiberOptions?.find((f) => f?.value === data?.arrangement?.internet_fiber)?.label} />
                  )}
              </Box>
              <Box>
                { watch('balcony_number') > 0
                && (
                  <Box paddingY={1}>
                    {toModify
                      ? (
                        <SurfaceTextField
                          control={control}
                          name="balcony_surface"
                          label="Superficie Balcon"
                        />
                      ) : (
                        <DisplayValue title="Superficie Balcon" value={`${data?.arrangement?.balcony_surface} m2`} />
                      )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          {toModify
            ? (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setToModify(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  Facilities,
};
