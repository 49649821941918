import {
  Autocomplete,
  Box,
  styled,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { propertyStatusOptions } from '../../../../data/data';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { useGetLogsByProperty } from '../../../../hooks/query/get-logs-by-property-query.hook';
import { SectionTitle } from '../../../../common/right-column/section-title';

interface IFormInput {
  status: string
}

function Status({ queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control, handleSubmit, reset,
  } = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      // @ts-ignore
      return queryInfo?.data?.getPropertiesById;
    }, [queryInfo]),
  });

  useEffect(() => {
    reset(queryInfo?.data?.getPropertiesById);
  }, [queryInfo.isSuccess]);

  const queryLogs = useGetLogsByProperty(searchParams.get('propertyId'));

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
      queryLogs.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        status: form?.status?.value,
      },
    };
    if (form?.status?.value !== queryInfo?.data?.getPropertiesById?.status) {
      mutate({ ...formValid });
    }
  };

  function checkValue(data: any) {
    if (propertyStatusOptions.find((f: any) => f.value === data)?.label) {
      return propertyStatusOptions.find((f: any) => f.value === data)?.label;
    }
    if (!propertyStatusOptions.find((f: any) => f.value === data)?.label) {
      return data;
    }
    return '';
  }

  return (
    <Box
      component="form"
      onBlur={handleSubmit(onSubmit)}
    >
      <SectionTitle title="statut" />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            blurOnSelect="mouse"
            disableClearable
            options={propertyStatusOptions}
            value={checkValue(field.value)}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            renderInput={
              (params) => (
                <CssAutocomplete
                  {...params}
                  size="small"
                  placeholder="Selectionner un statut"
                  fullWidth
                  color="secondary"
                  variant="outlined"
                />
              )
            }
          />
        )}
      />
    </Box>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.main,
  borderRadius: '4px',
  input: {
    fontWeight: 'bold',
    color: theme.palette.mode === 'dark' ? 'white' : 'white',
  },
  button: {
    color: theme.palette.mode === 'dark' ? 'inherit' : 'white !important',
  },
}));

export {
  Status,
};
