import React from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import { ProjectCard } from '../../projects/card/pages';
import { ProjectsList } from '../../projects/list/pages';
import { Layout } from '../../common/layout/layout';
import { Sales } from '../../kanban/pages';
import { PropertyList } from '../../properties/list/pages';
import { PropertyCard } from '../../properties/card/pages';
import { ClientCard } from '../../client/card/pages';
import { ProjectsManagementList } from '../../project-management/list/pages';
import { ProjectManagementCard } from '../../project-management/card/pages';

function AuthenticatedApp() {
  return (
    <Layout>
      <Routes>
        <Route path="project-list" element={<ProjectsList />} />
        <Route path="project-list">
          <Route path="projectcard" element={<ProjectCard />} />
        </Route>
        <Route path="project-management-list" element={<ProjectsManagementList />} />
        <Route path="project-management-list">
          <Route path="projectmanagementcard" element={<ProjectManagementCard />} />
        </Route>
        <Route path="property-list" element={<PropertyList />} />
        <Route path="property-list">
          <Route path="propertycard" element={<PropertyCard />} />
        </Route>
        <Route path="clientcard" element={<ClientCard />} />
        <Route path="sales" element={<Sales />} />
        <Route path="*" element={<ProjectsList />} />
      </Routes>
    </Layout>
  );
}

export {
  AuthenticatedApp,
};
