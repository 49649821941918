import {
  Box, IconButton, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';

function AddOrRemoveField({
  control, name, label, watch,
}: any) {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({
          field,
        }) => {
          return (
            <Box
              width="100%"
              height="70px"
              borderRadius="16px"
              bgcolor="background.default"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                onClick={() => field.onChange(field.value > 9 ? field.value : field.value + 1)}
                sx={{
                  opacity: watch(name) === 0 ? 0.5 : 1,
                  transition: 'all 0.3s',
                  cursor: 'pointer',
                }}
              >
                <Typography>{label}</Typography>
              </Box>
              <Box display={watch(name) === 0 ? 'none' : 'flex'} alignItems="center" justifyContent="center">
                <IconButton
                  disabled={field.value < 1}
                  onClick={() => field.onChange(field.value - 1)}
                  color="primary"
                >
                  <RemoveCircleIcon />
                </IconButton>
                <Typography marginX={3}>{field.value}</Typography>
                <IconButton
                  disabled={field.value > 9}
                  onClick={() => field.onChange(field.value > 9 ? field.value : field.value + 1)}
                  color="primary"
                >
                  <AddCircleIcon />
                </IconButton>
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
}

export {
  AddOrRemoveField,
};
