import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, Grid, IconButton, ToggleButton, Tooltip,
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import HomeIcon from '@mui/icons-material/Home';
import { useSearchParams } from 'react-router-dom';
import { getTime } from 'date-fns';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PhoneIcon from '@mui/icons-material/Phone';
import RedeemIcon from '@mui/icons-material/Redeem';
import { appointmentTypeOptions, offersOptions, statusOptions } from '../../../data/data';
import { CalendarFilter } from '../../../common/inputs/filters/calendar-filter';
import { AutocompleteFilter } from '../../../common/inputs/filters/autocomplete-filter';

function Filter({
  queryParamsValue, setQueryParamsValue, setPageIndex,
}: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<any>({
    sales: searchParams.get('sales') ? searchParams.get('sales') : null,
    hotDeal: searchParams.get('hotDeal') ? true : null,
    matched: searchParams.get('matched') ? true : null,
    prospection: searchParams.get('prospection') ? true : null,
    salesReferral: searchParams.get('salesReferral') ? true : null,
    // @ts-ignore
    status: searchParams.get('status') ? searchParams.get('status').split(',').map((e) => statusOptions.find((f) => f.value === e)) : [],
    lastAppointmentType: searchParams.get('lastAppointmentType') ? appointmentTypeOptions.find((f) => f?.value === searchParams.get('lastAppointmentType')) : null,
    offer: searchParams.get('offer') ? offersOptions.find((f) => f?.value === searchParams.get('offer')) : null,
    startDate: searchParams.get('startDate') ? Number(searchParams.get('startDate')) : null,
    endDate: searchParams.get('endDate') ? Number(searchParams.get('endDate')) : null,
  });

  const [salesView, setSalesView] = useState(filter.sales);
  const [hotDeal, setHotDeal] = useState(filter.hotDeal);
  const [matched, setMatched] = useState(filter.matched);
  const [prospection, setProspection] = useState(filter.prospection);
  const [salesReferral, setSalesReferral] = useState(filter.salesReferral);

  const resetFilters = () => {
    setQueryParamsValue({});
    setPageIndex(0);
    setSalesView(false);
    setHotDeal(false);
    setMatched(false);
    setProspection(false);
    setSalesReferral(false);
    setFilter({
      sales: null,
      hotDeal: null,
      matched: null,
      prospection: null,
      salesReferral: null,
      status: [],
      lastAppointmentType: null,
      offer: null,
      startDate: null,
      endDate: null,
    });
  };

  useEffect(() => {
    const searchParamsTmp = new URLSearchParams();
    if (filter.sales) {
      searchParamsTmp.set('sales', filter.sales);
      setQueryParamsValue((old: any) => ({ ...old, sales: filter?.sales ?? null }));
    }
    if (filter.hotDeal) {
      searchParamsTmp.set('hotDeal', filter.hotDeal);
      setQueryParamsValue((old: any) => ({ ...old, hotDeal: filter?.hotDeal ?? null }));
    }
    if (filter.prospection) {
      searchParamsTmp.set('prospection', filter.prospection);
      setQueryParamsValue((old: any) => ({ ...old, prospection: filter?.prospection ?? null }));
    }

    if (filter.salesReferral) {
      searchParamsTmp.set('salesReferral', filter.salesReferral);
      setQueryParamsValue((old: any) => ({ ...old, salesReferral: filter?.salesReferral ?? null }));
    }

    if (filter.matched) {
      searchParamsTmp.set('matched', filter.matched);
      setQueryParamsValue((old: any) => ({ ...old, matched: filter?.matched ?? null }));
    }
    if (filter?.status?.length !== 0) {
      searchParamsTmp.set('status', filter.status);
      setQueryParamsValue((old: any) => ({ ...old, status: filter?.status.map((e: any) => e?.value) ?? [] }));
    }
    if (filter.lastAppointmentType) {
      searchParamsTmp.set('lastAppointmentType', filter.lastAppointmentType);
      setQueryParamsValue((old: any) => ({ ...old, lastAppointmentType: filter?.lastAppointmentType?.value ?? null }));
    }
    if (filter.offer) {
      searchParamsTmp.set('offer', filter.offer);
      setQueryParamsValue((old: any) => ({ ...old, offer: filter?.offer?.value ?? null }));
    }
    if (filter.startDate) {
      searchParamsTmp.set('startDate', filter.startDate);
      setQueryParamsValue((old: any) => ({ ...old, startDate: filter?.startDate ?? null }));
    }
    if (filter.endDate) {
      searchParamsTmp.set('endDate', filter.endDate);
      setQueryParamsValue((old: any) => ({ ...old, endDate: filter?.endDate ?? null }));
    }
  }, [filter, setQueryParamsValue]);

  useEffect(() => {
    const defaultUrl = new URLSearchParams();
    Object.keys(filter).forEach((key) => {
      if (filter[key] && filter[key]?.length !== 0) {
        defaultUrl.set(key, queryParamsValue[key]);
      }
    });
    setSearchParams(defaultUrl.toString());
  }, [filter, queryParamsValue, setSearchParams]);

  return (
    <Grid container gap={1} item paddingTop={3}>
      <ButtonGroup variant="outlined" color="inherit" sx={{ maxHeight: '38.35px' }}>
        <Tooltip title="Vue commmerciale">
          <ToggleButton
            component={Button}
            selected={salesView}
            value="sales"
            color="primary"
            size="small"
            sx={{ textDecoration: 'none' }}
            onChange={() => {
              setPageIndex(0);
              resetFilters();
              setSalesView(!salesView);
              setQueryParamsValue((old: any) => ({ ...old, sales: !salesView ? 'true' : null }));
              setFilter((oldFilter: any) => ({ ...oldFilter, sales: !salesView ? 'true' : null }));
            }}
          >
            <WorkOutlineIcon fontSize="small" />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Hot deal">
          <ToggleButton
            component={Button}
            selected={hotDeal}
            value="sales"
            color="warning"
            size="small"
            disabled={salesView}
            sx={{ textDecoration: 'none' }}
            onChange={() => {
              setPageIndex(0);
              setHotDeal(!hotDeal);
              setQueryParamsValue((old: any) => ({ ...old, hotDeal: !hotDeal ? true : null }));
              setFilter((oldFilter: any) => ({ ...oldFilter, hotDeal: !hotDeal ? true : null }));
            }}
          >
            <LocalFireDepartmentIcon fontSize="small" />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Téléphone">
          <ToggleButton
            component={Button}
            selected={prospection}
            value="prospection"
            color="warning"
            size="small"
            disabled={salesView}
            sx={{ textDecoration: 'none' }}
            onChange={() => {
              setPageIndex(0);
              setProspection(!prospection);
              setQueryParamsValue((old: any) => ({ ...old, prospection: !prospection ? true : null }));
              setFilter((oldFilter: any) => ({ ...oldFilter, prospection: !prospection ? true : null }));
            }}
          >
            <PhoneIcon fontSize="small" />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Parrainage commercial">
          <ToggleButton
            component={Button}
            selected={salesReferral}
            value="salesReferral"
            color="warning"
            size="small"
            disabled={salesView}
            sx={{ textDecoration: 'none' }}
            onChange={() => {
              setPageIndex(0);
              setSalesReferral(!salesReferral);
              setQueryParamsValue((old: any) => ({ ...old, salesReferral: !salesReferral ? true : null }));
              setFilter((oldFilter: any) => ({ ...oldFilter, salesReferral: !salesReferral ? true : null }));
            }}
          >
            <RedeemIcon fontSize="small" />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Projets matchés">
          <ToggleButton
            component={Button}
            selected={matched}
            value="matched"
            color="warning"
            size="small"
            disabled={salesView}
            sx={{ textDecoration: 'none' }}
            onChange={() => {
              setPageIndex(0);
              setMatched(!matched);
              setQueryParamsValue((old: any) => ({ ...old, matched: !matched ? true : null }));
              setFilter((oldFilter: any) => ({ ...oldFilter, matched: !matched ? true : null }));
            }}
          >
            <HomeIcon fontSize="small" />
          </ToggleButton>
        </Tooltip>
      </ButtonGroup>
      <AutocompleteFilter
        disabled={salesView}
        value={filter?.offer}
        onChange={(_: any, value: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, offer: value?.value ?? null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, offer: value }));
        }}
        options={offersOptions}
        label="Offre"
        width={150}
      />
      <AutocompleteFilter
        multiple
        disabled={salesView}
        value={filter?.status ? filter.status : []}
        onChange={(_: any, value: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, status: value.map((e: any) => e?.value) ?? [] }));
          setFilter((oldFilter: any) => ({ ...oldFilter, status: value }));
        }}
        options={statusOptions}
        label="Statut"
        width={230}
      />
      <AutocompleteFilter
        disabled={salesView}
        value={filter?.lastAppointmentType}
        onChange={(_: any, value: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, lastAppointmentType: value?.value ?? null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, lastAppointmentType: value }));
        }}
        options={appointmentTypeOptions}
        label="Type"
        width={140}
      />
      <CalendarFilter
        value={filter?.startDate}
        disabled={salesView}
        onChange={(e: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, startDate: e ? getTime(new Date(e)) : null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, startDate: e ? getTime(new Date(e)) : null }));
        }}
        label="Date début"
        width={150}
      />
      <CalendarFilter
        value={filter?.endDate}
        disabled={salesView}
        onChange={(e: any) => {
          setPageIndex(0);
          setQueryParamsValue((old: any) => ({ ...old, endDate: e ? getTime(new Date(e)) : null }));
          setFilter((oldFilter: any) => ({ ...oldFilter, endDate: e ? getTime(new Date(e)) : null }));
        }}
        label="Date fin"
        width={150}
      />
      <Tooltip title="Réinitialiser les filtres">
        <IconButton
          color="warning"
          onClick={() => resetFilters()}
          sx={{ maxHeight: '38.34px' }}
          disabled={filter?.status?.length === 0 && !filter?.offer && !filter?.lastAppointmentType && !filter?.matched && !filter?.prospection && !filter?.salesReferral && !filter?.startDate && !filter?.endDate && !filter.sales && !filter.hotDeal}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export {
  Filter,
};
