import React from 'react';
import {
  Box,
} from '@mui/material';
import { Summary } from './summary';
import { Description } from './description';
import { GeneralInformation } from './general-information';
import { Conclusion } from './conclusion';
import { Facilities } from './facilities';
import { Arrangement } from './arrangement';
import { FutureLayout } from './future-layout';

function PropertyInfos({ queryInfo } : any) {
  const data = queryInfo?.data?.getPropertiesById;
  return (
    <Box>
      <Summary data={data} queryInfo={queryInfo} />
      <Description data={data} queryInfo={queryInfo} />
      <Arrangement data={data} queryInfo={queryInfo} />
      <Facilities data={data} queryInfo={queryInfo} />
      <FutureLayout data={data} queryInfo={queryInfo} />
      <GeneralInformation data={data} queryInfo={queryInfo} />
      <Conclusion data={data} queryInfo={queryInfo} />
    </Box>
  );
}

export {
  PropertyInfos,
};
