import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { TextFieldCustom } from '../../../../../common/inputs/textfieldcustom';
import AddressCustom from '../../../../../common/inputs/adresscustom';
import { useCreateRealEstateAgency } from '../../../../../hooks/mutation/create-real-estate-agency-mutation.hook';

function AddAgencyContainer({ setOpenAddAgency, queryAgency, setValue }: any) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control, handleSubmit, watch,
  } = useForm<any>({
    mode: 'onChange',
  });

  const mutate = useCreateRealEstateAgency({
    onSuccess: (resp: any) => {
      queryAgency.refetch();
      setValue('realEstateAgency', {
        value: resp?.createRealEstateAgency?._id,
        label: `${resp?.createRealEstateAgency?.realEstateAgencyName} - ${resp?.createRealEstateAgency?.realEstateAgencyAddress}`,
      });
      setOpenAddAgency(false);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      createRealEstateAgencyInput: {
        realEstateAgencyAddress: form?.realEstateAgencyAddress?.address_line_1,
        realEstateAgencyName: form?.realEstateAgencyName,
      },
    };
    mutate({ ...formValid });
  };

  return (
    <Box
      marginBottom={3}
    >
      <Box paddingBottom={1}>
        <TextFieldCustom
          name="realEstateAgencyName"
          control={control}
          required
          rules={{ required: 'Veuillez renseigner un nom' }}
          label="Nom de l'agence"
        />
      </Box>
      <Box marginY={1}>
        <AddressCustom
          size="small"
          control={control}
          rules={{ required: 'Veuillez renseigner une adresse' }}
          name="realEstateAgencyAddress"
          label="Adresse de l'agence *"
        />
      </Box>
      <Box display="flex" marginY={1}>
        <Button
          color="error"
          type="reset"
          onClick={() => setOpenAddAgency(false)}
          sx={{
            marginRight: 1,
            textTransform: 'none',
          }}
        >
          Annuler
        </Button>
        <Button
          color="primary"
          fullWidth
          type="submit"
          variant="contained"
          endIcon={<AddIcon />}
          onClick={handleSubmit(onSubmit)}
          disabled={!watch('realEstateAgencyName') || !watch('realEstateAgencyAddress')}
          sx={{
            marginLeft: 1,
            textTransform: 'none',
            color: (theme) => theme.palette.background.paper,
          }}
        >
          Ajouter agence immobilière
        </Button>
      </Box>
    </Box>
  );
}

export {
  AddAgencyContainer,
};
