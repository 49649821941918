import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { DisplayValue } from '../../pages';
import { AddOrRemoveField } from '../../../../common/inputs/addorremovefield';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';

interface IFormInput {
  bedroom_number: number
  bathroom_number: number
  laundry_room_number: number
  water_closet_number: number
}

function FutureLayout({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();

  const format = {
    bedroom_number: data?.arrangement?.futureLayout?.bedroom_number || 0,
    bathroom_number: data?.arrangement?.futureLayout?.bathroom_number || 0,
    laundry_room_number: data?.arrangement?.futureLayout?.laundry_room_number || 0,
    water_closet_number: data?.arrangement?.futureLayout?.water_closet_number || 0,
  };

  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return { ...format };
    }, [data, searchParams.get('propertyId')]),
  });

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        arrangement: {
          futureLayout: {
            bedroom_number: Number(form?.bedroom_number),
            bathroom_number: Number(form?.bathroom_number),
            laundry_room_number: Number(form?.laundry_room_number),
            water_closet_number: Number(form?.water_closet_number),
          },
        },
      },
    };
    Object.keys(formValid.data?.arrangement?.futureLayout).forEach((key) => {
      if (formValid.data?.arrangement?.futureLayout[key] === undefined || formValid.data?.arrangement?.futureLayout[key] === null || Number.isNaN(formValid.data?.arrangement?.futureLayout[key])) {
        delete formValid.data?.arrangement?.futureLayout[key];
      }
    });
    mutate({ ...formValid });
    setToModify(false);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
          <Typography color="primary" variant="h5" marginBottom={2}>Pièces à créer</Typography>
          <Grid container item columns={3} spacing={3}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="bedroom_number"
                      watch={watch}
                      label="Chambre"
                    />
                  ) : (
                    <DisplayValue title="Chambre" value={data?.arrangement?.futureLayout?.bedroom_number} />
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="bathroom_number"
                      watch={watch}
                      label="Salle de bain"
                    />
                  ) : (
                    <DisplayValue title="Salle de bain" value={data?.arrangement?.futureLayout?.bathroom_number} />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="water_closet_number"
                      watch={watch}
                      label="WC"
                    />
                  ) : (
                    <DisplayValue title="WC" value={data?.arrangement?.futureLayout?.water_closet_number} />
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <AddOrRemoveField
                      control={control}
                      name="laundry_room_number"
                      watch={watch}
                      label="Buanderie"
                    />
                  ) : (
                    <DisplayValue title="Buanderie" value={data?.arrangement?.futureLayout?.laundry_room_number} />
                  )}
              </Box>
            </Grid>
          </Grid>
          {toModify
            ? (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setToModify(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  FutureLayout,
};
