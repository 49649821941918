import { useUpdateUserMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useUpdateUser = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useUpdateUserMutation(headerWithAuth, options);

  return mutate;
};

export {
  useUpdateUser,
};
