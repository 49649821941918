import {
  Box, List, ListItem, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import HouseIcon from '@mui/icons-material/House';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { styled } from '@mui/material/styles';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import {
  employmentContractOptions, genderOptions, seniorityMergedOptions, tabBankOptions, tabCountryOptions, weddingPlanOptions,
} from '../../../../data/data';
import { ISelectorOptions } from './index';

interface IpropsDisplayData {
  title: string
  data: ISelectorOptions[]
  paddingtop?: boolean
}

function DisplayPersonalInfos({ data }: any) {
  const phoneNumber = data?.phone && parsePhoneNumber(data?.phone);
  const personalInformations: ISelectorOptions[] = [{
    key: 'Genre',
    value: data?.gender ? genderOptions.find((f) => f.value === data.gender)?.label : '',
  }, {
    key: 'Prénom',
    value: data?.first_name,
  }, {
    key: 'Nom de famille',
    value: data?.last_name,
  }, {
    key: 'Nationalité',
    // @ts-ignore
    value: data?.nationality ? tabCountryOptions.find((f: any) => f.value === data?.nationality)?.label : '',
  }, {
    key: 'Date de naissance',
    value: data?.birthdate ? format(new Date(data?.birthdate), 'dd/MM/yyyy') : '',
  }, {
    key: 'Lieu de naissance',
    value: data?.place_of_birth,
  }];

  const familySituation: ISelectorOptions[] = [{
    key: 'Marié ou pacsé',
    value: data ? data?.is_married === true ? 'Oui' : 'Non' : '',
  }, {
    key: 'Sous quel régime',
    // @ts-ignore
    value: data?.wedding_plan ? weddingPlanOptions.find((f: any) => f.value === data?.wedding_plan).label : '',
  }, {
    key: 'Nombre d’enfant',
    value: data?.number_of_children === 0 ? '0' : data?.number_of_children,
  }];

  const professionalSituation: ISelectorOptions[] = [{
    key: 'Profession',
    value: data?.job,
  }, {
    key: 'Employeur',
    value: data?.employer_name,
  }, {
    key: 'Contrat de travail',
    // @ts-ignore
    value: data?.employment_contract ? employmentContractOptions.find((f: any) => f.value === data?.employment_contract).label : '',
  }, {
    key: 'Ancienneté',
    // @ts-ignore
    value: data?.seniority ? seniorityMergedOptions.find((f: any) => f.value === data?.seniority).label : '',
  }];

  const bankInformation: ISelectorOptions[] = [{
    key: 'Banque principale',
    // @ts-ignore
    value: data?.main_bank ? tabBankOptions.find((f: any) => f.value === data?.main_bank).label : '',
  }, {
    key: 'Autres banques',
    // @ts-ignore
    value: `${data?.other_banks.map((bank: string) => tabBankOptions.find((elem) => elem.value === bank)?.label)}`,
  }];

  const contact = [{
    key: <HouseIcon opacity={0.54} fontSize="large" />,
    value: data?.street_address?.address_line_1,
    type: 'address',
  }, {
    key: <AlternateEmailIcon opacity={0.54} fontSize="large" />,
    value: data?.email,
    type: 'email',
  }, {
    key: <CallOutlinedIcon opacity={0.54} fontSize="large" />,
    value: data?.phone ? new AsYouType(phoneNumber?.country).input(data?.phone) : null,
    type: 'phone',
  }];

  return (
    <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <Box display="flex">
        <List sx={{ width: '50%' }}>
          <DisplayData
            title="Informations personnelles"
            data={personalInformations}
          />
          <DisplayData
            paddingtop
            title="Situtation professionnelle"
            data={professionalSituation}
          />
        </List>
        <List sx={{ width: '50%' }}>
          <DisplayData
            title="Situation familiale"
            data={familySituation}
          />
          <DisplayData
            paddingtop
            title="Informations bancaire"
            data={bankInformation}
          />
          <Typography color="primary" variant="h5" marginBottom={2} paddingTop={3}>Contact</Typography>
          {contact.map((e, i) => (
            <ListItem
              key={e.value + i.toString()}
              disablePadding
            >
              <ListItemText
                sx={{ paddingBottom: 2 }}
                primary={(
                  <Box flexDirection="row" display="flex">
                    <Typography display="flex" alignItems="center" marginRight={3}>
                      {e.key}
                    </Typography>
                    {e.type === 'address' && (
                      <Typography display="flex" alignItems="center">
                        {e.value ? e.value : '...'}
                      </Typography>
                    )}
                    {e.type === 'email' && (
                      <Typography display="flex" alignItems="center">
                        <Superlink
                          target="_blank"
                          href={`mailto:${e.value}`}
                        >
                          {e.value ? e.value : '...'}
                        </Superlink>
                      </Typography>
                    )}
                    {e.type === 'phone' && (
                      <Typography display="flex" alignItems="center">
                        <Superlink
                          href={`tel: ${e.value}`}
                          target="_blank"
                        >
                          {e.value ? e.value : '...'}
                        </Superlink>
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

function DisplayData({ title, data, paddingtop }: IpropsDisplayData) {
  return (
    <Box>
      <Typography color="primary" variant="h5" marginBottom={2} paddingTop={paddingtop ? 3 : 0}>{title}</Typography>
      {data.map((e, i) => (
        <ListItem
          key={e.value + i.toString()}
          disablePadding
        >
          <ListItemText
            primary={<Typography sx={{ opacity: 0.4 }}>{e.key}</Typography>}
            secondary={
              <Typography color="text.primary">{e.value ? e.value : '...'}</Typography>
            }
          />
        </ListItem>
      ))}
    </Box>
  );
}

const Superlink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

export {
  DisplayPersonalInfos,
};
