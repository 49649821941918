import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { SimulationContainer } from './simulation-container';
import { SummarySimulation } from './summary-simulation';
import { PricePerSurface } from './price-per-surface';
import { GlobalBudget } from './global-budget';
import { CashFLow } from './cashflow';
import { euro } from '../../../../common/inputs/format/euro-format';

function Simulation({ queryInfo, getGlobalConfig }: any) {
  const dataFinance = queryInfo && queryInfo?.data?.getPropertiesById?.finance;
  const totalBudget = queryInfo && queryInfo?.data?.getPropertiesById?.finance?.total_budget;
  const [firstSliderValue, setFirstSliderValue] = useState<any>(15);
  const [years, setYears] = useState<any>(getGlobalConfig?.data?.number_of_years_loan);
  const [loanDelay, setLoanDelay] = useState<any>(0);
  const [interestRateTmp, setInterestRateTmp] = useState<any>(getGlobalConfig?.data?.interest_rate * 100);
  const [monthlyLoanBudgetTmp, setMonthlyLoanBudgetTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_loan_amount ? dataFinance?.property_cash_flow?.monthly_loan_amount : 0);
  const [equityContributionTmp, setEquityContributionTmp] = useState<any>(dataFinance && totalBudget ? 15 * totalBudget / 100 : 0);
  const [monthlyOutcomeTmp, setMonthlyOutcomeTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_outcome ? dataFinance?.property_cash_flow?.monthly_outcome : 0);
  const [loanBudgetTmp, setLoanBudgetTmp] = useState<any>(totalBudget && equityContributionTmp ? totalBudget - equityContributionTmp : 0);
  const [budgetWithdelay, setBudgetWithDelay] = useState<any>(null);

  const simulationColor = {
    pie1: '#CE6A6B',
    pie2: '#EBACA2',
    pie3: '#BED3C3',
    pie4: '#4A919E',
    pie5: '#212E53',
    darkGreen: '#B9C7B7',
  };

  useEffect(() => {
    setEquityContributionTmp(firstSliderValue * totalBudget / 100);
  }, [firstSliderValue, totalBudget]);

  useEffect(() => {
    let monthlyOutcome = (monthlyLoanBudgetTmp + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees);
    if (budgetWithdelay && loanDelay > 0) {
      monthlyOutcome = (budgetWithdelay + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees);
    }
    setMonthlyOutcomeTmp(monthlyOutcome);
  }, [dataFinance, monthlyLoanBudgetTmp, budgetWithdelay, loanDelay]);

  useEffect(() => {
    setLoanBudgetTmp(totalBudget - equityContributionTmp);
    if (years === getGlobalConfig?.data?.number_of_years_loan_15 && getGlobalConfig?.data?.number_of_years_loan_15 === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_15 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan_20 && getGlobalConfig?.data?.number_of_years_loan_20 === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_20 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan && getGlobalConfig?.data?.number_of_years_loan === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate * 100);
    }
    setMonthlyLoanBudgetTmp(((interestRateTmp / 100) * loanBudgetTmp) / ((1 - (1 + (interestRateTmp / 100) / 12) ** (-12 * years)) * 12));
    if (loanDelay > 0) {
      const defaultMonthlyLoan = ((totalBudget - equityContributionTmp) * (interestRateTmp / 100)) / 12;
      setBudgetWithDelay(defaultMonthlyLoan);
    } else {
      setBudgetWithDelay(null);
    }
  }, [equityContributionTmp, interestRateTmp, loanBudgetTmp, totalBudget, years, loanDelay, getGlobalConfig]);

  return (
    <Box>
      <SimulationContainer queryInfo={queryInfo} />
      <ContainerSynthese
        title="Synthèse"
      >
        <SummarySimulation queryInfo={queryInfo} />
      </ContainerSynthese>
      {queryInfo?.data?.getPropertiesById?.finance?.district_price_per_m2
        && queryInfo?.data?.getPropertiesById?.finance?.district_price_per_m2 !== 0
        ? (
          <ContainerSynthese
            title="Prix au m2"
            value={euro.format(queryInfo?.data?.getPropertiesById?.finance?.expected_price_per_m2)}
          >
            <PricePerSurface queryInfo={queryInfo} simulationColor={simulationColor} />
          </ContainerSynthese>
        ) : null}
      <ContainerSynthese
        title="Budget global"
        value={euro.format(queryInfo?.data?.getPropertiesById?.finance?.total_budget)}
      >
        <GlobalBudget queryInfo={queryInfo} simulationColor={simulationColor} />
      </ContainerSynthese>
      <ContainerSynthese
        title="Cash flow"
      >
        {getGlobalConfig.isSuccess
          && <CashFLow queryInfo={queryInfo} getGlobalConfig={getGlobalConfig} />}
      </ContainerSynthese>
    </Box>
  );
}

function ContainerSynthese({ children, title, value }: any) {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography color="primary" variant="h5" marginBottom={2}>{title}</Typography>
          <Typography
            variant="h5"
            color="text.primary"
            fontWeight="bold"
          >
            {value}
          </Typography>
        </Box>
        {children}
      </Box>
    </Box>
  );
}

export {
  Simulation,
};
