import React, { useState } from 'react';
import {
  Box, Grid, Tab, Tabs,
} from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { RightColumn } from '../components/right-column';
import { Infos2 } from '../components/infos2';
import { Infos } from '../components/infos';

function ProjectManagementCard() {
  const [value, setValue] = useState(0); const tabs = [
    <AcUnitIcon />,
    <AcUnitIcon />,
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      paddingX={1}
      display="flex"
      width="100%"
    >
      <Box
        width="75%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <TabsHandler value={value} onChange={handleChange} tabs={tabs} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="25%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <RightColumn />
      </Box>
    </Box>
  );
}

function TabsHandler({ value = 0, onChange, tabs }: { value: number, onChange: any, tabs: any }) {
  return (
    <Box>
      <Tabs
        value={value}
        onChange={onChange}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '16px',
        }}
      >
        {tabs.map((e: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined, i: { toString: () => any; }) => (
          <Tab
            key={e + i.toString()}
            icon={e}
          />
        ))}
      </Tabs>
      <Grid
        item
        marginTop={3}
        sx={{
          height: 'calc(100vh - 182px)',
          borderRadius: '16px',
          overflow: 'auto',
        }}
      >
        {value === 0 ? <Infos /> : null}
        {value === 1 ? <Infos2 /> : null}
      </Grid>
    </Box>
  );
}

export {
  ProjectManagementCard,
};
