import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Modal,
  Theme,
  Paper,
  styled,
} from '@mui/material';
import { AllowedKanbanType, AllowedOffer } from '../../api';
import { ProjectsDetails } from './tasks-details/projects';
import { PropertiesDetails } from './tasks-details/properties';
import { SalesDetails } from './tasks-details/sales';

interface Iprops {
  task: {
    content: any
    id: string,
    title: string,
  },
  index: number,
  kanbanType: any,
}

function Task({ task, index, kanbanType } : Iprops) {
  const [modalOpen, setModalOpen] = useState(false);

  const toggle = (state: boolean, setState: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void;}) => {
    state ? setState(false) : setState(true);
  };

  const chooseOffersColor = (param: any) => {
    switch (param) {
      case AllowedOffer.INELIGIBLE: {
        return (theme: any) => theme?.palette?.error.main;
      }
      case AllowedOffer.ETINCELLE: {
        return (theme: any) => theme?.palette?.primary.main;
      }
      case AllowedOffer.ENVOL: {
        return (theme: any) => theme?.palette?.success.main;
      }
      case AllowedOffer.ETOILE: {
        return (theme: any) => theme?.palette?.warning.main;
      }
      default: {
        return (theme: any) => theme?.palette?.divider;
      }
    }
  };

  const selectColumnContent = (modal: any) => {
    switch (kanbanType) {
      case AllowedKanbanType.PROJECTS:
        return (
          <ProjectsDetails task={task} modal={modal} chooseOffersColor={chooseOffersColor} />
        );
      case AllowedKanbanType.PROPERTIES:
        return (
          <PropertiesDetails task={task} modal={modal} />
        );
      case AllowedKanbanType.SALES:
        return (
          <SalesDetails task={task} modal={modal} chooseOffersColor={chooseOffersColor} />
        );
      default:
        return '';
    }
  };

  return (
    <Draggable
      draggableId={task?.id}
      index={index}
      isDragDisabled={kanbanType === AllowedKanbanType.SALES}
      key={task?.id}
    >
      {(provided, snapshot) => {
        return (
          <>
            <PaperCustom
              elevation={0}
              isdragging={snapshot.isDragging.toString()}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Box
                onClick={() => toggle(modalOpen, setModalOpen)}
                sx={{
                  width: '80%',
                  position: 'absolute',
                  height: '80%',
                }}
              />
              <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  {selectColumnContent(true)}
                </Box>
              </Modal>
              {selectColumnContent(false)}
            </PaperCustom>
          </>
        );
      }}
    </Draggable>
  );
}

const PaperCustom = styled(Paper)((props: { isdragging: string, theme?: Theme | any }) => ({
  backgroundColor: props.theme.palette.background.paper,
  borderRadius: '5px',
  border: props.isdragging === 'true' ? `2px solid ${props.theme.palette.primary.light}` : `2px solid ${props.theme.palette.background.default}`,
  marginBottom: '8px',
  cursor: 'pointer',
  minHeight: '75px',
  position: 'relative',
  ':hover': {
    div: {
      opacity: 1,
    },
  },
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '3px solid',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
  borderColor: 'primary.main',
};

export {
  Task,
};
