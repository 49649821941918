import {
  Box, Button, ButtonGroup, ClickAwayListener, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
// @ts-ignore
import ImageGallery from 'react-image-gallery';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { useGetAccessToken } from '../../../../hooks/auth/get-access-token.hook';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { useDeleteDocumentsFromProperty } from '../../../../hooks/mutation/delete-documents-from-property-mutation.hoook';

function ImageGalleryContainer({ queryInfo }: any) {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [searchParams] = useSearchParams();
  const documentTypeImg = queryInfo?.data?.getPropertiesById?.propertyPictureDocuments;

  const { enqueueSnackbar } = useSnackbar();

  const setIsPreviewMutate = useUpdateProperty({
    onSuccess: () => {
      setIsPreview(false);
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const deleteMutate = useDeleteDocumentsFromProperty({
    onSuccess: () => {
      enqueueSnackbar('Votre photo ou vidéo a bien été supprimé !');
      setIsDelete(false);
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const submitDocumentAsPreview = (id: string) => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        document: {
          isPreview: true,
          _id: id,
        },
      },
    };
    setIsPreviewMutate({ ...formValid });
  };

  const submitDeleteDocument = (id: string) => {
    const formValid: any = {
      documentId: id,
    };
    deleteMutate({ ...formValid });
  };

  const images = documentTypeImg?.map((e: any) => ({
    original: e?.url,
    thumbnail: e?.url,
    mimeType: e?.mimeType || null,
    renderThumbInner: (item: any) => (
      <Box
        width="auto"
        height="70px"
        minHeight="70px"
        zIndex={10000}
        sx={{ cursor: 'pointer' }}
      >
        {item?.mimeType && item.mimeType.includes('video') ? (
          <Box position="relative" width="100%" height="100%">
            <Box
              component="video"
              height="70px"
              width="100%"
              muted
              controls={false}
              bgcolor="divider"
              sx={{
                position: 'relative',
              }}
            >
              <Box
                component="source"
                src={e.url}
                type={e.mimeType === 'video/quicktime' ? 'video/mp4' : e.mimeType}
              />
            </Box>
            <Box
              display={isDelete ? 'inherit' : 'none'}
              position="absolute"
              onClick={() => submitDeleteDocument(e?._id)}
              width="100%"
              height="100%"
              sx={{
                filter: 'grayscale(1)',
              }}
              top={0}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  fontSize: '24px',
                  opacity: 1,
                }}
              >
                <DeleteIcon />
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box position="relative" width="100%" height="100%">
            <Box
              bgcolor="divider"
              component="img"
              width="100%"
              height="100%"
              src={e.url}
              alt="bien"
              sx={{
                objectFit: 'contain',
                filter: isPreview ? e.isPreview ? 'grayscale(0)' : 'grayscale(1)' : 'grayscale(0)',
                position: 'relative',
              }}
            />
            <Box
              display={isPreview ? 'inherit' : 'none'}
              position="absolute"
              onClick={() => submitDocumentAsPreview(e?._id)}
              width="100%"
              height="100%"
              sx={{
                filter: 'grayscale(1)',
              }}
              top={0}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  textAlign: 'center',
                  width: '100%',
                  cursor: 'pointer',
                  height: '100%',
                  fontSize: '24px',
                  opacity: e?.isPreview ? 1 : 0,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                ★
              </Typography>
            </Box>
            <Box
              display={isDelete ? 'inherit' : 'none'}
              position="absolute"
              onClick={() => submitDeleteDocument(e?._id)}
              width="100%"
              height="100%"
              sx={{
                filter: 'grayscale(1)',
              }}
              top={0}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  fontSize: '24px',
                  opacity: 1,
                }}
              >
                <DeleteIcon />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    ),
    renderItem: (item: any) => (
      <Box
        width="100%"
        height={isFullScreen ? '90vh' : '200px'}
        bgcolor="divider"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {item?.mimeType && item.mimeType.includes('video')
          ? (
            <Box
              component="video"
              controls
              height="100%"
              width="100%"
            >
              <Box
                component="source"
                src={e.url}
                type={e.mimeType === 'video/quicktime' ? 'video/mp4' : e.mimeType}
              />
            </Box>
          ) : (
            <Box
              component="img"
              width="100%"
              height="100%"
              sx={{ objectFit: 'contain', display: 'block' }}
              src={e.url}
              alt="bien"
            />
          )}
      </Box>
    ),
  }));

  const handleClickAway = () => {
    setIsPreview(false);
    setIsDelete(false);
  };

  return (
    <Box width="100%">
      {queryInfo.isSuccess && !queryInfo.isLoading && documentTypeImg?.length > 0
        && (
          <Box
            width="100%"
            marginTop={4}
            sx={{
              '.image-gallery-left-nav': {
                padding: '0 !important',
                left: '10px',
                height: 'fit-content !important',
              },
              '.image-gallery-right-nav': {
                padding: '0 !important',
                right: '10px',
                height: 'fit-content !important',
              },
              '.image-gallery.fullscreen-modal': {
                zIndex: 200000,
              },
              '.image-gallery-fullscreen-button': {
                padding: '0 !important',
                top: '10px',
                right: '10px',
                height: 'fit-content !important',
              },
              '.image-gallery-left-nav .image-gallery-svg': {
                width: isFullScreen ? 'inherit' : '20px',
                height: isFullScreen ? '50px' : 'fit-content !important',
              },
              '.image-gallery-right-nav .image-gallery-svg': {
                width: isFullScreen ? 'inherit' : '20px',
                height: isFullScreen ? '50px' : 'fit-content !important',
              },
              '.image-gallery-fullscreen-button .image-gallery-svg': {
                width: isFullScreen ? 'inherit' : '20px',
                height: isFullScreen ? '30px' : 'fit-content !important',
              },
              '.image-gallery-thumbnails .image-gallery-thumbnails-container': {
                maxWidth: '1px',
              },
            }}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box position="relative">
                <Box width="100%">
                  <ImageGallery
                    items={images}
                    showPlayButton={false}
                    thumbnailPosition="bottom"
                    onScreenChange={() => setIsFullScreen(!isFullScreen)}
                  />
                </Box>
                <FileSaver
                  queryInfo={queryInfo}
                  documentTypeImg={documentTypeImg}
                  isPreview={isPreview}
                  setIsPreview={setIsPreview}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                />
              </Box>
            </ClickAwayListener>
          </Box>
        )}
    </Box>
  );
}

function FileSaver({
  queryInfo, documentTypeImg, setIsPreview, isPreview, isDelete, setIsDelete,
}: any) {
  const [searchParams] = useSearchParams();
  const { accessToken } = useGetAccessToken();
  const [loading, setLoading] = useState(false);

  const fileName = `${queryInfo?.data?.getPropertiesById?.description?.flat_type}—${queryInfo?.data?.getPropertiesById?.localisation?.street_address?.locality}`;

  const downloadImg = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/property/download/${searchParams.get('propertyId')}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => { return res.blob(); })
      .then((data) => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = `${fileName}.zip`;
        a.click();
        a.remove();
        setLoading(false);
      });
  };

  return (
    <Box marginTop={3} width="100%">
      {documentTypeImg.length > 0
        && (
        <ButtonGroup variant="outlined" fullWidth>
          {(!isDelete && !isPreview)
            && (
            <LoadingButton
              fullWidth
              variant="outlined"
              onClick={() => downloadImg()}
              loading={loading}
              size="small"
              sx={{
                textTransform: 'none',
              }}
            >
              Télécharger
            </LoadingButton>
            )}
          {!isDelete && (
            <Button
              fullWidth
              onClick={() => {
                setIsPreview(!isPreview);
                setIsDelete(false);
              }}
              size="small"
              color={isPreview ? 'error' : 'primary'}
              sx={{
                textTransform: 'none',
              }}
            >
              {isPreview ? 'Annuler' : 'Miniature'}
            </Button>
          )}
          {!isPreview
            && (
            <Button
              fullWidth
              onClick={() => {
                setIsDelete(!isDelete);
                setIsPreview(false);
              }}
              variant="outlined"
              size="small"
              color="error"
              sx={{
                textTransform: 'none',
              }}
            >
              {isDelete ? 'Annuler' : 'Supprimer'}
            </Button>
            )}
        </ButtonGroup>
        )}
      <Box marginTop={1}>
        {isPreview
        && (
        <Typography variant="body2" fontWeight="bold" fontStyle="italic">
          ⚠️ Cliquez sur une des photos miniatures pour qu‘elle deviennent la photo principale ou appuyez sur annuler pour ne rien faire.
        </Typography>
        )}
        {isDelete
          && (
            <Typography variant="body2" fontWeight="bold" fontStyle="italic">
              ⚠️ Cliquez sur une des photos miniatures pour la supprimer ou appuyez sur annuler pour ne rien faire.
            </Typography>
          )}
      </Box>
    </Box>
  );
}

export {
  ImageGalleryContainer,
};
