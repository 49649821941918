import { useUpdateProjectOfferMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useUpdateProjectOffer = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useUpdateProjectOfferMutation(headerWithAuth, options);

  return mutate;
};

export {
  useUpdateProjectOffer,
};
