import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

function Loader({ size = 80 }:any) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
      <CircularProgress size={size} />
    </Box>
  );
}
export { Loader };
