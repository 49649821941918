import { format } from 'date-fns';
import {
  genderOptions, tabBankOptions, tabCountryOptions, weddingPlanOptions,
} from '../../../../../data/data';

const data = (customers: any) => [{
  data: ['Genre', ...customers?.map((e: any) => genderOptions?.find((f) => f.value === e?.personal_info?.gender)?.label || '')],
}, {
  data: ['Nom', ...customers?.map((e: any) => e?.personal_info?.last_name || '')],
}, {
  data: ['Prénom', ...customers?.map((e: any) => e?.personal_info?.first_name || '')],
}, {
  data: ['Date de naissance', ...customers?.map((e: any) => format(new Date(e?.personal_info?.birthdate), 'dd/MM/yyyy' || ''))],
}, {
  data: ['Lieu de naissance', ...customers?.map((e: any) => e?.personal_info?.place_of_birth || '')],
}, {
  data: ['Nationalité', ...customers?.map((e: any) => tabCountryOptions?.find((f) => f.value === e?.personal_info?.nationality)?.label || '')],
}, {
  data: ['Adresse postale', ...customers?.map((e: any) => e?.personal_info?.street_address?.address_line_1 || '')],
}, {
  data: ['Mail', ...customers?.map((e: any) => e?.personal_info?.email || '')],
}, {
  data: ['Téléphone', ...customers?.map((e: any) => e?.personal_info?.phone || '')],
}, {
  data: ['Situation familiale', ...customers?.map((e: any) => (e?.personal_info?.is_married ? 'Marié' : 'Célibataire' || ''))],
}, {
  data: ['Régime matrimonial', ...customers?.map((e: any) => weddingPlanOptions?.find((f) => f.value === e?.personal_info?.wedding_plan)?.label || '')],
}, {
  data: ['Enfants à charge fiscalement', ...customers?.map((e: any) => e?.personal_info?.number_of_children || '')],
}, {
  data: ['Banque principale', ...customers?.map((e: any) => tabBankOptions?.find((f) => f.value === e?.personal_info?.main_bank)?.label || '')],
}, {
  data: ['Banque secondaire', ...customers?.map((e: any) => e?.personal_info?.other_banks?.map((bank: any) => `${tabBankOptions?.find((f) => f.value === bank)?.label} | `) || '')],
}];

const personalInfosFormatted = (customers: any) => data(customers).map((e) => (`
    <tr>
      ${e.data.map((elemData: any) => (`
      <td>
        <p style="font-weight: normal">${elemData}</p>
      </td>
    `).replaceAll(',', ''))}
    </tr>
  `).replaceAll(',', ''));

export {
  personalInfosFormatted,
};
