import {
  Box, Switch, Typography,
} from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { useSearchParams } from 'react-router-dom';
import { SectionTitle } from '../../../../common/right-column/section-title';
import { useUpdateProject } from '../../../../hooks/mutation/update-project-mutation.hook';

function Typology({ queryInfo }: any) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  return (
    <Box>
      <SectionTitle title="typologie" />
      <Box display="flex" alignItems="center">
        <LocalFireDepartmentIcon
          fontSize="small"
          color={queryInfo?.data?.getProjectById?.hotDeal ? 'warning' : 'inherit'}
        />
        <Typography
          color="warning"
          variant="body2"
          fontWeight="500"
          display="flex"
          alignItems="center"
          marginRight={2}
          marginLeft={1}
        >
          Projet prioritaire
        </Typography>
        <Switch
          checked={queryInfo?.data?.getProjectById?.hotDeal}
          size="small"
          onChange={(e, value) => {
            const formValid: any = {
              data: {
                hotDeal: value,
              },
              projectId: searchParams.get('projectId'),
            };
            mutate({ ...formValid });
          }}
        />
      </Box>
    </Box>
  );
}

export {
  Typology,
};
