import React, { useEffect, useMemo } from 'react';
import {
  Autocomplete, Box, TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { investmentMethodOptions } from '../../../../data/data';
import { useUpdateProject } from '../../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  investmentMethod: string
}

function InvestmentMethod({ queryInfo }: any) {
  const {
    control, reset, handleSubmit,
  } = useForm<IFormInput>({
    // @ts-ignore
    defaultValues: useMemo(() => {
      return {
        investmentMethod: investmentMethodOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.investmentMethod),
      };
    }, [queryInfo]),
  });

  useEffect(() => {
    reset({
    // @ts-ignore
      investmentMethod: investmentMethodOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.investmentMethod),
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      data: {
        investmentMethod: form?.investmentMethod?.value,
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
  };

  return (
    <Box
      component="form"
      onBlur={handleSubmit(onSubmit)}
    >
      <Box marginTop={3}>
        <Controller
          name="investmentMethod"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disablePortal
              blurOnSelect="mouse"
              disableClearable
              options={investmentMethodOptions}
              // @ts-ignore
              value={field.value}
              onChange={(e, value) => {
                field.onChange(value);
              }}
              renderInput={
                (params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Véhicule d'investissement"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  />
                )
              }
            />
          )}
        />
      </Box>
    </Box>
  );
}

export {
  InvestmentMethod,
};
