import {
  Box, Grid, Tab, Tabs,
} from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { RightColumn } from '../components/right-column';
import { Logs } from '../components/logs';
import { CreateProjectFromCrm } from '../components/create-project';
import { useFindClientById } from '../../../hooks/query/find-client-by-id-query.hook';

function ClientCard() {
  const [searchParams] = useSearchParams();
  const queryInfo = useFindClientById(searchParams.get('userId'));
  const [value, setValue] = useState(0);

  const tabs = [
    <FormatListBulletedIcon />,
    <AddShoppingCartIcon />,
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      paddingX={1}
      display="flex"
      width="100%"
    >
      <Box
        width="75%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <TabsHandler queryInfo={queryInfo} value={value} onChange={handleChange} tabs={tabs} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="25%"
        paddingX={1}
        sx={{ height: 'calc(100vh - 110px)' }}
      >
        <RightColumn queryInfo={queryInfo} />
      </Box>
    </Box>
  );
}

function TabsHandler({
  queryInfo, value = 0, onChange, tabs,
}: { queryInfo: any, value: number, onChange: any, tabs: any }) {
  return (
    <Box>
      <Tabs
        value={value}
        onChange={onChange}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '16px',
        }}
      >
        {tabs.map((e: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined, i: { toString: () => any; }) => (
          <Tab
            icon={e}
            key={e + i.toString()}
          />
        ))}
      </Tabs>
      <Grid
        item
        marginTop={3}
        sx={{
          height: 'calc(100vh - 182px)',
          borderRadius: '16px',
          overflow: 'auto',
        }}
      >
        {value === 0 ? queryInfo.isLoading ? null : <Logs /> : null}
        {value === 1 ? queryInfo.isLoading ? null : <CreateProjectFromCrm queryInfo={queryInfo} /> : null}
      </Grid>
    </Box>
  );
}

export {
  ClientCard,
};
