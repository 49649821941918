import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import createReport from 'docx-templates';
import { format } from 'date-fns';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { personalInfosFormatted } from './array-personal-infos';
import { incomeFormatted } from './array-income';
import { savingFormatted } from './array-saving';
import { costFormatted } from './array-cost';
import { consumerLoanFormatted } from './array-consumer-loan';
import { housingLoanFormatted } from './array-housing-loan';

function DiscoverySheet({ queryInfo }: any) {
  const saveDataToFile = async (data: string | ArrayBuffer | ArrayBufferView | Blob, fileName: string, mimeType: string) => {
    const blob = await new Blob([data], { type: mimeType });
    const url = await window.URL.createObjectURL(blob);
    await downloadURL(url, fileName, mimeType);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  };
  const downloadURL = (data: string, fileName: any, mimeType: string) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    // @ts-ignore
    // a.style = 'display: none';
    a.click();
    a.remove();
  };
  const dataById = queryInfo?.data?.getProjectById;
  const customers = queryInfo?.data?.getProjectById.customers;
  const customersLength = customers?.length;

  const formattedHeader = customers.map((e: any, i: number) => (`
      <td style="font-weight: bold">
         ${customersLength === 1 ? 'Emprunteur' : `Co-emprunteur ${i > 0 ? i : ''}`}
      </td>
  `).replaceAll(',', ''));

  const formattedVariantHeader = (`
      <td style="font-weight: bold">
        Établissement prêteur
      </td>
        <td style="font-weight: bold">
        Mensualité
      </td>
        <td style="font-weight: bold">
        Capital emprunté
      </td>
        <td style="font-weight: bold">
        Capital restant dû
      </td>
        <td style="font-weight: bold">
        Date de fin
      </td>
  `).replaceAll(',', '');

  const arrayHtml = (param: any, header: any, firstCell: any) => {
    return (`
    <html lang="EN">
    <style>
      table {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
      }
      th {
        border: 1px solid black;
        border-collapse: collapse;
      }
      td{
        border: 1px solid black;
        border-collapse: collapse;
      }
    </style>
    <body>
    <table>
      <thead>
      <tr>
        <td style="font-weight: bold">${firstCell}</td>
        ${header}
      </tr>
      </thead>
      <tbody>
      ${param}
      </tbody>
    </table>
    </body>
    </html>
  `).replaceAll(',', '');
  };

  const onTemplateChosen = async () => {
    const template = await fetch('/template.docx').then((res) => res.arrayBuffer());
    const report = await createReport({
      // @ts-ignore
      template,
      data: {
        createdAt: format(new Date(), 'dd/MM/yyyy'),
        name: dataById?.name,
        personalInfosHtml: arrayHtml(personalInfosFormatted(customers), formattedHeader, ''),
        incomeHtml: arrayHtml(incomeFormatted(customers, dataById), formattedHeader, ''),
        savingHtml: arrayHtml(savingFormatted(customers, dataById), formattedHeader, ''),
        costHtml: arrayHtml(costFormatted(customers), formattedHeader, ''),
        housingLoanHtml: arrayHtml(housingLoanFormatted(customers), formattedVariantHeader, 'Client'),
        consumerLoanHtml: arrayHtml(consumerLoanFormatted(customers), formattedVariantHeader, 'Client'),
      },
      noSandbox: true,
    });
    await saveDataToFile(
      report,
      `fiche-decouverte-emprunteur-${dataById?.name}.docx`,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    );
  };

  return (
    <>
      {customersLength > 0
        && (
          <>
            <Box width="100%">
              <Button
                fullWidth
                color="warning"
                variant="contained"
                size="small"
                onClick={() => onTemplateChosen()}
                startIcon={<SmartToyIcon />}
              >
                Fiche Emprunteur
              </Button>
            </Box>
            <Divider sx={{ marginY: 2 }} />
          </>
        )}
    </>
  );
}

export {
  DiscoverySheet,
};
