import { Box, Typography } from '@mui/material';
import React from 'react';
import { offersOptions } from '../../../../data/data';
import { SectionTitle } from '../../../../common/right-column/section-title';

function TypeformInfo({ queryInfo }: any) {
  const data = queryInfo?.data?.getProjectById?.createdBy?.typeformInfo;

  return (
    <Box>
      {(data?.employeeIncome || data?.employeesIncomes || data?.freelanceIncome || data?.freelanceIncomes || data?.savings || data?.groupSavings || data?.offer || data?.budget || data?.utmSource)
        && <SectionTitle title="Typeform" />}
      {data?.employeeIncome && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Salaire CDI seul : ${data?.employeeIncome}`}
        </Typography>
      )}
      {data?.employeesIncomes && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Salaires CDI à deux : ${data?.employeesIncomes}`}
        </Typography>
      )}
      {data?.freelanceIncome && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Revenu Indépendant seul : ${data?.freelanceIncome}`}
        </Typography>
      )}
      {data?.freelanceIncomes && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Revenus Indépendants à deux : ${data?.freelanceIncomes}`}
        </Typography>
      )}
      {data?.savings && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Epargne seul : ${data?.savings}`}
        </Typography>
      )}
      {data?.budget && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Budget : ${data?.budget}`}
        </Typography>
      )}
      {data?.groupSavings && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Epargne à deux : ${data?.groupSavings}`}
        </Typography>
      )}
      {data?.offer && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Offre : ${offersOptions?.find((e) => e.value === data?.offer)?.label}`}
        </Typography>
      )}
      {data?.utmSource && (
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {`Source : ${data?.utmSource}`}
        </Typography>
      )}
    </Box>
  );
}

export {
  TypeformInfo,
};
