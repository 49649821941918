import { Box, Typography } from '@mui/material';
import React from 'react';

function SectionTitle({ title, length, value }: any) {
  return (
    <Box width="100%" display="flex" justifyContent="space-between" marginBottom={1}>
      {title && <Typography sx={{ textTransform: 'uppercase' }} align="left" variant="body2" color="primary">{`${title} :`}</Typography>}
      {length && <Typography align="left" variant="body2" color="primary">{`Nb : ${length}`}</Typography>}
      {value && <Typography sx={{ textTransform: 'uppercase' }} align="left" variant="body2" color="primary">{value}</Typography>}
    </Box>
  );
}

export {
  SectionTitle,
};
