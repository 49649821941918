import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import {
  ThemeProvider, createTheme, responsiveFontSizes, useTheme,
} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import {
  blue, green, pink, red,
} from '@mui/material/colors';
import { PaletteMode } from '@mui/material';
import { frFR } from '@mui/x-data-grid';
import { frFR as corefrFR } from '@mui/material/locale';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

interface IProps {
  children?: React.ReactNode;
}

declare module '@mui/material/styles' {
  interface CustomTheme {
    variables?: {
      [key:string]: string;
    };
  }
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

function StyleProvider({ children }: IProps) {
  const [mode, setMode] = useState<PaletteMode | any>('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: string): string => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [setMode],
  );

  let theme = useMemo(
    () => createTheme({
      palette: {
        mode,
        ...(mode === 'light'
          ? {
            background: {
              default: '#eff8ff',
              paper: '#ffffff',
            },
            primary: {
              main: blue[500],
              light: '#DFF0FD',
            },
            secondary: {
              main: pink.A400,
            },
            success: {
              main: green[600],
              light: '#7BC67E',
            },
            error: {
              main: red[600],
              light: '#F88078',
            },
            info: {
              main: '#1a2836',
            },
          } : {
            background: {
              default: '#202020',
              paper: '#1f2733',
            },
            primary: {
              main: blue[500],
              light: '#DFF0FD',
            },
            secondary: {
              main: pink.A400,
            },
            success: {
              main: green[600],
              light: '#7BC67E',
            },
            error: {
              main: red[500],
              light: '#F88078',
            },
            info: {
              main: '#ffffff',
            },
          }
        ),
      },
      typography: {
        fontSize: 13,
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
    }, frFR, corefrFR),
    [mode],
  );

  theme = responsiveFontSizes(theme);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function ToggleColorMode() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode}>
      {theme.palette.mode === 'dark' ? <Brightness7Icon sx={{ color: 'white' }} /> : <Brightness4Icon sx={{ color: 'text.primary' }} />}
    </IconButton>
  );
}

export {
  ToggleColorMode,
  StyleProvider,
};
