import {
  Box, IconButton, List, ListItemText, Switch, TextField, Tooltip, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useUpdateUser } from '../../../../hooks/mutation/update-user-mutation.hook';

function CalendlyInfo({ queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const [editName, setEditName] = useState<boolean>(false);
  const [editRdv, setEditRdv] = useState<boolean>(false);
  const [calendlyName, setCalendlyName] = useState<string>('');

  // eslint-disable-next-line no-unsafe-optional-chaining
  const calendlyData = queryInfo?.data?.findClientById?.calendlyEvents[queryInfo?.data?.findClientById?.calendlyEvents?.length - 1];

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateUser({
    onSuccess: () => {
      queryInfo.refetch();
      setCalendlyName('');
      setEditRdv(false);
      setEditName(false);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  return (
    <Box>
      <Box width="100%" display="flex" justifyContent="space-between" marginTop={3} marginBottom={1}>
        <Typography align="left" variant="body2" color="primary">INFOS CALENDLY :</Typography>
      </Box>
      <List>
        <ListItemText
          secondaryTypographyProps={{ component: 'div' }}
          primary={<Typography variant="body2" fontWeight="500">Nom</Typography>}
          secondary={(
            <Box display="flex" alignItems="center">
              <Typography align="left" variant="body2" color="text.secondary">
                {calendlyData?.name ? calendlyData?.name : '...'}
              </Typography>
              <Tooltip title={!editName ? 'Editer nom calendly' : 'Annuler'}>
                <IconButton
                  onClick={() => {
                    setEditName(!editName);
                    setEditRdv(false);
                  }}
                  size="small"
                  sx={{ marginLeft: 1 }}
                >
                  {!editName ? <EditIcon fontSize="inherit" /> : <EditOffIcon fontSize="inherit" />}
                </IconButton>
              </Tooltip>
              {editName
                && (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <TextField
                      size="small"
                      label="Nouveau nom"
                      value={calendlyName}
                      onChange={(e) => {
                        setCalendlyName(e.target.value);
                      }}
                      sx={{ marginLeft: 1 }}
                    />
                    <Tooltip title="Enregistrer nouveau nom calendly">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          const formValid: any = {
                            data: {
                              calendlyName,
                            },
                            userId: searchParams.get('userId'),
                          };
                          mutate({ ...formValid });
                        }}
                        size="small"
                        sx={{ marginLeft: 1 }}
                      >
                        <SaveIcon
                          fontSize="inherit"
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
            </Box>
          )}
        />
      </List>
      <List>
        <ListItemText
          secondaryTypographyProps={{ component: 'div' }}
          primary={(
            <Typography variant="body2" fontWeight="500" height="24px" display="flex" alignItems="center">
              Rendez-vous Calendly
            </Typography>
          )}
          secondary={(
            queryInfo?.data?.findClientById?.first_event_taken !== undefined
            && (
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  {queryInfo?.data?.findClientById?.first_event_taken
                    ? <CheckIcon color="success" fontSize="small" />
                    : <CloseIcon color="error" fontSize="small" />}
                </Box>
                <Tooltip title={!editRdv ? 'Editer nom rendez-vous' : 'Annuler'}>
                  <IconButton
                    onClick={() => {
                      setEditRdv(!editRdv);
                      setEditName(false);
                    }}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  >
                    {!editRdv ? <EditIcon fontSize="inherit" /> : <EditOffIcon fontSize="inherit" />}
                  </IconButton>
                </Tooltip>
                { editRdv
                  && (
                    <Box>
                      <Switch
                        size="small"
                        checked={queryInfo?.data?.findClientById?.first_event_taken}
                        onChange={(e, value) => {
                          const formValid: any = {
                            data: {
                              first_event_taken: value,
                            },
                            userId: searchParams.get('userId'),
                          };
                          mutate({ ...formValid });
                        }}
                      />
                    </Box>
                  )}
              </Box>
            )
          )}
        />
      </List>
      <List>
        <ListItemText
          primary={<Typography variant="body2" fontWeight="500">Date de rdv</Typography>}
          secondary={(
            <Typography align="left" variant="body2" color="text.secondary">
              {calendlyData?.start_time ? format(new Date(calendlyData?.start_time), 'dd/MM/yyyy HH:mm') : '...'}
            </Typography>
          )}
        />
      </List>
      <List sx={{ width: '100%' }}>
        <ListItemText
          primary={<Typography variant="body2" fontWeight="500">Réponse Calendly</Typography>}
          secondary={(
            <Typography align="left" variant="body2" color="text.secondary">
              {calendlyData?.questions_and_answers[0]?.answer ? calendlyData?.questions_and_answers[0]?.answer : '...'}
            </Typography>
          )}
        />
      </List>
    </Box>
  );
}

export {
  CalendlyInfo,
};
