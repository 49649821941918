import React from 'react';
import {
  Box,
  Container, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Droppable } from 'react-beautiful-dnd';
import { Task } from './task';
import { AllowedKanbanType } from '../../api';
import {
  appointmentTypeVariantOptions,
  propertyStatusOptions,
  statusOptions,
} from '../../data/data';

interface Iprops {
  tasks: {
    id: string,
    title: string,
    content: any
  }[],
  column: {
    id: string,
    title: string,
    taskIds: string[]
  },
  kanbanType: any
}

function Column({ column, tasks, kanbanType }: Iprops) {
  const selectColumnTitle = () => {
    switch (kanbanType) {
      case AllowedKanbanType.PROJECTS:
        return (
          <Box margin="0 8px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold" variant="body2">{statusOptions?.find((f) => f.value === column?.title)?.label}</Typography>
            <Typography fontWeight="bold" color="primary">{tasks?.length}</Typography>
          </Box>
        );
      case AllowedKanbanType.PROPERTIES:
        return (
          <Box margin="0 8px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold" variant="body2">{propertyStatusOptions?.find((f) => f.value === column?.title)?.label}</Typography>
            <Typography fontWeight="bold" color="primary">{tasks?.length}</Typography>
          </Box>
        );
      case AllowedKanbanType.SALES:
        return (
          <Box margin="0 8px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold" variant="body2">{appointmentTypeVariantOptions?.find((f) => f.value === column?.title)?.label}</Typography>
            <Typography fontWeight="bold" color="primary">{tasks?.length}</Typography>
          </Box>
        );
      default:
        return 'title';
    }
  };

  return (
    <Container sx={{
      minWidth: '250px',
      width: '250px',
      margin: '0 10px',
      padding: '10px',
      border: '3px solid',
      borderColor: 'background.paper',
      borderRadius: '8px',
      backgroundColor: 'background.default',
      display: 'flex',
      maxHeight: '85vh',
      flexDirection: 'column',
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
    }}
    >
      <Typography
        textAlign="center"
        variant="body2"
        fontWeight="500"
        sx={{
          marginBottom: 1,
        }}
      >
        {selectColumnTitle()}
      </Typography>
      <Droppable
        droppableId={column?.id}
        key={column?.id}
      >
        {(provided) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tasks?.map((task, index) => (
              <Task key={task?.id} task={task} index={index} kanbanType={kanbanType} />
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
}

const TaskList = styled('div')(() => ({
  padding: '8px 0',
  transition: 'background-color 0.2s ease',
  flexGrow: '1',
  width: '100%',
  overflow: 'auto',
}));

export {
  Column,
};
