import React, { useEffect, useState } from 'react';
import {
  CSSObject, styled, Theme, useTheme,
} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Autocomplete, Chip, TextField, Typography,
} from '@mui/material';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { ToggleColorMode } from '../../style/style';
import { MenuBottom, MenuTop, TitlePage } from './layoutconfig';
import { SearchBar } from './searchbar';
import { kanbanTypeOptions } from '../../data/data';
import { AllowedKanbanType } from '../../api';
import logo_lsi_black from '../../assets/logo/logo_lsi_black.png';
import logo_lsi_white from '../../assets/logo/logo_lsi_white.png';
import { useAlgolia } from '../../hooks/search/algolia';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 1),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: 'calc(100% - 57px)',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type IProps = {
  children?: React.ReactNode;
};

function Layout({ children }: IProps) {
  const { searchClient, indexName } = useAlgolia();
  const [_, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [tmpParams, setTmpParams] = useState<string | undefined | any>('');
  const [pageSelected, setPageSelected] = useState('/');

  const [kanbanType, setKanbanType] = useState({
    value: AllowedKanbanType?.PROJECTS,
    label: 'Projets',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const firstArgsInUrl = location.pathname?.split('/')[1];
  const secondArgsInUrl = location.pathname?.split('/')[2];

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if ((firstArgsInUrl === 'project-list' || firstArgsInUrl === 'property-list') && !secondArgsInUrl) {
      setTmpParams(`?${searchParams}`);
    }
  }, [firstArgsInUrl, searchParams, secondArgsInUrl]);

  useEffect(() => {
    if (location.pathname === '/sales') {
      if (kanbanType?.value !== searchParams.get('kanbanType')) {
        setSearchParams({ kanbanType: kanbanType?.value });
      }
    }
  }, [kanbanType?.value, location.pathname, searchParams, setSearchParams]);

  const goToPreviousPage = () => {
    switch (secondArgsInUrl) {
      case 'projectcard':
        return `/project-list${tmpParams}`;
      case 'propertycard':
        return `/property-list${tmpParams}`;
      case 'projectmanagementcard':
        return `/project-management-list${tmpParams}`;
      default:
        return `/project-list${tmpParams}`;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          boxShadow: 'none',
          color: 'text.primary',
        }}
      >
        <Toolbar sx={{
          display: 'flex',
          backdropFilter: 'blur(6px)',
          backgroundColor: 'background.paper',
          paddingLeft: '0 !important',
          width: '100%',
          justifyContent: 'space-between',
        }}
        >
          <Box display="flex">
            <InstantSearch searchClient={searchClient} indexName={indexName}>
              <SearchBar />
            </InstantSearch>
            {(secondArgsInUrl === 'projectcard' || firstArgsInUrl === 'clientcard' || secondArgsInUrl === 'propertycard' || secondArgsInUrl === 'projectmanagementcard')
              && (
                <Typography
                  onClick={() => navigate(goToPreviousPage())}
                  variant="body2"
                  color="primary"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ArrowLeftIcon />
                  Retour à la liste
                </Typography>
              )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {location.pathname === '/sales'
              && (
                <Box display="flex" alignItems="center">
                  {kanbanType?.value === AllowedKanbanType.SALES
                    && (
                      <Box marginRight={2}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2" fontWeight="bold">
                            Statuts Filtrés :
                          </Typography>
                          <Chip label="Nouveau Lead" size="small" sx={{ marginX: 2, fontWeight: 'bold' }} />
                          <Chip label="Prospect" size="small" sx={{ marginRight: 2, fontWeight: 'bold' }} />
                          <Chip label="🏠 En attente de Bien" size="small" sx={{ fontWeight: 'bold' }} />
                        </Box>
                      </Box>
                    )}
                  <Box width={150} marginRight={3}>
                    <Autocomplete
                      disablePortal
                      blurOnSelect="mouse"
                      disableClearable
                      options={kanbanTypeOptions}
                      value={kanbanType}
                      onChange={(e, value) => {
                        setSearchParams({ kanbanType: value?.value });
                        setKanbanType(value);
                      }}
                      renderInput={
                        (params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Type Kanban"
                            fullWidth
                            variant="outlined"
                            color="primary"
                          />
                        )
                      }
                    />
                  </Box>
                </Box>
              )}
            <TitlePage pageSelected={pageSelected} />
            <ToggleColorMode />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          div: {
            borderRight: 'none',
          },
        }}
      >
        <DrawerHeader sx={{ justifyContent: !open ? 'center' : null }}>
          {open ? (
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <img
                  style={{
                    marginLeft: '16px',
                    width: 100,
                    objectFit: 'cover',
                  }}
                  src={theme?.palette?.mode === 'light' ? logo_lsi_black : logo_lsi_white}
                />
              </Box>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 800,
          marginTop: 3,
        }}
        >
          <List>
            <MenuTop setPageSelected={setPageSelected} />
          </List>
          <List>
            <MenuBottom open={open} />
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          width: '100%',
          backgroundColor: 'background.paper',
          paddingRight: '20px',
        }}
      >
        <Box
          sx={{
            borderRadius: '20px 20px 0 0',
            backgroundColor: 'background.default',
            width: '100%',
            marginTop: '27px',
            minHeight: 'calc(100vh - 64px)',
          }}
        >
          <DrawerHeader sx={{ height: 0 }} />
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export {
  Layout,
};
