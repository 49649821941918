import { useFindAllBusinessProvidersQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useFindAllBusinessProviders = () => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useFindAllBusinessProvidersQuery(headerWithAuth, {}, { enabled: !!accessToken });

  return queryInfo;
};

export {
  useFindAllBusinessProviders,
};
