import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import { DisplayValue } from '../../pages';
import { BooleanWithIcon } from '../../../../common/inputs/booleanwithicon';
import { useUpdateProperty } from '../../../../hooks/mutation/update-property-mutation.hook';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { euro } from '../../../../common/inputs/format/euro-format';

interface IFormInput {
  plumbing: boolean,
  plumbingPrice: string,
  plastering: boolean
  plasteringPrice: string,
  walls: boolean
  wallsPrice: string,
  furnitureOnDemand: boolean
  furnitureOnDemandPrice: string,
  elevation: boolean
  elevationPrice: string,
  electricity: boolean
  electricityPrice: string,
  woodWork: boolean
  woodWorkPrice: string,
  floors: boolean
  floorsPrice: string,
  roofing: boolean
  roofingPrice: string,
  facilities: boolean
  facilitiesPrice: string,
}

function BuildingTrade({ data, queryInfo }: any) {
  const [toModify, setToModify] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      // @ts-ignore
      return {
        ...data?.renovation?.professionNeeded,
      };
    }, [data, searchParams.get('propertyId')]),
  });

  useEffect(() => {
    reset({
      ...data?.renovation?.professionNeeded,
    });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        renovation: {
          professionNeeded: {
            plumbing: form?.plumbing,
            plumbingPrice: !watch('plumbing') ? null : Number(form?.plumbingPrice),
            plastering: form?.plastering,
            plasteringPrice: !watch('plastering') ? null : Number(form?.plasteringPrice),
            walls: form?.walls,
            wallsPrice: !watch('walls') ? null : Number(form?.wallsPrice),
            furnitureOnDemand: form?.furnitureOnDemand,
            furnitureOnDemandPrice: !watch('furnitureOnDemand') ? null : Number(form?.furnitureOnDemandPrice),
            elevation: form?.elevation,
            elevationPrice: !watch('elevation') ? null : Number(form?.elevationPrice),
            electricity: form?.electricity,
            electricityPrice: !watch('electricity') ? null : Number(form?.electricityPrice),
            woodWork: form?.woodWork,
            woodWorkPrice: !watch('woodWork') ? null : Number(form?.woodWorkPrice),
            floors: form?.floors,
            floorsPrice: !watch('floors') ? null : Number(form?.floorsPrice),
            roofing: form?.roofing,
            roofingPrice: !watch('roofing') ? null : Number(form?.roofingPrice),
            facilities: form?.facilities,
            facilitiesPrice: !watch('facilities') ? null : Number(form?.facilitiesPrice),
          },
        },
      },
    };
    mutate({ ...formValid });
    setToModify(false);
  };

  const checkValue = (value: boolean | null, price: any) => {
    return value ? `✔ ${price === 0 ? '' : euro.format(price) || null}` : <ClearIcon fontSize="small" color="error" />;
  };

  const professionNeeded = data?.renovation?.professionNeeded;

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
          <Typography color="primary" variant="h5" marginBottom={2}>Corps de métier à solliciter</Typography>
          <Grid container item columns={3} spacing={3}>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="plumbing"
                        watch={watch}
                        label="Plomberie"
                        radiusBottom={watch('plumbing')}
                      />
                      {watch('plumbing')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="plumbingPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Plomberie"
                      value={professionNeeded?.plumbingPrice > 0 ? euro.format(professionNeeded?.plumbingPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.plumbing} />
                    </DisplayValue>
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="plastering"
                        watch={watch}
                        label="Travaux d'isolation"
                        radiusBottom={watch('plastering')}
                      />
                      {watch('plastering')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="plasteringPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Travaux d'isolation"
                      value={professionNeeded?.plasteringPrice > 0 ? euro.format(professionNeeded?.plasteringPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.plastering} />
                    </DisplayValue>
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="walls"
                        watch={watch}
                        label="Revêtements muraux"
                        radiusBottom={watch('walls')}
                      />
                      {watch('walls')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="wallsPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Revêtements muraux"
                      value={professionNeeded?.wallsPrice > 0 ? euro.format(professionNeeded?.wallsPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.walls} />
                    </DisplayValue>
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="furnitureOnDemand"
                        watch={watch}
                        label="Mobilier sur mesure"
                        radiusBottom={watch('furnitureOnDemand')}
                      />
                      {watch('furnitureOnDemand')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="furnitureOnDemandPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Mobilier sur mesure"
                      value={professionNeeded?.furnitureOnDemandPrice > 0 ? euro.format(professionNeeded?.furnitureOnDemandPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.furnitureOnDemand} />
                    </DisplayValue>
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="elevation"
                        watch={watch}
                        label="Extension / surélévation"
                        radiusBottom={watch('elevation')}
                      />
                      {watch('elevation')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="elevationPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Extension / surélévation"
                      value={professionNeeded?.elevationPrice > 0 ? euro.format(professionNeeded?.elevationPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.elevation} />
                    </DisplayValue>
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="electricity"
                        watch={watch}
                        label="Électricité"
                        radiusBottom={watch('electricity')}
                      />
                      {watch('electricity')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="electricityPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Électricité"
                      value={professionNeeded?.electricityPrice > 0 ? euro.format(professionNeeded?.electricityPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.electricity} />
                    </DisplayValue>
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="woodWork"
                        watch={watch}
                        label="Menuiseries extérieures"
                        radiusBottom={watch('woodWork')}
                      />
                      {watch('woodWork')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="woodWorkPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Menuiseries extérieures"
                      value={professionNeeded?.woodWorkPrice > 0 ? euro.format(professionNeeded?.woodWorkPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.woodWork} />
                    </DisplayValue>
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="floors"
                        watch={watch}
                        label="Revêtements de sol"
                        radiusBottom={watch('floors')}
                      />
                      {watch('floors')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="floorsPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Revêtements de sol"
                      value={professionNeeded?.floorsPrice > 0 ? euro.format(professionNeeded?.floorsPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.floors} />
                    </DisplayValue>
                  )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="roofing"
                        watch={watch}
                        label="Toiture"
                        radiusBottom={watch('roofing')}
                      />
                      {watch('roofing')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="roofingPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Toiture"
                      value={professionNeeded?.roofingPrice > 0 ? euro.format(professionNeeded?.roofingPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.roofing} />
                    </DisplayValue>
                  )}
              </Box>
              <Box paddingY={1}>
                {toModify
                  ? (
                    <>
                      <BooleanWithIcon
                        control={control}
                        name="facilities"
                        watch={watch}
                        label="Mobilier et équipements"
                        radiusBottom={watch('facilities')}
                      />
                      {watch('facilities')
                        && (
                          <CurrencyTextFieldCustom
                            control={control}
                            name="facilitiesPrice"
                            label="Prix"
                            variant="standard"
                            buildingTrade
                          />
                        )}
                    </>
                  ) : (
                    <DisplayValue
                      title="Mobilier et équipements"
                      value={professionNeeded?.facilitiesPrice > 0 ? euro.format(professionNeeded?.facilitiesPrice) : ' '}
                    >
                      <Condition item={professionNeeded?.facilities} />
                    </DisplayValue>
                  )}
              </Box>
            </Grid>
          </Grid>
          {toModify
            ? (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  sx={{ marginRight: '10px', fontWeight: 'bold', textTransform: 'none' }}
                  onClick={() => setToModify(false)}
                  color="error"
                  type="reset"
                  variant="contained"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<SaveIcon />}
                >
                  Enregistrer
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setToModify(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<EditIcon />}
                >
                  Modifier
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

function Condition({ item }: any) {
  return (
    <>
      {item
        ? <CheckIcon sx={{ marginRight: '3px' }} fontSize="small" color="success" />
        : <ClearIcon sx={{ marginRight: '3px' }} fontSize="small" color="error" />}
    </>
  );
}

export {
  BuildingTrade,
};
