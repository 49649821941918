import { Box, styled } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

function RichTextCustom({ control, name }: any) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
      }) => {
        return (
          <Box width="100%">
            <RichText
              value={field.value || ''}
              onChange={(e: any) => field.onChange(e.target.value)}
              placeholder="Écrivez ici ..."
            />
          </Box>
        );
      }}
    />
  );
}

export {
  RichTextCustom,
};

const RichText = styled('textarea')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  height: '85px',
  padding: '0px',
  display: 'flex',
  paddingLeft: '14px',
  paddingRight: '14px',
  paddingTop: '14px',
  paddingBottom: '14px',
  width: '100%',
  fontSize: '14px',
  letterSpacing: '2px',
  color: theme.palette.text.primary,
  resize: 'none',
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  ':focus-visible ': {
    outline: 'none',
  },
  fontFamily: [
    'Poppins',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
}));
