import React from 'react';
import {
  Box,
} from '@mui/material';
import { RenovationItem } from './renovationitem';
import { BuildingTrade } from './building-trade';

function Renovation({ queryInfo } : any) {
  const data = queryInfo?.data?.getPropertiesById;
  return (
    <Box>
      <RenovationItem data={data} queryInfo={queryInfo} />
      <BuildingTrade data={data} queryInfo={queryInfo} />
    </Box>
  );
}

export {
  Renovation,
};
