import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { DragDropContext, DragUpdate } from 'react-beautiful-dnd';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Column } from '../components/column';
import { AllowedKanbanType, useGetKanbanQuery } from '../../api';
import { useGetAccessToken } from '../../hooks/auth/get-access-token.hook';
import { useUpdateProjectStatus } from '../../hooks/mutation/update-project-status-mutation.hook';
import { useUpdateProperty } from '../../hooks/mutation/update-property-mutation.hook';

function Sales() {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const [initialData, setInitialData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [kanbanType, setKanbanType] = useState(AllowedKanbanType.PROJECTS);
  const queryClient = useQueryClient();

  useEffect(() => {
    // @ts-ignore
    setKanbanType(searchParams.get('kanbanType'));
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('kanbanType')) {
      setSearchParams({ kanbanType: AllowedKanbanType.PROJECTS });
    }
  });

  const queryInfo = useGetKanbanQuery(headerWithAuth, {
    data: {
      type: kanbanType,
    },
  }, { enabled: !!accessToken && !!searchParams.get('kanbanType') && !!kanbanType });

  const key = useGetKanbanQuery.getKey({
    data: {
      type: kanbanType,
    },
  });

  useEffect(() => {
    if (queryInfo?.data?.getKanban) {
      setInitialData(queryInfo?.data?.getKanban);
    }
  }, [queryInfo]);

  const { enqueueSnackbar } = useSnackbar();

  const mutateProjects = useUpdateProjectStatus({
    onSuccess: async () => {
      await queryClient.invalidateQueries(key);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  // @ts-ignore
  const mutateProperties = useUpdateProperty({
    onSuccess: async () => {
      await queryClient.invalidateQueries(key);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onDragEnd = (result: DragUpdate) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    if (kanbanType === AllowedKanbanType.PROJECTS) {
      const formValid: any = {
        status: destination?.droppableId,
        projectId: draggableId,
      };
      mutateProjects({ ...formValid });
    }

    if (kanbanType === AllowedKanbanType.PROPERTIES) {
      const formValid: any = {
        data: {
          status: destination?.droppableId,
        },
        propertyId: draggableId,
      };
      mutateProperties({ ...formValid });
    }

    const start = initialData.columns[source.droppableId];
    const finish = initialData.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...initialData,
        columns: {
          ...initialData.columns,
          [newColumn.id]: newColumn,
        },
      };

      setInitialData(newState);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...initialData,
      columns: {
        ...initialData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setInitialData(newState);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'calc(100vh - 96px)',
      }}
    >
      {queryInfo.isLoading || !queryInfo.isSuccess
        ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <CircularProgress size={80} />
          </Box>
        ) : (
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                overflow: 'auto',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              {initialData?.columnOrder?.map((columnId: any) => {
                const column = initialData?.columns[columnId];
                const tasks = column?.taskIds?.map((taskId: any) => initialData?.tasks[taskId]);
                return (
                  <Column kanbanType={kanbanType} key={column?.id} column={column} tasks={tasks} />
                );
              })}
            </Box>
          </DragDropContext>
        )}
    </Box>
  );
}

export {
  Sales,
};
