import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Grid, Paper, Box, Button, Tooltip, Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import {
  green, grey, red, yellow,
} from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { ProjectOutputEdge } from '../../../api/infinite_scroll_index';
import { HeaderList } from '../components/header-list';
import { useInfiniteGetAllProjects } from '../../../hooks/query/infinite-get-all-projects-query.hook';
import { Loader } from '../../../common/components/loader';

function ProjectsManagementList() {
  const [pageIndex, setPageIndex] = useState(0);
  const [rowPerPageOptions, setRowPerPageOptions] = useState<{ value: number, label: string }>({
    value: 100,
    label: '100',
  });
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const [queryParamsValue, setQueryParamsValue] = useState({});

  const freshFilter = Object?.fromEntries(Object?.entries(queryParamsValue)?.filter(([_, v]) => {
    // @ts-ignore
    return v !== null && v?.length !== 0;
  }));

  useEffect(() => {
    setPageIndex(0);
  }, [rowPerPageOptions]);

  const isDarkTheme = useTheme().palette.mode === 'dark';

  const colorBackgroundCell = {
    red: isDarkTheme ? red[500] : red[50],
    green: isDarkTheme ? green[500] : green[50],
    yellow: isDarkTheme ? yellow[900] : yellow[50],
    grey: isDarkTheme ? grey[700] : grey[200],
  };

  const {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetAllProjects({
    args: { first: rowPerPageOptions?.value },
    filters: {
      ...freshFilter,
    },
  }, rowPerPageOptions);

  // eslint-disable-next-line no-shadow
  function createRow(data: ProjectOutputEdge[]) {
    return data && data?.map((e: any) => ({
      ...e?.node,
      id: e?.node?._id,
      identifier: 'L032-ARCUEIL-21 avenue Jeanne d\'Arc',
      type: 'LSI',
      customer: 'Steeven Lambert',
      state: 'En cours',
      compromise: '',
      authenticAct: '01/12/23',
      geo: '0',
      aps: 'Attente client',
      pcdp: '11/07/22',
      dce: 'Valid. carrelage',
      signedQuote: '30/09/22',
      launchingThework: '29/08/22',
      cr1: 'N/A',
      cr2: 'N/A',
      cr3: 'N/A',
      dealers: 'Enedis (ok signé) + fibre',
      endOfWork: 'Paiement ?',
      furniture: '',
      customerDelivery: '',
      comments: 'En attente confirmation Souheil',
    }));
  }

  const numberProjects = data?.pages[0]?.getAllProjects?.pageData?.count;

  const columns: GridColDef[] = [{
    field: 'identifier',
    headerName: 'Indicatif',
    width: 300,
    renderCell: (params) => {
      return (
        <RenderCell
          color={colorBackgroundCell?.grey}
          value={params.value}
          bold
          to="/project-management-list/projectmanagementcard"
          disabledTooltip={false}
        />
      );
    },
  }, {
    field: 'type',
    headerName: 'Type',
    width: 150,
    renderCell: (params) => {
      return (
        <RenderCell
          color={colorBackgroundCell?.yellow}
          value={params.value}
        />
      );
    },
  }, {
    field: 'customer',
    headerName: 'Client',
    width: 150,
    renderCell: (params) => {
      return (
        <RenderCell
          color={colorBackgroundCell?.green}
          value={params.value}
        />
      );
    },
  }, {
    field: 'state',
    headerName: 'Etat',
    width: 150,
    renderCell: (params) => {
      return (
        <RenderCell
          color={colorBackgroundCell?.red}
          value={params.value}
        />
      );
    },
  }, {
    field: 'compromise',
    headerName: 'Compromis',
    width: 150,
  }, {
    field: 'authenticAct',
    headerName: 'Acte authentique',
    width: 150,
  }, {
    field: 'geo',
    headerName: 'GEO',
    width: 150,
  }, {
    field: 'aps',
    headerName: 'APS',
    width: 150,
  }, {
    field: 'pcdp',
    headerName: 'PC / DP',
    width: 150,
  }, {
    field: 'dce',
    headerName: 'DCE',
    width: 150,
  }, {
    field: 'signedQuote',
    headerName: 'Devis signé',
    width: 150,
  }, {
    field: 'launchingThework',
    headerName: 'Lancement travaux',
    width: 150,
  }, {
    field: 'cr1',
    headerName: 'CR1',
    width: 150,
  }, {
    field: 'cr2',
    headerName: 'CR2',
    width: 150,
  }, {
    field: 'cr3',
    headerName: 'CR3',
    width: 150,
  }, {
    field: 'dealers',
    headerName: 'Concessionnaires',
    width: 150,
  }, {
    field: 'endOfWork',
    headerName: 'Fin de travaux',
    width: 150,
  }, {
    field: 'furniture',
    headerName: 'Meubles',
    width: 150,
  }, {
    field: 'customerDelivery',
    headerName: 'Livraison client',
    width: 150,
  }, {
    field: 'comments',
    headerName: 'Commentaires',
    width: 300,
  }];

  // eslint-disable-next-line no-unsafe-optional-chaining
  const totalPages = data ? Math.trunc(data?.pages[0]?.getAllProjects.pageData.count / rowPerPageOptions?.value) : 0;

  return (
    <Grid container item columns={1} spacing={2} paddingX={2}>
      <HeaderList
        numberProjects={numberProjects}
        selectionModel={selectionModel}
        isSuccess={isSuccess}
        rowPerPageOptions={rowPerPageOptions}
        setRowPerPageOptions={setRowPerPageOptions}
        queryParamsValue={queryParamsValue}
        setQueryParamsValue={setQueryParamsValue}
      />
      <Grid item xs={1}>
        <Paper elevation={0} sx={{ borderRadius: '16px', padding: 2, width: '100%' }}>
          <Box sx={{ height: 'calc(100vh - 340px)', width: '100%' }}>
            {
              !isFetching && isSuccess
                ? (
                  <DataGrid
                    rows={createRow(data?.pages[pageIndex]?.getAllProjects?.page?.edges)}
                    columns={columns}
                    pageSize={rowPerPageOptions?.value}
                    density="compact"
                    hideFooter
                    onSelectionModelChange={(e: any) => {
                      setSelectionModel(e);
                    }}
                    loading={isFetching && isSuccess}
                    rowsPerPageOptions={[rowPerPageOptions?.value]}
                    sx={{
                      border: 'none',
                    }}
                  />
                ) : <Loader />
            }
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop={2}>
            <Button
              type="button"
              disabled={pageIndex === 0}
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              sx={{
                marginRight: 3,
                width: 150,
              }}
              onClick={() => {
                setPageIndex(() => pageIndex - 1);
              }}
            >
              Précédent
            </Button>
            <Button
              variant="contained"
              disabled={data && pageIndex === totalPages}
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                marginRight: 3,
                width: 150,
              }}
              onClick={() => {
                if (hasNextPage) {
                  fetchNextPage();
                }
                setPageIndex(() => pageIndex + 1);
              }}
            >
              Suivant
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

function RenderCell({
  color, value, bold = false, to = null, disabledTooltip = true,
}: any) {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      bgcolor={color}
      width="100%"
      height="98%"
      paddingX={0.5}
      onClick={() => to && navigate(to)}
      sx={{
        cursor: to ? 'pointer' : 'inherit',
      }}
    >
      <Tooltip title={value || ''} disableHoverListener={!!disabledTooltip}>
        <Typography
          fontWeight={bold ? 'bold' : '500'}
          variant="body2"
          color="text.primary"
          sx={{
            display: 'flex',
            alignItems: 'center',
            transition: 'color 0.1s',
            ':hover': {
              color: to ? 'primary.main' : 'inherit',
            },
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    </Box>
  );
}

export {
  ProjectsManagementList,
};
