import Box from '@mui/material/Box';
import React from 'react';
import {
  Grid,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Typography,
} from '@mui/material';
import { TextFieldCustom } from '../../../../common/inputs/textfieldcustom';
import { CurrencyTextFieldCustom } from '../../../../common/inputs/currencytextfieldcustom';
import { AllowedEmploymentContract } from '../../../../api';
import { IncomeOthers } from './incomeothers';
import { euro } from '../../../../common/inputs/format/euro-format';

function Income({
  data, modify, control,
}: any) {
  return (
    <Box>
      <Typography paddingLeft={1} variant="h5" color="primary">Revenus</Typography>
      <Grid container item spacing={3} columns={2} display="flex" width="100%" paddingY={1}>
        <Grid item xs={1}>
          <Box paddingY={1}>
            <CurrencyTextFieldCustom
              control={control}
              name="amount_wages"
              read={!modify}
              label={data?.personal_info?.employment_contract === AllowedEmploymentContract.CDI ? 'Salaire mensuel' : 'Revenus annuels nets'}
            />
          </Box>
          <Box paddingY={1}>
            <TextFieldCustom
              type="number"
              control={control}
              read={!modify}
              name="number_real_estate"
              label="Nombre de biens en location"
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box paddingY={1}>
            <CurrencyTextFieldCustom
              control={control}
              read={!modify}
              name="amount_financial_products"
              label="Revenus financiers total annuel"
            />
          </Box>
          <Box paddingY={1}>
            <CurrencyTextFieldCustom
              read={!modify}
              control={control}
              name="amount_real_estate"
              label="Montant de vos revenus locatifs annuels"
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography marginLeft={1} marginBottom={1} textAlign="left" variant="h6" color="text.primary">Autres revenus</Typography>
          {
            modify ? <IncomeOthers />
              : (
                <Box marginBottom={2}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width={200}>Type de revenu</TableCell>
                          <TableCell width={200}>Montant</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.personal_finance?.income?.others?.map((row: any, i: any) => (
                          <TableRow
                            key={row + i.toString()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{row?.label}</TableCell>
                            <TableCell>{row?.amount ? euro.format(row?.amount) : null}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Typography marginTop={2} variant="body2" paddingLeft={1} textAlign="right">
                      {`Total ${euro.format(data?.personal_finance?.income?.total_others)}`}
                    </Typography>
                  </TableContainer>
                </Box>
              )
          }
        </Grid>
      </Grid>
    </Box>
  );
}

export {
  Income,
};
