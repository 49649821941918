import { Box, Typography } from '@mui/material';
import React from 'react';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { alpha } from '@mui/material/styles';

function DisplayComment({
  picto,
  mail,
  createdAt,
  comment,
  display,
}: any) {
  return (
    <Box
      bgcolor="background.paper"
      borderRadius="16px"
      paddingY={3}
      paddingX={3}
      marginY={3}
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box display="flex" height={45} justifyContent="space-between">
        <Box
          sx={{
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
          }}
          height="100%"
          width="10px"
          position="absolute"
          top={0}
          left={0}
          borderRadius="16px 0 0 16px "
        />
        <Box display="flex">
          <Box
            bgcolor="divider"
            width={45}
            height={45}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="16px"
          >
            <Typography
              variant="h5"
              sx={{ textTransform: 'uppercase' }}
              fontWeight="bold"
              color="background.paper"
            >
              {picto}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" marginLeft={1}>
            <Typography variant="body1">{mail}</Typography>
            <Typography
              variant="caption"
              color="text.secondary"
            >
              {createdAt}
            </Typography>
          </Box>
        </Box>
        <Box color="primary.main">
          <TextsmsIcon />
        </Box>
      </Box>
      <Box marginTop={2}>
        <Typography whiteSpace="pre-line" display="flex" variant="body2" color="text.secondary">
          {comment}
        </Typography>
      </Box>
      <Box
        bgcolor="divider"
        width="2px"
        height="24px"
        position="absolute"
        top="100%"
        left={80}
        display={display}
      />
    </Box>
  );
}

export {
  DisplayComment,
};
