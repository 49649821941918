import {
  Box, ToggleButton, ToggleButtonGroup, Tooltip,
} from '@mui/material';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import FlareIcon from '@mui/icons-material/Flare';
import StarRateIcon from '@mui/icons-material/StarRate';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AllowedOffer } from '../../../../api';
import { offersOptions } from '../../../../data/data';
import { SectionTitle } from '../../../../common/right-column/section-title';
import { useUpdateProjectOffer } from '../../../../hooks/mutation/update-project-offer-mutation.hook';
import { useGetLogsByProject } from '../../../../hooks/query/get-logs-by-project-query.hook';

function Offers({ queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const queryLogs = useGetLogsByProject(searchParams.get('projectId'));
  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProjectOffer({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
      queryLogs.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    // @ts-ignore
    mutate({ offer: newAlignment, projectId: searchParams.get('projectId') });
  };

  return (
    <Box flexDirection="column" display="flex" justifyContent="space-between">
      <SectionTitle
        title="offre"
        value={offersOptions.find((f) => f?.value === queryInfo?.data?.getProjectById?.offer)?.label ? offersOptions.find((f) => f?.value === queryInfo?.data?.getProjectById?.offer)?.label : 'Non défini'}
      />
      <Box display="flex" alignItems="center" justifyContent="center">
        <ToggleButtonGroup
          color="primary"
          value={queryInfo?.data?.getProjectById?.offer}
          exclusive
          fullWidth
          size="small"
          onChange={handleAlignment}
        >
          <Tooltip title="Inéligible">
            <ToggleButton
              size="small"
              disabled={queryInfo?.data?.getProjectById?.offer === AllowedOffer.INELIGIBLE}
              value={AllowedOffer.INELIGIBLE}
            >
              <CancelIcon fontSize="small" />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Etincelle">
            <ToggleButton
              size="small"
              disabled={queryInfo?.data?.getProjectById?.offer === AllowedOffer.ETINCELLE}
              value={AllowedOffer.ETINCELLE}
            >
              <FlareIcon fontSize="small" />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Envol">
            <ToggleButton
              size="small"
              disabled={queryInfo?.data?.getProjectById?.offer === AllowedOffer.ENVOL}
              value={AllowedOffer.ENVOL}
            >
              <AirplanemodeActiveIcon fontSize="small" />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Étoile">
            <ToggleButton
              size="small"
              disabled={queryInfo?.data?.getProjectById?.offer === AllowedOffer.ETOILE}
              value={AllowedOffer.ETOILE}
            >
              <StarRateIcon fontSize="small" />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
}

export {
  Offers,
};
