import {
  Box, Paper,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import 'react-image-gallery/styles/css/image-gallery.css';

function RightColumn() {
  return (
    <Item elevation={0}>
      <Box />
    </Item>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto',
}));

export {
  RightColumn,
};
