import React from 'react';
import {
  Box, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Chip,
} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
  flatTypeOptions, matchingStateOptions, propertyStatusOptions, propertyTypeOptions,
} from '../../../../data/data';
import {
  AllowedMatchingState,
} from '../../../../api';
import { useGetMatchingListByProjectId } from '../../../../hooks/query/get-matching-list-by-project-id-query.hook';

function PropertyMatched() {
  const [searchParams] = useSearchParams();
  const queryInfo = useGetMatchingListByProjectId(searchParams.get('projectId'));

  const chooseColor = (param: any) => {
    switch (param) {
      case AllowedMatchingState.ACCEPTED: {
        return 'success.main';
      }
      case AllowedMatchingState.PENDING: {
        return 'warning.main';
      }
      case AllowedMatchingState.REFUSED: {
        return 'error.main';
      }
      default: {
        return 'primary';
      }
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box width="100%" bgcolor="background.paper" borderRadius="16px" padding={3} marginBottom={2}>
        <Box display="flex" alignItems="center">
          <Typography marginRight={3} color="primary" variant="h5">🏠 Liste des biens matchés avec ce projet</Typography>
          {!queryInfo?.isSuccess
            ? <CircularProgress size={20} />
            : (
              <Chip
                label={`nb: ${queryInfo?.data?.getMatchingListByProjectId?.length || '0'}`}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
        </Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Matching</TableCell>
                <TableCell>Adresse</TableCell>
                <TableCell>Statut</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queryInfo?.data?.getMatchingListByProjectId?.map((row) => (
                <TableRow
                  key={row?._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Link
                      to={`/property-list/propertycard?propertyId=${row?.property?._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography color="primary" fontWeight="bold">
                        {row?.property?.type ? `${propertyTypeOptions?.find((f) => f.value === row?.property?.type)?.label} ${row?.property?.description?.flat_type ? `type ${flatTypeOptions?.find((f) => f.value === row?.property?.description?.flat_type)?.label}` : ''} ${row?.property?.description?.surface ? `de ${row?.property?.description?.surface}m2` : ''}` : ''}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography color={chooseColor(row.state)} fontWeight="bold">
                      {matchingStateOptions.find((f) => f.value === row?.state)?.label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {row?.property?.localisation?.street_address?.address_line_1}
                  </TableCell>
                  <TableCell>
                    {propertyStatusOptions.find((f) => f.value === row?.property?.status)?.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export {
  PropertyMatched,
};
