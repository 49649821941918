import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import { euro } from '../../../../common/inputs/format/euro-format';

function PricePerSurface({ queryInfo, simulationColor }: any) {
  const data = queryInfo?.data?.getPropertiesById?.finance;

  function calcPercentage(value1: any, value2: any) {
    const calc = (((value1 - value2) / value2) * 100).toFixed(1);
    // @ts-ignore
    const operator = calc >= 0 ? 'à peine plus' : 'moins';
    return `${Math.abs(Number(calc))}% ${operator}`;
  }

  const calcBeforeRenovation = `${calcPercentage(data?.expected_price_per_m2, data?.district_price_per_m2)} cher que les biens du quartier`;

  return (
    <Box marginBottom={2}>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography
            variant="h6"
            marginRight={1}
            fontWeight="300"
            sx={{
              textAlign: 'left',
            }}
          >
            Quartier
          </Typography>
          <Typography
            variant="h6"
            color="text.primary"
            fontWeight="400"
            sx={{
              textAlign: 'left',
            }}
          >
            {euro.format(data?.district_price_per_m2)}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          marginTop={4}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            height="100px"
            width="50%"
          >
            <Box display="flex" alignItems="center" height="40px" justifyContent="center">
              <Box paddingY={1} display="flex">
                <Typography
                  marginRight={1}
                  fontWeight="300"
                  variant="h6"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  Prix/m2 avant travaux
                </Typography>
                <Typography
                  color={simulationColor?.darkGreen}
                  fontWeight="600"
                  variant="h6"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  {euro.format(data?.expected_price_per_m2)}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" height="40px" justifyContent="center">
              <Box paddingY={1} display="flex">
                <Typography
                  marginRight={1}
                  fontWeight="300"
                  variant="h6"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  Prix/m2 après travaux
                </Typography>
                <Typography
                  color={simulationColor?.darkGreen}
                  fontWeight="600"
                  variant="h6"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  {euro.format(data?.expected_price_per_m2_after_renovation)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            bgcolor="text.primary"
            sx={{
              height: '130px',
              width: '1px',
              marginY: 0,
            }}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{
              width: '50%',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                height: '100px',
              }}
            >
              <Box height="40px" width="100%">
                <Box bgcolor={simulationColor?.pie3} paddingX={5} borderRadius={20} position="relative" paddingY={1}>
                  <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="25px"
                    height="25px"
                    bgcolor="text.primary"
                    borderRadius={30}
                    left="-5px"
                    top="-10px"
                  >
                    <Typography color="background.default" fontSize="15px">%</Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    fontWeight="400"
                    textAlign="center"
                  >
                    {calcBeforeRenovation}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export {
  PricePerSurface,
};
