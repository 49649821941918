import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function PropertiesDetails({ task, modal }: any) {
  const navigate = useNavigate();
  return (
    <Box padding="8px">
      <Box display="flex" justifyContent="space-between" paddingY={0.5}>
        <Typography
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          variant="body2"
          fontWeight="bold"
          color="primary"
        >
          {task?.title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start" paddingY={0.5}>
        <Typography fontWeight="500" color="text.primary" variant="body2">{`🏙️ ${task?.content?.localisation?.street_address?.locality}`}</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start" paddingY={0.5}>
        <Typography fontWeight="500" color="text.primary" variant="body2">{`🐣 ${task?.content?.createdAt ? format(new Date(task?.content?.createdAt), 'dd/MM/yyyy') : ''}`}</Typography>
      </Box>
      {modal
        && (
          <Box display="flex" justifyContent="center" alignItems="center" paddingY={1}>
            <Button
              onClick={() => navigate(`/property-list/propertycard?propertyId=${task?.id}`)}
              disabled={!task?.id}
              sx={{ fontWeight: 'bold' }}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Aller vers le bien
            </Button>
          </Box>
        )}
    </Box>
  );
}

export {
  PropertiesDetails,
};
